define('pcrSetupAssayItem',[
    'module',
    'backbone.marionette',
    'template!pcrSetupAssayItem',
    'underscore'
], function (
    module,
    Marionette,
    Tpl,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        ui: {
            input: 'input'
        },

        events: {
            'click': 'onClick'
        },
        className: 'assayItem',
        
        modelEvents: {
            'change':'render'
        },

        initialize: function () {
            this.listenTo(this.options.pcrSetup, 'change:currentAssay', _.bind(this.render, this));
            this.listenTo(this.options.pcrSetup, 'change:currentProtocol', _.bind(this.render, this));
        },

        serializeData: function () {
            var templateData = {};
            templateData.code = this.model.get('assay').get('code');
            templateData.wellSize = this.model.get('wells').length;
            templateData.assayId = this.model.get('assay').get('id');
            return templateData;
        },

        onRender: function () {
            if (this.options.pcrSetup.get('currentProtocol') && this.model.get('assay').get('refKitProt') !== this.options.pcrSetup.get('currentProtocol')) {
                this.ui.input.prop('disabled', true);
                this.$el.removeClass('selectable');
            } else {
                this.$el.addClass('selectable');
            }
            this.ui.input.prop('checked', this.model.get('assay') === this.options.pcrSetup.get('currentAssay'));
        },

        onClick: function () {
            if (!this.$el.hasClass('selectable')) {
                return;
            }
            if (!this.options.pcrSetup.get('currentAssay') || this.options.pcrSetup.get('currentAssay') !== this.model.get('assay')) {
                this.options.pcrSetup.set({currentProtocol: this.model.get('assay').get('refKitProt'), currentAssay: this.model.get('assay')});
            } else {
                var size = this.options.pcrSetup.get('assays').filter(_.bind(function (assay) {
                    return assay.get('refKitProt') === this.options.pcrSetup.get('currentProtocol');
                }, this)).length;
                var data = {currentAssay: null};
                if (size === 1) {
                    data.currentProtocol = null;
                }
                this.options.pcrSetup.set(data);
            }
        }
    });
});
