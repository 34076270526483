define('runReplaceAssayView',[
    'module',
    'backbone',
    'underscore',
    'jquery',
    'dialogFormView',
    'template!runReplaceAssayView',
    'commonWellSelectionView',
    'commonCancelActionView',
    'autocompleteView',
    'customBootboxMessage'
], function (
    module,
    Backbone,
    _,
    $,
    DialogFormView,
    Tpl,
    CommonWellSelectionView,
    CommonCancelActionView,
    AutocompleteView,
    CustomBootboxMessage
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },

        regions: {
            assayRegion: '.js-assay-region',
            newAssayRegion: '.js-new-assay-region',
            wellSelectionRegion: '.js-well-selection-region',
            cancelActionRegion: '.js-cancel-action-region'
        },

        fieldsToValidate: function () {
            return [
                {name: 'assay', type: 'required'},
                {name: 'newAssay', type: 'required'},
                {name: 'wellSelection', type: 'required', subView: this.wellSelectionView}
            ];
        },

        modelEvents: {
            'change:assay': 'onValidateChange',
            'change:newAssay': 'onValidateChange',
            'change:wellSelection': 'onValidateChange'
        },

        initialize: function () {
            this.model = new Backbone.Model();
            this.model.set('assay', null);
            this.model.set('newAssay', null);
            this.model.set('wellSelection', null);
        },

        onRender: function () {
            var SettingService = require('services/caccounts/setting');
            var setting = SettingService.findByCodeAsync('LAB-SETTINGS');
            var direction = 'C';
            if (setting && setting.get('params')) {
                direction = setting.get('params');
            }
            this.wellSelectionView = new CommonWellSelectionView({
                direction: direction,
                start: 'A01',
                to: this.options.model.get('plateSize'),
                model: this.model,
                fieldName: 'wellSelection'
            });
            this.getRegion('wellSelectionRegion').show(this.wellSelectionView);

            this.commonCancelActionView = new CommonCancelActionView();
            this.listenTo(this.commonCancelActionView, 'action:click', _.bind(this.onActionClick, this));
            this.listenTo(this.commonCancelActionView, 'cancel:click', _.bind(this.onCancel, this));
            this.getRegion('cancelActionRegion').show(this.commonCancelActionView);
            this.commonCancelActionView.enableButtons(false);

            var AssayService = require('services/caccounts/assay');
            this.getRegion('assayRegion').show(new AutocompleteView(
                this._getAutocompleteOptionObject(AssayService.getAutocompleteParamFromRun(this.options.model, {
                    modelProperty: 'assay',
                    paramUrl: {
                        'sord': 'asc',
                        'sidx': 'code'
                    }
                }))
            ));

            this.getRegion('newAssayRegion').show(new AutocompleteView(
                this._getAutocompleteOptionObject(AssayService.getAutocompleteParam({
                    modelProperty: 'newAssay',
                    paramUrl: {
                        'sord': 'asc',
                        'sidx': 'code',
                        'cyclerSecId': this.options.model.get('refCycler') && this.options.model.get('refCycler').get('id') ? this.options.model.get('refCycler').get('id') : null,
                        'cyclerPublicSecId': this.options.model.get('refCyclerPublic') ? this.options.model.get('refCyclerPublic').id : null,
                        'kitProtSecId': this.options.model.get('refKitProt') ? this.options.model.get('refKitProt').get('id') : null
                    }
                }))
            ));
        },

        onValidateChange: function () {
            this.commonCancelActionView.enableButtons(this.validate(this.fieldsToValidate()));
        },

        onActionClick: function () {
            var confirmParams = {
                message: _.i18n('plateLayout.replace.assay.confirm'),
                type: 'warning'
            };
            CustomBootboxMessage.customConfirm(confirmParams, _.bind(function (result) {
                if (result) {
                    var loading = $('.js-global-loader');
                    loading.show();
                    this.options.model.replaceAssayWells(this.model)
                        .done(_.bind(function () {
                            this.hide();
                        }, this))
                        .always(_.bind(function () {
                            loading.hide();
                        }, this));
                } else {
                    this.hide();
                }
            }, this));


        }
    });
});
