define('exportCustomJqGridView',[
    'module',
    'dialogFormView',
    'underscore',
    'jquery',
    'template!exportCustomJqGridView'
], function (
    module,
    DialogFormView,
    _,
    $,
    tpl
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        events: {
            'click .js-retry': 'onRetry'
        },

        attributes: {
            style: 'display: flex; column-gap: 5px;'
        },
        onRetry: function () {
            // get select row from jqGrid
            var test = this.options.jqGrid.getCheckedRowsList();
            var LisRecordService = require('services/caccounts/lisRecords');
            _.each(test, function (row) {
                var model = LisRecordService.getModel(row);
                model.get('queries')
                    .chain()
                    .filter(function (query) {
                        return query.get('responseType') === 'QUEUE' || query.get('responseType') === 'FAILED';
                    })
                    .each(function (query) {
                        query.retry();
                    });
            });
        }
    });
});
