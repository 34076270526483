/**
 * Created by RKL on 26/08/2015.
 */
define('errorView',[
    'module',
    'template!errorView',
    'dialogFormView',
    'errorCollection'
], function (
    module,
    viewTpl,
    DialogFormView,
    ErrorCollection
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: viewTpl,
        regions: {
            'errorList': '.js-collection'
        },
        serializeData: function () {
            var templateData = {};
            templateData.identifier = this.model.get('code') + ' (' + this.model.get('id') + ') from ' + this.model.get('className');
            return templateData;
        },
        onRender: function () {
            var errorView = new ErrorCollection({
                collection: this.model.get('errors')
            });
            this.getRegion('errorList').show(errorView);
        }
    });
});
