
define('template!kitLotsPcrLotView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-xs-12 m-b-5">\n    <div class="col-xs-1 p-0 js-left"><span class="mdi-arrow-left-bold mdi clickable"\n                                            style="line-height: 22px; font-size: 22px;"></span></div>\n    <div class="col-xs-10 text-center">\n        <span>' +
((__t = ( _.i18n('lot') )) == null ? '' : __t) +
' : ' +
((__t = ( code )) == null ? '' : __t) +
'</span>\n    </div>\n    <div class="col-xs-1 p-0 js-right"><span class="mdi-arrow-right-bold mdi clickable"\n                                             style="line-height: 22px; font-size: 22px;"></span></div>\n</div>\n<div class="col-xs-12 m-b-5">\n    <div class="col-xs-2"></div>\n    <div class="col-xs-8 rounded">\n        <div class="row">\n            <label class="col-xs-3 text-right">' +
((__t = ( _.i18n('lot.delivery') )) == null ? '' : __t) +
'</label>\n            <span class="col-xs-3">' +
((__t = ( delivery )) == null ? '' : __t) +
'</span>\n            <label class="col-xs-3 text-right">' +
((__t = ( _.i18n('lot.beginUse') )) == null ? '' : __t) +
'</label>\n            <span class="col-xs-3">' +
((__t = ( current )) == null ? '' : __t) +
'</span>\n        </div>\n        <div class="row">\n            <label class="col-xs-3 text-right">' +
((__t = ( _.i18n('lot.expiration') )) == null ? '' : __t) +
'</label>\n            <span class="col-xs-3">' +
((__t = ( expiration )) == null ? '' : __t) +
'</span>\n            <label class="col-xs-3 text-right">' +
((__t = ( _.i18n('lot.endUse') )) == null ? '' : __t) +
'</label>\n            <span class="col-xs-3">' +
((__t = ( paused )) == null ? '' : __t) +
'</span>\n        </div>\n        <div class="row">\n            <label class="col-xs-3 text-right">' +
((__t = ( _.i18n('common.status') )) == null ? '' : __t) +
'</label>\n            <span class="col-xs-3">' +
((__t = ( status )) == null ? '' : __t) +
'</span>\n        </div>\n    </div>\n    <div class="col-xs-2"></div>\n</div>\n<table class="col-xs-12 table table-striped">\n    <thead>\n    <tr>\n        <th class="col-w-22">' +
((__t = ( _.i18n('lot.assay') )) == null ? '' : __t) +
'</th>\n        <th class="col-w-6">' +
((__t = ( _.i18n('lot.reagent') )) == null ? '' : __t) +
'</th>\n        <th class="col-w-29">' +
((__t = ( _.i18n('lot.target') )) == null ? '' : __t) +
'</th>\n        <th class="col-w-14 js-result">' +
((__t = ( _.i18n('lot.result') )) == null ? '' : __t) +
'</th>\n        <th class="col-w-8">' +
((__t = ( _.i18n('lot.val') )) == null ? '' : __t) +
'</th>\n        <th class="col-w-8">' +
((__t = ( _.i18n('lot.deltaWarning') )) == null ? '' : __t) +
'</th>\n        <th class="col-w-8">' +
((__t = ( _.i18n('lot.deltaError') )) == null ? '' : __t) +
'</th>\n        <th class="col-w-5">' +
((__t = ( _.i18n('lot.chart') )) == null ? '' : __t) +
'</th>\n    </tr>\n    </thead>\n    <tbody class="collection"></tbody>\n</table>';

}
return __p
};});

