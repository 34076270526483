/**
 * Created by RKL on 10/09/2015.
 */
define('formView',[
    'module',
    'backbone.marionette',
    'underscore',
    'jquery',
    'validate',
    'dateUtils',
    'backbone',
    'moment',
    'jqueryui'
], function (
    module,
    Marionette,
    _,
    $,
    validateUtils,
    dateUtils,
    Backbone,
    moment
) {
    'use strict';

    module.exports = Marionette.View.extend({

        attributes: function () {
            return {'dataView': this.cid};
        },
        onInputChange: function (e) {
            e.preventDefault();
            e.stopPropagation();
            var $target = $(e.currentTarget),
                value = $target.val(),
                fieldName = $target.data('field-name'),
                isDate = $target.attr('type') === 'datepicker',
                isCheckBox = $target.attr('type') === 'checkbox';

            if (isDate) {
                value = value ? moment(value, 'DD-MM-YYYY').valueOf() : null;
            } else if (isCheckBox) {
                value = $target.is(':checked');
            }
            if ($target.hasClass('uppercase-input')) {
                value = value.toUpperCase();
            }
            this.onChange(fieldName, value);
        },

        onChange: function (fieldName, value) {
            var data = this._onChange(fieldName, value);
            this.model.set(data, {silent: true});
            this.model.trigger('change:' + fieldName);
            this.triggerMethod('enable:cancel:confirm');
        },

        _onChange: function (fieldName, value) {
            var toSave = {};
            toSave[fieldName] = value;
            var requireName;

            if (value && this.model.get(fieldName) instanceof Backbone.Model && this.model.get(fieldName).service) {
                requireName = this.model.get(fieldName).service;
            }
            if (this.model.getRelations) {
                var relation = _.first(this.model.getRelations().filter(function (relation) {
                    return relation.key === fieldName;
                }));
                if (relation) {
                    requireName = relation.relatedModel.prototype.service;
                }
            }
            if (requireName) {
                var service = require(requireName);
                var data;
                if (value instanceof Backbone.Model) {
                    data = value.toJSON();
                } else {
                    data = value;
                }
                toSave[fieldName] = data ? service.getModel(data) : null;
            }
            return toSave;
        },

        validate: function (fields, dontShowError) {
            // clear error design
            this.$('.has-error').removeClass('has-error');
            this.$('.invalid').removeClass('invalid');

            var errors = this._validate(fields, dontShowError, this);

            if (!_.isEmpty(errors)) {
                this.onError(errors, dontShowError);
                return false;
            } else {
                return true;
            }
        },
        _validate: function (fields, dontShowError, view) {
            var model = view.model,
                errors = [],
                error;

            _.each(fields, _.bind(function (field) {
                if (field.subView) {
                    var fieldToValidates;
                    if (field.subView.fieldsToValidate instanceof Function) {
                        fieldToValidates = field.subView.fieldsToValidate();
                    } else {
                        fieldToValidates = field.subView.fieldsToValidate;
                    }
                    errors = _.union(errors, this._validate(fieldToValidates, dontShowError, field.subView));
                    _.each(errors, function (error) {
                        if (!error.subView) {
                            error.subView = field.subView;
                        }
                    });
                    return;
                }
                var nameField = view.$('input[data-field-name="' + field.name + '"]');
                var formGroup = view.$('.form-group[data-field-name*="' + field.name + '"]');
                formGroup.removeClass('has-error');
                nameField.removeClass('invalid');

                view.$el.find('input[min][data-field-name="' + field.name + '"]').each(function (index, input) {
                    var $input = $(input);
                    error = validateUtils.validateMin(model,
                        parseInt($input.attr('min'), 10),
                        $input.data('field-name'));
                    errors = _.union(errors, error);
                });

                view.$el.find('input[max][data-field-name="' + field.name + '"]').each(function (index, input) {
                    var $input = $(input);
                    error = validateUtils.validateMax(model,
                        parseInt($input.attr('max'), 10),
                        $input.data('field-name'));
                    errors = _.union(errors, error);
                });

                if (field.type === 'required') {
                    error = validateUtils.validateFields(model, _.clone(field));
                    errors = _.union(errors, error);
                } else if (field.type === 'email') {
                    error = validateUtils.validateEmail(model, _.clone(field));
                    errors = _.union(errors, error);
                } else if (field.type === 'number') {
                    error = validateUtils.validateNumber(model, _.clone(field));
                    errors = _.union(errors, error);
                } else if (field.type === 'multipleEmail') {
                    error = validateUtils.validateMultipleEmail(model, _.clone(field));
                    errors = _.union(errors, error);
                } else if (field.type === 'datePicker') {
                    error = validateUtils.validateDatePicker(model, _.clone(field));
                    errors = _.union(errors, error);
                }
            }, this));
            return errors;
        },

        onError: function (errors, dontShowError) {
            var nameField, formGroup;

            _.each(errors, _.bind(function (error) {
                var view = error.subView || this;
                nameField = view.$('input[data-field-name="' + error.name + '"]');
                formGroup = view.$('[data-field-name*="' + error.name + '"]');
                formGroup.addClass('has-error');
                nameField.addClass('invalid');
            }, this));
            if (dontShowError) {
                return;
            }
            this.triggerMethod('errorMessage', _.i18n('warning.mandatoryFields'));
        },

        onFocus: function (e) {
            var target = $(e.currentTarget),
                fieldName = target.data('field-name'),
                formGroup = this.$('.form-group[data-field-name="' + fieldName + '"]');

            formGroup.removeClass('has-error');
            target.removeClass('invalid');
            this.triggerMethod('hideMessage');
        },

        onDropdownFocus: function (e) {
            var $target = $(e.currentTarget).find('input.invalid-field-value');
            $target.attr('placeholder', _.i18n('common.empty.placeholder'));
            $target.removeClass('invalid-field-value');
        },
        onBeforeDestroy: function () {
            this.undelegateEvents();
        },
        setPermissions: function (enable) {
            /*jshint -W069 */
            /*Disable Warning Justification on using [] instead of dot notation*/
            if (enable) {
                this.triggerMethod('enable:cancel:confirm');
            } else {
                if (this.ui.confirm) {
                    this.ui.confirm.hide();
                }
                this.$el.find('input,select,textarea').attr('disabled', true);
                this.$el.find('input,select,textarea').addClass('disabled');
                this.$el.find('input,select,textarea').removeClass('clickable');
                this.$el.find('.mdi-content-add,.mdi-action-delete').addClass('invisible');
            }
            /*jshint +W069 */
        },

        initDatePicker: function (element, fieldName, silent) {
            var that = this, format = '@';
            if (!fieldName) {
                console.error('Field name is required');
                return;
            }
            element.datepicker({
                autoSize: true,
                dateFormat: format,
                dayNamesMin: moment.weekdaysShort(),
                firstDay: 1,
                changeYear: true,
                prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>',
                onSelect: function () {
                    var toSave = {};
                    toSave[fieldName] = moment(Number(element.val())).format('YYYY-MM-DD');
                    if (silent) {
                        that.model.set(toSave, {silent: true});
                    } else {
                        that.model.set(toSave);
                    }
                    that.model.trigger('change:' + fieldName);
                }
            });
        }
    });
});

