define('assayVersionLotItem',[
    'module',
    'backbone.marionette',
    'template!assayVersionLotItem',
    'dialogFormView',
    'dynamicCreateEditCustomListToEntityItem'
], function (
    module,
    Marionette,
    Tpl,
    DialogFormView,
    DynamicCreateEditCustomListToEntityItem
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        attributes: {
            style: 'flex: 1;'
        },
        regions: {
            lot: '.js-lot',
            kit: '.js-kit'
        },

        serializeData: function () {
            var templateData = {};

            templateData.reagentType = this.model.get('reagent').get('type');
            templateData.reagentCode = this.model.get('reagent').get('code');
            templateData.volumeByTest = this.model.get('volumeByTest');
            templateData.volumeDeath = this.model.get('volumeDeath');

            return templateData;
        },
        onRender: function () {
            this.showChildView('kit', new DynamicCreateEditCustomListToEntityItem({
                model: this.model.get('reagent').get('kit')
            }));
            if (this.options.lot) {
                var PcrKitLotService = require('services/caccounts/kitlotpcr');
                this.showChildView('lot', new DynamicCreateEditCustomListToEntityItem({
                    model: PcrKitLotService.getById(this.options.lot.get('lotId'))
                }));
            }
        }
    });
});
