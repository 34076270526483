/**
 * Created by OBL on 14/01/2016.
 */
define('errorController',[
    'module',
    'backbone',
    'underscore',
    'errorView',
    'services/errors',
    'roleMissingView'
], function (
    module,
    Backbone,
    _,
    ViewError,
    Errors,
    RoleMissingView
) {
    'use strict';

    module.exports = {
        showE: function (entity) {
            var errorView = new ViewError({
                model: entity.get('entityError')
            });
            errorView.show({
                title: _.i18n('error'),
                className: 'baseTableEditAddPopup ' + 'ListPopup'
            });
        },
        showMissingRoles: function (missingRoles) {
            var roles = new Backbone.Collection();
            if (_.isArray(missingRoles)) {
                _.each(missingRoles, function (role) {
                    roles.push(new Backbone.Model({code: role, name: role}));
                });
            } else {
                roles.push(new Backbone.Model({code: missingRoles, name: missingRoles}));
            }
            var model = new Backbone.Model({roles: roles});
            var errorView = new RoleMissingView({
                model: model,
                collection: model.get('roles')
            });
            errorView.show({
                title: _.i18n('error'),
                className: 'baseTableEditAddPopup ' + 'ListPopup'
            });
        }
    };
});

