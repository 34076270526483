
define('template!errorView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<h3>' +
((__t = ( _.i18n('error.found.label') )) == null ? '' : __t) +
'</h3>\n<label class="col-xs-12">' +
((__t = ( _.i18n('error.identifier') )) == null ? '' : __t) +
' : ' +
((__t = ( identifier )) == null ? '' : __t) +
'\n</label>\n<div class="js-collection"></div>';

}
return __p
};});

