define('services/caccounts/displayModes',[
    'entities/ns',
    'underscore',
    'jquery',
    'module',
    'dynamic',
    'backbone',
    'entities/caccounts/displayModes',
    'entities/caccounts/displayModeConfigurations'
], function (
    app,
    _,
    $,
    module,
    Dynamic,
    Backbone
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.DisplayMode,
                collection: app.DisplayModeCollection
            };
        },
        getName: function () {
            return 'displaymode';
        },
        getUrl: function () {
            return 'rest/v2/displayModes/';
        },
        getDynamicJson: function () {
            return 'DisplayModeJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.default'),
                name: 'byDefault',
                classes: 'dynamic-link',
                formatter: viewDynamic.booleanFormatterNoCheckBox,
                search: true,
                index: 'byDefault',
                width: 20
            }, {
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 60
            }, {
                label: _.i18n('kitProts'),
                name: 'kitProts',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameListFormatter,
                index: 'kitProts',
                sortable: false,
                width: 45
            }, {
                label: _.i18n('assays'),
                name: 'assays',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameListFormatter,
                index: 'assays',
                sortable: false,
                width: 45
            }, {
                label: _.i18n('common.tag'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push({
                'field': 'kitProts',
                'param': {
                    'type': 'LIST2ENTITY'
                }
            });
            custom.push({
                'field': 'assays',
                'param': {
                    'type': 'LIST2ENTITY'
                }
            });

            custom.push({
                'field': 'configurations',
                'param': {
                    'type': 'LIST',
                    config: {
                        add: 'POPUP',
                        enableDelete: false,
                        enable: false
                    },
                    'display': {'css': 'overflow-y: auto; max-height: 150px;'}
                }
            });
            return custom;
        },
        getDefaultDisplayMode: function (force) {
            var defaultDisplayMode = Backbone.Relational.store.getCollection(app.DisplayMode).findWhere({byDefault: true});
            if (defaultDisplayMode && !force) {
                return defaultDisplayMode;
            }
            return app.DisplayMode.findOrCreate({
                'id': 'default',
                'code': 'default',
                'name': 'Default',
                'displayTooltip': 'LIST',
                'displayValidation': 'TARGET',
                'description': 'Default display mode',
                'configurations': [{
                    'location': 'Plate',
                    'parameter': {
                        'type': 'DOT'
                    }
                }, {
                    'location': 'Val',
                    'parameter': {
                        'type': 'DOT'
                    }
                }, {
                    'location': 'WellList',
                    'parameter': {
                        'type': 'DOT'
                    }
                }, {
                    'location': 'TargetList',
                    'parameter': {
                        'type': 'DOT'
                    }
                }, {
                    'location': 'ValMini',
                    'parameter': {
                        'type': 'DOT'
                    }
                }, {
                    'location': 'TooltipDot',
                    'parameter': {
                        'type': 'DOT'
                    }
                }, {
                    'location': 'Well',
                    'parameter': {
                        'type': 'NONE'
                    }
                }, {
                    'location': 'TooltipHistogram',
                    'parameter': {
                        'type': 'HISTOGRAM',
                        'param': 'NORMAL;0.0;45.0;20.0;42.0'
                    }
                }]
            });
        },
        getDefaultModel: function () {
            return {
                configurations: [{
                    'location': 'Plate',
                    'parameter': {
                        'type': 'DOT'
                    }
                }, {
                    'location': 'Val',
                    'parameter': {
                        'type': 'DOT'
                    }
                }, {
                    'location': 'WellList',
                    'parameter': {
                        'type': 'DOT'
                    }
                }, {
                    'location': 'TargetList',
                    'parameter': {
                        'type': 'DOT'
                    }
                }, {
                    'location': 'ValMini',
                    'parameter': {
                        'type': 'DOT'
                    }
                }, {
                    'location': 'TooltipDot',
                    'parameter': {
                        'type': 'DOT'
                    }
                }, {
                    'location': 'Well',
                    'parameter': {
                        'type': 'NONE'
                    }
                }, {
                    'location': 'TooltipHistogram',
                    'parameter': {
                        'type': 'HISTOGRAM',
                        'param': 'NORMAL;0.0;45.0;20.0;42.0'
                    }
                }]
            };
        }
    }, Dynamic);
});

