define('kitLotPcrTargetReagentAssayView',[
    'module',
    'underscore',
    'template!kitLotPcrTargetReagentAssayView',
    'dialogFormView',
    'autocompleteView'
], function (
    module,
    _,
    Tpl,
    DialogFormView,
    AutocompleteView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input-child',
            chart: '.js-show-qc-graph'
        },
        regions: {
            result: '.js-result'
        },

        events: {
            'change @ui.input': 'onInputChange',
            'click @ui.chart': 'showQCDetails'
        },
        tagName: 'tr',

        onRender: function () {
            var MbAnaResService = require('services/caccounts/mbanares');
            this.getRegion('result').show(new AutocompleteView(
                this._getAutocompleteOptionObject(MbAnaResService.getAutocompleteParam({
                    modelProperty: 'refMbAnaRes',
                    values: {
                        mbAnaResGrSecId: this.model.get('target') && this.model.get('target').get('refMbAnaResGr') ? this.model.get('target').get('refMbAnaResGr').id : null
                    }
                }))
            ));
        },

        serializeData: function () {
            var templateData = {};
            templateData.assay = this.model.get('refAssayReagent').get('refAssay').get('code');
            templateData.target = this.model.get('target').get('code');
            templateData.reagent = this.model.get('refAssayReagent').get('reagent') ? this.model.get('refAssayReagent').get('reagent').get('code') : '';
            templateData.val = this.model.get('val');
            templateData.deltaWarning = this.model.get('deltaWarning');
            templateData.deltaError = this.model.get('deltaError');
            templateData.disabled = this.options.editable ? '' : 'disabled="disabled"';
            templateData.enableQc = this.model.get('refAssayReagent').get('reagent') && this.model.get('refAssayReagent').get('reagent').get('code').toUpperCase() !== 'IC';
            return templateData;
        },

        showQCDetails: function () {
            require(['kitLotsPcrController'], _.bind(function (Controller) {
                Controller.showQCFromLot(this.model);
            }, this));
        }
    });
});
