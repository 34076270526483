define('wellDisplaySampleView',[
        'module',
        'dialogFormView',
        'underscore',
        'jquery',
        'bootbox',
        'backbone'
    ], function (
        module,
        DialogFormView,
        _,
        $,
        Bootbox,
        Backbone
    ) {
        'use strict';

        module.exports = DialogFormView.extend({
            template: _.template('<%= sample %>'),
            tagName: 'span',
            className: function () {
                var classList = 'cs-sample-font';
                if (this.options.test) {
                    classList += ' well-sample-test';
                }
                if (this.options.plateView) {
                    classList += ' plateView';
                }
                if (!this.options.plateView && (this.model.get ? (this.model.get('id') && this.model.get('id') !== '') : this.model.get('id'))) {
                    classList += ' clickable';
                }
                if (this.model.get('verified') === 'NOT_VALIDATED' || this.model.get('entityError')) {
                    classList += ' error';
                }
                return classList;
            },

            events: {
                'click': 'onClickSample'
            },

            onClickSample: function (e) {
                if (!this.$el.hasClass('clickable')) {
                    return;
                }
                e.preventDefault();
                e.stopImmediatePropagation();
                $('.js-global-loader').show();
                this.model.fetch().done(_.bind(function () {
                    if (!this.model.get('id')) {
                        Bootbox.alert(_.i18n('quickSearch.sample.notFound'));
                    } else {
                        require(['samplesController'], _.bind(function (Controller) {
                            Controller.showDetails({model: this.model});
                        }, this));
                    }
                }, this))
                    .always(function () {
                        $('.js-global-loader').hide();
                    });
            },

            serializeData: function () {
                return {
                    sample: this.model instanceof Backbone.Model ? (this.options.shortted && this.model.get('shortId') ?
                            this.model.get('shortId') : this.model.get('code')) :
                        this.model.shortId ? this.model.shortId : this.model.code
                };
            }
        });
    }
);

