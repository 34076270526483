define('services/caccounts/sampleClass',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/sampleClass'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.SampleClass,
                collection: app.SampleClassCollection
            };
        },
        getName: function () {
            return 'sampleClass';
        },
        getUrl: function () {
            return 'rest/v2/sampleClass/';
        },
        getDynamicJson: function () {
            return 'SampleClassJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('sampleClass.sequence'),
                name: 'sequence',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 15
            }, {
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('sampleClass.sampleType'),
                name: 'sampleType',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 15
            }, {
                label: _.i18n('sample.validityDays'),
                name: 'validityDays',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 15
            }, {
                label: _.i18n('common.regex'),
                name: 'regexSampleId',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 45
            }, {
                label: _.i18n('common.regexShortId'),
                name: 'regexShortSampleId',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 45
            }, {
                label: _.i18n('common.regexOrderId'),
                name: 'regexOrderId',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 45
            }, {
                label: _.i18n('sampleClass.lis'),
                name: 'refLIS',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameFormatter,
                search: true,
                index: 'refLIS.code',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'comment'
            });
            return columns;
        },
        getJqGridSidx: function () {
            return 'sequence';
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = {};
            }
            return new app.SampleClassCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name', 'refLIS.code']};
        },
        getCreateEditLine: function () {
            return [
                {'field': 'code', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-20'}}},
                {'field': 'name', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-20'}}},
                {'field': 'validityDays', 'param': {'type': 'NUMBER', 'display': {'class': 'col-w-6'}}},
                {'field': 'regexSampleId', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-49'}}},
                {
                    'field': 'regexShortSampleId',
                    'param': {'type': 'TEXT', 'display': {'class': 'col-w-49'}}
                },
                {'field': 'regexOrderId', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-49'}}}

            ];
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push(
                {
                    'field': 'validityDays',
                    'param': {'type': 'INTEGER', 'display': {'class': 'col-xs-1 p-r-0 text-right'}}
                },
                {'field': 'sequence', 'param': {'type': 'STRING', 'display': {'class': 'col-xs-1 p-r-0 text-right'}}},
                {
                    'field': 'sampleType',
                    'param': {'type': 'CODELIST', 'code': 'SMPTYPE', 'display': {'class': 'col-xs-1 p-r-0'}}
                },
                {'field': 'regexShortSampleId', 'param': {'display': {'class': 'col-xs-3'}}},
                {'field': 'regexSequenceSample', 'param': {'display': {'class': 'col-xs-3'}}},
                {'field': 'regexOrderId', 'param': {'display': {'class': 'col-xs-3'}}},
                {'field': 'refLIS', 'param': {'display': {'class': 'col-xs-3'}}}
            );
            return custom;
        },
        findBySampleId: function (sampleId) {
            var url = Settings.url(this.getUrl() + 'findBySampleId', {sampleId: sampleId}),
                defer = $.Deferred();
            $.ajax({
                method: 'GET',
                url: url,
                contentType: 'application/json',
                success: _.bind(function (modelJSON) {
                    defer.resolve(modelJSON === '' ? null : app.SampleClass.findOrCreate(modelJSON));
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },
        generate: function (model) {
            var request = {
                sampleId: model.get('sampleId').replaceAll('#', '$'),
                firstStep: model.get('firstStep'),
                step: model.get('step'),
                plateSize: model.get('plateSize'),
                plateFillDirection: model.get('wellSelection').get('direction'),
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to')
            };

            var url = Settings.url(this.getUrl() + 'generate', request),
                defer = $.Deferred();
            $.ajax({
                method: 'GET',
                url: url,
                contentType: 'application/json',
                success: function (response) {
                    defer.resolve(response);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        }
    }, Dynamic);
});

