define('dynamicCreateEditList',['module',
        'backbone.marionette',
        'template!dynamicCreateEditList',
        'dynamicListHeaderCollectionView',
        'dynamicListItemCollectionView',
        'backbone',
        'underscore'
    ],
    function (module,
              Marionette,
              ItemViewTpl,
              HeaderCollectionView,
              ItemCollectionView,
              Backbone,
              _
    ) {
        'use strict';

        module.exports = Marionette.View.extend({
            template: ItemViewTpl,

            events: {
                'click .mdi-plus-circle.clickable': 'onAddModel'
            },

            regions: {
                headerRegion: '.js-header',
                collectionRegion: '.js-collection'
            },

            modelEvents: {},

            className: 'dynamic-create-edit-list',

            serializeData: function () {
                return {
                    field: this.options.field,
                    name: this.options.dynamicConfigurationField.service.getName()
                };
            },

            onRender: function () {
                var configurations = this.options.dynamicConfigurationField.service.getCreateEditLine();
                _.each(configurations, _.bind(function (configuration) {
                    configuration.name = this.options.dynamicConfigurationField.service.getName();
                }, this));
                var enableAdd = true;
                if (this.options.dynamicConfigurationField && this.options.dynamicConfigurationField.config) {
                    if (_.isFunction(this.options.dynamicConfigurationField.config.enable)) {
                        enableAdd = this.options.dynamicConfigurationField.config.enable(this.model);
                    } else if (!_.isUndefined(this.options.dynamicConfigurationField.config.enable)) {
                        enableAdd = this.options.dynamicConfigurationField.config.enable;
                    }
                }
                if (enableAdd) {
                    configurations.unshift({button: true, enable: enableAdd});
                }
                configurations = new Backbone.Collection(configurations);
                var view = new HeaderCollectionView({
                    collection: configurations
                });
                this.getRegion('headerRegion').show(view);

                configurations = this.options.dynamicConfigurationField.service.getCreateEditLine();
                _.each(configurations, _.bind(function (configuration) {
                    configuration.name = this.options.dynamicConfigurationField.service.getName();
                }, this));
                // add new configurations
                var enableToDelete = true;
                if (this.options.dynamicConfigurationField && this.options.dynamicConfigurationField.config) {
                    if (_.isFunction(this.options.dynamicConfigurationField.config.enableDelete)) {
                        enableToDelete = this.options.dynamicConfigurationField.config.enableDelete(this.model);
                    } else if (!_.isUndefined(this.options.dynamicConfigurationField.config.enableDelete)) {
                        enableToDelete = this.options.dynamicConfigurationField.config.enableDelete;
                    }
                }
                if (enableAdd) {
                    configurations.unshift({button: true, enable: enableToDelete});
                }

                view = new ItemCollectionView({
                    collection: this.collection,
                    dynamicConfigurationField: this.options.dynamicConfigurationField,
                    configurations: configurations,
                    model: this.model
                });
                this.getRegion('collectionRegion').show(view);
                if (this.options.model.isNew() && !this.options.dynamicConfigurationField.service.canAddNewModel()) {
                    var addModel = this.$el.find('.mdi-plus-circle');
                    addModel.removeClass('clickable');
                    addModel.addClass('disabled');
                    addModel.prop('title', _.i18n('common.error.cannotAddModelWhenParentIsNew'));
                }
            },
            onAddModel: function () {
                var relation = this.model.getRelation(this.options.field);
                var reverseProperty;
                if (relation && relation.reverseRelation && relation.reverseRelation.key) {
                    reverseProperty = relation.reverseRelation.key;
                } else {
                    var serviceSubModel = require(relation.related.model.prototype.service);
                    var relationSubModel = serviceSubModel.getModel();
                    var relations = relationSubModel.getRelations();
                    var mo = _.chain(relations).filter(_.bind(function (relation) {
                        return relation.relatedModel === this.model.constructor;
                    }, this)).first().value();
                    if (mo) {
                        reverseProperty = mo.key;
                    }
                }
                var data = {};
                if (reverseProperty) {
                    data[reverseProperty] = this.model;
                }
                if (this.options.dynamicConfigurationField.service.getDefaultModel) {
                    // extend data with default values
                    data = _.extend(data, this.options.dynamicConfigurationField.service.getDefaultModel());
                }
                var model = this.options.dynamicConfigurationField.service.getModel(data);

                this.collection.add(model);
                model.set(data, {silent: true});

                if (this.options.dynamicConfigurationField.custom) {
                    if (this.options.dynamicConfigurationField.custom.param.config && this.options.dynamicConfigurationField.custom.param.config.add === 'POPUP') {
                        this.options.dynamicConfigurationField.service.showDetails({
                            model: model,
                            service: this.options.dynamicConfigurationField.service
                        });
                    }
                }
            }
        });
    });

