
define('template!prepRunWellMultiDisplayTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="ui-show-result-cell wellSelectableItem noWrapHidden ' +
((__t = ( error ? 'has-error' : '')) == null ? '' : __t) +
' ">\n    <!-- plateView-wellBackground -->\n    <div class="prepRunWellGrid col-xs-12 p-r-0 p-l-0 ' +
((__t = ( validatedProgess ? 'watermark-progress': '' )) == null ? '' : __t) +
'"\n         style="height: 100%; background-color: ' +
((__t = ( backgroundColor )) == null ? '' : __t) +
';">\n        ';
 if(otherWell) { ;
__p += '\n        <div style="height: 20px; width: 20px; top: 0; right: 0; z-index: 10;\n		position: absolute;">\n            ';
 if(otherWell === 'repeat') { ;
__p += '\n            <div class="repeatStatus repeat-' +
((__t = ( repeatStatus )) == null ? '' : __t) +
'"></div>\n            ';
 } else if(otherWell === 'other') { ;
__p += '\n            <div class="repeatStatus repeat-other"></div>\n            ';
 } ;
__p += '\n        </div>\n        ';
 } ;
__p += '\n        <div class="rightSideAbsolute">\n            <div class="btn-group-vertical">\n                <button class="btn prepRunWellKitLotBtn js-btn-kitLot">\n                    <span class="mdi mdi-flask-outline"></span>\n                </button>\n                ';
 if(well.smpType === 'U') { ;
__p += '\n                <button class="btn prepRunsBtn js-btn-sample">S</button>\n                <button class="btn prepRunsBtn js-btn-comment">C</button>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n        <div class="bioGroupColorBar"></div>\n        <div class="plateView-icon-container" style="width: 100%; height: 100%;">\n            ';
 if (well.repeatStatus) { ;
__p += '\n            <span class="repeatStatus repeat-' +
((__t = ( well.repeatStatus )) == null ? '' : __t) +
' iconTop"></span>\n            <span class="repeatStatus repeat-' +
((__t = ( well.repeatStatus )) == null ? '' : __t) +
' iconBottom"></span>\n            ';
 } ;
__p += '\n            <div class="col-xs-12 p-r-0 p-l-0\n                    ' +
((__t = ((well.repeat)?'repeatOpacity':'')) == null ? '' : __t) +
'\n                    " style="height: 100%;">\n                <!-- repeatOpacity : classe nécessaire si on affiche une icone sur la cellule -->\n                <row class=" col-xs-12 p-l-0 p-r-0" style="position:relative;">\n                    <div class="col-xs-2 p-l-0 p-r-0" style="line-height: 17px;">\n                        ';
 if (well.smpType === 'NC') { ;
__p += '\n                        <span class="mdi mdi-minus-circle smpType-plateViewIcon"></span>\n                        ';
 } else if(well.smpType === 'PC') { ;
__p += '\n                        <span class="mdi mdi-plus-circle smpType-plateViewIcon"></span>\n                        ';
 } else if(well.smpType === 'RC') { ;
__p += '\n                        <span class="mdi mdi mdi-alpha-r-circle smpType-plateViewIcon"></span>\n                        ';
 } else if(well.smpType === 'C') { ;
__p += '\n                        <span class="mdi mdi-alpha-c-circle smpType-plateViewIcon"></span>\n                        ';
 } else if(well.smpType === 'OC') { ;
__p += '\n                        <span class="mdi mdi-compass smpType-plateViewIcon"></span>\n                        ';
 } else if(well.smpType === 'D') { ;
__p += '\n                        <span class="mdi mdi-alpha-d-circle smpType-plateViewIcon"></span>\n                        ';
 } else if(well.smpType === 'C') { ;
__p += '\n                        <span class="mdi mdi-alpha-c-circle smpType-plateViewIcon"></span>\n                        ';
 } else if(well.smpType === 'P') { ;
__p += ' <!-- Pool -->\n                        <span class="mdi mdi-google-circles-communities smpType-plateViewIcon"></span>\n                        ';
 } else {;
__p += '\n                        ';
 } ;
__p += '\n                        ';
 if(well.pooled) { ;
__p += ' <!-- pooled -->\n                        <span class="pooled-label mdi mdi-adjust smpType-plateViewIcon"></span>\n                        ';
 } ;
__p += '\n                    </div>\n                    <div class="col-xs-10 p-l-0 p-r-0">\n                        <div class="js-well-sample"></div>\n                    </div>\n                </row>\n                <div style="position: relative;" class="m-t-20">\n                    <row class="col-xs-12 p-l-0 p-r-0 h-16 m-t-1" style="display: flex;">\n                        <div class="col-xs-2 p-l-0 p-r-0">\n                            ' +
((__t = ( assaysToDisplay.assaysIcons.waiting )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="col-xs-10 p-l-0 p-r-0">\n                            <div class="noWrapHidden cs-sample-font" style="font-size: 15px; bottom: 2px;">\n                                ' +
((__t = ( assaysToDisplay.waiting )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                    </row>\n                    <row class="col-xs-12 p-l-0 p-r-0 h-16 m-t-1" style="display: flex;">\n                        <div class="col-xs-2 p-l-0 p-r-0">\n                            ' +
((__t = ( assaysToDisplay.assaysIcons.running )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="col-xs-10 p-l-0 p-r-0">\n                            <div class="noWrapHidden cs-sample-font" style="font-size: 15px; bottom: 2px;">\n                                ' +
((__t = ( assaysToDisplay.running )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                    </row>\n                    <row class="col-xs-12 p-l-0 p-r-0 h-16 m-t-1" style="display: flex;">\n                        <div class="col-xs-2 p-l-0 p-r-0">\n                            ' +
((__t = ( assaysToDisplay.assaysIcons.done )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="col-xs-10 p-l-0 p-r-0">\n                            <div class="noWrapHidden cs-sample-font" style="font-size: 15px; bottom: 2px;">\n                                ' +
((__t = ( assaysToDisplay.done )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                    </row>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>';

}
return __p
};});

