define('pcrSetupPrepRunItem',[
    'module',
    'underscore',
    'backbone.marionette',
    'template!pcrSetupPrepRunItem',
    'pcrSetupPrepWellCollection',
    'backbone'
], function (
    module,
    _,
    Marionette,
    Tpl,
    WellCollectionView,
    Backbone
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        className: 'rounded runCard',

        ui: {
            input: '.js-info-input',
            wells: '.js-wells'
        },

        regions: {
            wells: '.js-wells'
        },

        modelEvents: {
            'change': 'render'
        },

        id: function () {
            return this.model.get('id');
        },

        attributes: function () {
            return {
                'style': 'background-color: white;'
            };
        },

        serializeData: function () {
            var templateData = {};
            templateData.name = this.model.get('name');
            templateData.pcrProtocolCodes = '';
            if (this.model.get('pcrProtocols')) {
                templateData.pcrProtocolCodes = this.model.get('pcrProtocols').pluck('code').join(', ');
            }
            return templateData;
        },

        _generateWellHeader: function (collection) {
            var plateSize = this.model.get('plateSize');
            var column = Number.parseInt(plateSize.substring(1));
            var row = plateSize.substring(0, 1).charCodeAt(0);
            var PrepWellLiteService = require('services/caccounts/pcrSetup/prepWellLite');
            collection.push(PrepWellLiteService.create({pos: '000', type: 'header', name: ''}));
            for (var i = 1; i <= column; i++) {
                collection.push(PrepWellLiteService.create({
                    pos: '0' + String(i).padStart(2, '0'),
                    type: 'header',
                    name: String(i).padStart(2, '0')
                }));
            }
            for (var j = 65; j <= row; j++) {
                collection.push(PrepWellLiteService.create({
                    pos: String.fromCharCode(j) + '00',
                    type: 'header',
                    name: String.fromCharCode(j)
                }));
            }
            collection.sort();
        },

        clearSelection: function () {
            this.wellCollectionView.clearSelection();
        },

        onRender: function () {
            var collection = new Backbone.Collection();
            this.model.get('wells').each(function (well) {
                collection.push(well);
            });
            collection.comparator = 'pos';
            this._generateWellHeader(collection);
            this.wellCollectionView = new WellCollectionView({
                collection: collection,
                model: this.model,
                wellWidth: 100 / (this.model.getColumnNumber() + 1),
                pcrSetup: this.options.pcrSetup
            });
            this.getRegion('wells').show(this.wellCollectionView);
            this.listenTo(this.wellCollectionView, 'enableButtons', _.bind(function (enable) {
                this.trigger('enableButtons', enable);
            }, this));
        }
    });
});
