define('services/admin/cyclers',[
    'entities/ns',
    'settings',
    'underscore',
    'module',
    'dynamic',
    'rolesMixin',
    'entities/admin/cyclers'
], function (
    app,
    Settings,
    _,
    module,
    Dynamic,
    RolesMixin
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.CyclerPublic,
                collection: app.CyclerPublicCollection
            };
        },
        getName: function () {
            return 'cyclerpublic';
        },
        getUrl: function () {
            return 'rest/public/cyclers/';
        },
        getDynamicJson: function () {
            return 'CyclerPublicJson';
        },
        getTypeDynamicJson: function () {
            return 'ADMIN';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.exportable = false;
            config.archivable = false;
            config.selectArchivable = false;
            config.importable = false;
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'name',
                searchoptions: {size: 10},
                width: 100
            }, {
                label: _.i18n('detectionformat.label'),
                name: 'refDetectionFormat.code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'refDetectionFormat.code',
                searchoptions: {size: 10},
                width: 30
            }, {
                label: _.i18n('cycler.inputPlateLayoutService'),
                name: 'inputPlateLayoutService',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'inputPlateLayoutService',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('cycler.outputPlateLayoutService'),
                name: 'outputPlateLayoutService',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'outputPlateLayoutService',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('cycler.inputResultService'),
                name: 'inputResultService',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'inputResultService',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('cycler.customInputPlateLayout'),
                name: 'customInputPlateLayout',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'customInputPlateLayout',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('cycler.customOutputPlateLayout'),
                name: 'customOutputPlateLayout',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'customOutputPlateLayout',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('cycler.customInputResult'),
                name: 'customInputResult',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'customInputResult',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('plateSize.label'),
                name: 'plateSize',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'plateSize',
                searchoptions: {size: 10},
                width: 20
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'description'
            });
            return columns;
        },
        canAddOrEdit: function () {
            return Settings.get('currentUserModel').hasRole(RolesMixin.SUPER_USER);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name', 'plateSize']};
        },
        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore.call(this);
            ignored.push('customInputPlateLayout', 'customOutputPlateLayout', 'customInputResult');
            return ignored;
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push(
                {
                    'field': 'outputPlateLayoutService',
                    'param': {
                        'type': 'CYCLERCUSTOMIO',
                        'field': 'customOutputPlateLayout',
                        'url': 'rest/v2/inputPlateLayoutService/find'
                    }
                },
                {
                    'field': 'inputPlateLayoutService',
                    'param': {
                        'type': 'CYCLERCUSTOMIO',
                        'field': 'customInputPlateLayout',
                        'url': 'rest/v2/outputPlateLayoutService/find'
                    }
                },
                {
                    'field': 'inputResultService',
                    'param': {
                        'type': 'CYCLERCUSTOMIO',
                        'field': 'customInputResult',
                        'url': 'rest/v2/outputResultService/find'
                    }
                },
                {
                    'field': 'plateSize',
                    'param': {
                        'type': 'CODELIST',
                        'code': 'PLATESIZES_CYC'
                    }
                }
            );
            return custom;
        },
        findAllFilter: function (model, params) {
            if (!params) {
                return true;
            }
            var ret = false;
            if (params.cyclerPublicSecId) {
                ret = ret && model.get('cyclerPublic').id === params.cyclerPublicSecId;
            }
            return ret;
        }
    }, Dynamic);
});

