
define('template!runPcrOtherPlateLayoutView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="js-plateLayout"></div>\n<div class="js-assays"></div>\n<div class="mdi mdi-printer js-print clickable"></div>';

}
return __p
};});

