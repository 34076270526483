define('adminAttachmentController',[
    'module',
    'underscore',
    'backbone',
    'adminAttachmentsCreateEditView',
    'dynamicController',
    'jquery'
], function (
    module,
    _,
    Backbone,
    CreateEditLayoutView,
    DynamicController,
    $
) {
    'use strict';

    module.exports = _.defaults({
        showListForContext: function (region, data, displayArchived, service) {
            data.entityNamePath = 'caccounts/attachment';
            var entityConfig = _.bind(service.getJqGridColumn, service);
            data.getJqGridColumn = function (viewDynamic) {
                var configuration = entityConfig(viewDynamic);
                configuration = _.filter(configuration, function (column) {
                    return !['context', 'contextSecId', 'contextCode'].includes(column.name);
                });
                _.each(configuration, function (column) {
                    column.search = false;
                });
                return configuration;
            };

            this._showList(region, data, displayArchived, service);
        },

        /**
         *
         * @param {Object} param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Object} param.service - The service object.
         * @param {Function} param.callBackOnClose - The callback function to be called after the CreateEditLayoutView is shown.
         * @throws {Error} If the model does not exist.
         */
        showDetails: function (param) {
            param.service = require('services/caccounts/attachment');
            $('.js-global-loader').show();
            if (!param.model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }

            var codeListCode = 'ATTACH-TYPE-' + param.model.get('context');
            var UserCodeListService = require('services/caccounts/userCodelist');
            UserCodeListService.findItemsByCode(codeListCode).fail(function () {
                codeListCode = 'ATTACH-TYPE-BASETABLE';
            }).always(function () {
                $('.js-global-loader').hide();
                var createEditView = new CreateEditLayoutView({
                    model: param.model,
                    codeListCode: codeListCode,
                    service: param.service
                });
                createEditView.show({
                    title: _.i18n(param.service.getName() + '.new'),
                    className: 'baseTableEditAddPopup kitPcrsEditAddPopup'
                }, _.bind(function () {
                    if (param.callBackOnClose) {
                        param.callBackOnClose();
                    }
                }, param.callBackOnClose ? param.callBackOnClose.context : undefined));
            });
        }
    }, DynamicController);
});

