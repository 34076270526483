define('services',[
'module',
    'services/./menu',
    'services/./trash',
    'services/./languages',
    'services/./errors',
    'services/./system'
], function (module) {
'use strict';
module.exports = {
    getServices: function() {
        var ret = [];
    ret.push(require('services/./menu'));
    ret.push(require('services/./trash'));
    ret.push(require('services/./languages'));
    ret.push(require('services/./errors'));
    ret.push(require('services/./system'));
        return ret;
    }
};
});
