define('htmlMinify',[
    'module'
], function (module) {

    'use strict';

    module.exports = {
        process: function (html) {
            return html
                .replace(/<!--[\s\S]*?-->/g, '')  // Supprime les commentaires HTML
                .replace(/\s+/g, ' ')             // Remplace plusieurs espaces par un seul
                .replace(/\s*\n\s*/g, '')         // Supprime les lignes vides et espaces inutiles
                .trim();                          // Supprime les espaces en début et fin
        }
    };
});
