define('entities/caccounts/pcrwells',[
    'entities/ns',
    'backbone',
    'settings',
    'colorUtils',
    'jquery',
    'underscore',
    'rolesMixin',
    'bootbox',
    'customBootboxMessage',
    'entities/caccounts/pcrruns',
    'entities/caccounts/pcrwellresults',
    'entities/caccounts/pcrWellResultByType',
    'entities/caccounts/samples',
    'entities/caccounts/assay',
    'entities/caccounts/assayVersion',
    'entities/caccounts/prepwells',
    'entities/caccounts/curves',
    'entities/caccounts/kitlotpcr'
], function (
    app,
    Backbone,
    Settings,
    ColorUtils,
    $,
    _,
    RolesMixin,
    bootbox,
    CustomBootboxMessage
) {
    'use strict';

    app.PcrWell = Backbone.RelationalModel.extend({
        service: 'services/caccounts/pcrwells',
        fetch: function () {
            this.url = Settings.url('rest/v2/wells/pcr/' + this.get('id'), {details: true});
            return Backbone.RelationalModel.prototype.fetch.call(this);
        },
        initialize: function () {
            this.set('tags', []);
        },
        idAttribute: 'id',

        relations: [{
            type: Backbone.HasMany,
            key: 'wellsPooled',
            relatedModel: 'PrepWell',
            collectionType: 'PrepWellCollection'
        }, {
            type: Backbone.HasMany,
            key: 'wellsPool',
            relatedModel: 'PrepWell',
            collectionType: 'PrepWellCollection'
        }, {
            type: Backbone.HasOne,
            key: 'run',
            relatedModel: 'PcrRun',
            reverseRelation: {
                includeInJSON: false
            },
            includeInJSON: ['id', 'code', 'name', 'refCycler', 'refCyclerPublic', 'refLmbCycler']
        }, {
            type: Backbone.HasOne,
            key: 'refPrepWell',
            relatedModel: 'PrepWell',
            includeInJSON: ['id']
        }, {
            type: Backbone.HasOne,
            key: 'refAssay',
            relatedModel: 'Assay',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'assayVersion',
            relatedModel: 'AssayVersion',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'curves',
            relatedModel: 'Curve',
            collectionType: 'CurveCollection'
        }, {
            type: Backbone.HasOne,
            key: 'smpId',
            relatedModel: 'Sample',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'referencedWells',
            relatedModel: 'PcrWell',
            collectionType: 'PcrWellCollection',
            includeInJSON: 'id'
        }, {
            type: Backbone.HasOne,
            key: 'wellReference',
            relatedModel: 'PcrWell',
            includeInJSON: 'id'
        }, {
            type: Backbone.HasMany,
            key: 'pcrKitLots',
            relatedModel: 'KitLotPcr',
            collectionType: 'KitLotPcrCollection'
        }, {
            type: Backbone.HasMany,
            key: 'pcrKitLotsForValidation',
            relatedModel: 'KitLotPcr',
            collectionType: 'KitLotPcrCollection'
        }, {
            type: Backbone.HasMany,
            key: 'resultByTypes',
            relatedModel: 'PcrWellResultByType',
            collectionType: 'PcrWellResultByTypeCollection'
        }, {
            type: Backbone.HasMany,
            key: 'results',
            relatedModel: 'PcrWellResult',
            collectionType: 'PcrWellResultCollection'
        }],

        getTargetsResultIcons: function () {
            var targetsResultsIcons = [];
            this.getResults().each(_.bind(function (target) {
                targetsResultsIcons.push(target.getResultIcon());
            }, this));

            return targetsResultsIcons;
        },

        postUrl: function (runSecId) {
            return Settings.url('rest/v2/runs/pcr/' + runSecId + '/wells');
        },

        save: function () {
            this.url = this.postUrl(this.get('run').get('id'));
            return Backbone.Model.prototype.save.call(this);
        },

        getDisplay: function (type) {
            var resultByType = this.get('resultByTypes').findWhere({type: type});
            if (!resultByType) {
                resultByType = app.PcrWellResultByType.findOrCreate({
                    id: this.id + '_' + type,
                    type: type,
                    results: []
                });
                this.get('resultByTypes').add(resultByType);
            }
            if (type !== 'OVAR' && this.get('assayVersion')) {
                // remove all ovar added in the resultByType
                var results = resultByType.get('results').filter(function (result) {
                    return result.get('type') !== 'OVAR';
                });
                if (_.isEmpty(results)) {
                    // add all assayConfigurationResult except ovar
                    this.get('assayVersion').get('results').each(_.bind(function (result) {
                        if (result.get('type') !== 'OVAR') {
                            // get the result from the result
                            var r = this.get('results').find(function (r) {
                                return r.get('refAssayResult') === result;
                            });
                            if (r) {
                                resultByType.get('results').push(r);
                            }
                        }
                    }, this));
                }
            }
            return resultByType.get('results');
        },

        findResultByTargetId: function (targetId) {
            return this.get('results').chain().filter(function (r) {
                return r.get('refAssayResult').get('target').id === targetId;
            }).first().value();
        },

        getResults: function () {
            var results = new Backbone.Collection();
            this.get('resultByTypes').each(function (resultByType) {
                resultByType.get('results').each(function (result) {
                    if (!results.includes(result)) {
                        results.push(result);
                    }
                });
            });
            return results;
        },

        saveHeader: function (requestModel) {
            var url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/header'),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(requestModel),
                contentType: 'application/json',
                success: _.bind(function () {
                    var defers = [];
                    defers.push(this.fetch());
                    defers.push(this.get('run').fetch());
                    $.when.apply($, defers).done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        saveSmpid: function () {
            var url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/sampleId'),
                defer = $.Deferred();

            var body = this.get('smpId').get('code');
            $.ajax({
                url: url,
                type: 'PUT',
                data: body,
                contentType: 'application/json',
                success: _.bind(function () {
                    this._refreshData().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: _.bind(function (err) {
                    this.fetch().done(function () {
                        defer.reject(err);
                    });
                }, this)
            });
            return defer.promise();
        },

        /**
         * Validation 1 for a well
         * @returns {*|jQuery}
         */
        validation1: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/validation');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this._refreshData().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Validation 2 for a well
         * @returns {*|jQuery}
         */
        validation2: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/validation2');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this._refreshData().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Validation 12 for a well
         * @returns {*|jQuery}
         */
        validation12: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/validation12');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this._refreshData().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Cancel the validation process for the PCR well.
         *
         * @returns {Promise} A Promise that resolves when the validation is successfully canceled, and rejects with an error if there was an error canceling the validation.
         */
        cancelValidation: function () {
            var defer = $.Deferred();
            var url;
            if (parseInt(this.get('valWst'), 10) === 3) {
                url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/cancelValidation1');
            } else {
                url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/cancelValidation2');
            }


            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this._refreshData().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        /**
         * Validation Expert for a well
         * @returns {*|jQuery}
         */
        validationExpert: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/validationExpert');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this._refreshData().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Validation Expert for a well
         * @returns {*|jQuery}
         */
        requireExpertValidation: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/requireExpertValidation');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this._refreshData().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Repeat PCR for a well
         * @returns {*|jQuery}
         */
        repeatPCR: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/repeat/PT');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this._refreshData().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },


        /**
         * Repeat PCR for a well
         * @returns {*|jQuery}
         */
        repeatPCRControl: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/repeat/PC');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this._refreshData().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Repeat Extraction for a well
         * @param {String} dillution
         * @returns {*|jQuery}
         */
        repeatExtraction: function (dillution) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/repeat/XT', {dillution: dillution});

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this._refreshData().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Repeat Extraction for a well
         * @param {String} dillution
         * @returns {*|jQuery}
         */
        repeatExtractionControl: function (dillution) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/repeat/XC', {dillution: dillution});

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this._refreshData().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Repeat Extraction for a well
         * @returns {*|jQuery}
         */
        repeatPoolToDissolve: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/repeat/DP');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this._refreshData().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Find pcrKitLot for a well sampleType
         * @returns {*|jQuery}
         */
        getControlKitLot: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/controlKitLot');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: function (response) {
                    var PcrWellService = require('services/caccounts/pcrwells');
                    var AssayReagentTargetPcrKitLotService = require('services/caccounts/assayReagentTargetPcrKitLots');
                    _.each(response, function (item) {
                        item.assayReagentTargetPcrKitLot = AssayReagentTargetPcrKitLotService.getModel(item.assayReagentTargetPcrKitLot);
                        _.each(item.values, function (value) {
                            value.pcrWell = PcrWellService.getModel(value.pcrWell);
                        });
                    });
                    defer.resolve(response);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Find pcrKitLot for a well
         * @returns {*|jQuery}
         */
        getPcrKitLots: function () {
            var defer = $.Deferred();

            var ret = this.get('assayVersion').get('reagents').chain().map(_.bind(function (reagent) {
                var pcrKitLot = this.get('pcrKitLots').chain().filter(function (kitLot) {
                    return kitLot.get('kit') === reagent.get('reagent').get('kit');
                }).first().value();

                if (!pcrKitLot) {
                    return null;
                }
                if (this.get('smpType') === 'PC' || this.get('smpType') === 'OC' || this.get('smpType') === 'NC' || this.get('smpType') === 'RC') {
                    if (reagent.get('reagent').get('type') === 'IC') {
                        return null;
                    }
                }

                return {
                    kit: reagent.get('reagent').get('kit'),
                    lotId: pcrKitLot.get('id'),
                    code: pcrKitLot.get('code'),
                    volumeByTest: reagent.get('volumeByTest'),
                    reagent: reagent.get('reagent')
                };
            }, this)).filter(function (ret) {
                return ret;
            }).value();

            defer.resolve(ret);
            return defer.promise();
        },

        getPDF: function () {
            return Settings.getDocUrl('PcrWellJson.pdf');
        },

        getErrorObject: function () {
            var errorObject = {
                error: false,
                warning: false,
                ok: false
            };

            switch (this.get('errorInd')) {
                case 'E':
                    errorObject.error = true;
                    break;
                case 'W':
                    errorObject.warning = true;
                    break;
            }

            if (!errorObject.warning && !errorObject.error && this.getResults().some({'codeErr': 'W'})) {
                errorObject.warning = true;
            }

            if (!errorObject.error && this.getResults().some({'codeErr': 'E'})) {
                errorObject.warning = false;
                errorObject.error = true;
            }

            if (!errorObject.error && !errorObject.warning) {
                errorObject.ok = true;
            }
            return errorObject;
        },

        findDissolveds: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/dissolved');

            $.ajax({
                url: url,
                type: 'GET',
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        getGraphToDisplay: function () {
            if (this.getDisplay('WELL').isEmpty()) {
                return _.map(_.uniq(this.get('curves').pluck('type')), function (type) {
                    return type.substring(0, 1);
                });
            }
            var type = '';
            this.getDisplay('WELL').chain().filter(function (result) {
                return result instanceof app.PcrWellResultCurve;
            }).each(function (result) {
                if (result.get('curves').isEmpty()) {
                    return;
                }
                if (result.get('curves').any(function (curve) {
                    return curve.get('type') === 'AMP' || curve.get('type') === 'AMP_IC';
                })) {
                    if (!type.includes('A')) {
                        type += 'A';
                    }
                }
                if (result.get('curves').any(function (curve) {
                    return curve.get('type') === 'MELT';
                })) {
                    if (!type.includes('M')) {
                        type += 'M';
                    }
                }
            });
            return type;
        },

        isReadOnly: function (dontCheckResult) {
            return this.get('repeatStatus') !== null || (!dontCheckResult && this.getResults().any(function (result) {
                return result.isReadOnly();
            }));
        },

        canChangeSmpId: function () {
            return (this.get('refPrepWell') === undefined || this.get('refPrepWell') === null || !this.get('refPrepWell')) && (!this.get('valWst') || this.get('valWst') <= 5 || this.get('valWst') === 9);
        },

        hasAlreadyTargetValidated: function () {
            return this.getResults().any(function (result) {
                return result.get('valSt') >= 3 && result.get('valSt') !== 9;
            });
        },

        findResult: function (assayVersionResult) {
            return this.getResults().chain().filter(function (result) {
                return result.get('refAssayResult') === assayVersionResult;
            }).first().value();
        },
        /**
         * Refresh well
         * @returns {*|jQuery}
         */
        refreshWell: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/refresh');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function (logs) {
                    var content = '<ul>';
                    logs = _.filter(logs, function (log) {
                        return !(log.action === 'RECALC' && !log.comment);
                    });
                    _.map(logs, function (log) {
                        content += '<li><ul>' +
                            '<li> LEVEL : ' + log.level + '</li>' +
                            '<li> ACTION : ' + log.action + '</li>' +
                            '<li> WELLS : ' + log.wells.join(', ') + '</li>' +
                            '<li> COMMENT : ' + log.comment + '</li>' +
                            '</ul></li>';
                    });
                    content += '</ul>';
                    if (logs.length > 0) {
                        bootbox.alert({
                            title: _.i18n('well.refresh') + ' - ' + this.get('pos') + ' - ' + this.get('run').get('code'),
                            message: content
                        });
                    } else {
                        CustomBootboxMessage.customThumbUpNotification();
                    }

                    var AssayVersionService = require('services/caccounts/assayVersion');
                    AssayVersionService.findByWell(this).done(_.bind(function () {
                        this._refreshData().done(function () {
                            defer.resolve();
                        });
                    }, this));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        saveDetail: function () {
            var url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/detail'),
                defer = $.Deferred();

            var tags = this.get('tags');
            if (tags && (!Array.isArray(tags)) && tags !== '' && tags !== undefined) {
                tags = this.get('tags').split(/[,;\s]/);
            }

            var body = {
                tags: tags
            };

            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(body),
                contentType: 'application/json',
                success: _.bind(function () {
                    this._refreshData().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        getType: function () {
            var types = this.getResults().chain().map(function (model) {
                return model.get('type');
            }).uniq().value();
            if (_.any(types, function (type) {
                return type === 'MAGPIX';
            })) {
                return 'MAGPIX';
            }
            if (_.any(types, function (type) {
                return type === 'HL7';
            })) {
                return 'HL7';
            }
            return 'CURVE';
        },
        changeValidationLot: function (param) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/test/lot');

            $.ajax({
                url: url,
                type: 'POST',
                data: JSON.stringify(param),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        findPcrKitLotForValidation: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/test/lot');

            $.ajax({
                url: url,
                type: 'GET',
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        setTest: function (test) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/' + this.get('id') + '/test', {test: test});

            $.ajax({
                url: url,
                type: 'GET',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        loadProperties: function () {
            var defer = $.Deferred();
            $.ajax({
                url: Settings.url('rest/v2/properties/findByWell/pcr/' + this.get('id')),
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function (response) {
                    defer.resolve(response);
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        _refreshData: function () {
            var defer = $.Deferred();
            var defers = [];
            defers.push(this.get('run').fetch());
            defers.push(this.fetch());
            $.when.apply($, defers).done(function () {
                defer.resolve();
            });
            return defer.promise();
        },
        getDisplayMode: function () {
            var displayMode = this.get('refAssay') && this.get('refAssay').get('displayMode') ? this.get('refAssay').get('displayMode') : null;
            if (!displayMode) {
                var run = this.get('run');
                displayMode = run.get('refKitProt') && run.get('refKitProt').get('displayMode') ? run.get('refKitProt').get('displayMode') : null;
            }
            if (!displayMode) {
                displayMode = this.get('assayVersion') && this.get('assayVersion').get('kitProt') && this.get('assayVersion').get('kitProt').get('displayMode') ? this.get('assayVersion').get('kitProt').get('displayMode') : null;
            }
            if (!displayMode) {
                var DisplayModeService = require('services/caccounts/displayModes');
                displayMode = DisplayModeService.getDefaultDisplayMode();
            }
            return displayMode;
        }
    });

    app.PcrWell.role = RolesMixin.WELL_PCR;

    app.PcrWellCollection = Backbone.Collection.extend({
        fetch: function (options) {
            var defer = $.Deferred();
            if (options && options.params && options.params.method === 'POST') {
                // This is a POST request
                this.url = Settings.url('rest/v2/wells/pcr/');
                $.ajax({
                    url: this.url,
                    type: 'POST',
                    data: JSON.stringify(this.map('id')),
                    contentType: 'application/json',
                    success: _.bind(function (response) {
                        this.reset(response);
                        this.each(function (model) {
                            model.get('results').fetch(model.id);
                        });
                        defer.resolve();
                    }, this),
                    error: function (err) {
                        console.error(err);
                        defer.reject(err);
                    }
                });
                return defer.promise();
            }
            if (options && options.run) {
                this.url = Settings.url('rest/v2/wells/pcr/from/' + options.run.get('id'), options.params);
            } else if (options && options.pcrRunSecId) {
                this.url = Settings.url('rest/v2/wells/pcr/from/' + options.pcrRunSecId, options.params);
            } else if (options && options.params) {
                this.url = Settings.url('rest/v2/wells/pcr/', options.params);
            } else {
                var params = {
                    details: true
                };
                this.url = Settings.url('rest/v2/wells/pcr/', params);
                $.ajax({
                    url: this.url,
                    type: 'POST',
                    contentType: 'application/json',
                    data: JSON.stringify(this.map('id')),
                    success: _.bind(function (response) {
                        this.reset(response);
                        defer.resolve();
                    }, this),
                    error: function (err) {
                        defer.reject(err);
                    }
                });
                return defer.promise();
            }
            return Backbone.Model.prototype.fetch.call(this);
        },
        model: app.PcrWell,
        comparator: function (m1, m2) {
            if (!m1 || !m1.get('pos')) {
                return -1;
            }
            if (!m2 || !m2.get('pos')) {
                return 1;
            }

            if (m1.get('run') !== m2.get('run') && m1.get('run') instanceof app.PcrRun && m2.get('run') instanceof app.PcrRun) {
                if (m1.get('run').get('period') && m2.get('run').get('period')) {
                    return String(m1.get('run').get('period')).localeCompare(String(m2.get('run').get('period')));
                }
                if (m1.get('run').get('created') && m2.get('run').get('created')) {
                    return String(m1.get('run').get('created')).localeCompare(String(m2.get('run').get('created')));
                }
            }
            var labSetting;
            if (this.forceLabSetting) {
                labSetting = this.forceLabSetting;
            } else {
                var SettingService = require('services/caccounts/setting');
                var setting = SettingService.findByCodeAsync('LAB-SETTINGS');
                labSetting = setting && setting.get('params') ? setting.get('params') : 'C';
            }
            if (labSetting === 'R') {
                return m1.get('pos').localeCompare(m2.get('pos'));
            }
            return (m1.get('pos').substring(1) + m1.get('pos').charCodeAt(0)).localeCompare(m2.get('pos').substring(1) + m2.get('pos').charCodeAt(0));
        },

        /**
         *
         * @param assayResult
         * @returns {[]|*|Array}
         */
        search: function (assayResult) {
            var models = _.filter(this.models, function (model) {
                if (!assayResult) {
                    return true;
                }
                return model.get('results').some(function (result) {
                    return result.get('refAssayResult') && result.get('refAssayResult').get('id') === assayResult.id;
                });
            });
            return new app.PcrWellCollection(models);
        },

        getAssays: function () {
            var assays = this.chain()
                .filter(function (model) {
                    return model.get('refAssay') && !model.get('refAssay').isNew();
                })
                .map(function (model) {
                    return model.get('refAssay');
                })
                .uniq(false, function (assay) {
                    return assay && assay.get('id');
                })
                .compact()
                .map(function (model) {
                    return model;
                })
                .value();

            if (this.isEmpty()) {
                return null;
            }
            return assays;
        },

        /**
         * Repeat PCR for a well
         * @returns {*|jQuery}
         */
        repeatPCR: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/repeat/PT');

            $.ajax({
                url: url,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify(this.map(function (m) {
                    return m.id;
                })),
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },


        /**
         * Repeat PCR for a well
         * @returns {*|jQuery}
         */
        repeatPCRControl: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/repeat/PC');

            $.ajax({
                url: url,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify(this.map(function (m) {
                    return m.id;
                })),
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Repeat Extraction for a well
         * @param {String} dillution
         * @returns {*|jQuery}
         */
        repeatExtraction: function (dillution) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/repeat/XT', {dillution: dillution});

            $.ajax({
                url: url,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify(this.map(function (m) {
                    return m.id;
                })),
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Repeat Extraction for a well
         * @param {String} dillution
         * @returns {*|jQuery}
         */
        repeatExtractionControl: function (dillution) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/repeat/XC', {dillution: dillution});

            $.ajax({
                url: url,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify(this.map(function (m) {
                    return m.id;
                })),
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        /**
         * Repeat Extraction for a well
         * @returns {*|jQuery}
         */
        repeatPoolToDissolve: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/pcr/repeat/DP');

            $.ajax({
                url: url,
                type: 'POST',
                contentType: 'application/json',
                data: JSON.stringify(this.map(function (m) {
                    return m.id;
                })),
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    });
});

