define('entities/caccounts/assayReagentVersion',[
    'entities/ns',
    'backbone',
    'rolesMixin',
    'entities/caccounts/pcrreagents',
    'entities/caccounts/kitspcr'
], function (
    app,
    Backbone,
    RolesMixin
) {
    'use strict';

    app.AssayReagentVersion = Backbone.RelationalModel.extend({
        service: 'services/caccounts/assayReagentVersion',
        defaults: {
            'reagent': null,
            'volumeByTest': 0.0,
            'volumeDeath': 0.0
        },
        idAttribute: 'id',
        relations: [{
            type: Backbone.HasOne,
            key: 'reagent',
            relatedModel: 'PcrReagent'
        }]
    });

    app.AssayReagentVersion.role = [RolesMixin.RUN_PCR, RolesMixin.ASSAY];

    app.AssayReagentVersionCollection = Backbone.Collection.extend({
        model: app.AssayReagentVersion
    });

});

