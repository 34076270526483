define('standardCurveAssayConfigurationResultView',[
    'module',
    'dialogFormView',
    'template!standardCurveAssayConfigurationResultView',
    'savingBehavior',
    'underscore',
    'settings',
    'standardCurveAssayConfigurationResultPcrRunCollection',
    'standardCurveAssayConfigurationResultContentView'
], function (
    module,
    DialogFormView,
    Tpl,
    SavingBehavior,
    _,
    Settings,
    StandardCurveAssayConfigurationResultPcrRunCollection,
    StandardCurveAssayConfigurationResultContentView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm'
        },
        
        events: {
            'click .js-cancel-popup': 'onCancel',
            'click .js-confirm': 'onConfirm'
        },
        
        regions: {
            'pcrRuns': '.js-pcrRuns',
            content: '.js-content'
        },

        modelEvents: {
            sync: 'onSave'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        serializeData: function () {
            return {};
        },
        onRender: function () {
            var view = new StandardCurveAssayConfigurationResultPcrRunCollection({
                collection: this.options.model.get('values'),
                model: this.model
            });
            this.listenTo(view, 'pcrRun:click', _.bind(this.onPcrRunClick, this));
            this.getRegion('pcrRuns').show(view);
            if (this.options.readOnly) {
                this.setPermissions(false);
            } else {
                var service = require('services/caccounts/standardCurve/standardCurveAssayConfigurationResults');
                this.setPermissions(service.canAddOrEdit());
            }
        },

        onPcrRunClick: function (model) {
            this.currentModel = model;
            this.showChildView('content', new StandardCurveAssayConfigurationResultContentView({
                model: model.get('standardCurveAssayConfigurationResult')
            }));
        },

        onConfirm: function () {
            this.currentModel.changeStatus().done(_.bind(function () {
                this.triggerMethod('saved', this.model);
                this.box.modal('hide');
            }, this));
        },

        onSave: function () {
            if (this.enableSave) {
                this.triggerMethod('saved', this.options.model);
                this.box.modal('hide');
            }
        }
    });
});

