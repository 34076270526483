define('jqGridColumnsView',[
    'module',
    'dialogCollectionView',
    'jqGridColumnItemView',
    'underscore'
], function (
    module,
    DialogCollectionView,
    JqGridColumnItemView,
    _
) {
    'use strict';

    module.exports = DialogCollectionView.extend({
        template: _.template('<ul class="js-container jqgrid-columns"></ul>' +
            '            <div class="cancelConfirmRow">\n' +
            '                <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n' +
            '                <button class="mdi-navigation-check btn popupBtn-confirm js-confirm"></button>\n' +
            '            </div>'),
        className: 'd-f column',
        childView: JqGridColumnItemView,
        childViewContainer: '.js-container',
        events: {
            'click .js-cancel-popup': 'onCancel',
            'click .js-confirm': 'onConfirm'
        },
        onConfirm: function () {
            this.hide();
        }
    });
});
