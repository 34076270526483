define('runPrepContentWellListView',[
    'module',
    'underscore',
    'jquery',
    'backbone.marionette',
    'jqGridController',
    'wellUtils',
    'jqGridFormatter'
], function (
    module,
    _,
    $,
    Marionette,
    JqGridController,
    WellUtils,
    jqGridFormatter
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<div class="js-grid-region" style="flex-grow: 1; width: 100%; height: 100%;"></div>'),
        regions: {
            gridRegion: '.js-grid-region'
        },

        className: 'content d-f',
        attributes: {
            style: 'flex-grow: 1; width: 100%; height: 100%;'
        },

        initialize: function () {
            this.assays = this.model.getFormattedRunAssayStatus();
        },

        paginationObject: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: 9999,
                currentPage: 1
            };
        },

        serializeData: function () {
            return {};
        },

        onRender: function () {
            var data = this.getListViewData(this.model.get('wells'));

            var view = JqGridController.show({
                data: data,
                pager: false,
                filtersName: 'prepWellTableListFilters',
                gridOptions: _.bind(this.gridOptions, this),
                paginationObject: this.paginationObject
            }, this.getRegion('gridRegion'));
            this.listenTo(view, 'click', _.bind(this.onClick, this));
            this.listenTo(view, 'loadComplete', _.bind(this.onLoadComplete, this));
        },

        onLoadComplete: function (jqGridView, datas) {
            this.setWellListPopover(jqGridView, datas);
        },

        onClick: function (obj) {
            var results = obj.gridView.getCheckedRowsList();
            if (results.length > 0) {
                results = _.pluck(results, 'id');
            }
            var PrepWellService = require('services/caccounts/prepwells');
            var wellModel = PrepWellService.getModel({id: obj.rowId});
            require(['prepWellController'], _.bind(function (PrepWellController) {
                PrepWellController.showDetails({
                    model: wellModel,
                    cSelection: results,
                    callBackOnClose: _.bind(function () {
                        obj.gridView.trigger('reloadGrid');
                    }, this)
                });
            }, this));
        },

        gridOptions: function () {
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'id',
                        key: true,
                        hidden: true
                    }, {
                        label: this.getSmpTypeLabelIcon,
                        name: 'smpType',
                        formatter: _.bind(this.smptypeFormater, this),
                        search: false,
                        sortable: true,
                        index: 'smpType',
                        fixed: true,
                        width: 45
                    }, {
                        label: _.i18n('well.pos'),
                        classes: 'well-list-pos-label',
                        name: 'pos',
                        formatter: _.bind(this.wellPosFormatter, this),
                        sortable: true,
                        index: 'pos',
                        fixed: true,
                        width: 55,
                        search: true,
                        searchoptions: {
                            sopt: ['cn']
                        }
                    }, {
                        label: _.i18n('well.smpId'),
                        name: 'smpId.code',
                        formatter: _.bind(this.wellSmpidFormatter, this),
                        classes: 'css-grid-cell-accentuatuion-02 displayPopover',
                        search: true,
                        sortable: true,
                        title: false,
                        index: 'smpId.code',
                        searchoptions: {
                            sopt: ['cn']
                        },
                        fixed: true,
                        width: 130
                    }, {
                        label: _.i18n('common.empty.placeholder'),
                        name: 'smpId.code',
                        sortable: false,
                        search: false,
                        classes: 'ignore-row-click css-no-leftBorder cell-copyForClipboard',
                        formatter: jqGridFormatter.copyToClipboardFormatter,
                        fixed: true,
                        width: 30
                    }, {
                        label: jqGridFormatter.getRepeatLabelIcon(),
                        name: 'repeatStatus',
                        formatter: _.bind(this.repeatStatusFormater, this),
                        search: false,
                        sortable: true,
                        index: 'repeatStatus',
                        fixed: true,
                        width: 50
                    }, {
                        label: '<div class="mdi mdi-human-male assay-waiting" style="text-shadow: #fff 0px 0 9px;"></div>',
                        name: 'assaysWaiting',
                        formatter: jqGridFormatter.wellAssayWaitingFormatter,
                        classes: '',
                        search: true,
                        sortable: true,
                        index: 'assaysWaiting',
                        fixed: true,
                        width: 150
                    }, {
                        label: '<div class="mdi mdi-run assay-running" style="text-shadow: #fff 0px 0 9px;"></div>',
                        name: 'assaysRunning',
                        formatter: jqGridFormatter.wellAssayRunningFormatter,
                        classes: '',
                        search: true,
                        sortable: true,
                        index: 'assaysRunning',
                        fixed: true,
                        width: 150
                    }, {
                        label: '<div class="mdi mdi-human-handsup assay-done" style="text-shadow: #fff 0px 0 9px;"></div>',
                        name: 'assaysDone',
                        formatter: jqGridFormatter.wellAssayDoneFormatter,
                        classes: '',
                        search: true,
                        sortable: true,
                        index: 'assaysDone',
                        fixed: true,
                        width: 150
                    }, {
                        label: _.i18n('well.sample.comment'),
                        name: 'wellComment',
                        formatter: _.bind(this.wellCommentFormatter, this),
                        search: true,
                        sortable: true,
                        index: 'wellComment',
                        fixed: true,
                        width: 150
                    }, {
                        label: _.i18n('well.tags'),
                        name: 'tags',
                        formatter: jqGridFormatter.defaultFormatter,
                        search: true,
                        sortable: true,
                        index: 'tags',
                        fixed: true,
                        width: 150
                    }
                ],
                sortname: this.sortOn ? this.sortOn : 'row'
            };
            return options;
        },

        wellCommentFormatter: function (cellValue, options, object) {
            var comment = object.smpId ? (object.smpId.lisComment ? object.smpId.lisComment : '') +
                ' ' + (object.smpId.comment ? object.smpId.comment : '') : '';
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + comment + '</span>';
        },

        repeatStatusFormater: function (cellValue) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12" style="right: 5px;">';
            if (cellValue === 'PT') {
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px;"></span>';
            } else if (cellValue === 'PC') {
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px;"></span>';
                start += '<span class="controlSpan" style="position: absolute; left: 25px; top: -7px; color: white; font-size: 11px;">c</span>';
            } else if (cellValue === 'XT') {
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px;"></span>';
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px; left: 21px;"></span>';
                start += '<span class="p-l-0 p-r-0" style="font-size:11px; position: absolute;' +
                    'left: 40px; bottom: -8px;">' +
                    '</span>';
            } else if (cellValue === 'XC') {
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px;"></span>';
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px; left: 21px;"></span>';
                start += '<span class="controlSpan" style="position: absolute; left: 28px; top: -7px; color: white; font-size: 11px;">c</span>';
                start += '<span class="p-l-0 p-r-0" style="font-size:11px; position: absolute;' +
                    'left: 40px; bottom: -8px;">' +
                    '</span>';
            }
            start += '</div>';
            end = '</div>';
            return start + end;
        },

        smptypeFormater: function (cellValue) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12 p-l-15">';

            if (cellValue === 'NC') {
                start += '<span class="mdi mdi-minus-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'PC') {
                start += '<span class="mdi mdi-plus-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'OC') {
                start += '<span class="mdi mdi-compass" style="font-size: 17px;"></span>';
            } else if (cellValue === 'D') {
                start += '<span class="mdi mdi-alpha-d-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'C') {
                start += '<span class="mdi mdi-alpha-c-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'RC') {
                start += '<span class="mdi mdi-alpha-r-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'P') {
                start += '<span class="mdi mdi-google-circles-communities" style="font-size: 17px;"></span>';
            }

            start += '</div>';
            end = '</div>';
            return start + end;
        },

        wellPosFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '" style="position: relative;">' + cellValue + '</span>';
        },

        wellSmpidFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '" style="font-weight: 600;">' + cellValue + '</span>';
        },

        getListViewData: function (filteredWells) {
            var wellListViewData = this.getWellListViewData(filteredWells);
            this.availableRepeatStatus = wellListViewData.repeatStatusList;
            this.smpTypeList = wellListViewData.smpTypeList;
            this.warningSearch = wellListViewData.warningSearch;
            var data = {
                datatype: 'local',
                data: wellListViewData.wellsForData
            };
            return data;
        },
        getWellListViewData: function (wells) {
            var wellsForData = [];

            wells
                .chain()
                .filter(function (well) {
                    return well.get('smpType') !== 'E' && well.get('smpId') && well.get('smpId').get('code') && (!well.get('assayWaiting').isEmpty() || !well.get('assayRunning').isEmpty() || !well.get('assayDone').isEmpty());
                })
                .each(_.bind(function (well) {
                    var assayStatusFormatted = WellUtils.getFormattedAssayStatus(well);
                    var comment = well.get('smpId').get('lisComment') ? well.get('smpId').get('lisComment') : '';
                    comment += well.get('smpId').get('comment') ? (comment ? ' - ' : '') + well.get('smpId').get('comment') : '';
                    var model = {
                        id: well.get('id'),
                        smpType: well.get('smpType'),
                        pos: well.get('pos'),
                        smpId: well.get('smpId') ? {
                            name: well.get('smpId').get('name'),
                            code: well.get('smpId').get('code'),
                            lisComment: well.get('smpId').get('lisComment'),
                            comment: well.get('smpId').get('comment')
                        } : {name: '', code: ''},
                        repeatStatus: well.get('repeatStatus'),
                        tags: well.get('tags'),
                        comment: comment
                    };
                    model.assaysWaiting = assayStatusFormatted.waiting;
                    model.assaysRunning = assayStatusFormatted.running;
                    model.assaysDone = assayStatusFormatted.done;
                    wellsForData.push(model);
                }, this));

            return {
                wellsForData: wellsForData,
                assaysIcons: WellUtils.getPrepWellAssaysListViewIcons()
            };
        },

        setWellListPopover: function (jqGridView, datas) {
            _.each(datas, _.bind(function (data) {
                var well = this.model.get('wells').get(data.id);
                if (!well) {
                    return;
                }
                var popovers = jqGridView.$el.find('tr#' + data.id + ' .displayPopover');
                if (popovers.length < 1) {
                    return;
                }

                var params = {
                    model: well
                };

                params.targets = popovers;

                // if ((well.get('wellRes') && well.get('wellRes').get('result') && well.get('wellRes').get('result').get('code')) &&
                //     (well.get('wellRes') && well.get('wellRes').get('quantificationFormatted'))) {
                //     params.targets.push($('.ovrerallResultPopover-' + well.get('pos')));
                // }
                var placement = 'right';
                var index = _.indexOf(datas, data);
                if (index < 3) {
                    placement = 'bottom';
                } else if (index > datas.length - 4) {
                    placement = 'top';
                }
                params.placement = placement;
                WellUtils.showPrepWellPopover(params);
            }, this));
        }
    });
});

