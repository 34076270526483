define('services/caccounts/extractors',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'jquery',
    'entities/caccounts/extractors'
], function (
    app,
    _,
    module,
    Dynamic,
    $
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.Extractor,
                collection: app.ExtractorCollection
            };
        },
        getName: function () {
            return 'extractor';
        },
        getUrl: function () {
            return 'rest/v2/extractors/';
        },
        getDynamicJson: function () {
            return 'ExtractorJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('extractor.lmbExtractors'),
                name: 'lmbExtractors',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameListFormatter,
                searchoptions: {
                    size: 10
                },
                sortable: false,
                search: true,
                index: 'lmbExtractorCode',
                width: 100
            }, {
                label: _.i18n('extractor.extractionMethod'),
                name: 'extractionMethods',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameListFormatter,
                searchoptions: {
                    size: 10
                },
                sortable: false,
                search: true,
                index: 'lmbExtractorCode',
                width: 100
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push({
                'field': 'extractionMethods',
                'param': {
                    'type': 'LIST2ENTITY'
                }
            });
            return custom;
        },
        getList: function (fieldName) {
            var defer = $.Deferred();
            switch (fieldName) {
                case 'extractionMethods':
                    var ExtractionMethodService = require('services/caccounts/extractionMethod');
                    ExtractionMethodService.findAll().done(function (items) {
                        defer.resolve({items: items});
                    });
                    break;
            }
            return defer.promise();
        }
    }, Dynamic);
});

