define('services/caccounts/extractionreagents',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/extractionreagents'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ExtractionReagent,
                collection: app.ExtractionReagentCollection
            };
        },
        getName: function () {
            return 'extractionreagent';
        },
        getUrl: function () {
            return 'rest/v2/reagents/extraction/';
        },
        getDynamicJson: function () {
            return 'ExtractionReagentJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 80
            }, {
                label: _.i18n('common.shortName'),
                name: 'shortName',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'shortName',
                width: 80
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'assay-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 550, 'fields': ['name', 'kit.code', 'kit.name']};
        },
        getCreateEditFieldCustom: function () {
            return [
                {
                    'field': 'kit',
                    'param': {
                        'type': 'REFERENCE',
                        'display': {readonly: true}
                    }
                }
            ];
        },
        getCreateEditLine: function () {
            return [
                {
                    'field': 'code',
                    'param': {
                        'type': 'USERCODELIST',
                        'code': 'EXTRACT_REAGENT',
                        'display': {'class': 'col-w-20'}
                    }
                },
                {'field': 'name', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-45'}}},
                {'field': 'shortName', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-45'}}}
            ];
        },
        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.callbackAdd = undefined;
            configuration.callbackEdit = undefined;
            configuration.canAddOrEdit = false;
            return configuration;
        },
        canAddOrEdit: function () {
            return false;
        },
        canRead: function () {
            return false;
        }
    }, Dynamic);
});

