/**
 * Created by Matteo on 11/07/2015.
 */
define('selectCaccountListView',[
    'module',
    'underscore',
    'jquery',
    'backbone.marionette',
    'autocompleteView',
    'settings',
    'template!selectCaccountListTpl',
    'moment'
], function (
    module,
    _,
    $,
    Marionette,
    AutocompleteView,
    Settings,
    listTpl,
    moment
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: listTpl,
        views: {},

        ui: {
            signin: '.js-sign-in',
            form: 'form.js-form'
        },

        events: {
            'keydown .autocomplete': 'onKeyPress',
            'focus .autocomplete': 'onFocus'
        },

        regions: {
            selectAccounts: '.select-accounts'
        },

        className: 'login-form-container',

        triggers: {
            'click @ui.signin': 'onSignin'
        },

        serializeData: function () {
            return {
                year: moment().year()
            };
        },

        getAccountsAutocompleteOptions: function () {
            return {
                type: 'remote',
                remote: Settings.url('rest/v2/sessions.json/token/byuser/caccounts'),
                valueKey: 'code',
                apiKey: 'id',
                placeholder: _.i18n('selectaccount.placeholder'),
                value: null,
                name: 'accounts',
                onPressKey: true,
                lazyLoad: true,
                keys: {
                    query: 'caccountCode',
                    limit: 'rows'
                },
                callback: _.bind(this.onSelect, this),
                service: require('services/global/caccounts')
            };
        },

        onRender: function () {
            var accountsView = new AutocompleteView(
                this.getAccountsAutocompleteOptions()
            );

            this.getRegion('selectAccounts').show(accountsView);
        },

        onKeyPress: function (e) {
            var charCode = (e.which) ? e.which : e.keyCode;

            this.selectPressed = false;
            if (charCode === 13) {
                this.selectPressed = true;

                return false;
            }
        },

        onFocus: function () {
            this.ui.form.removeClass('has-error');
            this.$('.autocomplete').removeClass('invalid');
        },

        onError: function () {
            this.ui.form.addClass('has-error');
            this.$('.autocomplete').addClass('invalid');
        },

        onSelect: function (field, model) {
            this.selectedAccount = model;

            if (this.selectPressed) {
                this.trigger('onSignin');
            }
        }
    });
});

