define('jqGridSelectFormatterItemView',[
    'module',
    'backbone.marionette',
    'underscore',
    'commonGenericSelectView'
], function (
    module,
    Marionette,
    _,
    CommonSelect
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<label><%= label %>' + '</label><div class="js-select"></div>'),
        regions: {
            select: '.js-select'
        },
        className: 'selectFormatterItem',
        initialize: function () {
            if (this.options.currentFormatter) {
                this.currentItem = _.find(this.model.get('formatters').items, _.bind(function (item) {
                    return item.formatter === this.options.currentFormatter;
                }, this));
            } else {
                this.currentItem = _.find(this.model.get('formatters').items, _.bind(function (item) {
                    return item.formatter === this.model.get('formatter');
                }, this));
            }
        },
        serializeData: function () {
            var label = this.model.get('label');
            if (this.model.get('formatters').groupLabel) {
                label = this.model.get('formatters').groupLabel;
            }
            return {label: label};
        },
        onRender: function () {
            var view = new CommonSelect({
                collection: this.model.get('formatters').items
            });
            this.showChildView('select', view);
            view.listenTo(view, 'select:change', _.bind(this.onChangeSelect, this));
            view.trigger('change:select', this.currentItem.value);
        },
        onChangeSelect: function (view, event) {
            this.currentItem = _.findWhere(this.model.get('formatters').items, {value: event.currentTarget.value});
        }
    });

});
