define('assayResultVersion',[
    'module',
    'template!assayResultVersion',
    'underscore',
    'jquery',
    'backbone',
    'dialogFormView',
    'arrayCustomCurveView',
    'arrayCriCurveView',
    'arrayCustomAssayResultView',
    'dynamicCreateEditTypeParam',
    'assayResultTargetValuesVersionCollection'
], function (
    module,
    Tpl,
    _,
    $,
    Backbone,
    DialogFormView,
    ArrayCustomCurveView,
    ArrayCriCurveView,
    ArrayCustomAssayResultView,
    TypeParamView,
    AssayResultTargetValuesVersionCollection
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        regions: {
            selectAlgoResult: '.js-algoResult',
            selectAlgoValidation: '.js-algoValidation',
            selectAlgoExport: '.js-algoExport',
            targetValues: '.js-targetValues'
        },

        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.id = this.model.get('id') ? this.model.get('id') : '';
            return templateData;
        },

        onRender: function () {
            this.renderAlgoResult();

            var CodeListService = require('services/admin/codelist');
            var selectAlgoValidationtView = new TypeParamView({
                fieldName: 'algoValidation',
                model: this.model,
                getAutocompleteParam: CodeListService.getAutocompleteParamForCode({codeListCode: 'ALGO-VALAUT'}),
                codeListCode: 'ALGO-VALAUT',
                readOnly: true
            });
            this.getRegion('selectAlgoValidation').show(selectAlgoValidationtView);

            var selectAlgoExportView = new TypeParamView({
                fieldName: 'algoExport',
                model: this.model,
                getAutocompleteParam: CodeListService.getAutocompleteParamForCode({codeListCode: 'ALGOTEXPORT'}),
                codeListCode: 'ALGOTEXPORT',
                readOnly: true
            });
            this.getRegion('selectAlgoExport').show(selectAlgoExportView);
            var type ;
            if(this.options.targetValueFiltered) {
                type = 'IC';
                if (['PC', 'NC', 'OC', 'RC'].includes(this.options.targetValueFiltered)) {
                    type = 'QC-' + this.options.targetValueFiltered;
                }
            }
            this.getRegion('targetValues').show(new AssayResultTargetValuesVersionCollection({
                collection: new Backbone.Collection(this.model.get('targetValues')),
                targetValueFiltered: type
            }));
        },
        renderAlgoResult: function () {
            var CodeListService = require('services/admin/codelist');
            var selectAlgoResultView = new TypeParamView({
                fieldName: 'algoResult',
                model: this.model,
                getAutocompleteParam: CodeListService.getAutocompleteParamForCode({codeListCode: 'ALGO-RES'}),
                codeListCode: 'ALGO-RES',
                customInput: _.bind(this.onShowAlgoRes, this),
                display: {
                    type: {
                        'class': 'col-w-20'
                    },
                    param: {
                        'class': 'col-w-80'
                    }
                },
                readOnly: true
            });
            this.getRegion('selectAlgoResult').show(selectAlgoResultView);
        },

        onShowAlgoRes: function () {
            switch (this.model.get('algoResult').type) {
                case 'MUTV':
                case 'AMP':
                case 'AMP_IC':
                    return new ArrayCustomCurveView({
                        model: this.model,
                        readOnly: true
                    });
                case 'CRI':
                    return new ArrayCriCurveView({
                        model: this.model,
                        readOnly: true
                    });
                case 'COMB':
                case 'COMBCUTOFF':
                case 'STD':
                    return new ArrayCustomAssayResultView({
                        model: this.model,
                        fieldName: 'algoResult',
                        fieldNameCode: this.model.get('algoResult').type,
                        readOnly: true
                    });
                default:
                    return new ArrayCustomAssayResultView({
                        model: this.model,
                        fieldName: 'algoResult',
                        fieldNameCode: this.model.get('algoResult').type,
                        readOnly: true
                    });
            }
        }
    });
});
