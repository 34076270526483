define('adminTrashView',[
    'module',
    'backbone.marionette',
    'underscore',
    'jquery',
    'customBootboxMessage',
    'jqGridController',
    'template!adminTrashView',
    'jqGridFormatter'
], function (
    module,
    Marionette,
    _,
    $,
    CustomBootboxMessage,
    JqGridController,
    Tpl,
    jqGridFormatter
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,
        regions: {
            trashList: '.trashRegion'
        },

        className: 'dynamic jq-grid-table-page trash-listView',
        
        onRender: function () {
            var TrashService = require('services/trash');
            var data = {
                url: TrashService.getTrashCollectionUrl()
            };
            var view = JqGridController.show({
                data: data,
                pager: true,
                filtersName: 'trashTableListFilters',
                gridOptions: _.bind(this.gridOptions, this)
            }, this.getRegion('trashList'));
            this.listenTo(view, ' click', _.bind(this.onDownloadTrash, this));
        },
        gridOptions: function () {
            var options = {
                colModel: [
                    {
                        label: ' ',
                        name: 'export',
                        sortable: false,
                        search: false,
                        classes: 'export-link-action ignore-row-click',
                        formatter: jqGridFormatter.exportActionIconFormatter,
                        fixed: true,
                        width: 20
                    }, {
                        label: _.i18n('common.filename'),
                        name: 'name',
                        classes: 'trash-link',
                        formatter: this.getRowObjectFormatter,
                        search: true,
                        index: 'name',
                        searchoptions: {size: 30}
                    }
                ],
                sortname: 'name'
            };
            return options;
        },

        onDownloadTrash: function (obj) {
            var rowData = obj.gridView.getGrid().jqGrid('getRowData', obj.rowId);
            if (rowData.name) {
                this.downloadTrashByFileName(rowData.name)
                    .done(_.bind(function (result) {
                        if (!result) {
                            this.showExportFailNotification(rowData.name);
                        }
                    }, this));
            }
        },

        downloadTrashByFileName: function (fileName) {
            this.exportErrors = [];
            var defer = $.Deferred();
            var TrashService = require('services/trash');
            TrashService.downloadTrashByFileName(fileName)
                .done(_.bind(function (response) {
                    if (response.status && response.status !== 200) {
                        this.exportErrors.push(fileName);
                        defer.resolve(this.exportErrors);
                    } else {
                        var element = document.createElement('a');
                        element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(response.data));
                        element.setAttribute('download', response.fileName);
                        element.style.display = 'none';
                        document.body.appendChild(element);
                        element.click();
                        document.body.removeChild(element);
                        defer.resolve(this.exportErrors);
                    }
                }, this));
            return defer.promise();
        },

        showExportFailNotification: function (fileName) {
            if (fileName) {
                var message = ': </br>';
                message = message + fileName;
                var params = {
                    title: _.i18n('download.fail.notification.title'),
                    message: _.i18n('download.fail.notification.message') + message,
                    type: 'warning'
                };
                CustomBootboxMessage.customAlert(params);
            }
        },

        getRowObjectFormatter: function (cellvalue, options, rowObject) {
            return rowObject;
        }
    });
});

