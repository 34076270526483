/**
 * Created by RKL on 21/08/2015.
 */
define('routingController',[
    'module',
    'underscore',
    'jquery',
    'preProcessView',
    'menuRoutingGroupSelect',
    'backbone',
    'bootbox',
    'routingGroupDispatchSampleView',
    'settings',
    'rolesMixin',
    'privileges'
], function (
    module,
    _,
    $,
    PreProcessView,
    MenuRoutingGroupSelect,
    Backbone,
    Bootbox,
    RoutingGroupDispatchSampleView,
    Settings,
    RolesMixin,
    Privileges
) {
    'use strict';

    module.exports = {
        menu: function (region) {
            var deferreds = [];

            var PreProcesseService = require('services/caccounts/preprocess');
            deferreds.push(PreProcesseService.findAll());
            var RoutingActionService = require('services/caccounts/routingaction');
            deferreds.push(RoutingActionService.findRoutingAction({type: 'REGISTRATION'}));
            deferreds.push(RoutingActionService.findRoutingAction({type: 'ROUTING'}));
            deferreds.push(RoutingActionService.findRoutingAction({type: 'ORP'}));

            $.when.apply($, deferreds).done(_.bind(function (preProcessCollection, sampleRegistrationCollection, routingCollection, orpCollection) {
                if (!Settings.get('currentUserModel').hasType(RolesMixin.ROUTING, Privileges.PREPROC)) {
                    preProcessCollection.reset();
                }
                if (!Settings.get('currentUserModel').hasType(RolesMixin.ROUTING, Privileges.REG)) {
                    sampleRegistrationCollection.reset();
                }
                if (!Settings.get('currentUserModel').hasType(RolesMixin.ROUTING, Privileges.ROUT)) {
                    routingCollection.reset();
                }
                if (!Settings.get('currentUserModel').hasType(RolesMixin.ROUTING, Privileges.ORP)) {
                    orpCollection.reset();
                }
                var view = new MenuRoutingGroupSelect({
                    registrationCollection: sampleRegistrationCollection,
                    preProcessingCollection: preProcessCollection,
                    routingCollection: routingCollection,
                    orpCollection: orpCollection
                });
                region.show(view);
            }));
        },
        showRouting: function (region, type, routingActionSecId) {
            var RoutingActionService = require('services/caccounts/routingaction');
            var routingAction = RoutingActionService.getModel({id: routingActionSecId});
            routingAction.fetch().done(function () {
                if (!routingAction || !routingAction.get('id')) {
                    Bootbox.alert(_.i18n('routingAction.notFound'));
                    return;
                }
                var SettingService = require('services/caccounts/setting');
                SettingService.findByCode('ROUTACT_DMODE').done(_.bind(function (setting) {
                    var view = new RoutingGroupDispatchSampleView({
                        setting: setting,
                        routingAction: routingAction,
                        type: type
                    });
                    region.show(view);
                }, this));
            });
        },
        showPreProcess: function (region, id) {
            var PreProcesseService = require('services/caccounts/preprocess');
            var model = PreProcesseService.getModel({id: id});
            model.fetch().done(_.bind(function () {
                var view = new PreProcessView({
                    model: model
                });
                region.show(view);
            }, this));
        }
    };
});

