define('entities/caccounts/displayModeConfigurations',[
    'entities/ns',
    'backbone',
    'settings',
    'underscore',
    'rolesMixin'
], function (
    app,
    Backbone,
    Settings,
    _,
    RolesMixin
) {
    'use strict';

    app.DisplayModeConfiguration = Backbone.RelationalModel.extend({
        service: 'services/caccounts/displayModeConfigurations',
        fetch: function () {
            this.url = Settings.url('rest/v2/displayModeConfigurations/' + this.get('id'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'parameter': null,
            'location': null
        },
        idAttribute: 'id',
        postUrl: function () {
            return Settings.url('rest/v2/displayModeConfigurations');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'DisplayModeConfigurationJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'DisplayModeConfiguration',
        importable: true,
        getConfig: function (location) {
            if (location === 'ValMini') {
                return {
                    align: 'horizontal',
                    displayCt: false,
                    displayResult: false
                };
            }
            if (location === 'WellTarget') {
                return {
                    align: 'horizontal',
                    displayCt: false,
                    displayResult: true
                };
            }
            if (location === 'TooltipDot') {
                return {
                    align: 'horizontal',
                    displayCt: false,
                    displayResult: true
                };
            }
            if (location === 'WellCurve') {
                return {
                    align: 'horizontal',
                    displayCt: true,
                    displayQuantification: true,
                    displayResult: true
                };
            }
            var param = this.get('parameter').param ? this.get('parameter').param.split(';') : [];
            switch (this.get('parameter').type) {
                case 'NONE':
                    return {};
                case 'DOT' :
                    var data = {
                        displayResult: false,
                        displayCt: false,
                        displayQuantification: false
                    };

                    if (param.length > 1) {
                        data.displayResult = param[1] === 'true';
                    }
                    if (param.length > 2) {
                        data.displayCt = param[2] === 'true';
                    }
                    if (param.length > 3) {
                        data.displayQuantification = param[3] === 'true';
                    }

                    switch (location) {
                        case 'Plate':
                        case 'TooltipHistogram':
                        case 'Well':
                        case 'Val':
                        case 'WellList':
                            data.width = 50;
                            break;
                        case 'TargetList':
                            data.width = 20;
                            break;
                    }
                    return data;
                case 'HISTOGRAM':
                    var lines = [];
                    var min = 0;
                    var max = 6;
                    var display = 'VERTICAL';
                    var displayResult = false;
                    var displayCt = false;
                    var displayQuantification = false;

                    var delta = 0;
                    if (param.length > 1 && _.isNumber(Number(param[1]))) {
                        min = Number(param[1]);
                        if (min < 0) {
                            delta = Math.abs(min);
                        } else {
                            delta = min;
                        }
                    }
                    // check ici que grid 1 qui est en 0 s'affiche bien'
                    if (param.length > 2 && _.isNumber(Number(param[2]))) {
                        max = Number(param[2]);
                    }
                    if (param.length > 3 && _.isNumber(Number(param[3]))) {
                        lines.push({index: Number(param[3]) + delta, color: 'black', dashed: false});
                    }
                    if (param.length > 4 && _.isNumber(Number(param[4]))) {
                        lines.push({index: Number(param[4]) + delta, color: 'black', dashed: false});
                    }
                    if (param.length > 5 && _.isNumber(Number(param[5]))) {
                        lines.push({index: Number(param[5]) + delta, color: 'black', dashed: true});
                    }
                    if (param.length > 6 && param[6]) {
                        display = param[6];
                    }
                    if (param.length > 7 && param[7]) {
                        displayResult = param[7] === 'true';
                    }
                    if (param.length > 8 && param[8]) {
                        displayCt = param[8] === 'true';
                    }
                    if (param.length > 9 && param[9]) {
                        displayQuantification = param[9] === 'true';
                    }

                    switch (location) {
                        case 'Plate':
                            return {
                                type: display === 'HORIZONTAL' ? 'horizontalBar' : 'bar',
                                display: {
                                    ct: displayCt,
                                    result: displayResult,
                                    quantification: displayQuantification
                                },
                                lines: lines,
                                min: min,
                                max: max
                            };
                        case 'TooltipHistogram':
                            return {
                                type: display === 'HORIZONTAL' ? 'horizontalBar' : 'bar',
                                display: {
                                    ct: displayCt,
                                    result: displayResult,
                                    quantification: displayQuantification
                                },
                                lines: lines,
                                min: min,
                                max: max
                            };
                        case 'Well':
                            return {
                                type: display === 'HORIZONTAL' ? 'horizontalBar' : 'bar',
                                display: {
                                    ct: displayCt,
                                    result: displayResult,
                                    quantification: displayQuantification
                                },
                                lines: lines,
                                min: min,
                                max: max,
                                width: 90,
                                height: 20
                            };
                        case 'Val':
                            return {
                                type: display === 'HORIZONTAL' ? 'horizontalBar' : 'bar',
                                display: {
                                    ct: displayCt,
                                    result: displayResult,
                                    quantification: displayQuantification
                                },
                                lines: lines,
                                min: min,
                                max: max,
                                height: 30,
                                width: display === 'HORIZONTAL' ? 60 : 14
                            };
                        case 'WellList':
                            return {
                                type: display === 'HORIZONTAL' ? 'horizontalBar' : 'bar',
                                display: {
                                    ct: displayCt,
                                    result: displayResult,
                                    quantification: displayQuantification
                                },
                                lines: lines,
                                min: min,
                                max: max,
                                height: 35,
                                width: 20
                            };
                        case 'TargetList':
                            return {
                                type: display === 'HORIZONTAL' ? 'horizontalBar' : 'bar',
                                display: {
                                    ct: displayCt,
                                    result: displayResult,
                                    quantification: displayQuantification
                                },
                                lines: lines,
                                min: min,
                                max: max,
                                height: 35,
                                width: 20
                            };
                    }
                    break;
                case 'REFVALUE':
                    var config = {
                        zoneMin: 30,
                        zoneMax: 60
                    };
                    if (param.length > 0) {
                        config.zoneMin = parseFloat(param[0]);
                    }
                    if (param.length > 1) {
                        config.zoneMax = parseFloat(param[1]);
                    }
                    return config;
                default:
                    throw new Error('Unknown parameter type ' + this.get('parameter').type);
            }
            throw new Error('Unknown location ' + location);
        },
        getSequenceFromLocation: function () {
            switch (this.get('location')) {
                case 'Plate':
                    return 0;
                case 'Well':
                    return 1;
                case 'ValMini':
                    return 2;
                case 'Val':
                    return 3;
                case 'TooltipDot':
                    return 4;
                case 'TooltipHistogram':
                    return 5;
                case 'WellList':
                    return 6;
                case 'TargetList':
                    return 7;
            }
        }
    });

    app.DisplayModeConfiguration.role = RolesMixin.DISPLAYMODE;

    app.DisplayModeConfigurationCollection = Backbone.Collection.extend({
        model: app.DisplayModeConfiguration,
        comparator: function (m1, m2) {
            var str1 = m1.getSequenceFromLocation();
            var str2 = m2.getSequenceFromLocation();
            return str1 - str2;
        }
    });

});

