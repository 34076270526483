define('services/caccounts/displayModeConfigurations',[
    'entities/ns',
    'settings',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/displayModeConfigurations'
], function (
    app,
    Settings,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.DisplayModeConfiguration,
                collection: app.DisplayModeConfigurationCollection
            };
        },
        getName: function () {
            return 'displaymodeconfiguration';
        },
        getUrl: function () {
            return 'rest/v2/displayModeConfigurations/';
        },
        getDynamicJson: function () {
            return 'DisplayModeConfigurationJson';
        },
        getCreateEditLine: function () {
            return [
                {
                    'field': 'location',
                    'param': {
                        'type': 'LABEL',
                        'display': {'class': 'col-w-30'}
                    }
                },
                {
                    'field': 'parameter',
                    'param': {
                        'type': 'TYPEPARAM',
                        'display': {'class': 'col-w-70'},
                        codeListCode: 'DM_'
                    }
                }
            ];
        },
        getConfigCreateEditLine: function () {
            var config = Dynamic.getConfigCreateEditLine();
            config.editButton = false;
            return config;
        }
    }, Dynamic);
});

