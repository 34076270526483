/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemButton',[
    'module',
    'underscore',
    'dynamicListItemPropertyView'
], function (
    module,
    _,
    ItemView
) {
    'use strict';

    module.exports = ItemView.extend({
        template: _.template('<span class="mdi <%= action %> action"></span>'),
        attributes: function () {
            var attr = ItemView.prototype.attributes.apply(this, arguments);
            return _.extend(attr, {
                style: 'width: 24px; line-height: 24px;'
            });
        },
        className: 'clickable',
        ui: {
            span: 'span'
        },
        events: {
            'click': 'onClick'
        },

        onClick: function () {
            if (this.$el.hasClass('clickable')) {
                this.options.callBackOnClick();
            }
        },

        serializeData: function () {
            var templateData = {};
            templateData.action = 'mdi-delete';
            if (this.options.field.get('field') === 'delete') {
                templateData.action = 'mdi-delete';
            } else if (this.options.field.get('field') === 'add') {
                templateData.action = 'mdi-add';
            }
            return templateData;
        },
        onRender: function () {
            if (!this.options.field.get('enable')) {
                this.$el.removeClass('clickable');
                this.ui.span.addClass('disabled');
                this.ui.span.attr('disabled', 'disabled');
            }
        }
    });
});
