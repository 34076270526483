define('samplePcrWellView',[
    'module',
    'backbone.marionette',
    'template!samplePcrWellView',
    'wellUtils',
    'underscore',
    'linkNewTabView',
    'pcrWellResultController'
], function (
    module,
    Marionette,
    WellTargetTpl,
    WellUtils,
    _,
    LinkNewTabView,
    PcrWellResultController
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: WellTargetTpl,

        modelEvents: {
            'change': 'render'
        },

        regions: {
            pcrRun: '.js-pcrrun'
        },

        className: 'col-w-all col-w-100 d-f bottom-border-cell-light',

        onRender: function () {
            var params = {
                model: this.model,
                placement: 'bottom',
                target: this.$el
            };

            WellUtils.showPcrWellPopover(params);
            var PcrRunService = require('services/caccounts/pcrruns');
            this.getRegion('pcrRun').show(new LinkNewTabView(PcrRunService.parameterForLink(this.model.get('run'), PcrRunService)));
        },

        serializeData: function () {
            var templateData = {};
            templateData.id = this.model.get('id');
            templateData.runSecId = this.model.get('runSecId');
            templateData.pos = this.model.get('pos');
            templateData.refAssay = this.model.get('refAssay') ? this.model.get('refAssay').get('code') : '-';
            templateData.results = [];
            this.model.getDisplay('PLATE').each(_.bind(function (result) {
                var resultForTemplateData = {};
                result.targetCode = result.get('mbAna') ? result.get('mbAna').get('code') : '';
                result.cts = result.get('cts');
                result.quantifications = result.get('quantifications');
                result.resCode = result.get('result') ? result.get('result').get('code') : '';
                resultForTemplateData.iconHtml = '';
                if (result.get('refAssayResult')) {
                    resultForTemplateData.iconHtml = PcrWellResultController.generateContent(result, 'WellCurve');
                }
                templateData.results.push(resultForTemplateData);
            }, this));
            return templateData;
        }
    });
});

