define('services/caccounts/lisana',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'backbone',
    'entities/caccounts/lisana'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic,
    Backbone
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.LisAna,
                collection: app.LisAnaCollection
            };
        },
        getName: function () {
            return 'lisana';
        },
        getUrl: function () {
            return 'rest/v2/lisAnas/';
        },
        getDynamicJson: function () {
            return 'LisAnaJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('lisana.lis'),
                name: 'lis',
                formatter: viewDynamic.codeNameFormatter,
                search: true,
                index: 'lis',
                width: 40
            }, {
                label: _.i18n('common.code'),
                name: 'code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 80
            }, {
                label: _.i18n('common.target'),
                name: 'target.code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'refMbAna.code',
                width: 40
            }, {
                label: _.i18n('common.assay.fromResult'),
                name: 'assayFromResult',
                formatter: viewDynamic.codeNameListFormatter,
                sortable: false,
                search: true,
                index: 'assayCode',
                width: 40
            }, {
                label: _.i18n('common.assayGroup'),
                name: 'assayGroups',
                formatter: viewDynamic.codeNameListFormatter,
                sortable: false,
                search: true,
                index: 'assayGroupCode',
                width: 40
            }, {
                label: _.i18n('common.criterias'),
                name: 'criterias',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'criterias',
                width: 40
            }, {
                label: _.i18n('common.tags'),
                name: 'tags',
                formatter: viewDynamic.defaultListFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },

        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push(
                {'field': 'assayFromResult', 'param': {'type': 'LIST2ENTITY'}},
                {'field': 'assayGroups', 'param': {'type': 'LIST2ENTITY'}},
                {
                    field: 'target',
                    'param': {
                        'type': 'REFERENCE',
                        'display': {'class': 'col-xs-3 p-r-0'}
                    }
                },
                {
                    field: 'lis',
                    'param': {
                        'type': 'REFERENCE',
                        'display': {'class': 'col-xs-3 p-r-0'}
                    }
                });
            return custom;
        },
        getCollection: function (modelJson) {
            return new app.LisAnaCollection(modelJson);
        },
        getCreateEditFieldValidate: function () {
            var array = Dynamic.getCreateEditFieldValidate();
            array.push({name: 'lis', type: 'required'});
            return array;
        },
        defineDefaultValue: function (model) {
            var defer = $.Deferred();
            var LisService = require('services/caccounts/lis');
            LisService.findDefault().done(function (value) {
                if (value && value.get('id')) {
                    model.get('lisSet').push(value);
                }
                defer.resolve();
            });
            return defer.promise();
        },
        findAllByLis: function (lis) {
            var defer = $.Deferred();
            if (!lis) {
                defer.resolve(this.getCollection());
                return defer.promise();
            } else {
                defer.resolve(this.getCollection(Backbone.Relational.store.getCollection(this.getNamespace().model).filter(function (model) {
                    return model.get('lis') === lis;
                })));
                return defer.promise();
            }
        },
        getListConfiguration: function () {
            var defer = $.Deferred();
            var configuration = Dynamic.getListConfiguration.call(this);
            configuration.done(function (resp) {
                var UserCodeListService = require('services/caccounts/userCodelist');
                resp.push({
                    fieldName: 'criterias',
                    values: UserCodeListService.findItemsByCode('FCRIT_LISANA'),
                    codeListCode: 'FCRIT_LISANA'
                });
                defer.resolve(resp);
            });
            return defer.promise();
        }
    }, Dynamic);
});

