define('runAssignAssayView',[
    'module',
    'backbone',
    'underscore',
    'jquery',
    'dialogFormView',
    'template!runAssignAssayView',
    'commonWellSelectionView',
    'commonCancelActionView',
    'autocompleteView'
], function (
    module,
    Backbone,
    _,
    $,
    DialogFormView,
    Tpl,
    CommonWellSelectionView,
    CommonCancelActionView,
    AutocompleteView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },

        regions: {
            assayRegion: '.js-assay-region',
            wellSelectionRegion: '.js-well-selection-region',
            cancelActionRegion: '.js-cancel-action-region'
        },

        fieldsToValidate: function () {
            return [
                {name: 'assay', type: 'required'},
                {name: 'wellSelection', type: 'required', subView: this.wellSelectionView}
            ];
        },

        modelEvents: {
            'change:assay': 'onValidateChange',
            'change:wellSelection': 'onValidateChange'
        },

        initialize: function () {
            this.model = new Backbone.Model();
            this.model.set('assay', null);
            this.model.set('wellSelection', null);
            if (!this.options.start) {
                this.options.start = 'A01';
            }
            if (!this.options.to) {
                this.options.to = this.options.model.get('plateSize');
            }
        },

        onRender: function () {
            this.wellSelectionView = new CommonWellSelectionView({
                direction: this.options.direction,
                start: this.options.start,
                to: this.options.to,
                model: this.model,
                fieldName: 'wellSelection'
            });
            this.getRegion('wellSelectionRegion').show(this.wellSelectionView);

            this.commonCancelActionView = new CommonCancelActionView();
            this.listenTo(this.commonCancelActionView, 'action:click', _.bind(this.onActionClick, this));
            this.listenTo(this.commonCancelActionView, 'cancel:click', _.bind(this.onCancel, this));
            this.getRegion('cancelActionRegion').show(this.commonCancelActionView);

            var paramUrl = {
                'sord': 'asc',
                'sidx': 'code'
            };
            switch (this.options.model.getType()) {
                case 'PcrRun':
                case 'PcrRunTemplate':
                    paramUrl.cyclerSecId = this.options.model.get('refCycler') && this.options.model.get('refCycler').get('id') ? this.options.model.get('refCycler').get('id') : null;
                    paramUrl.cyclerPublicSecId = this.options.model.get('refCyclerPublic') ? this.options.model.get('refCyclerPublic').get('id') : null;
                    paramUrl.kitProtSecId = this.options.model.get('refKitProt') ? this.options.model.get('refKitProt').get('id') : null;
                    break;
                case 'PrepRun':
                case 'PrepRunTemplate':
                    paramUrl.reagentType = 'IC';
                    paramUrl.onlyWithCurrentLot = true;
                    break;
            }

            var AssayService = require('services/caccounts/assay');
            var view = new AutocompleteView(
                this._getAutocompleteOptionObject(AssayService.getAutocompleteParam({
                    modelProperty: 'assay',
                    paramUrl: paramUrl
                }))
            );
            this.getRegion('assayRegion').show(view);
            this.commonCancelActionView.enableButtons(false);
        },

        onValidateChange: function () {
            this.commonCancelActionView.enableButtons(this.validate(this.fieldsToValidate()));
        },

        onActionClick: function () {
            var loading = $('.js-global-loader');
            loading.show();
            this.options.model.applyAssayWells(this.model)
                .done(_.bind(function () {
                    this.hide();
                }, this))
                .always(_.bind(function () {
                    loading.hide();
                }, this));
        }
    });
});
