define('prepRunCreateBeadPooling',[
    'module',
    'dialogFormView',
    'fieldUtils',
    'backbone',
    'template!prepRunCreateBeadPooling',
    'savingBehavior',
    'underscore',
    'jquery',
    'autocompleteView',
    'prepRunCreateBeadPoolingCollection',
    'dynamicCreateEditCodeList'
], function (
    module,
    DialogFormView,
    FieldUtils,
    Backbone,
    Tpl,
    SavingBehavior,
    _,
    $,
    AutocompleteView,
    PrepRunCreateBeadPoolingCollection,
    CodeListView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input'
        },
        regions: {
            selectRunContainer: '.js-select-run-container-region',
            sourcePlateDirection: '.js-source-plate-direction-region'
        },

        events: {
            'click .js-cancel-popup': 'hide',
            'click .js-confirm': 'onConfirm'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        fieldsToValidate: [
            {name: 'posFrom', type: 'required'},
            {name: 'posTo', type: 'required'},
            {name: 'runs', type: 'required'},
            {name: 'plateFillDirection', type: 'required'}
        ],

        initialize: function () {
            var runs = new Backbone.Collection();
            runs.push(new Backbone.Model());
            runs.push(new Backbone.Model());

            var SettingService = require('services/caccounts/setting');
            var setting = SettingService.findByCodeAsync('LAB-SETTINGS');
            var direction = 'C';
            if (setting && setting.get('params')) {
                direction = setting.get('params');
            }
            this.model = new Backbone.Model({
                posFrom: 'A01',
                posTo: 'H12',
                runs: runs,
                plateFillDirection: direction
            });
        },

        onRender: function () {
            this.getRegion('selectRunContainer').show(new PrepRunCreateBeadPoolingCollection({
                collection: this.model.get('runs')
            }));
            this.getRegion('sourcePlateDirection').show(new CodeListView({
                model: this.model,
                config: {
                    modelProperty: 'plateFillDirection',
                    code: 'PLATE_FILL_DIRECTION'
                }
            }));
            this.triggerMethod('enable:cancel:confirm');
        },

        onConfirm: function () {
            $('.js-global-loader').show();
            this.options.model.createBeadPooling(this.model)
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                    this.hide();
                }, this));
        },

        hide: function () {
            this.triggerMethod('hide');
            this.box.modal('hide');
            this.destroy();
        }
    });
});
