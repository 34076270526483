define('runPcrOtherPlateLayoutItemView',[
    'module',
    'backbone',
    'backbone.marionette',
    'underscore',
    'wellUtils'
], function (
    module,
    Backbone,
    Marionette,
    _,
    WellUtils
) {
    'use strict';

    module.exports = Marionette.View.extend({
        getTemplate: function () {
            if (this.model.get('assay')) {
                var mdi = WellUtils.findMdiBySampleType(this.model.get('smpType'));
                return _.template('<span class="js-well mdi ' + mdi + '"></span>');
            }
            return _.template('<span class="js-well mdi mdi-circle-outline"></span>');
        },
        className: 'grid-item',
        ui: {
            well: '.js-well'
        },
        attributes: {
            'style': 'width: 100%; height: 100%; align-content: center; justify-content: center; display: flex;'
        },
        onRender: function () {
            if (this.model.get('assay')) {
                this.$el.css('background-color', this.model.get('background-color'));
            }
        }
    });
});

