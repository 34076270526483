
define('template!pcrWellResultHistogramNormalChartView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<canvas class="chart-canvas" style="flex: 1;"></canvas>';

}
return __p
};});

