define('qcListView',[
    'module',
    'backbone.marionette',
    'jquery',
    'underscore',
    'jqGridController',
    'pcrWellResultController',
    'jqGridFormatter',
    'services/caccounts/displayModes',
    'services/caccounts/pcrwells',
    'services/caccounts/pcrwellresults',
    'wellUtils'
], function (
    module,
    Marionette,
    $,
    _,
    JqGridController,
    PcrWellResultController,
    JqGridFormatter,
    DisplayModeService,
    PcrWellService,
    PcrWellResultService,
    WellUtils
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<div class="col-xs-12 js-grid p-l-0 p-r-2"></div>'),
        regions: {
            grid: '.js-grid'
        },
        initialize: function () {
            this.assay = this.model.get('refAssay') ? this.model.get('refAssay') : this.model.get('refAssayReagent').get('refAssay');
            this.cyclers = this.model.get('refAssay') ? [this.model.get('run').get('refCycler')] : [];
        },
        serializeData: function () {
            return {};
        },
        onRender: function () {
            var view = JqGridController.show({
                data: this.options.dataForPcrKitLot,
                pager: true,
                filtersName: this.filtersName,
                gridOptions: _.bind(this.gridOptions, this),
                paginationObject: this.paginationObject,
                service: require('services/caccounts/pcrwells')
            }, this.getRegion('grid'));
            this.listenTo(view, 'click', _.bind(this.onClick, this));
            this.listenTo(view, 'loadComplete', _.bind(this.onLoadComplete, this));
        },
        onClick: function (obj) {
            var PcrWellService = require('services/caccounts/pcrwells');
            var well = PcrWellService.getModel({id: obj.rowId});
            well.fetch().done(function () {
                require(['pcrWellController'], function (PcrWellController) {
                    PcrWellController.showDetails({model: well});
                });
            });
        },
        gridOptions: function (data, jqGridView) {
            var PcrKitLotService = require('services/caccounts/kitlotpcr');
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'id',
                        key: true,
                        hidden: true
                    }, {
                        label: _.i18n('qc.run.date'),
                        name: 'run.period',
                        classes: 'displayPopover',
                        formatter: JqGridFormatter.dateFormatterFromSettings,
                        search: false,
                        width: 150
                    }, {
                        label: _.i18n('qc.run'),
                        name: 'run.name',
                        classes: 'displayPopover',
                        formatter: JqGridFormatter.defaultFormatter,
                        search: false,
                        width: 200
                    }, {
                        label: _.i18n('well.pos'),
                        name: 'pos',
                        classes: 'displayPopover',
                        formatter: JqGridFormatter.defaultFormatter,
                        search: false,
                        width: 40
                    }, {
                        label: _.i18n('well.sampleType'),
                        name: 'smpType',
                        classes: '',
                        formatter: JqGridFormatter.defaultFormatter,
                        search: data.service.getName() === PcrKitLotService.getName(),
                        width: 40,
                        index: 'qualityControl'
                    }, {
                        label: _.i18n('well.assay'),
                        name: 'refAssay',
                        classes: '',
                        formatter: JqGridFormatter.codeNameFormatter,
                        search: data.service.getName() === PcrKitLotService.getName(),
                        width: 150,
                        index: 'assayCode'
                    }],

                sidx: 'runNameDate',
                sord: 'desc',
                autowidth: true,
                shrinkToFit: false,
                onSelectRow: _.bind(function (rowId) {
                    this.trigger('wellSelected', rowId);
                }, this)
            };
            var assayConfiguration;
            if (this.assay.get('configurations').length === 1) {
                assayConfiguration = this.assay.get('configurations').first();
            } else {
                assayConfiguration = this.assay.get('configurations').chain()
                    .filter(_.bind(function (config) {
                        return config.get('cyclers').any(_.bind(function (cyclerConfig) {
                            return _.any(this.cyclers, function (cycler) {
                                return cycler === cyclerConfig;
                            });
                        }, this));
                    }, this))
                    .first()
                    .value();
            }

            var extraOptions = jqGridView.columnsForAssayConfiguration(assayConfiguration, 'WELL_LIST', 'WellList');
            options.colModel = options.colModel.concat(extraOptions.colModel);
            options.formatters = extraOptions.formatters;

            return options;
        },
        onLoadComplete: function (gridView, datas) {
            var collection = PcrWellService.getCollection(datas);
            collection.each(function (well) {
                if (!well) {
                    return;
                }
                var popovers = gridView.$el.find('tr#' + well.id + ' .displayPopover');
                if (popovers.length < 1) {
                    return;
                }

                var params = {
                    model: well
                };

                params.targets = popovers;
                params.placement = 'bottom';
                WellUtils.showPcrWellPopover(params);
            });
        }
    });
});
