define('entities/caccounts/kitlotpcr',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'rolesMixin',
    'entities/caccounts/kitspcr',
    'entities/caccounts/assayReagentTargetPcrKitLots',
    'entities/caccounts/userpids',
    'entities/caccounts/pcrwells',
    'entities/caccounts/prepwells'
], function (
    app,
    Backbone,
    Settings,
    $,
    _,
    RolesMixin
) {
    'use strict';

    app.KitLotPcr = Backbone.RelationalModel.extend({
        service: 'services/caccounts/kitlotpcr',
        fetch: function () {
            this.url = Settings.url('rest/v2/kitlots/pcr/' + this.get('id'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        initialize: function () {
            this.listenTo(this, 'change:status', _.bind(this.onSetStatus, this));
        },
        defaults: {
            'kit': null,
            'code': '',
            'status': 'DELIVERED',
            'expiration': null,
            'description': '',
            'assayReagentTargetPcrKitLots': [],
            'deliveryBy': null,
            'testBy': null,
            'validatedBy': null,
            'validated2By': null,
            'currentBy': null,
            'pausedBy': null,
            'exhaustedBy': null
        },
        relations: [{
            type: Backbone.HasOne,
            key: 'kit',
            relatedModel: 'KitPcr',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'assayReagentTargetPcrKitLots',
            relatedModel: 'AssayReagentTargetPcrKitLot',
            collectionType: 'AssayReagentTargetPcrKitLotCollection',
            reverseRelation: {
                key: 'refKitLotPcr',
                includeInJSON: ['id', 'code', 'name']
            }
        }, {
            type: Backbone.HasOne,
            key: 'deliveryBy',
            relatedModel: 'UserPid',
            includeInJSON: ['id', 'firtName', 'lastName', 'email', 'nickName']
        }, {
            type: Backbone.HasOne,
            key: 'testBy',
            relatedModel: 'UserPid',
            includeInJSON: ['id', 'firtName', 'lastName', 'email', 'nickName']
        }, {
            type: Backbone.HasOne,
            key: 'validatedBy',
            relatedModel: 'UserPid',
            includeInJSON: ['id', 'firtName', 'lastName', 'email', 'nickName']
        }, {
            type: Backbone.HasOne,
            key: 'validated2By',
            relatedModel: 'UserPid',
            includeInJSON: ['id', 'firtName', 'lastName', 'email', 'nickName']
        }, {
            type: Backbone.HasOne,
            key: 'currentBy',
            relatedModel: 'UserPid',
            includeInJSON: ['id', 'firtName', 'lastName', 'email', 'nickName']
        }, {
            type: Backbone.HasOne,
            key: 'pausedBy',
            relatedModel: 'UserPid',
            includeInJSON: ['id', 'firtName', 'lastName', 'email', 'nickName']
        }, {
            type: Backbone.HasOne,
            key: 'exhaustedBy',
            relatedModel: 'UserPid',
            includeInJSON: ['id', 'firtName', 'lastName', 'email', 'nickName']
        }],
        idAttribute: 'id',

        get: function (attr) {
            if (attr === 'statusDate') {
                switch (this.get('status')) {
                    case 'DELIVERED':
                        return this.get('deliveryDate');
                    case 'TEST':
                        return this.get('testDate');
                    case 'VAL1':
                        return this.get('validatedDate');
                    case 'VAL2':
                        return this.get('validated2Date');
                    case 'CURRENT':
                        return this.get('currentDate');
                    case 'PAUSED':
                        return this.get('pausedDate');
                    case 'EXHAUSTED':
                        return this.get('exhaustedDate');
                }
            } else if (attr === 'statusBy') {
                switch (this.get('status')) {
                    case 'DELIVERED':
                        return this.get('deliveryBy');
                    case 'TEST':
                        return this.get('testBy');
                    case 'VAL1':
                        return this.get('validatedBy');
                    case 'VAL2':
                        return this.get('validated2By');
                    case 'CURRENT':
                        return this.get('currentBy');
                    case 'PAUSED':
                        return this.get('pausedBy');
                    case 'EXHAUSTED':
                        return this.get('exhaustedBy');
                }
            } else if (attr === 'statusComment') {
                switch (this.get('status')) {
                    case 'DELIVERED':
                        return this.get('deliveryComment');
                    case 'TEST':
                        return this.get('testComment');
                    case 'VAL1':
                        return this.get('validatedComment');
                    case 'VAL2':
                        return this.get('validated2Comment');
                    case 'CURRENT':
                        return this.get('currentComment');
                    case 'PAUSED':
                        return this.get('pausedComment');
                    case 'EXHAUSTED':
                        return this.get('exhaustedComment');
                }
            }
            return Backbone.RelationalModel.prototype.get.call(this, attr);
        },

        postUrl: function () {
            return Settings.url('rest/v2/kitlots/pcr/');
        },

        save: function () {
            this.set({name: this.get('code')}, {silent: true});
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        onSetStatus: function (model, status) {
            model.set({status: status}, {silent: true});

            var data = {};

            if (['DELIVERED', 'TEST', 'VAL1', 'VAL2', 'CURRENT', 'PAUSED'].includes(status)) {
                data.exhaustedDate = null;
                data.exhaustedBy = null;
            }

            if (['DELIVERED', 'TEST', 'VAL1', 'VAL2', 'CURRENT'].includes(status)) {
                data.pausedDate = null;
                data.pausedBy = null;
            }

            if (['DELIVERED', 'TEST', 'VAL1', 'VAL2'].includes(status)) {
                data.currentDate = null;
                data.currentBy = null;
            }

            if (['DELIVERED', 'TEST', 'VAL1'].includes(status)) {
                data.validated2Date = null;
                data.validated2By = null;
            }

            if (['DELIVERED', 'TEST'].includes(status)) {
                data.validatedDate = null;
                data.validatedBy = null;
            }

            if (['DELIVERED'].includes(status)) {
                data.testDate = null;
                data.testBy = null;
            }
            this.set(data);
        },

        jsonObjectName: 'PcrKitLotJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'PcrKitLot',

        importable: true,
        getAssays: function () {
            return _.uniq(_.compact(new Backbone.Collection(this.get('assayReagentTargetPcrKitLots').pluck('refAssayReagent')).pluck('refAssay')));
        },
        getReagents: function (assayModel) {
            return _.uniq(_.compact(new Backbone.Collection(new Backbone.Collection(this.get('assayReagentTargetPcrKitLots').pluck('refAssayReagent')).filter(function (assayReagent) {
                return !assayModel || assayReagent.get('refAssay') === assayModel;
            })).pluck('reagent')));
        },
        loadWells: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/kitlots/pcr/' + this.get('id') + '/wells');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: function (response) {
                    defer.resolve({
                        pcrWells: new app.PcrWellCollection(response.pcrWells),
                        prepWells: new app.PrepWellCollection(response.prepWells)
                    });
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    });

    app.KitLotPcr.role = RolesMixin.KIT_PCR_LOT;

    app.KitLotPcrCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/kitlots/pcr/');
        },
        model: app.KitLotPcr,
        comparator: function (m1, m2) {
            var str1 = (m1.get('kit') ? m1.get('kit').get('code') : '') + '_' + m1.get('deliveryDate') + '_' + m1.get('code');
            var str2 = (m2.get('kit') ? m2.get('kit').get('code') : '') + '_' + m2.get('deliveryDate') + '_' + m2.get('code');
            return str2.localeCompare(str1);
        }
    });

});

