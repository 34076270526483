require([
    'jquery',
    'pdfmake',
    'htmlMinify',
    'JSZip',
    'vfs_fonts',

    // grid
    'jqgrid/grid.base',
    'jqgrid/grid.celledit',
    'jqgrid/grid.common',
    'jqgrid/grid.filter',
    'jqgrid/grid.formedit',
    'jqgrid/grid.grouping',
    'jqgrid/grid.import',
    'jqgrid/grid.inlinedit',
    'jqgrid/grid.jqueryui',
    'jqgrid/grid.pivot',
    'jqgrid/grid.subgrid',
    'jqgrid/grid.treegrid',
    'jqgrid/jqDnR',
    'jqgrid/jqModal',
    'jqgrid/jquery.fmatter',
    'jqgrid/grid.utils',
    'jqgrid/addons/ui.multiselect',
    'jqgrid/grid.aria',
    'jqgrid/grid.transposed',
    'jqgrid/grid.frozenrows',
    'jqgrid/grid.database',
    'jqgrid/grid.export',
    'grid.postext'

], function ($,
             pdfMake,
             htmlMinify,
             JSZip) {
    'use strict';
    pdfMake.addVirtualFileSystem(window.vfs);
    window.JSZip = JSZip;

    // override call method formatter
    var oldCall = $.jgrid.stripHtml;
    $.extend($.jgrid, {
        stripHtml: function (v) {
            return htmlMinify.process(oldCall(v));
        }
    });
});
define("jqgridMbl", ["jqueryui"], function(){});

