define('runPcrValidationMainWellView',[
    'module',
    'underscore',
    'jquery',
    'jqGridController',
    'backbone.marionette',
    'jqGridFormatter',
    'wellUtils',
    'colorUtils',
    'pcrWellResultController'
], function (
    module,
    _,
    $,
    JqGridController,
    Marionette,
    JqGridFormatter,
    WellUtils,
    ColorUtils,
    PcrWellResultController
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<div class="js-grid-region d-f" style="flex: 1; width: 100%; height: 100%;"></div>'),
        regions: {
            gridRegion: '.js-grid-region'
        },

        className: 'd-f',
        attributes: {
            style: 'flex: 1; width: 100%; height: 100%;'
        },

        paginationObject: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: 9999,
                currentPage: 1
            };
        },

        onRender: function () {
            var data = this.getListViewData(this.model.get('wellSelected'));

            var view = JqGridController.show({
                data: data,
                pager: true,
                filtersName: 'wellValidationTableListFilters',
                gridOptions: _.bind(this.gridOptions, this),
                paginationObject: this.paginationObject
            }, this.getRegion('gridRegion'));
            this.listenTo(view, 'click', _.bind(this.onClick, this));
            this.listenTo(view, 'loadComplete', _.bind(this.onLoadComplete, this));
        },

        onLoadComplete: function (jqGridView, datas) {
            this.setWellListPopover(jqGridView, datas);
        },

        modelEvents: {
            'change:assayResultVersion': 'render'
        },

        gridOptions: function (data, jqGridView) {
            var SettingService = require('services/caccounts/setting');
            var setting = SettingService.findByCodeAsync('LAB_SETTINGS');
            var direction = setting && setting.get('params') ? setting.get('params') : 'C';
            var maxColumns = this.model.get('run').getColumn();
            var maxRows = this.model.get('run').getLine().charCodeAt(0) - 'A'.charCodeAt(0);
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'id',
                        key: true,
                        hidden: true
                    }, {
                        label: ' ',
                        name: 'select',
                        sortable: false,
                        search: false,
                        classes: 'select-link-action ignore-row-click',
                        formatter: JqGridFormatter.rowSelectorFormatter,
                        fixed: true,
                        width: 25,
                        checked: true
                    }, {
                        label: JqGridFormatter.getSmpTypeLabelIcon(),
                        name: 'smpType',
                        formatter: _.bind(this.smptypeFormater, this),
                        search: true,
                        sortable: true,
                        index: 'smpType',
                        stype: 'select',
                        searchrules: {select: true},
                        searchoptions: {sopt: ['eq'], value: this.smpTypeList},
                        fixed: true,
                        width: 30
                    }, {
                        label: _.i18n('well.pos'),
                        name: 'pos',
                        formatter: JqGridFormatter.wellPosFormatter,
                        classes: 'css-grid-cell-accentuatuion-03',
                        sortable: true,
                        title: false,
                        index: 'pos',
                        fixed: true,
                        width: 50,
                        search: false,
                        searchoptions: {
                            sopt: ['cn']
                        },
                        sorttype: function (pos) {
                            var match = pos.match(/^([A-H])(\d{2})$/);
                            if (!match) return pos; // Sécurité si le format ne correspond pas

                            var row = match[1]; // Lettre (axe Y)
                            var col = parseInt(match[2], 10); // Nombre (axe X)

                            if (direction === 'C') {
                                // Tri colonne par colonne : B01 doit venir après A01
                                return col * maxRows + (row.charCodeAt(0) - 'A'.charCodeAt(0));
                            } else {
                                // Tri ligne par ligne : A02 doit venir après A01
                                return (row.charCodeAt(0) - 'A'.charCodeAt(0)) * maxColumns + col;
                            }
                        }
                    }, {
                        label: _.i18n('well.smpId'),
                        name: 'smpId.code',
                        formatter: _.bind(this.wellSmpidFormatter, this),
                        classes: 'css-grid-cell-accentuatuion-02 displayPopover',
                        search: true,
                        sortable: true,
                        title: false,
                        index: 'smpId.code',
                        searchoptions: {
                            sopt: ['cn']
                        },
                        fixed: true,
                        width: 130
                    }, {
                        label: _.i18n('well.validation'),
                        name: 'valWst',
                        formatter: JqGridFormatter.validationFormatter,
                        search: false,
                        width: 40,
                        fixed: true
                    }
                ],
                sortname: this.sortOn ? this.sortOn : 'pos'
            };

            var extraOptions = jqGridView.columnsForAssayVersion(this.model.get('assayResultVersion').get('assayVersion'), 'VALIDATION', 'Val');
            options.colModel = options.colModel.concat(extraOptions.colModel);
            options.formatters = extraOptions.formatters;
            options.shrinkToFit = true;
            options.autowidth = true;
            
            return options;
        },

        onClick: function (obj) {
            require(['pcrWellController'], _.bind(function (PcrWellController) {
                var well = this.model.get('wellSelected').get(obj.rowId);
                PcrWellController.showDetails({model: well});
            }, this));
        },
        onGridComplete: function () {
            var $item = this.$('.js-wellRes-color-cell');
            _.each($item, _.bind(function (item) {
                var $item = $(item);
                var color = $item.attr('data-color');
                var rgbObj = ColorUtils.hexToRgba(color);
                var rgbaCol = 'rgba(' + rgbObj.r + ',' + rgbObj.g + ',' + rgbObj.b + ', 0.2) !important';
                $item.parent().parent().parent().attr('style', 'background-color: ' + rgbaCol + ';');
            }, this));
        },

        wellSmpidFormatter: function (cellValue, options, object) {
            if (!cellValue) {
                cellValue = '';
            }
            if (object.pos) {
                return '<span class="cell-default wellSmpidPopover-' + object.pos + '" data-row-id="' +
                    options.rowId + '" style="font-weight: 600;">' + cellValue + '</span>';
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '" style="font-weight: 600;">' + cellValue + '</span>';
        },

        smptypeFormater: function (cellValue) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12 p-l-15">';

            if (cellValue === 'NC') {
                start += '<span class="mdi mdi-minus-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'PC') {
                start += '<span class="mdi mdi-plus-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'OC') {
                start += '<span class="mdi mdi-compass" style="font-size: 17px;"></span>';
            } else if (cellValue === 'D') {
                start += '<span class="mdi mdi-alpha-d-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'C') {
                start += '<span class="mdi mdi-alpha-c-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'RC') {
                start += '<span class="mdi mdi-alpha-r-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'P') {
                start += '<span class="mdi mdi-google-circles-communities" style="font-size: 17px;"></span>';
            }

            start += '</div>';
            end = '</div>';
            return start + end;
        },

        getListViewData: function (filteredWells) {
            var wellListViewData = this.getWellListViewData(filteredWells);
            var data = {
                datatype: 'local',
                data: wellListViewData.wellsForData
            };
            return data;
        },
        getWellListViewData: function (wells) {
            var wellsForData = wells.map(function (well) {
                var obj = well.toJSON();
                well.getResults().each(function (result) {
                    var code = 'target-' + result.get('refAssayResult').get('target').id;
                    obj[code] = result.get('result').get('code');
                });
                return obj;
            });
            return {
                wellsForData: wellsForData
            };
        },
        setWellListPopover: function (jqGridView, datas) {
            _.each(datas, _.bind(function (data) {
                var well = this.model.get('wellSelected').get(data.id);
                if (!well) {
                    return;
                }
                var popovers = jqGridView.$el.find('tr#' + data.id + ' .displayPopover');
                if (popovers.length < 1) {
                    return;
                }

                var params = {
                    model: well
                };

                params.targets = popovers;

                var placement = 'right';
                var index = _.indexOf(datas, data);
                if (index < 3) {
                    placement = 'bottom';
                } else if (index > datas.length - 4) {
                    placement = 'top';
                }
                params.placement = placement;
                WellUtils.showPcrWellPopover(params);
            }, this));
        },
        resultFormatter: function (cellValue, call, object) {
            var model = this.model.get('wellSelected').findWhere({id: object.id});
            var html = '';
            var result = model.findResultByTargetId(call.colModel.targetId);
            if (result) {
                html = PcrWellResultController.generateContent(result, 'Val');
            }
            if (result.get('refAssayResult') === this.model.get('assayResultVersion')) {
                html = '<div class="selected">' + html + '</div>';
            }
            return html;
        }
    });
});

