define('tableView',[
    'module',
    'dialogCollectionView',
    'underscore',
    'tableItemView'
], function (
    module,
    DialogFormCollection,
    _,
    ItemView
) {
    'use strict';

    module.exports = DialogFormCollection.extend({
        getTemplate: function () {
            var ret = '<thead><tr>';
            _.each(this.options.columns, function (column) {
                ret += '<th class="' + column.className + '">' + column.label + '</th>';
            });
            ret += '</tr></thead>';
            ret += '<tbody></tbody></table>';
            return _.template(ret);
        },
        className: 'table table-striped',
        tagName: 'table',
        childView: ItemView,
        childViewContainer: 'tbody',
        childViewOptions: function () {
            return {columns: this.options.columns};
        }
    });
});
