
define('template!superAdminGodModeView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="thumbnail">\n    <div class="caption">\n        <h3>' +
((__t = ( _.i18n('godMode.displayLabel') )) == null ? '' : __t) +
'</h3>\n        <p>\n            <input type="checkbox" class="js-display-label" />\n        </p>\n    </div>\n</div>\n\n<div class="thumbnail">\n    <div class="caption">\n        <h3>' +
((__t = ( _.i18n('godMode.alertCodeList') )) == null ? '' : __t) +
'</h3>\n        <p>\n            <input type="checkbox" class="js-alert-code-list" />\n        </p>\n    </div>\n</div>\n\n<div class="thumbnail">\n    <div class="caption">\n        <h3>' +
((__t = ( _.i18n('godMode.clearCache.caccount') )) == null ? '' : __t) +
'</h3>\n        <p>\n            <button class="btn btn-primary js-clear-cache-caccount">' +
((__t = ( _.i18n('godMode.clearCache') )) == null ? '' : __t) +
'</button>\n        </p>\n    </div>\n</div>\n<div class="thumbnail">\n    <div class="caption">\n        <h3>' +
((__t = ( _.i18n('godMode.clearCache') )) == null ? '' : __t) +
'</h3>\n        <p>\n            <button class="btn btn-primary js-clear-cache">' +
((__t = ( _.i18n('godMode.clearCache') )) == null ? '' : __t) +
'</button>\n        </p>\n    </div>\n</div>\n\n<div class="thumbnail">\n    <div class="caption">\n        <h3>' +
((__t = ( _.i18n('godMode.showCacheList') )) == null ? '' : __t) +
'</h3>\n        <p>\n            <button class="btn btn-primary js-show-cache">' +
((__t = ( _.i18n('godMode.showCache') )) == null ? '' : __t) +
'</button>\n        </p>\n    </div>\n</div>';

}
return __p
};});

