define('runPcrContentContaminationWellView',[
    'module',
    'backbone.marionette',
    'backbone',
    'wellUtils',
    'template!runPcrContentContaminationWellView',
    'underscore',
    'jquery',
    'sampleCommentView',
    'qcChartPopup',
    'fieldUtils',
    'colorUtils'
], function (
    module,
    Marionette,
    Backbone,
    WellUtils,
    wellTpl,
    _,
    $,
    EditSampleCommentView,
    QcView,
    FieldUtils,
    ColorUtils
) {
    'use strict';

    module.exports = Marionette.View.extend({
        className: 'cell-well pcr',
        events: {
            'click': 'onItemClick',
            'click .js-btn-preprun-kitLot': 'onKitLotClick',
            'click .btn-assay': 'onAssayClick',
            'click .smp-id-link': 'onSampleIdClick',
            'click .js-btn-comment': 'onCommentClick',
            'click .mdi-alert-octagram': 'onClickError'
        },

        ui: {
            'divMeterBar': '.divMeterBar'
        },

        onCommentClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            this.model.get('smpId').fetch().done(_.bind(function () {
                this.editSampleCommentView = new EditSampleCommentView({
                    wellModel: this.model,
                    model: this.model.get('smpId')
                });
                this.editSampleCommentView.show({
                    title: _.i18n('edit.sampleComment'),
                    className: ''
                });
            }, this))
                .always(_.bind(function () {
                    this.$el.trigger('mouseleave');
                }, this));
        },

        onKitLotClick: function (e) {
            e.preventDefault();
            e.stopPropagation();

            var loader = $('.js-global-loader');
            loader.show();
            this.model.fetch().done(_.bind(function () {
                require(['assayVersionController'], _.bind(function (AssayVersionController) {
                    this.model.getPcrKitLots().done(_.bind(function (response) {
                        AssayVersionController.showDetails({
                            model: this.model.get('assayVersion'),
                            options: {
                                lots: response
                            }
                        });
                    }, this))
                        .always(_.bind(function () {
                            loader.hide();
                            this.$el.trigger('mouseleave');
                        }, this));
                }, this));
            }, this));
        },

        onAssayClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (this.model.get('refAssay')) {
                this.trigger('well:filteredDetailAssay', this.model.get('refAssay').get('id'));
            }
            this.$el.trigger('mouseleave');
        },
        modelEvents: {
            'change': 'render',
            'change:assayResultVersion': 'changeAssayResultVersion'
        },

        initialize: function () {
            this.assayResultVersion = this.model.get('run').get('wells').chain()
                .map(function (well) {
                    return well.get('assayVersion');
                })
                .filter(function (assayVersion) {
                    return assayVersion;
                })
                .map(function (assayVersion) {
                    return assayVersion.get('results').first();
                })
                .first().value();

            var SettingService = require('services/caccounts/setting');
            var setting = SettingService.findByCodeAsync('CONTAM-ALGO');
            this.contaminationAlgoSetting = setting && setting.get('params') ? setting.get('params').split(';') : [];

            setting = SettingService.findByCodeAsync('CONTAM-WARN');
            this.contaminationWariningSetting = setting && setting.get('params') ? setting.get('params').split(';') : [];
        },

        getTemplate: function () {
            if (this.model.getDisplay('CONTAMINATION').isEmpty() || ['PC', 'QC', 'NC', 'RC'].includes(this.model.get('smpType'))) {
                return _.template('');
            }
            return wellTpl;
        },

        getWellResDifference: function (num1, num2) {
            var difference = 0;
            if (num1 < num2) {
                difference = num2 - num1;
            } else if (num1 > num2) {
                difference = num1 - num2;
            }
            return difference;
        },

        getColorContaminationArrowColor: function (num1, num2) {
            var color = '#333333';
            var lowCt = 8;
            var highCt = 10;

            if (this.contaminationWariningSetting[0]) {
                lowCt = this.contaminationWariningSetting[0];
            }
            if (this.contaminationWariningSetting[1]) {
                highCt = this.contaminationWariningSetting[1];
            }
            if (this.contaminationWariningSetting[2]) {
                color = this.contaminationWariningSetting[2];
            }

            var difference = this.getWellResDifference(num1, num2);

            if (difference < lowCt) {
                return 'transparent';
            } else if (difference > highCt) {
                this.backgroundColorFull = true;
                return color;
            } else {
                this.backgroundColorNormal = true;
                return color;
            }
        },

        getContaminationCellColor: function () {
            var color = '#FF6500';
            if (this.contaminationWariningSetting[3]) {
                color = this.contaminationWariningSetting[3];
            }

            if (!this.isWellToDisplay(this.model)) {
                return 'transparent';
            }

            if (this.backgroundColorFull) {
                return ColorUtils.defineAlpha(color, '9B');
            } else if (this.backgroundColorNormal) {
                return ColorUtils.defineAlpha(color, '33');
            } else {
                return '#efefef';
            }
        },

        serializeData: function () {
            var templateData = {
                well: {
                    smpType: this.model.get('smpType'),
                    repeatStatus: this.model.get('repeatStatus'),
                    showQC: this.model.get('showQC'),
                    pos: this.model.get('pos'),
                    tags: this.model.get('tags'),
                    existOtherWellSameAssay: this.model.get('existOtherWellSameAssay'),
                    existOtherWellOtherAssay: this.model.get('existOtherWellOtherAssay')
                },
                comment: this.model.get('smpId') ? ((this.model.get('smpId').get('lisComment') ? this.model.get('smpId').get('lisComment') + ' ' : '') + (this.model.get('smpId').get('comment') ? this.model.get('smpId').get('comment') : '')).substring(0, 15) : null,
                error: this.model.get('entityError') || this.model.get('smpId').get('verified') === 'NOT_VALIDATED' || this.model.get('smpId').get('entityError'),
                displayKitLot: !!this.model.get('assayVersion'),
                fontSize: '15px',
                ctTop: '32px',
                colorOtherWellSameAssay: '#FF0000',
                colorOtherWellOtherAssay: '#FFFFFF',
                wellResColor: 'transparent',
                topLeftCT: false,
                topCenterCT: false,
                topRightCT: false,
                middleLefCT: false,
                middleRightCT: false,
                bottomLeftCT: false,
                bottomCenterCT: false,
                bottomRightCT: false,
                wellResNum: null
            };
            var SettingService = require('services/caccounts/setting');
            var setting = SettingService.findByCodeAsync('OTHER-ASSAY-IND');

            if (setting && setting.get('params')) {
                var otherAssayInd = setting.get('params').split(';');
                if (otherAssayInd[0]) {
                    templateData.colorOtherWellSameAssay = otherAssayInd[0];
                }
                if (otherAssayInd[1]) {
                    templateData.colorOtherWellOtherAssay = otherAssayInd[1];
                }
            }

            templateData.topLeftCtColor = templateData.topCenterCT = templateData.topRightCT = templateData.middleLefCT =
                templateData.middleRightCT = templateData.bottomLeftCtColor = templateData.bottomCenterCtColor = templateData.bottomRightCT = 'transparent';

            templateData.contaminationCellColor = 'transparent';

            var column = this.model.get('pos').substring(1);
            var line = this.model.get('pos').substring(0, this.model.get('pos').length - 2);
            var previousLine = WellUtils.getPreviousLine(line);
            var nextLine = WellUtils.getNextLine(line);
            var previousColumn = WellUtils.getPreviousColumn(column);
            var nextColumn = WellUtils.getNextColumn(column);

            var adjacentWells = {topLeftCT: false, topCenterCT: false, topRightCT: false, middleLefCT: false, middleRightCT: false, bottomLeftCT: false, bottomCenterCT: false, bottomRightCT: false};
            if (previousLine && previousColumn) { // top-left-well-ct
                adjacentWells.topLeftCT = this.findWellCt(previousLine, previousColumn);
            }

            if (previousLine) { // top-center-well-ct
                adjacentWells.topCenterCT = this.findWellCt(previousLine, column);
            }

            if (previousLine && nextColumn) { // top-right-well-ct
                adjacentWells.topRightCT = this.findWellCt(previousLine, nextColumn);
            }

            if (previousColumn) { // middle-left-well-ct
                adjacentWells.middleLefCT = this.findWellCt(line, previousColumn);
            }

            if (nextColumn) { // middle-right-well-ct
                adjacentWells.middleRightCT = this.findWellCt(line, nextColumn);
            }

            if (nextLine && previousColumn) { // bottom-left-well-ct
                adjacentWells.bottomLeftCT = this.findWellCt(nextLine, previousColumn);
            }

            if (nextLine) { // bottom-center-well-ct
                adjacentWells.bottomCenterCT = this.findWellCt(nextLine, column);
            }

            if (nextLine && nextColumn) { // bottom-right-well-ct
                adjacentWells.bottomRightCT = this.findWellCt(nextLine, nextColumn);
            }

            this.calculatedWellRes = null;
            var result = this.model.findResult(this.assayResultVersion);
            if (result) {
                var wellResNums = this.getWellResNumValues(result.get('cts') ? result.get('cts') : null);
                var wellResNumMin = wellResNums && wellResNums.wellResNumMin ? wellResNums.wellResNumMin : null;
                templateData.wellResNum = wellResNumMin;

                var ctMin = wellResNumMin;
                if (ctMin < 20) { // >=15 et <20
                    templateData.fontSize = '30px;';
                    templateData.ctTop = '16px;';
                } else if (ctMin >= 20 && ctMin < 25) {
                    templateData.fontSize = '25px;';
                    templateData.ctTop = '22px;';
                } else if (ctMin >= 25 && ctMin < 30) {
                    templateData.fontSize = '20px;';
                    templateData.ctTop = '28px;';
                } else if (ctMin >= 30) {
                    templateData.fontSize = '15px;';
                    templateData.ctTop = '32px;';
                } else {
                    templateData.fontSize = null;
                    templateData.ctTop = null;
                }
                if (result.get('result')) {
                    templateData.wellResColor = result.get('result').get('color');
                }

                this.calculatedWellRes = wellResNumMin ? this.getCalculatedWellRes(wellResNumMin) : null;
                // topLeftCT
                if (adjacentWells.topLeftCT && adjacentWells.topLeftCT < wellResNumMin) {
                    templateData.topLeftCT = 'incoming';
                    templateData.topLeftCtColor = this.getColorContaminationArrowColor(adjacentWells.topLeftCT, wellResNumMin);
                } else {
                    templateData.topLeftCT = false;
                }
                // topCenterCT
                if (adjacentWells.topCenterCT && adjacentWells.topCenterCT < wellResNumMin) {
                    templateData.topCenterCT = 'incoming';
                    templateData.topCenterCtColor = this.getColorContaminationArrowColor(adjacentWells.topCenterCT, wellResNumMin);
                } else {
                    templateData.topCenterCT = false;
                }
                // topRightCT
                if (adjacentWells.topRightCT && adjacentWells.topRightCT < wellResNumMin) {
                    templateData.topRightCT = 'incoming';
                    templateData.topRightCtColor = this.getColorContaminationArrowColor(adjacentWells.topRightCT, wellResNumMin);
                } else {
                    templateData.topRightCT = false;
                }
                // middleLefCT
                if (adjacentWells.middleLefCT && adjacentWells.middleLefCT < wellResNumMin) {
                    templateData.middleLefCT = 'incoming';
                    templateData.middleLefCtColor = this.getColorContaminationArrowColor(adjacentWells.middleLefCT, wellResNumMin);
                } else {
                    templateData.middleLefCT = false;
                }
                // middleRightCT
                if (adjacentWells.middleRightCT && adjacentWells.middleRightCT < wellResNumMin) {
                    templateData.middleRightCT = 'incoming';
                    templateData.middleRightCtColor = this.getColorContaminationArrowColor(adjacentWells.middleRightCT, wellResNumMin);
                } else {
                    templateData.middleRightCT = false;
                }
                // bottomLeftCT
                if (adjacentWells.bottomLeftCT && adjacentWells.bottomLeftCT < wellResNumMin) {
                    templateData.bottomLeftCT = 'incoming';
                    templateData.bottomLeftCtColor = this.getColorContaminationArrowColor(adjacentWells.bottomLeftCT, wellResNumMin);
                } else {
                    templateData.bottomLeftCT = false;
                }
                // bottomCenterCT
                if (adjacentWells.bottomCenterCT && adjacentWells.bottomCenterCT < wellResNumMin) {
                    templateData.bottomCenterCT = 'incoming';
                    templateData.bottomCenterCtColor = this.getColorContaminationArrowColor(adjacentWells.bottomCenterCT, wellResNumMin);
                } else {
                    templateData.bottomCenterCT = false;
                }
                // bottomRightCT
                if (adjacentWells.bottomRightCT && adjacentWells.bottomRightCT < wellResNumMin) {
                    templateData.bottomRightCT = 'incoming';
                    templateData.bottomRightCtColor = this.getColorContaminationArrowColor(adjacentWells.bottomRightCT, wellResNumMin);
                } else {
                    templateData.bottomRightCT = false;
                }
            }

            templateData.errorWarning = this.model.getDisplay('CONTAMINATION').some(function (result) {
                return result.get('codeErr');
            });

            templateData.expertMode = this.model.getDisplay('CONTAMINATION').some(function (result) {
                return result.get('valSt') === 3.1;
            });
            templateData.hasComment = this.model.get('smpId').get('lisComment') || this.model.get('smpId').get('comment');
            return templateData;
        },

        onRender: function () {
            var highValue = this.contaminationAlgoSetting && this.contaminationAlgoSetting[1] ? this.contaminationAlgoSetting[1] : 40;
            var divider = this.contaminationAlgoSetting && this.contaminationAlgoSetting[2] ? this.contaminationAlgoSetting[2] : 25;

            if (this.calculatedWellRes) {
                var fillerValue = ((parseInt(highValue, 10) - parseInt(this.calculatedWellRes, 10)) / divider) * 100;
                this.ui.divMeterBar.width(fillerValue + '%');
            }

            var params = {
                model: this.model,
                target: this.$el
            };
            WellUtils.showPcrWellPopover(params);

            var color = this.getContaminationCellColor();
            this.$el.css('background-color', ColorUtils.rgbaString(ColorUtils.hexToRgba(color)));
        },

        onSampleIdClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            $('.empty-page-loader').show();

            if (this.model.get('smpType') !== 'PC' &&
                this.model.get('smpType') !== 'NC' &&
                this.model.get('smpType') !== 'RC' &&
                this.model.get('smpType') !== 'OC') {

                require(['samplesController'], _.bind(function (Controller) {
                    this.model.get('smpId').fetch().done(_.bind(function () {
                        Controller.showDetails({model: this.model.get('smpId')});
                    }, this)).always(_.bind(function () {
                        $('.empty-page-loader').hide();
                        this.$el.trigger('mouseleave');
                    }, this));
                }, this));

            } else {
                if (!this.model.get('smpType') || !this.model.get('refAssay') || !this.model.get('refAssay').get('id')) {
                    return;
                }
                this.model.getControlKitLot().done(_.bind(function (controlKits) {
                    this.qcChartPopupLayoutView = new QcView({
                        wellModel: this.model,
                        qcTargetModel: null,
                        collection: new Backbone.Collection(controlKits)
                    });
                    this.qcChartPopupLayoutView.show({
                        title: _.i18n('kitlot:qc:chart:popup'),
                        className: 'runQcChartViewPopup'
                    });
                }, this))
                    .always(_.bind(function () {
                        $('.empty-page-loader').hide();
                        this.$el.trigger('mouseleave');
                    }, this))
                    .always(_.bind(function () {
                        this.$el.trigger('mouseleave');
                    }, this));
            }
        },

        onItemClick: function () {
            require(['pcrWellController'], _.bind(function (PcrWellController) {
                PcrWellController.showDetails({model: this.model});
            }, this));
        },
        findWellCt: function (line, column) {
            var retVal = false;
            var foundWell = this.model.get('run').get('wells').findWhere({'pos': line + column});
            var foundWellToDisplay = this.isWellToDisplay(foundWell);
            var result = foundWell.findResult(this.assayResultVersion);

            if (foundWell && result && result.get('cts').length > 0 && foundWellToDisplay) {
                var wellResNums = this.getWellResNumValues(result.get('cts'));
                retVal = wellResNums && wellResNums.wellResNumMin ? wellResNums.wellResNumMin : false;
            }
            return retVal;
        },
        isWellToDisplay: function (well) {
            if (!well) {
                return false;
            }

            if (!well.get('refAssay')) {
                return false;
            }

            if (!well.get('assayVersion').get('results').any(_.bind(function (result) {
                return result === this.assayResultVersion;
            }, this))) {
                return false;
            }

            var wellResult = well.getDisplay('CONTAMINATION').chain().filter(_.bind(function (result) {
                return result && result.get('refAssayResult') && result.get('refAssayResult') === this.assayResultVersion;
            }, this)).first().value();
            return !!(wellResult && wellResult.get('result') && wellResult.get('result').get('code') &&
                (well.get('smpType') !== 'PC' && well.get('smpType') !== 'NC' && well.get('smpType') !== 'RC' && well.get('smpType') !== 'OC') &&
                wellResult.get('cts').length > 0);
        },
        getCalculatedWellRes: function (wellResNumMin) {
            var lowValue = this.contaminationAlgoSetting ? this.contaminationAlgoSetting[0] : 15;
            var highValue = this.contaminationAlgoSetting ? this.contaminationAlgoSetting[1] : 40;

            if (wellResNumMin < lowValue) {
                return lowValue;
            } else if (wellResNumMin > highValue) {
                return highValue;
            } else {
                return wellResNumMin;
            }
        },
        getWellResNumValues: function (wellResNum) {
            if (!wellResNum) {
                return false;
            }
            var wellResNumMin = wellResNum;
            if (wellResNum && Array.isArray(wellResNum)) {
                if (wellResNum.length === 0) {
                    return false;
                }
                wellResNumMin = FieldUtils.arrayMin(wellResNum);
                wellResNum = wellResNum.join(';');
            }
            if (wellResNum &&
                (typeof wellResNum === 'string' || wellResNum instanceof String)) {
                if (wellResNum.includes(';')) {
                    var wellResNumbers = [];
                    var wellResStringArray = wellResNum.split(';');
                    _.each(wellResStringArray, function (n) {
                        if (n !== '') {
                            wellResNumbers.push(Number(n));
                        }
                    });
                    wellResNumMin = FieldUtils.arrayMin(wellResNumbers);
                } else {
                    wellResNumMin = Number(wellResNum);
                }
            }
            return {
                wellResNumMin: wellResNumMin,
                wellResNum: wellResNum
            };
        },
        changeAssayResultVersion: function (assayResultVersion) {
            this.assayResultVersion = assayResultVersion;
            this.render();
        },
        onClickError: function (e) {
            e.preventDefault();
            e.stopPropagation();
            require(['errorController'], _.bind(function (ErrorController) {
                ErrorController.showE(this.model);
            }, this));
        }
    });
});

