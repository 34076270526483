define('services/global/userpids',[
    'entities/ns',
    'jquery',
    'settings',
    'module',
    'dynamic',
    'underscore',
    'entities/global/userpids'
], function (
    app,
    $,
    Settings,
    module,
    Dynamic,
    _
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.UserPidGlobal,
                collection: app.UserPidGlobalCollection
            };
        },
        getName: function () {
            return 'userpidGlobal';
        },
        getUrl: function () {
            return 'rest/global/userpid/';
        },
        getDynamicJson: function () {
            return 'UserpidGlobalJson';
        },
        getTypeDynamicJson: function () {
            return 'GLOBAL';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.exportable = false;
            config.archivable = false;
            config.selectArchivable = false;
            config.importable = false;
            config.attachmentable = false;
            config.selectable = false;
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('userpid.lastName'),
                name: 'lastName',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'lastName',
                searchoptions: {size: 30},
                width: 25
            }, {
                label: _.i18n('userpid.firstName'),
                name: 'firstName',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'firstName',
                width: 20
            }, {
                label: _.i18n('userpid.nickName'),
                name: 'nickName',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'nickName',
                width: 15
            }, {
                label: _.i18n('userpid.logonId'),
                name: 'logonId',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'logonId',
                searchoptions: {size: 30},
                width: 40
            }, {
                label: _.i18n('userpid.email'),
                name: 'email',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'email',
                searchoptions: {size: 30},
                width: 40
            }, {
                label: _.i18n('userpid.superUser'),
                name: 'superUser',
                classes: 'dynamic-link',
                formatter: viewDynamic.booleanFormatter,
                search: false,
                index: 'superUser',
                width: 50,
                fixed: true
            }, {
                label: _.i18n('userpid.creatDate'),
                name: 'creatDate',
                classes: 'dynamic-link',
                formatter: viewDynamic.dateTimeSecondeFormatter,
                search: true,
                index: 'creatDate',
                searchoptions: {
                    dataInit: function (element) {
                        $(element).datepicker({
                            dateFormat: 'dd-mm-yy',
                            changeYear: true,
                            changeMonth: true,
                            showOn: 'focus',
                            prevText: '<i class="mdi-hardware-keyboard-arrow-left"></i>',
                            nextText: '<i class="mdi-hardware-keyboard-arrow-right"></i>'
                        });
                    }
                },
                width: 25
            }, {
                label: _.i18n('userpid.logo'),
                name: 'logo',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'logo',
                searchoptions: {size: 30},
                width: 20
            });
            return columns;
        },

        getCreateEditFieldValidate: function () {
            return [
                {name: 'firstName', type: 'required'},
                {name: 'lastName', type: 'required'},
                {name: 'nickName', type: 'required'},
                {name: 'email', type: 'required'}
            ];
        },
        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.urlPath = config.url ? config.url : this.getCollectionUrl();
            configuration.values = config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'nickName'
            };
            return configuration;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 300, 'fields': ['email', 'firstName', 'lastName']};
        },
        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore.call(this);
            ignored.push('online', 'password', 'logo');
            return ignored;
        },
        typeFormatter: function (cellValue) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }
            var caccounts = _.pluck(_.pluck(cellValue, 'caccount'), 'code');
            return '<span class="cell-default">' + caccounts + '</span>';
        },

        postData: function (data) {
            var defer = $.Deferred();

            $.ajax({
                type: 'POST',
                url: Settings.url('rest/global/userpid/'),
                data: data,
                processData: false,
                contentType: false,
                success: function (resp) {
                    defer.resolve(resp);
                }
            });
            return defer.promise();
        },
        canAddOrEdit: function () {
            return Settings.get('currentUserModel') && Settings.get('currentUserModel').hasRole(this.getNamespace().model.role);
        },
        getJqGridSidx: function () {
            return 'lastName';
        },
        getJqGridSord: function () {
            return 'asc';
        }
    }, Dynamic);

});

