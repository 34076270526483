define('runPcrKitLotView',[
    'module',
    'dialogFormView',
    'template!runPcrKitLotView',
    'runPcrKitLotCollectionView'
], function (
    module,
    DialogFormView,
    Tpl,
    RunPcrKitLotCollectionView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,
        ui: {
            confirm: '.js-confirm'
        },
        events: {
            'click @ui.confirm': 'hide'
        },
        regions: {
            'collection': '.js-collection'
        },
        onRender: function () {
            this.showChildView('collection', new RunPcrKitLotCollectionView({
                collection: this.options.collection
            }));
        }
    });
});
