define('dynamicCreateEditCustomListToEntityItem',[
    'module',
    'formView',
    'backbone',
    'underscore'
], function (
    module,
    FormView,
    Backbone,
    _
) {
    'use strict';

    module.exports = FormView.extend({
        template: _.template('<%= value %>'),
        className: 'label clickable',
        tagName: 'span',
        events: {
            'click': 'onClick'
        },

        attributes: function () {
            var title = '';
            if (this.model instanceof Backbone.Model) {
                if (this.model.get('code')) {
                    title = this.model.get('code');
                }
                if (this.model.get('name')) {
                    if (title !== '') {
                        title += ' - ';
                    }
                    title += this.model.get('name');
                }
            }
            return {
                title: title
            };
        },

        serializeData: function () {
            return {value: this.model.get('code')};
        },
        
        onClick: function (e) {
            e.preventDefault();
            e.stopPropagation();
            var service = require(this.model.service);
            service.showDetails({model: this.model, service: service, callBackRefresh: _.bind(this.render, this)});
        }
    });
});

