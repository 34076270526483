define('pcrWellResultNoneView',[
    'module',
    'backbone.marionette',
    'underscore'
], function (
    module,
    Marionette,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('')
    });
});
