// Kick off the application.
require([
    'keycloak',
    'underscore',
    'app'
], function (
    Keycloak,
    _,
    App
) {
    'use strict';

    var env = document.env || {};
    var keycloak = new Keycloak['default']({
        url: env.keycloakUrl || 'https://sso.mobiolink.com',
        realm: env.keycloakRealm || 'Mobiolink',
        clientId: env.keycloakClientId || 'mobiolink-opendid-client-app-dev'
    });

    // get url before #
    var url = window.location.href;
    var hashIndex = url.indexOf('#');
    if (hashIndex > -1) {
        url = url.substring(0, hashIndex);
    }
    // remove trailing slash
    if (url.endsWith('/')) {
        url = url.slice(0, -1);
    }
    keycloak.init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: url + '/silent-check-sso.html'
    }).then(function (authenticated) {
        if (authenticated) {
            console.log('Utilisateur authentifié');
            App.keycloak = keycloak;
            App.start();
        } else {
            console.warn('Utilisateur non authentifié');
            keycloak.login();
        }
    }, function (error) {
        console.error('Erreur lors de l\'initialisation de Keycloak:', error);
    });


    setInterval(_.bind(function () {
        keycloak.updateToken(30).then(function () {
        }, _.bind(function () {
            keycloak.login();
        }, this));
    }, this), 60000);

    keycloak.onTokenExpired = _.bind(function () {
        keycloak.updateToken(30).then(function () {
        }, function () {
            keycloak.login();
        });
    }, this);

    keycloak.onAuthLogout = _.bind(function () {
        keycloak.login();
    }, this);
});

define("main", function(){});

