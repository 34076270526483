define('kitLotsPcrLotView',[
    'module',
    'backbone.marionette',
    'backbone',
    'underscore',
    'settings',
    'template!kitLotsPcrLotView',
    'kitLotPcrTargetReagentAssayView',
    'dateUtils',
    'rolesMixin',
    'privileges'
], function (
    module,
    Marionette,
    Backbone,
    _,
    Settings,
    Tpl,
    ItemView,
    DateUtils,
    RolesMixin,
    Privileges
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        template: Tpl,
        childViewContainer: '.collection',
        childView: ItemView,
        childViewOptions: function () {
            return {editable: (Settings.get('currentUserModel').hasType(RolesMixin.KIT_PCR_LOT, Privileges.KIT_LOT_TARGET))};
        },
        events: {
            'click .js-left': 'onLeft',
            'click .js-right': 'onRight'
        },
        ui: {
            left: '.js-left',
            right: '.js-right'
        },
        modelEvents: {
            'change': 'render'
        },
        initialize: function (options) {
            this.collection = this.model.get('assayReagentTargetPcrKitLots');
            if (this.options.reagentType) {
                this.collection = new Backbone.Collection(this.collection.filter(_.bind(function (model) {
                    return model.get('type') === this.options.reagentType;
                }, this)));
            }
            this.editable = options.editable;
        },
        onRender: function () {
            this.enableLeft(this.options.enableLeft);
            this.enableRight(this.options.enableRight);
        },
        enableLeft: function (enable) {
            if (!enable) {
                this.ui.left.addClass('invisible');
            }
        },
        onLeft: function () {
            this.trigger('left', this.model);
        },
        enableRight: function (enable) {
            if (!enable) {
                this.ui.right.addClass('invisible');
            }
        },
        onRight: function () {
            this.trigger('right', this.model);
        },
        serializeData: function () {
            var templateData = {};
            templateData.code = this.model.get('code');
            templateData.delivery = DateUtils.toDateFormatString(this.model.get('deliveryDate'));
            templateData.expiration = DateUtils.toDateFormatString(this.model.get('expiration'));
            templateData.current = DateUtils.toDateFormatString(this.model.get('currentDate'));
            templateData.paused = DateUtils.toDateFormatString(this.model.get('exhaustedDate') ? this.model.get('exhaustedDate') : this.model.get('pausedDate'));
            templateData.status = this.model.get('status');

            if (templateData.delivery === 'Invalid date') {
                templateData.delivery = '-';
            }
            if (templateData.expiration === 'Invalid date') {
                templateData.expiration = '-';
            }
            if (templateData.beginUse === 'Invalid date') {
                templateData.beginUse = '-';
            }
            if (templateData.endUse === 'Invalid date') {
                templateData.endUse = '-';
            }
            return templateData;
        },
        filterCollection: function (assay, reagent) {
            this.collection = new Backbone.Collection(this.model.get('assayReagentTargetPcrKitLots').filter(function (element) {
                return (!assay || element.get('refAssayReagent').get('refAssay').get('id') === assay.get('id')) &&
                    (!reagent || element.get('refAssayReagent').get('reagent').get('id') === reagent.get('id'));
            }));
            this.render();
        }
    });
});

