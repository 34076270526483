define('assayReagentQCItemView',[
    'module',
    'template!assayReagentQCItemTpl',
    'underscore',
    'jquery',
    'autocompleteView',
    'dialogFormView'
], function (
    module,
    Tpl,
    _,
    $,
    AutocompleteView,
    DialogFormView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            show: '.show-row'
        },

        events: {
            'click .delete-row': 'onDelete',
            'click .show-row': 'onShowConfiguration'
        },

        regions: {
            pcrKit: '.js-kit',
            reagent: '.js-reagent'
        },

        fieldsToValidate: [
            // {name: 'reagent', type: 'required'}
        ],

        className: 'col-xs-12 p-0',

        initialize: function () {
            if (this.model.get('reagent')) {
                this.pcrKit = this.model.get('reagent').get('kit');
            }
        },
        
        onRender: function () {
            this.displayPcrKitAutocomplete();
            if (this.model.get('reagent')) {
                this.displayReagent(this.model.get('reagent').get('kit'));
            }
            this.showEye();
        },

        onChangePcrKit: function (fieldName, model) {
            if (model) {
                var PcrKitService = require('services/caccounts/kitspcr');
                model = PcrKitService.getModel(model.toJSON());
                model.fetch().done(_.bind(function () {
                    if (model.get('reagents')) {
                        this.displayReagent(model);
                    }
                }, this));
            }
        },

        displayReagent: function (pcrKit) {
            if (!pcrKit) {
                return;
            }
            var PcrReagentService = require('services/caccounts/pcrreagents');
            this.getRegion('reagent').show(new AutocompleteView(
                this._getAutocompleteOptionObject(PcrReagentService.getAutocompleteParam({
                    data: _.map(pcrKit.get('reagents').filter(_.bind(function (model) {
                        return ['QC'].includes(model.get('type'));
                    }, this)), function (model) {
                        return model.toJSON();
                    }),
                    modelProperty: 'reagent',
                    callBackOnChange: this.onChangeReagent,
                    canAddOrEdit: false
                }))));
        },
        onChangeReagent: function (fieldName, model) {
            var PcrReagentService = require('services/caccounts/pcrreagents');
            model = model ? PcrReagentService.getModel(model.toJSON()) : null;
            this.onChange(fieldName, model);
            this.trigger('item:updateReagent', model);
            if (this.model.get('reagent') && this.model.get('reagent').get('kit').get('refCurrentLot')) {
                this.showEye();
            }
        },

        showEye: function () {
            if (this.model.get('id') && this.model.get('reagent') && this.model.get('reagent').get('kit') && this.model.get('reagent').get('kit').get('refCurrentLot')) {
                this.ui.show.removeClass('invisible');
            } else {
                this.ui.show.addClass('invisible');
            }
        },

        onDelete: function () {
            this.trigger('item:delete', this.model);
        },
        refresh: function () {
            this.displayPcrKitAutocomplete();
        },

        displayPcrKitAutocomplete: function () {
            var PcrKitService = require('services/caccounts/kitspcr');
            this.getRegion('pcrKit').show(new AutocompleteView(_.extend(
                this._getAutocompleteOptionObject(PcrKitService.getAutocompleteParamForAssay({
                    modelProperty: 'reagent.kit',
                    callBackOnChange: this.onChangePcrKit,
                    assaySecId: this.model.get('refAssay').get('id'),
                    reagentTypes: ['QC']
                })), {model: this.pcrKit})));
        },

        onShowConfiguration: function () {
            if (this.model.get('reagent')) {
                $('.js-global-loader').show();
                require(['kitLotsPcrController'], _.bind(function (Controller) {
                    var model = this.model.get('reagent').get('kit').get('refCurrentLot');
                    model.fetch().done(_.bind(function () {
                        Controller.showDetails({
                            model: model, 
                            options: {
                                current: {
                                    assay: this.model.get('refAssay'),
                                    reagent: this.model.get('reagent')
                                }
                            }
                        });
                        $('.js-global-loader').hide();
                    }, this));
                }, this));
            }
        }
    });
});

