
define('template!prepWellContentView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div style="width: 500px;margin-left: auto;margin-right: auto;">\n    <div class="d-f m-b-2" style="min-height: 19px;">\n        <div class="col-w-19 p-l-5 p-r-5 d-f">\n            <label>\n                ' +
((__t = ( _.i18n('assays.waiting') )) == null ? '' : __t) +
'\n            </label>\n        </div>\n        <div class="col-w-1 d-f"></div>\n        <div class="col-w-5 d-f">\n            ' +
((__t = ( assaysToDisplay.assaysIcons.waiting )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-w-72 d-f css-prepRun-listView-assayWaiting"\n             style="font-size: 15px;">\n            ' +
((__t = ( assaysToDisplay.waiting )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-w-3 d-f assayWaiting">\n            <span class="mdi mdi-playlist-edit clickable js-edit-assay" style="float: right; line-height: 20px; font-size: 19px;"></span>\n        </div>\n    </div>\n    <div class="d-f m-b-2" style="min-height: 19px;">\n        <div class="col-w-19 p-l-5 p-r-5 d-f">\n            <label>\n                ' +
((__t = ( _.i18n('assays.running') )) == null ? '' : __t) +
'\n            </label>\n        </div>\n        <div class="col-w-1 d-f"></div>\n        <div class="col-w-5 d-f">\n            ';
 if(assaysToDisplay.running.length > 0) { ;
__p += ' ' +
((__t = ( assaysToDisplay.assaysIcons.running )) == null ? '' : __t) +
' ';
 }
            ;
__p += '\n        </div>\n        <div class="col-w-75 d-f css-prepRun-listView-assayRunning"\n             style="font-size: 15px;">\n            ' +
((__t = ( assaysToDisplay.running )) == null ? '' : __t) +
'\n        </div>\n    </div>\n    <div class="d-f m-b-2" style="min-height: 19px;">\n        <div class="col-w-19 p-l-5 p-r-5 d-f">\n            <label>\n                ' +
((__t = ( _.i18n('assays.done') )) == null ? '' : __t) +
'\n            </label>\n        </div>\n        <div class="col-w-1 d-f"></div>\n        <div class="col-w-5 d-f">\n            ';
 if(assaysToDisplay.done.length > 0) { ;
__p += ' ' +
((__t = ( assaysToDisplay.assaysIcons.done )) == null ? '' : __t) +
' ';
 } ;
__p += '\n        </div>\n        <div class="col-w-75 d-f css-prepRun-listView-assayDone"\n             style="font-size: 15px;">\n            ' +
((__t = ( assaysToDisplay.done )) == null ? '' : __t) +
'\n        </div>\n    </div>\n</div>\n<div style="display: grid; grid-template-columns: 1fr 300px; gap: 20px;">\n    <div class="d-f column gap-5" style="width: 100%">\n        <div class="js-pcrWells" style="width: 100%"></div>\n        ';
 if(displayPool) { ;
__p += '\n        <div class="js-wellsPool" style="width: 100%"></div>\n        ';
 } ;
__p += '\n        ';
 if(displayPooled) { ;
__p += '\n        <div class="js-wellsPooled" style="width: 100%"></div>\n        ';
 } ;
__p += '\n    </div>\n    <div class="d-f column gap-5" style="width: 100%">\n        <div class="js-pcrKitLots" style="width: 100%"></div>\n        ';
 if (pcrKitsLotsForValidation) { ;
__p += '\n        <div class="js-pcrKitLotsValidation"></div>\n        ';
 } ;
__p += '\n    </div>\n</div>';

}
return __p
};});

