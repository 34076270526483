define('entities/menu',[
    'entities/ns',
    'backbone',
    'backboneRelational'
], function (
    app,
    Backbone
) {
    'use strict';

    app.Menu = Backbone.RelationalModel.extend({
        defaults: {},
        idAttribute: 'itemPageName'
    });

    app.MenuCollection = Backbone.Collection.extend({
        model: app.Menu
    });
});
