define('createEditLayoutPcrTemplatesView',[
    'module',
    'backbone.marionette',
    'dialogFormView',
    'template!pcrRunTemplatesCreateEditLayoutTpl',
    'savingBehavior',
    'underscore',
    'jquery',
    'settings',
    'autocompleteView',
    'dynamicCreateEditCodeList',
    'dynamicCreateEditUserCodeList',
    'runController',
    'dynamicCreateEditDescriptionCommentTags',
    'dynamicCreateEditCodeNameSequence'
], function (
    module,
    Marionette,
    DialogFormView,
    createEditLayoutTpl,
    SavingBehavior,
    _,
    $,
    Settings,
    AutocompleteView,
    CodeListView,
    UserCodeListView,
    RunController,
    DynamicCreateEditDescriptionCommentTags,
    DynamicCreateEditCodeNameSequence
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: createEditLayoutTpl,

        ui: {
            input: '.js-info-input',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm',
            plateLayout: '.js-define-plateLayout',
            printRunSheet: '.js-printRunSheet',
            plateSize: '.js-selectPlateSize',


            generateSmpIds: '.js-generate-smpids',
            removeSmpIds: '.js-remove-smpids',
            assignAssay: '.js-assign-assay',
            removeAssay: '.js-remove-assay',
            addTest: '.js-add-test',
            removeTest: '.js-remove-test'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm',
            'click @ui.plateLayout': 'onPlateLayout',
            'change @ui.printRunSheet': 'onChangePrintRunSheet',

            'click @ui.generateSmpIds': 'onGenerateSmpIds',
            'click @ui.removeSmpIds': 'onRemoveSmpIds',
            'click @ui.assignAssay': 'onAssignAssay',
            'click @ui.removeAssay': 'onRemoveAssay',
            'click @ui.addTest': 'onAddTest',
            'click @ui.removeTest': 'onRemoveTest'
        },

        regions: {
            codeNameSeq: '.js-codeNameSeq-region',
            selectRefKitProt: '.js-select-refKitProt',
            selectCyclerPublic: '.js-select-cyclerPublic-region',
            selectCycler: '.js-select-cycler-region',
            selectDepartment: '.js-selectDepartment',
            selectRunGroup: '.js-selectRunGroup',
            selectSequenceFormat: '.js-selectSequenceFormat',
            selectPattern: '.js-selectPattern',
            selectPlateSize: '.js-selectPlateSize',
            descriptionRegion: '.js-description'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            change: 'render'
        },

        fieldsToValidate: [
            {name: 'code', type: 'required'},
            {name: 'plateSize', type: 'required'}
        ],

        onGenerateSmpIds: function () {
            RunController.generateSampleId(this.model);
        },
        onRemoveSmpIds: function () {
            RunController.removeSampleId(this.model);
        },
        onAssignAssay: function () {
            RunController.assignAssay(this.model);
        },
        onRemoveAssay: function () {
            RunController.removeAssay(this.model);
        },
        onAddTest: function () {
            RunController.defineTestWell(this.model);
        },
        onRemoveTest: function () {
            RunController.removeTestWell(this.model);
        },

        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.newItem = this.model.isNew();

            templateData.sortSequence = this.model.get('sortSequence') ? this.model.get('sortSequence') : '';
            if (this.model.isNew()) {
                templateData.printRunSheet = this.options.printRunSheet;
            }

            return templateData;
        },

        onRender: function () {
            this.showChildView('codeNameSeq', new DynamicCreateEditCodeNameSequence({
                'field': 'code',
                'model': this.model,
                'value': this.model.get('code'),
                service: this.options.service,
                'readOnly': this.options.readOnly
            }));
            this.getRegion('selectPattern').show(new UserCodeListView({
                model: this.model,
                config: {
                    modelProperty: 'pattern',
                    code: 'PATTERN_PCR'
                }
            }));
            this.getRegion('selectDepartment').show(new UserCodeListView({
                model: this.model,
                config: {
                    modelProperty: 'department',
                    code: 'DEPT'
                }
            }));
            this.getRegion('selectRunGroup').show(new UserCodeListView({
                model: this.model,
                config: {
                    modelProperty: 'group',
                    code: 'RUNGROUP_PCR'
                }
            }));
            this.getRegion('selectSequenceFormat').show(new UserCodeListView({
                model: this.model,
                config: {
                    modelProperty: 'sequenceFormat',
                    code: 'SEQUENCE'
                }
            }));

            this.showKitProtRegion(true);
            this.showCyclerRegion();
            this.showPlateSizeRegion();
            var service = require('services/caccounts/pcrruntemplates');
            this.getRegion('descriptionRegion').show(new DynamicCreateEditDescriptionCommentTags({
                model: this.model,
                service: service
            }));

            this.setPermissions(service.canAddOrEdit());
        },

        onChangeSelection: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onChange(fieldName, model ? model.toJSON() : null);
            this.model.trigger('change');
        },

        onEditKitProt: function (id) {
            var KitProtService = require('services/caccounts/kitprots');
            var model = KitProtService.getModel({id: id});
            model.fetch().done(_.bind(function () {
                this.showEditAddKitProtView(model);
            }, this));
        },

        onAddKitProt: function () {
            var KitProtService = require('services/caccounts/kitprots');
            this.showEditAddKitProtView(KitProtService.getModel());
        },

        showEditAddKitProtView: function (model) {
            require(['dynamicController'], _.bind(function (DynamicController) {
                DynamicController.showDetails({
                    model: model,
                    useCreateEditView: _.bind(function (view) {
                        view.on('saved', _.bind(function (model) {
                            this.model.set('refKitProt', model.attributes);
                            this.autocompleteKitProtView.options.value = model.get('code');
                            this.autocompleteKitProtView.render();
                        }, this));
                    }, this)
                });
            }, this));
        },

        showPlateSizeRegion: function () {
            if (this.model.get('cyclerPublic') && this.model.get('cyclerPublic').get('plateSize')) {
                this.getRegion('selectPlateSize').show(new Marionette.View({
                    template: _.template(this.model.get('cyclerPublic').get('plateSize')),
                    tagName: 'span'
                }));
                this.model.set('plateSize', this.model.get('cyclerPublic').get('plateSize'));
            } else {
                var PlateSizeService = require('services/caccounts/platesize');
                this.getRegion('selectPlateSize').show(new AutocompleteView(
                    this._getAutocompleteOptionObject(PlateSizeService.getAutocompleteParam({
                        modelProperty: 'plateSize',
                        callBackOnChange: this.onChangePlateSize,
                        paramUrl: {
                            'sord': 'asc',
                            'sidx': 'plateSize',
                            'cyclerCode': this.model.get('refCyclerPublic') ? this.model.get('refCyclerPublic').code : null
                        }
                    }))));
            }
        },

        showCyclerPublicRegion: function (fromKitProtRegion) {
            if (this.model.get('refCycler')) {
                this.getRegion('selectCyclerPublic').show(new Marionette.View({
                    template: _.template(this.model.get('refCycler').get('refCycler').get('code')),
                    tagName: 'span'
                }));
            } else {
                var CyclerService = require('services/caccounts/cyclers');
                this.getRegion('selectCyclerPublic').show(new AutocompleteView(
                    this._getAutocompleteOptionObject(CyclerService.getAutocompleteParamForPublic({
                        modelProperty: 'refCyclerPublic',
                        callBackOnChange: this.onChangeCyclerPublic,
                        paramUrl: {
                            'sord': 'asc',
                            'sidx': 'code',
                            'plateSize': this.model.get('plateSize')
                        }
                    }))));
                if (!fromKitProtRegion) {
                    this.showKitProtRegion(false);
                }
                this.showCyclerRegion();
            }
        },

        showKitProtRegion: function (showCyclerPublicRegion) {
            var KitProtService = require('services/caccounts/kitprots');
            this.getRegion('selectRefKitProt').show(new AutocompleteView(
                this._getAutocompleteOptionObject(KitProtService.getAutocompleteParam({
                    modelProperty: 'refKitProt',
                    callBackOnChange: this.onChangeSelection,
                    callBackAdd: this.onAddKitProt,
                    callBackEdit: this.onEditKitProt,
                    paramUrl: {
                        'sord': 'asc',
                        'sidx': 'code',
                        'cyclerCode': this.model.get('refCyclerPublic') ? this.model.get('refCyclerPublic').get('code') : null
                    }
                }))));
            if (showCyclerPublicRegion) {
                this.showCyclerPublicRegion(true);
            }
        },

        showCyclerRegion: function () {
            var CyclerService = require('services/caccounts/cyclers');
            this.getRegion('selectCycler').show(new AutocompleteView(
                this._getAutocompleteOptionObject(CyclerService.getAutocompleteParam({
                    modelProperty: 'refCycler',
                    callBackOnChange: this.onChangeCycler,
                    paramUrl: {
                        'sord': 'asc',
                        'sidx': 'code',
                        'plateSize': this.model.get('plateSize'),
                        cyclerSecId: this.model.get('refCyclerPublic') ? this.model.get('refCyclerPublic').id : null
                    }
                }))));
        },

        onChangeCyclerPublic: function (fieldName, model) {
            this.onChange(fieldName, model ? model.toJSON() : null);
            this.showCyclerRegion();
            this.showPlateSizeRegion();
            this.showKitProtRegion(false);
        },

        onChangeCycler: function (fieldName, model) {
            this.onChange(fieldName, model ? model.toJSON() : null);
            if (this.model.get(fieldName)) {
                this.onChange('refCyclerPublic', this.model.get(fieldName).get('refCycler'));
            }
            this.showCyclerPublicRegion();
            this.showPlateSizeRegion();
            this.showKitProtRegion(false);
        },

        onChangePlateSize: function (fieldName, model) {
            this.onChange(fieldName, model ? model.get('code') : null);
            this.showCyclerPublicRegion();
        },

        onConfirm: function () {
            if (this.validate(this.fieldsToValidate)) {
                this.model.save().done(_.bind(function () {
                    this.hide();
                }, this));
            }
        },

        onChangePrintRunSheet: function (event) {
            this.model.set('printRunSheet', event.target.checked);
        },

        onPlateLayout: function () {
            RunController.editPlateLayout(this.model);
        }
    });
});

