define('assayResultTargetValuesVersionCollection',[
    'module',
    'backbone',
    'backbone.marionette',
    'assayResultTargetValueVersion'
], function (
    module,
    Backbone,
    Marionette,
    AssayResultTargetValueVersion
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        childView: AssayResultTargetValueVersion,
        viewFilter: function (view) {
            if (this.options.targetValueFiltered) {
                return view.model.get('type') === this.options.targetValueFiltered;
            }
            return true;
        }
    });
});
