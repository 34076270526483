define('jqGridSelectFormatterView',[
    'module',
    'dialogCollectionView',
    'underscore',
    'backbone',
    'jqGridSelectFormatterItemView',
    'jqGridFormatter'
], function (
    module,
    DialogCollectionView,
    _,
    Backbone,
    ItemView,
    JqGridFormatter
) {
    'use strict';

    module.exports = DialogCollectionView.extend({
        template: _.template('<div class="js-container"></div>' +
            '<div class="cancelConfirmRow">' +
            '    <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>' +
            '    <button class="mdi-navigation-check btn popupBtn-confirm js-confirm"></button>' +
            '</div>'),
        events: {
            'click .js-confirm': 'onConfirm',
            'click .js-cancel-popup': 'hide'
        },
        initialize: function () {
            this.collection = new Backbone.Collection(
                _.chain(this.options.collection)
                    .filter(function (item) {
                        return item.formatters;
                    })
                    .uniq(function (item) {
                        if (!item.formatters.group) {
                            if (!item.formatters.label) {
                                return item.formatters;
                            }
                            return item.label;
                        }
                        return item.formatters.group;
                    })
                    .map(function (item) {
                        return _.clone(item);
                    })
                    .value());

            var formatters = [
                {name: _.i18n('displayMode.color'), value: 'display mode', formatter: JqGridFormatter.displayModeFormatter, width: 24},
                {name: _.i18n('displayMode.result'), value: 'res num', formatter: JqGridFormatter.resultNumericFormatter, ovarFormatter: JqGridFormatter.displayModeFormatter, width: 40, only: {curve: true}},
                {name: _.i18n('displayMode.quantification'), value: 'quantification', formatter: JqGridFormatter.quantificationFormatter, ovarFormatter: JqGridFormatter.displayModeFormatter, width: 40},
                {name: _.i18n('displayMode.colorAndResult'), value: 'display mode + res num', formatter: JqGridFormatter.displayModeResultNumericFormatter, ovarFormatter: JqGridFormatter.displayModeFormatter, width: 64},
                {name: _.i18n('displayMode.colorAndQuantification'), value: 'display mode + quantification', formatter: JqGridFormatter.displayModeQuantificationFormatter, ovarFormatter: JqGridFormatter.displayModeFormatter, width: 64, only: {curve: true}},
                {name: _.i18n('displayMode.colorAndResultAndQuantification'), value: 'all', formatter: JqGridFormatter.displayModeResultNumericQuantificationFormatter, ovarFormatter: JqGridFormatter.displayModeFormatter, width: 94, only: {curve: true}}
            ];
            formatters = _.filter(formatters, _.bind(function (formatter) {
                return !this.options.formatters || _.any(_.keys(this.options.formatters), _.bind(function (key) {
                    return !formatter.only || formatter.only[key] === this.options.formatters[key];
                }, this));
            }, this));
            this.collection.each(function (item) {
                switch (item.get('formatters')) {
                    case 'result':
                        item.set('formatters', {
                            group: 'result',
                            groupLabel: _.i18n('result'),
                            items: formatters
                        });
                        break;
                    default:
                        throw new Error('Unknown formatter group: ' + item.formatters);
                }
            });
        },
        childViewContainer: '.js-container',
        childView: ItemView,
        childViewOptions: function (model) {
            return {
                currentFormatter: model.get('formatter')
            };
        },
        onConfirm: function () {
            this.children.each(_.bind(function (view) {
                if (view.model.get('formatters').group) {
                    _.chain(this.options.collection)
                        .filter(function (item) {
                            if (_.isString(item.formatters)) {
                                return item.formatters === view.model.get('formatters').group;
                            }
                            return item.formatters && item.formatters.group && item.formatters.group === view.model.get('formatters').group;
                        })
                        .each(function (item) {
                            this.options.viewFormatter[item.name] = view.currentItem;
                        }, this);
                } else {
                    this.options.viewFormatter[view.model.get('name')] = view.currentItem;
                }
            }, this));
            this.hide();
        }
    });
});
