define('runPlateLayoutImportView',[
    'module',
    'backbone',
    'underscore',
    'jquery',
    'dialogFormView',
    'template!runPlateLayoutImportView',
    'commonCancelUploadView',
    'customBootboxMessage',
    'autocompleteView'
], function (
    module,
    Backbone,
    _,
    $,
    DialogFormView,
    Tpl,
    CommonCancelUploadView,
    CustomBootboxMessage,
    AutocompleteView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input'
        },

        regions: {
            cancelUploadRegion: '.js-cancel-upload-region',
            selectFileFormat: '.js-select-fileFormat-region'
        },
        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },
        modelEvents: {
            'change:fileFormat': 'onValidateChange'
        },

        fieldsToValidate: function () {
            return [
                {name: 'fileFormat', type: 'required'}
            ];
        },

        initialize: function () {
            this.model = new Backbone.Model({fileFormat: null});
        },

        onValidateChange: function () {
            this.commonCancelUploadView.enableButtons(this.validate(this.fieldsToValidate()));
        },

        onRender: function () {
            this.commonCancelUploadView = new CommonCancelUploadView({settingImport: this.options.settingImport, accept: '.xlsx,.xls,.xml,.rdml,.csv'});
            this.listenTo(this.commonCancelUploadView, 'upload:click', _.bind(this.onUpload, this));
            this.listenTo(this.commonCancelUploadView, 'cancel:click', _.bind(this.onCancel, this));
            this.getRegion('cancelUploadRegion').show(this.commonCancelUploadView);
            this.commonCancelUploadView.enableButtons(false);

            var FileFormatPlateLayoutService = require('services/caccounts/fileFormatPlateLayouts');
            var view = new AutocompleteView(
                this._getAutocompleteOptionObject(FileFormatPlateLayoutService.getAutocompleteParamForImport({
                    modelProperty: 'fileFormat',
                    values: {
                        'prep': this.options.type === 'prep' ? true : null,
                        'pcr': this.options.type === 'pcr' ? true : null
                    }
                }))
            );
            this.getRegion('selectFileFormat').show(view);
        },

        onUpload: function (fileInfo) {
            var callback = _.bind(function (result) {
                if (result) {
                    this.confirmUpload(fileInfo);
                }
            }, this);
            // check if fileinfo is a filelist
            var file;
            if (fileInfo instanceof FileList) {
                file = fileInfo[0];
            } else {
                file = fileInfo;
            }
            if (file.name.includes(this.options.model.get('code'), 0)) {
                callback(true);
            } else {
                var confirmParams = {
                    message: _.i18n('run.controlRunName'),
                    type: 'warning'
                };
                CustomBootboxMessage.customConfirm(confirmParams, callback);
            }
        },
        confirmUpload: function (files) {
            var loading = $('.js-global-loader');
            loading.show();
            this.model.set('files', files);
            this.options.model.importFilePlateLayout(this.model)
                .done(_.bind(function () {
                    this.hide();
                }, this))
                .fail(_.bind(function (response) {
                    alert(response.statusText + ' -- ' + response.status);
                }, this))
                .always(function () {
                    loading.hide();
                });
        }
    });
});

