
define('template!runPcrKitLotView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="js-collection"></div>\n\n<div class="cancelConfirmRow">\n    <button class="mdi-navigation-check btn popupBtn-confirm js-confirm"></button>\n</div>';

}
return __p
};});

