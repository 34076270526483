define('assayCreateEditLayoutView',[
    'module',
    'backbone',
    'dialogFormView',
    'template!assayCreateEditLayoutTpl',
    'savingBehavior',
    'underscore',
    'jquery',
    'autocompleteView',
    'assayReagentQCView',
    'assayReagentICView',
    'assayReagentMMXView',
    'dynamicCreateEditTab',
    'assayConfigurationView',
    'dynamicCreateEditDescriptionCommentTags',
    'dynamicCreateEditTypeParam',
    'dynamicCreateEditCodeName'
], function (
    module,
    Backbone,
    DialogFormView,
    Tpl,
    SavingBehavior,
    _,
    $,
    AutocompleteView,
    AssayReagentQCView,
    AssayReagentICView,
    AssayReagentMMXView,
    DynamicCreateEditTab,
    AssayConfigurationView,
    DynamicCreateEditDescriptionCommentTags,
    TypeParamView,
    DynamicCreateEditCodeName
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange',
            'click .js-confirm': 'onConfirm',
            'click .js-add-reagent': 'onAddReagent'
        },

        regions: {
            groupNameSelect: '.js-select-group-name-region',
            kitProtSelect: '.js-select-kitProt-region',
            assayConfigurationRegion: '.js-assayConfiguration-region',
            protocolVariantRegion: '.js-select-protocol-variant-region',
            displayModeRegion: '.js-select-displayMode-region',
            reagentQC: '.js-reagent-qc',
            reagentIC: '.js-reagent-ic',
            reagentMMX: '.js-reagent-mmx',
            descriptionRegion: '.js-description',
            selectAlgoValidation: '.js-select-algo-deathVolume-region',
            codeName: '.js-codeName-region'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            sync: 'onSave',
            'change:refAssayGroup': 'renderAssayGroup',
            'change:refKitProt': 'renderKitProt'
        },

        fieldsToValidate: [
            {name: 'code', type: 'required'},
            {name: 'refKitProt', type: 'required'}
        ],

        collectionFieldsToValidate: [
            {name: 'target', type: 'required'},
            {name: 'AlgoRes-API', type: 'required'},
            {name: 'resnumExportMode', type: 'required'}
        ],

        className: 'assay',

        initialize: function (options) {
            if (options.assayModel) {
                this.model = options.assayModel.clone();
            }
            if (this.model.isNew() && this.model.get('configurations').isEmpty()) {
                var AssayConfigurationService = require('services/caccounts/assayconfiguration');
                this.model.get('configurations').push(AssayConfigurationService.getModel());
                this.options.newItem = true;
            }
        },

        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.newItem = this.options.newItem;
            templateData.volumeMMX = null;
            return templateData;
        },

        onEditAssayGroup: function (id) {
            var AssayGroupService = require('services/caccounts/assaygroup');
            var model = AssayGroupService.getModel({id: id});
            model.fetch().done(_.bind(function () {
                this.showEditAddAssayGroupView(model);
            }, this));
        },

        onAddAssayGroup: function () {
            var AssayGroupService = require('services/caccounts/assaygroup');
            var model = AssayGroupService.getModel();
            this.showEditAddAssayGroupView(model);
        },

        showEditAddAssayGroupView: function (model) {
            var AssayGroupService = require('services/caccounts/assaygroup');
            AssayGroupService.showDetails({
                model: model,
                service: AssayGroupService,
                callBackRefresh: _.bind(function (model) {
                    this.model.set('refAssayGroup', model.attributes);
                    this.autocompleteAssayGroupView.options.value = model.get('code');
                    this.autocompleteAssayGroupView.render();
                }, this)
            });
        },

        showEditAddKitProtView: function (model) {
            require(['dynamicController'], _.bind(function (DynamicController) {
                DynamicController.showDetails({
                    model: model, 
                    useCreateEditView: _.bind(function (view) {
                        view.on('saved', _.bind(function (model) {
                            this.model.set('refKitProt', model.attributes);
                            this.autocompleteKitProtView.options.value = model.get('code');
                            this.autocompleteKitProtView.render();
                        }, this));
                    }, this)
                });
            }, this));
        },

        onChangeSelection: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onChange(fieldName, model ? model.toJSON() : null);
            this.triggerMethod('enable:cancel:confirm');
        },

        onChangeCodeList: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.onChange(fieldName, model ? model.get('code') : null);
            this.triggerMethod('enable:cancel:confirm');
        },

        onConfirm: function () {
            if (this.collectionIsValid()) {
                var collection = new Backbone.Collection();
                collection.push(this.qcView.collection.models);
                collection.push(this.icView.model);
                collection.push(this.mmxView.collection.models);
                var models = collection.filter(function (model) {
                    return model.get('reagent') && model.get('reagent').get('type');
                });
                this.model.set('reagents', new Backbone.Collection(models));
                this.model.save();
            }
        },

        collectionIsValid: function () {
            return this.validate(this.fieldsToValidate);
        },

        onSave: function (model) {
            this.model = model;
            this.triggerMethod('saved', model);
            this.box.modal('hide');
        },

        onCancel: function () {
            this.box.modal('hide');
        },

        onAddReagent: function () {
            var AssayReagentService = require('services/caccounts/assayreagent');
            this.model.get('reagents').add(AssayReagentService.getModel());
        },

        onRender: function () {
            this.assayConfigurationCollectionView = new DynamicCreateEditTab({
                collection: this.model.get('configurations'),
                model: this.model,
                view: AssayConfigurationView,
                current: this.options.assayConfiguration
            });

            this.getRegion('assayConfigurationRegion').show(this.assayConfigurationCollectionView);

            this.renderAssayGroup();

            this.renderKitProt();

            var DisplayModeService = require('services/caccounts/displayModes');
            this.getRegion('displayModeRegion').show(new AutocompleteView(
                this._getAutocompleteOptionObject(DisplayModeService.getAutocompleteParam({
                    modelProperty: 'displayMode',
                    callBackOnChange: this.onChangeSelection
                }))));

            var CodeListService = require('services/admin/codelist');
            this.getRegion('protocolVariantRegion').show(new AutocompleteView(
                this._getAutocompleteOptionObject(
                    CodeListService.getAutocompleteParamForCode({
                        modelProperty: 'protocolVariant',
                        codeListCode: 'PROT-VARIANTS',
                        callBackOnChange: this.onChangeCodeList
                    }))));

            var defersQC = [];
            this.model.getReagentsQC().each(_.bind(function (item) {
                if (item.get('reagent') && item.get('reagent').get('kit')) {
                    defersQC.push(item.get('reagent').get('kit').fetch());
                }
            }, this));

            $.when.apply($, defersQC)
                .done(_.bind(function () {
                    this.qcView = new AssayReagentQCView({
                        model: this.model,
                        collection: this.model.getReagentsQC()
                    });
                    this.getRegion('reagentQC').show(this.qcView);
                }, this));

            if (this.model.getReagentIC().get('reagent') && this.model.getReagentIC().get('reagent').get('kit')) {
                this.model.getReagentIC().get('reagent').get('kit').fetch().done(_.bind(function () {
                    this.icView = new AssayReagentICView({
                        model: this.model.getReagentIC()
                    });
                    this.getRegion('reagentIC').show(this.icView);
                }, this));
            } else {
                this.icView = new AssayReagentICView({
                    model: this.model.getReagentIC()
                });
                this.getRegion('reagentIC').show(this.icView);
            }

            this.mmxView = new AssayReagentMMXView({
                model: this.model,
                collection: this.model.getReagentsMMX()
            });
            this.getRegion('reagentMMX').show(this.mmxView);

            var AssayService = require('services/caccounts/assay');
            this.getRegion('descriptionRegion').show(new DynamicCreateEditDescriptionCommentTags({
                model: this.model,
                service: AssayService
            }));

            var selectAlgoValidationtView = new TypeParamView({
                fieldName: 'algoDeathVolume',
                model: this.model,
                getAutocompleteParam: CodeListService.getAutocompleteParamForCode({codeListCode: 'ALGO-DEATHV'}),
                codeListCode: 'ALGO-DEATHV'
            });
            this.getRegion('selectAlgoValidation').show(selectAlgoValidationtView);

            this.getRegion('codeName').show(new DynamicCreateEditCodeName({
                'field': 'code',
                'model': this.model,
                'value': this.model.get('code')
            }));

            this.renderAssayGroup();
            this.renderKitProt();

            this.setPermissions(AssayService.canAddOrEdit());
        },
        renderAssayGroup: function () {
            var AssayGroupService = require('services/caccounts/assaygroup');
            this.autocompleteAssayGroupView = new AutocompleteView(
                this._getAutocompleteOptionObject(AssayGroupService.getAutocompleteParam({
                    modelProperty: 'refAssayGroup',
                    callBackOnChange: this.onChangeSelection,
                    callBackAdd: this.onAddAssayGroup,
                    callBackEdit: this.onEditAssayGroup
                }))
            );
            this.getRegion('groupNameSelect').show(this.autocompleteAssayGroupView);
        },
        renderKitProt: function () {
            var KitProtService = require('services/caccounts/kitprots');
            this.autocompleteKitProtView = new AutocompleteView(
                this._getAutocompleteOptionObject(KitProtService.getAutocompleteParam({
                    modelProperty: 'refKitProt',
                    callBackOnChange: this.onChangeSelection
                })));
            this.getRegion('kitProtSelect').show(this.autocompleteKitProtView);
        }
    });
});

