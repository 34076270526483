define('standardCurveAssayConfigurationResultGraph',[
    'module',
    'underscore',
    'chartView'
], function (
    module,
    _,
    ChartView
) {
    'use strict';

    module.exports = ChartView.extend({
        min: 0,
        max: 0,
        slopeEnable: false,
        brutEnable: false,
        amplGraph: false,
        displayLegend: true,
        deriv2Enable: false,
        withoutCC: false,
        raw: false,
        fixedColor: false,

        serializeData: function () {
            this.data = {
                datasets: []
            };

            var c;
            c = {r: 0, g: 0, b: 0, line: 1};

            this.data.datasets.push(this.addData(
                _.map(this.options.collection, function (item) {
                    return {x: item.log, y: item.ct};
                }),
                c.r,
                c.g,
                255,
                _.i18n('standardCurve.legendContent'),
                c.line,
                c.dashed
            ));

            this.data.datasets.push(this.addData(
                _.map(this.options.collection, function (item) {
                    return {x: item.log, y: item.regression};
                }),
                255,
                c.g,
                c.b,
                _.i18n('standardCurve.regressionLine'),
                c.line,
                c.dashed
            ));

            if (this.data.datasets.length) {
                if (this.baseline !== null && this.baseline !== undefined) {
                    this.data.datasets.push(this.addLine(this.baseline, 'baseline', 255, 0, 0));
                }
                if (this.threshold !== null && this.threshold !== undefined) {
                    this.data.datasets.push(this.addLine(this.threshold, 'threshold', 0, 0, 255));
                }
            }
            this.generateLegend();
            return this.data;
        },

        addData: function (collection, r, g, b, label, borderWidth, dashed) {
            var dataCreated = [],
                color;

            if (!borderWidth) {
                borderWidth = 3;
            }

            color = 'rgba(' + r + ', ' + g + ', ' + b + ', 1)';

            dataCreated = _.sortBy(collection, function (o) {
                return o.x;
            });

            var result = {
                label: label,
                tooltip: 'TooltipHistogram',
                borderColor: color,
                borderWidth: borderWidth,
                fill: false,
                pointRadius: [],
                cubicInterpolationMode: 'monotone',
                data: dataCreated,
                pointStyle: null,
                hidden: false,
                lineTension: 0
            };
            if (dashed) {
                result = _.defaults(dashed, result);
            }

            result.pointRadius = 0;

            return result;
        },
        generateLegend: function () {
            var minArray = [];
            var maxArray = [];
            _.each(this.data.datasets, function (dataset) {
                var xArray = _.pluck(dataset.data, 'x');
                minArray.push(_.min(xArray));
                maxArray.push(_.max(xArray));
            });
            var xMin = _.min(minArray);
            var xMax = _.max(maxArray);
            var stepSize = parseFloat(((this.max - this.min) / 10).toFixed(2)); // this.max-this.min>1.2?0.2:0.1;
            if (!stepSize) {
                stepSize = 0.01;
            }

            var xAxe = {
                type: 'linear',
                position: 'bottom',
                ticks: {
                    max: xMax,
                    min: xMin,
                    stepSize: 1
                }
            };

            if (this.xLabel) {
                xAxe.scaleLabel = {
                    display: true,
                    labelString: this.xLabel
                };
            }

            var yAxe;

            if (false && !this.brutEnable) {
                yAxe = {
                    ticks: {
                        max: this.max + (stepSize - (this.max % stepSize)),
                        min: this.min - (stepSize + (this.min % stepSize)),
                        stepSize: stepSize
                    }
                };
            } else {
                yAxe = {};
            }

            if (this.yLabel) {
                yAxe.scaleLabel = {
                    display: true,
                    labelString: this.yLabel
                };
            }

            this.options.scales = {
                xAxes: [xAxe],
                yAxes: [yAxe]
            };


            this.options.legend = {
                labels: {
                    boxWidth: 1
                },
                display: this.displayLegend
            };
        }
    });
});
