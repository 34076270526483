define('prepWellPcrWellItem',[
    'module',
    'backbone.marionette',
    'template!prepWellPcrWellItem',
    'pcrWellResultController',
    'underscore',
    'linkNewTabView'
], function (
    module,
    Marionette,
    Tpl,
    PcrWellResultController,
    _,
    LinkNewTabView
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,
        className: 'd-f baseline bottom-border-cell-light',
        events: {
            'click .js-show-pcrWell-from-prepWell': 'onShowPcrWellFromPrepWell',
            'click .js-run': 'onShowRun'
        },
        regions: {
            run: '.js-run-region'
        },

        serializeData: function () {
            var templateData = {};
            templateData.id = this.model.get(this.model.idAttribute);
            templateData.pos = this.model.get('pos');
            templateData.runName = this.model.get('run').get('code');
            templateData.assay = this.model.get('refAssay') ? this.model.get('refAssay').get('code') : '';
            templateData.results = this.model.getDisplay('WELL_LIST').map(function (result) {
                return result.cid;
            });
            return templateData;
        },
        onRender: function () {
            this.model.getDisplay('WELL_LIST').each(_.bind(function (result) {
                var addRegion = this.addRegion('js-result-' + result.cid, '.js-result-' + result.cid);
                PcrWellResultController.show(result, 'WellList', addRegion);
            }, this));
            this.showChildView('run', new LinkNewTabView({
                service: require('services/caccounts/pcrruns'),
                code: this.model.get('run').get('code'),
                id: this.model.get('run').id
            }));
        },
        onShowPcrWellFromPrepWell: function () {
            require(['pcrWellController'], _.bind(function (PcrWellController) {
                PcrWellController.showDetails({model: this.model});
            }, this));
        },
        onShowRun: function () {
            var url = '#/runs/prep/' + this.model.get('run').id;
            // open new tab with url
            window.open(url, '_blank');
        }
    });
});
