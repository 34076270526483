define('entities/caccounts/assayReagentTargetPcrKitLots',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'rolesMixin',
    'underscore',
    'entities/caccounts/assayreagent',
    'entities/caccounts/mbanas',
    'entities/caccounts/mbanares'
], function (
    app,
    Backbone,
    Settings,
    $,
    RolesMixin,
    _
) {
    'use strict';

    app.AssayReagentTargetPcrKitLot = Backbone.RelationalModel.extend({
        service: 'services/caccounts/assayReagentTargetPcrKitLots',
        defaults: {
            refAssayReagent: null,
            refMbAnaRes: null,
            refPcrKitLot: null,
            mbAnaRess: [],
            val: undefined,
            deltaWarning: undefined,
            deltaError: undefined
        },
        relations: [{
            type: Backbone.HasOne,
            key: 'refAssayReagent',
            relatedModel: 'AssayReagent',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'target',
            relatedModel: 'MbAna',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'refMbAnaRes',
            relatedModel: 'MbAnaRes',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'refPcrKitLot',
            relatedModel: 'KitLotPcr',
            includeInJSON: ['id', 'code', 'name']
        }],
        idAttribute: 'id',

        jsonObjectName: 'AssayReagentTargetPcrKitLotJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'AssayReagentTargetPcrKitLot',

        importable: true,
        /**
         * Find pcrKitLot for a well sampleType
         * @returns {*|jQuery}
         */
        getControlKitLot: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/kitlots/pcr/' + this.get('refKitLotPcr').get('id') + '/values/' + this.get('id') + '/controlKitLot');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: function (response) {
                    var PcrWellService = require('services/caccounts/pcrwells');
                    var AssayReagentTargetPcrKitLotService = require('services/caccounts/assayReagentTargetPcrKitLots');
                    _.each(response, function (item) {
                        item.assayReagentTargetPcrKitLot = AssayReagentTargetPcrKitLotService.getModel(item.assayReagentTargetPcrKitLot);
                        _.each(item.values, function (value) {
                            value.pcrWell = PcrWellService.getModel(value.pcrWell);
                        });
                    });
                    defer.resolve(response);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    });

    app.AssayReagentTargetPcrKitLot.role = [RolesMixin.ASSAY, RolesMixin.KIT_PCR_LOT];

    app.AssayReagentTargetPcrKitLotCollection = Backbone.Collection.extend({
        model: app.AssayReagentTargetPcrKitLot,
        comparator: function (item1, item2) {
            
            var str1 = item1.get('refPcrKitLot').get('kit').get('code') + '_' + item1.get('refPcrKitLot').get('code') + '_' + item1.get('refAssayReagent').get('refAssay').get('code') + '_' +
                item1.get('refAssayReagent').get('reagent').get('type') + '_' + item1.get('refAssayReagent').get('reagent').get('code') + '_' +  item1.get('target').get('code');
            var str2 = item2.get('refPcrKitLot').get('kit').get('code') + '_' + item2.get('refPcrKitLot').get('code') + '_' + item2.get('refAssayReagent').get('refAssay').get('code') + '_' +
                item2.get('refAssayReagent').get('reagent').get('type') + '_' + item2.get('refAssayReagent').get('reagent').get('code') + '_' +  item2.get('target').get('code');

            return str1.localeCompare(str2);
        }
    });
});

