define('assayVersionLotCollection',[
    'module',
    'backbone.marionette',
    'template!assayVersionLotCollection',
    'assayVersionLotItem'
], function (
    module,
    Marionette,
    Tpl,
    View
) {
    'use strict';


    module.exports = Marionette.CollectionView.extend({
        template: Tpl,
        childView: View,
        childViewContainer: '.collection',
        childViewOptions: function (model) {
            var lot = this.options.lots.find(function (lot) {
                return lot.get('reagent') === model.get('reagent');
            });
            return {
                model: model,
                lot: lot
            };
        },
        viewFilter: function (view) {
            if (!this.options.targetValueFiltered) {
                return true;
            }
            if (view.model.get('reagent').get('type') === 'MMX') {
                return true;
            }
            if (this.options.targetValueFiltered === 'PC') {
                return view.model.get('reagent').get('type') === 'QC' && view.model.get('reagent').get('code') === 'PC';
            } else if (this.options.targetValueFiltered === 'NC') {
                return view.model.get('reagent').get('type') === 'QC' && view.model.get('reagent').get('code') === 'NC';
            } else if (this.options.targetValueFiltered === 'RC') {
                return view.model.get('reagent').get('type') === 'QC' && view.model.get('reagent').get('code') === 'RC';
            } else if (this.options.targetValueFiltered === 'OC') {
                return view.model.get('reagent').get('type') === 'QC' && view.model.get('reagent').get('code') === 'OC';
            } else {
                return view.model.get('reagent').get('type') === 'IC';
            }
        }
    });
});
