define('services/caccounts/assayconfiguration',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/assayconfiguration'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.AssayConfiguration,
                collection: app.AssayConfigurationCollection
            };
        },
        getName: function () {
            return 'assayConfiguration';
        },
        getUrl: function () {
            return 'rest/v2/assayConfigurations/';
        },
        getDynamicJson: function () {
            return 'AssayConfigurationJson';
        },
        getJqGridColumn: function () {
            return new Error('can\'t get grid column');
        },

        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.valueProperty = config.valueProperty ? config.valueProperty : 'code';
            configuration.queryProperty = config.queryProperty ? config.queryProperty : 'code';
            configuration.urlPath = config.url ? config.url : this.getCollectionUrl();
            configuration.values = _.extend(config.values, _.extend(config.paramUrl ? config.paramUrl : {}, {
                'sord': 'asc',
                'sidx': 'assay.code'
            }));

            configuration.valueKey = config.valueKey ? config.valueKey : 'code';
            return configuration;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': []};
        },
        getListConfiguration: function () {
            var defer = $.Deferred();
            var resp = [];
            var CyclerService = require('services/caccounts/cyclers');
            resp.push({
                fieldName: 'cyclers',
                values: function () {
                    var defer = $.Deferred();
                    CyclerService.findAll().done(function (result) {
                        defer.resolve(result.chain().uniq(function (cycler) {
                            return cycler.get('refCycler');
                        }).value());
                    });
                    return defer.promise();
                }
            });
            defer.resolve(resp);
            return defer.promise();
        }
    }, Dynamic);
});

