define('kitLotsPcrTargetFilterView',[
    'module',
    'dialogFormView',
    'template!kitLotsPcrTargetFilterView',
    'savingBehavior',
    'underscore',
    'jquery',
    'autocompleteView'
], function (
    module,
    DialogFormView,
    Tpl,
    SavingBehavior,
    _,
    $,
    AutocompleteView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'click .js-confirm': 'onConfirm'
        },

        regions: {
            assay: '.js-assay',
            reagent: '.js-reagent'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            'change': 'render'
        },

        serializeData: function () {
            return {};
        },

        onRender: function () {
            var service = require('services/caccounts/kitlotpcr');
            this.setPermissions(service.canAddOrEdit());
            var AssayService = require('services/caccounts/assay');
            this.showChildView('assay', new AutocompleteView(
                AssayService.getAutocompleteParam({
                    modelProperty: 'assay',
                    valueProperty: 'code',
                    queryProperty: 'code',
                    data: _.map(this.options.kitLot.getAssays(), function (assay) {
                        return assay.toJSON();
                    }),
                    value: this.model.get('assay') ? this.model.get('assay').get('code') : null,
                    callBackOnChange: _.bind(function (fieldName, model) {
                        this.model.set('assay', model);
                        if (model) {
                            this.model.set('reagent', null);
                        }
                    }, this)
                })));
            var PcrReagentService = require('services/caccounts/pcrreagents');
            this.showChildView('reagent', new AutocompleteView(
                PcrReagentService.getAutocompleteParam({
                    modelProperty: 'reagent',
                    valueProperty: 'code',
                    queryProperty: 'code',
                    data: _.map(this.options.kitLot.getReagents(this.model.get('assay')), function (assay) {
                        return assay.toJSON();
                    }),
                    value: this.model.get('reagent') ? this.model.get('reagent').get('code') : null,
                    callBackOnChange: _.bind(function (fieldName, model) {
                        this.model.set('reagent', model);
                    }, this)
                })));
        },

        onConfirm: function () {
            this.hide();
        }
    });
});

