define('jqGridFormatter',[
    'module',
    'underscore',
    'dateUtils',
    'services/caccounts/pcrwells',
    'pcrWellResultController'
], function (
    module,
    _,
    dateConverter,
    PcrWellService,
    PcrWellResultController
) {
    'use strict';

    module.exports = {
        defaultFormatter: function (cellValue) {
            if (!cellValue) {
                return '';
            }
            if (_.isArray(cellValue)) {
                return cellValue.join(', ');
            }
            return cellValue;
        },
        defaultHtmlFormatter: function (cellValue) {
            if (!cellValue) {
                return '';
            }
            return '<div class="d-f">' + cellValue + '</div>';
        },
        ovarResultFormatter: function (cellValue, call, object) {
            var model = PcrWellService.getById(object.id);
            if (!model) {
                model = PcrWellService.getModel(object);
            }
            var html = '';
            var result = model.getDisplay('OVAR').first();
            if (result) {
                var color = result.get('result') ? result.get('result').get('color') : 'transparent';
                html = '<div style="height: 100%; background-color: ' + color + '; width: 20px; margin-left: auto; margin-right: auto;"></div>';
            }
            return html;
        },
        typeParamFormatter: function (cellValue) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }

            return '<span class="cell-default">' + (cellValue.type ? cellValue.type + ';' + (cellValue.param ? cellValue.param : '') : '') + '</span>';
        },
        userFormatter: function (cellValue, options, model) {
            var title = '';
            cellValue = model[options.colModel.name] ? model[options.colModel.name].nickName : '';
            if (model[options.colModel.name]) {
                if (model && model[options.colModel.name] && model[options.colModel.name].email) {
                    title = model[options.colModel.name].email;
                }
                if (cellValue === '' && title) {
                    cellValue = title;
                }
                if (model && model[options.colModel.name] && model[options.colModel.name].firstName && model[options.colModel.name].lastName) {
                    title = model[options.colModel.name].firstName + ' ' + model[options.colModel.name].lastName;
                }
            }

            return '<span class="cell-default" title="' + title + '"' +
                '>' + cellValue + '</span>';
        },

        validationFormatter: function (cellValue) {
            if (parseInt(cellValue, 10) === 3) {
                return '<div title="Validation 1">' +
                    '<span class="mdi mdi-check check-val1-graphAction-icon val-green" style="left: 1px;"></span>' +
                    '</div>';
            }
            if (parseInt(cellValue, 10) === 4) {
                return '<div title="Validation 1&amp;2" style="position: relative;">' +
                    '<span class="mdi mdi-check check-val1-graphAction-icon val-green"></span>' +
                    '<span class="mdi mdi-check check-val2-graphAction-icon val-green"></span>' +
                    '</div>';
            }
            return '';
        },

        copyToClipboardFormatter: function (cellValue) {
            return '<span class="mdi mdi-content-copy" title="' + _.i18n('common.copyToClipboard') + '" data-copy-value="' + cellValue + '"></span>';
        },

        concatFormatter: function (cellValue, options, rawData) {
            cellValue = [];
            _.each(options.colModel.name, function (name) {
                if (rawData[name]) {
                    cellValue.push(rawData[name]);
                }
            });

            return '<span class="cell-default">' + cellValue.join(' - ') + '</span>';
        },
        typeFormatter: function (cellValue) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }
            cellValue = cellValue.split(';');

            return '<span class="cell-default">' + cellValue[0] + '</span>';
        },
        paramFormatter: function (cellValue) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }
            cellValue = cellValue.split(';');
            if (cellValue.length > 0) {
                cellValue = cellValue.slice(1).join(';');
            } else {
                cellValue = '';
            }

            return '<span class="cell-default">' + cellValue + '</span>';
        },
        getRepeatLabelIcon: function () {
            return '<div style="position: relative;">' +
                '<span class="mdi mdi-play mdi-flip-h" style="font-size: 21px; top: 0;"></span>' +
                '<span class="mdi mdi-play mdi-flip-h" style="position: absolute; font-size: 21px; top: 0; left: 6px;"></span>' +
                '</div>';
        },

        getWarningLabelIcon: function () {
            return '<span class="mdi mdi-alert" style="font-size: 21px; top: -1px; color: #ff5200;"></span>';
        },

        getExpertLabelIcon: function () {
            return '<span class="mdi mdi-school" style="font-size: 21px; top: -1px; color: #0014FF;"></span>';
        },

        getSmpTypeLabelIcon: function () {
            return '<span class="mdi mdi-checkbox-blank-circle-outline" style="font-size: 21px; top: -1px;"></span>';
        },

        getSameAssayLabelIcon: function () {
            return '<div class="existOther" style="position:relative; overflow: initial; height: 20px;  left: 3px">' +
                '<span class="triangle-same-assay-plateView"></span>' +
                '</div>';
        },

        getOtherAssayLabelIcon: function () {
            return '<div class="existOther" style="position:relative; overflow: initial; height: 20px;  left: 3px">' +
                '<span class="triangle-other-assay-plateView"></span>' +
                '</div>';
        },

        rawFormatter: function (cellValue) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }

            return '<span class="cell-default">' + String(cellValue).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;') + '</span>';
        },

        defaultListFormatter: function (cellValue) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = [];
            }

            cellValue = cellValue.join(', ');
            return '<span class="cell-default">' + cellValue + '</span>';
        },

        codeNameListFormatter: function (cellValue) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }

            return '<span class="cell-default">' + _.pluck(cellValue, 'code').join(', ') + '</span>';
        },
        booleanFormatterNoCheckBox: function (cellvalue) {
            if (cellvalue) {
                return '<span class="mdi mdi-check-bold js-icon"></span>';
            }
            return '';
        },

        lisAnaFormatter: function (cellValue) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }

            return '<span class="cell-default">' + _.pluck(cellValue, 'code').join(', ') + '</span>';
        },

        booleanFormatter: function (cellvalue) {
            var checked = cellvalue ? 'checked' : '',
                htmlEl = '<div class="checkbox-group">' +
                    '<div class="checkbox checkbox-primary list-checkbox">' +
                    '<input disabled="disabled" type="checkbox" ' + checked + ' ' +
                    '></div></div>';
            return htmlEl;
        },

        simpleBooleanFormatter: function (cellvalue) {
            var checked = cellvalue ? 'checked' : '',
                htmlEl = '<input disabled="disabled" type="checkbox" ' + ' ' + checked + ' ' + '>';
            return htmlEl;
        },

        deleteIconFormatter: function () {
            return '<span class="mdi mdi-delete cell-delete js-icon clickable"></span>';
        },

        attachmentActionIconFormatter: function () {
            return '<span class="mdi mdi-attachment mdi-flip-v mdi-rotate-90 cell-attachment js-icon" ' +
                'title="' + _.i18n('attachment') + '"></span>';
        },

        duplicateActionIconFormatter: function (cellValue, options, model) {
            var codeName = model.code ? model.code : (model.name ? model.name : (model.sampleId ? model.sampleId : (model.code ? model.code : '')));
            return '<span class="mdi mdi-content-duplicate cell-duplicate js-icon" ' +
                'title="' + _.i18n('duplicate') + '" data-row-codeName="' + codeName + '"></span>';
        },

        errorIconFormatter: function (cellValue) {
            if (_.isEmpty(cellValue)) {
                return '';
            }
            return '<span class="mdi mdi-alert-octagram cell-alert js-icon" ' +
                'title="' + _.i18n('common.showErrors') + '"></span>';
        },

        deleteActionIconFormatter: function (cellValue, options, model) {
            var codeName = model.code ? model.code : (model.name ? model.name : (model.sampleId ? model.sampleId : (model.code ? model.code : '')));
            return '<span class="mdi mdi-delete-outline cell-delete js-icon" ' +
                'title="' + _.i18n('delete') + '" data-row-codeName="' + codeName + '"></span>';
        },

        archiveRestoreActionIconFormatter: function (cellValue, options, model) {
            var codeName = model.code ? model.code : (model.name ? model.name : (model.sampleId ? model.sampleId : (model.code ? model.code : '')));
            if (cellValue) {
                return '<span class="mdi mdi-delete-restore cell-unArchive js-icon" ' +
                    'title="' + _.i18n('restore') + '" data-row-codeName="' + codeName + '"></span>';
            } else {
                return '<span class="mdi mdi-archive-arrow-down-outline cell-archive js-icon" ' +
                    'title="' + _.i18n('archive') + '" data-row-codeName="' + codeName + '"></span>';
            }
        },

        archiveRestoreActionIconFormatterNoAction: function (cellValue, options, model) {
            var codeName = model.code ? model.code : (model.name ? model.name : (model.sampleId ? model.sampleId : (model.code ? model.code : '')));
            if (cellValue) {
                return '<span class="mdi mdi-delete-restore js-icon disabled" ' +
                    'title="' + _.i18n('restore') + '" data-row-codeName="' + codeName + '"></span>';
            } else {
                return '<span class="mdi mdi-archive-arrow-down-outline js-icon disabled" ' +
                    'title="' + _.i18n('archive') + '" data-row-codeName="' + codeName + '"></span>';
            }
        },

        exportActionIconFormatter: function () {
            return '<span class="mdi mdi-export-variant cell-export js-icon" ' +
                'title="' + _.i18n('export') + '"></span>';
        },

        rowSelectorFormatter: function (cellValue, options) {
            var mdi = 'mdi-checkbox-blank-outline';
            if (options.colModel.checked) {
                mdi = 'mdi-checkbox-marked-outline';
            }
            return '<span class="mdi ' + mdi + ' cell-select"></span>';
        },

        dateFormatterFromSettings: function (cellValue) {
            var date = '';
            if (cellValue) {
                date = dateConverter.toDateFormatStringFromSettings(cellValue, this.settings);
            }
            return '<span class="cell-date">' + date + '</span>';
        },
        toDateTimeFormatString: function (cellValue) {
            var date = '';
            if (cellValue) {
                date = dateConverter.toDateTimeFormatString(cellValue);
            }
            return '<span class="cell-date">' + date + '</span>';
        },


        dateTimeFormatterFromSettings: function (cellValue) {
            var date = '';
            if (cellValue) {
                date = dateConverter.toDateTimeFormatStringFromSettings(cellValue, this.settings);
            }
            return '<span class="cell-date">' + date + '</span>';
        },

        dateTimeFormatter: function (cellValue) {
            var date = '';
            if (cellValue) {
                date = dateConverter.toDateTimeShortYearFormatString(cellValue);
            }

            return '<span class="cell-date">' + date + '</span>';
        },

        dateTimeSecondeFormatter: function (cellValue) {
            var date = '';
            if (cellValue) {
                date = dateConverter.toDateTimeSecondeFormatString(cellValue);
            }

            return '<span class="cell-date">' + date + '</span>';
        },

        assayWaitingFormatter: function (cellValue) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = [];
            }
            if (_.isEmpty(cellValue)) {
                return '<div class="cell-default "></div>';
            }
            var val = [];
            _.each(cellValue, function (value) {
                val.push(value.assay.code + ' (' + value.count + ')');
            });

            return '<div class="cell-default assayStatus assayWaitingCell">' + val.join(', ') + '</div>';
        },

        assayRunningFormatter: function (cellValue) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = [];
            }
            if (_.isEmpty(cellValue)) {
                return '<div class="cell-default"></div>';
            }
            var val = [];
            _.each(cellValue, function (value) {
                val.push(value.assay.code + ' (' + value.count + ')');
            });

            return '<div class="cell-default assayStatus assayRunningCell">' + val.join(', ') + '</div>';
        },

        assayDoneFormatter: function (cellValue) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = [];
            }
            if (_.isEmpty(cellValue)) {
                return '<div class="cell-default"></div>';
            }
            var val = [];
            _.each(cellValue, function (value) {
                val.push(value.assay.code + ' (' + value.count + ')');
            });

            return '<div class="cell-default assayStatus assayDoneCell">' + val.join(', ') + '</div>';
        },

        wellAssayWaitingFormatter: function (cellValue) {
            if (cellValue !== '-') {
                return '<div class="cell-default assayStatus assayWaitingCell">' + cellValue + '</div>';
            } else {
                return '<div class="cell-default"></div>';
            }
        },

        wellAssayRunningFormatter: function (cellValue) {
            if (cellValue !== '-') {
                return '<div class="cell-default assayStatus assayRunningCell">' + cellValue + '</div>';
            } else {
                return '<div class="cell-default"></div>';
            }
        },

        wellAssayDoneFormatter: function (cellValue) {
            if (cellValue !== '-') {
                return '<div class="cell-default assayStatus assayDoneCell">' + cellValue + '</div>';
            } else {
                return '<div class="cell-default"></div>';
            }
        },
        codeNameFormatter: function (cellvalue) {
            if (cellvalue && cellvalue.code) {
                return cellvalue.code;
            } else {
                return '';
            }
        },

        mbAnaResGrFormatter: function (cellvalue) {
            if (cellvalue) {
                return '<span>' + cellvalue.code + '</span>';
            } else {
                return '';
            }
        },

        displayModeFormatter: function (cellValue, options, object) {
            var content = '';
            if (!options.colModel.hidden && options.colModel.targetId) {
                // get the result from results
                var targetId = options.colModel.targetId;
                var model = PcrWellService.getById(object.id);
                var result = model.get('results').find(function (result) {
                    return result.get('refAssayResult') && result.get('refAssayResult').get('target').id === targetId;
                });
                if (result) {
                    content = PcrWellResultController.generateContent(result, options.colModel.location);
                }
            }
            return '<div class="d-f gap-1">' + content + '</div>';
        },

        resultNumericFormatter: function (cellValue, options, object) {
            var content = '';
            if (options.colModel.index && options.colModel.targetId) {
                // get the result from results
                var targetId = options.colModel.targetId;
                var model = PcrWellService.getById(object.id);
                var result = model.get('results').find(function (result) {
                    return result.get('refAssayResult') && result.get('refAssayResult').get('target').id === targetId;
                });
                if (result) {
                    content = result.get('cts').join(', ');
                }
            }
            return '<div class="d-f gap-1">' + content + '</div>';
        },

        quantificationFormatter: function (cellValue, options, object) {
            var content = '';
            if (options.colModel.index && options.colModel.targetId) {
                // get the result from results
                var targetId = options.colModel.targetId;
                var model = PcrWellService.getById(object.id);
                var result = model.get('results').find(function (result) {
                    return result.get('refAssayResult') && result.get('refAssayResult').get('target').id === targetId;
                });
                if (result) {
                    content = result.get('quantifications').join(', ');
                }
            }
            return '<div class="d-f gap-1">' + content + '</div>';
        },

        displayModeResultNumericFormatter: function (cellValue, options, object) {
            var content = '';
            if (options.colModel.index && options.colModel.targetId) {
                // get the result from results
                var targetId = options.colModel.targetId;
                var model = PcrWellService.getById(object.id);
                var result = model.get('results').find(function (result) {
                    return result.get('refAssayResult') && result.get('refAssayResult').get('target').id === targetId;
                });
                if (result) {
                    content = PcrWellResultController.generateContent(result, options.colModel.location) + ' ' + result.get('cts').join(', ');
                }
            }
            return '<div class="d-f gap-1">' + content + '</div>';
        },

        displayModeQuantificationFormatter: function (cellValue, options, object) {
            var content = '';
            if (options.colModel.index && options.colModel.targetId) {
                // get the result from results
                var targetId = options.colModel.targetId;
                var model = PcrWellService.getById(object.id);
                var result = model.get('results').find(function (result) {
                    return result.get('refAssayResult') && result.get('refAssayResult').get('target').id === targetId;
                });
                if (result) {
                    content = PcrWellResultController.generateContent(result, options.colModel.location) + ' ' + result.get('quantifications').join(', ');
                }
            }
            return '<div class="d-f gap-1">' + content + '</div>';
        },

        displayModeResultNumericQuantificationFormatter: function (cellValue, options, object) {
            var content = '';
            if (options.colModel.index && options.colModel.targetId) {
                // get the result from results
                var targetId = options.colModel.targetId;
                var model = PcrWellService.getById(object.id);
                var result = model.get('results').find(function (result) {
                    return result.get('refAssayResult') && result.get('refAssayResult').get('target').id === targetId;
                });
                if (result) {
                    var quantifications = result.get('quantifications');
                    quantifications = _.map(quantifications, function (resultat) {
                        if (resultat >= 1000) {
                            var premierChiffre = Math.floor(resultat / Math.pow(10, Math.floor(Math.log10(resultat))));
                            var exposant = Math.floor(Math.log10(resultat));
                            return premierChiffre + 'E' + exposant;
                        } else if (resultat >= 100) {
                            return Math.round(resultat).toString();
                        } else if (resultat >= 10) {
                            return resultat.toFixed(1);
                        } else {
                            return resultat.toFixed(2);
                        }
                    });
                    content = PcrWellResultController.generateContent(result, options.colModel.location) + ' ' + result.get('cts').join(', ') + (quantifications.length > 0 ? ' (' + quantifications.join(', ') + ')' : '');
                }
            }
            return '<div class="d-f gap-1">' + content + '</div>';
        }
    };
});
