/**
 * Created by GSP on 15/09/2015.
 */
/**
 * Created by RKL on 10/09/2015.
 */
define('pcrWellResultHistogramNormalView',[
    'module',
    'backbone.marionette',
    'underscore',
    'jquery',
    'template!pcrWellResultHistogramNormalView',
    'pcrWellResultHistogramNormalChartView'
], function (
    module,
    Marionette,
    _,
    $,
    viewTpl,
    PcrWellResultHistogramNormalChartView
) {
    'use strict';

    module.exports = Marionette.View.extend({

        template: viewTpl,

        ui: {
            chart: '.js-chart',
            img: '.js-image',
            result: '.js-result',
            ct: '.js-ct',
            quantification: '.js-quantification'
        },

        regions: {
            chart: '.js-chart'
        },

        modelEvents: {
            'change': 'render'
        },

        className: 'd-f gap-1',

        serializeData: function () {
            return {
                code: this.model.get('resultCode'),
                ct: this.model.get('ct') ? this.model.get('ct').join(', ') : '',
                quantification: this.model.get('quantification') ? this.model.get('quantification').join(', ') : ''
            };
        },

        attributes: function () {
            var height = this.options.config.height;
            var width = this.options.config.width;
            var title = '';
            if (this.model.get('refAssayResult') && this.model.get('refAssayResult').get('target')) {
                title = this.model.get('refAssayResult').get('target').get('code') + ' - ' + (this.model.get('result') ? this.model.get('result').get('code') : '');
                if (!_.isEmpty(this.model.get('cts'))) {
                    title += ' - ' + this.model.get('cts').join(', ');
                    if (!_.isEmpty(this.model.get('quantifications'))) {
                        title += ' (' + this.model.get('quantifications').join(', ') + ')';
                    }
                }
            }
            var attr = {
                title: title,
                'data-code': this.model.get('refAssayResult') && this.model.get('refAssayResult').get('target') && this.model.get('refAssayResult').get('target').get('code'),
                'data-id': this.model.get('refAssayResult') && this.model.get('refAssayResult').get('target') && this.model.get('refAssayResult').get('target').id
            };
            if (this.options.generateImage) {
                height = height ? height : 80;
                width = width ? width : 400;
                return _.extend({style: 'position: relative;height:' + height + 'px; width:' + width + 'px;max-width:100%;display:flex;'}, attr);
            }
            if (this.options.config.max) {
                height = height ? height : 16;
                return _.extend({style: 'position: relative;height:' + height + 'px; width:100%;max-width:100%;padding-right:5px;display:flex;'}, attr);
            }
            return _.extend({style: 'position: relative; width:100%; max-width:100%;padding-right:5px; height: 17px;display:flex;'}, attr);
        },

        onRender: function () {
            if (this.options.generateImage) {
                var view = new PcrWellResultHistogramNormalChartView(this.options);
                view.render();
                this.ui.img.attr('src', view.toBase64Image());
                view.destroy();
                this.ui.img.show();
                this.ui.chart.hide();
            } else {
                this.ui.img.hide();
                this.ui.chart.show();
                this.showChildView('chart', new PcrWellResultHistogramNormalChartView(this.options));
            }
            if (this.options.config.display && this.options.config.display.result) {
                this.ui.result.show();
            } else {
                this.ui.result.hide();
            }
            if (this.options.config.display && this.options.config.display.ct) {
                this.ui.ct.show();
            } else {
                this.ui.ct.hide();
            }
            if (this.options.config.display && this.options.config.display.quantification) {
                this.ui.quantification.show();
            } else {
                this.ui.quantification.hide();
            }
        }
    });
});
