define('dynamicListHeaderItemView',['module',
    'backbone.marionette',
    'underscore',
    'template!dynamicListHeaderItemView',
    'template!dynamicListHeaderItemButtonView'
], function (module,
             Marionette,
             _,
             Tpl,
             ButtonTpl
) {
    'use strict';

    module.exports = Marionette.View.extend({
        getTemplate: function () {
            return this.model.get('button') ? ButtonTpl : Tpl;
        },

        ui: {
            span: 'span'
        },

        attributes: function () {
            if (this.model.get('button')) {
                return {'style': 'width: 24px; line-height: 24px;'};
            }
            return {};
        },

        className: function () {
            return 'm-l-2 m-r-2 ' + (this.model.get('param') && this.model.get('param').display ? this.model.get('param').display['class'] : '');
        },

        serializeData: function () {
            var templateData = {};
            templateData.name = this.model.get('name');
            templateData.field = this.model.get('field');
            templateData.enable = this.model.get('enable');
            return templateData;
        },

        onRender: function () {
            if (this.model.get('button')) {
                if (this.model.get('enable')) {
                    this.ui.span.addClass('clickable');
                } else {
                    this.ui.span.addClass('disabled');
                    this.$el.attr('disabled', 'disabled');
                }
            }
        }
    });
});

