define('entities/caccounts/assay',[
    'entities/ns',
    'backbone',
    'settings',
    'underscore',
    'rolesMixin',
    'entities/caccounts/assayreagent',
    'entities/caccounts/assayconfiguration',
    'entities/caccounts/kitprots',
    'entities/caccounts/assaygroup',
    'entities/caccounts/displayModes'
], function (
    app,
    Backbone,
    Settings,
    _,
    RolesMixin
) {
    'use strict';

    app.Assay = Backbone.RelationalModel.extend({
        service: 'services/caccounts/assay',
        fetch: function () {
            this.url = Settings.url('rest/v2/assays/' + this.get('id'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'refKitProt': null,
            'code': '',
            'name': '',
            'description': undefined,
            'comment': '',
            'refAssayGroup': undefined,
            'reagents': undefined,
            'volumeBySample': null,
            'volumeIC': null,
            'algoDeathVolume': {type: 'NO', param: ''},
            'configurations': [],
            'displayMode': null
        },
        idAttribute: 'id',

        relations: [{
            type: Backbone.HasMany,
            key: 'configurations',
            relatedModel: 'AssayConfiguration',
            collectionType: 'AssayConfigurationCollection'
        }, {
            type: Backbone.HasMany,
            key: 'reagents',
            relatedModel: 'AssayReagent',
            collectionType: 'AssayReagentCollection',
            reverseRelation: {
                includeInJSON: ['id', 'code', 'name']
            }
        }, {
            type: Backbone.HasOne,
            key: 'refKitProt',
            relatedModel: 'KitProt',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'refAssayGroup',
            relatedModel: 'AssayGroup',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'displayMode',
            relatedModel: 'DisplayMode',
            includeInJSON: ['id', 'code', 'name']
        }],

        postUrl: function () {
            return Settings.url('rest/v2/assays/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'AssayJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Assay',

        importable: true,
        getReagentsQC: function () {
            return new app.AssayReagentCollection(this.get('reagents').filter(function (assayReagent) {
                return assayReagent && assayReagent.get('reagent') && assayReagent.get('reagent').get('type') === 'QC';
            }));
        },
        getReagentIC: function () {
            var model = _.first(this.get('reagents').filter(function (assayReagent) {
                return assayReagent && assayReagent.get('reagent') && ['IC'].includes(assayReagent.get('reagent').get('type'));
            }));
            if (!model) {
                model = new app.AssayReagent({refAssay: this});
            }
            return model;
        },
        getReagentsMMX: function () {
            return new app.AssayReagentCollection(this.get('reagents').filter(function (assayReagent) {
                return assayReagent && assayReagent.get('reagent') && !['QC', 'IC'].includes(assayReagent.get('reagent').get('type')) && assayReagent.get('reagent').get('type');
            }));
        },
        getDisplayMode: function () {
            var displayMode = this.get('displayMode') ? this.get('displayMode') : null;
            if (!displayMode) {
                displayMode = this.get('refKitProt') && this.get('refKitProt').get('displayMode') ? this.get('refKitProt').get('displayMode') : null;
            }
            if (!displayMode) {
                var DisplayModeService = require('services/caccounts/displayModes');
                displayMode = DisplayModeService.getDefaultDisplayMode();
            }
            return displayMode;
        },
        getMeltParam: function () {
            var ret = {
                start: null,
                end: null,
                step: null,
                upOrDown: null
            };
            var param = this.get('refKitProt').get('meltParam');
            if (!param) {
                return ret;
            }
            var split = param.split(';');
            if (split.length > 1) {
                ret.start = Number(split[1]);
            }
            if (split.length > 2) {
                ret.end = Number(split[2]);
            }
            return ret;
        },
        getAmpParam: function () {
            return this.get('refKitProt').get('ampParam');
        }
    });

    app.Assay.role = RolesMixin.ASSAY;

    app.AssayCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/assays/');
        },
        model: app.Assay
    });
});

