define('runPcrControllerConfiguration',[
        'module',
        'underscore',
        'runPcrHeaderDetailsView',
        'runPcrContentPlateView',
        'runPcrContentWellListView',
        'pcrWellView',
        'runPcrHeaderPlateLeftView',
        'runPcrHeaderPlateRightView',
        'runPcrValidationView',
        'runPcrContentTargetListView',
        'runPcrContentContaminationWellView',
        'runPcrHeaderContaminationLeftView'
    ],
    function (
        module,
        _,
        RunPcrHeaderDetailsView,
        RunPcrContentPlateView,
        RunPcrContentWellListView,
        PcrWellView,
        RunPcrHeaderPlateLeftView,
        RunPcrHeaderPlateRightView,
        RunPcrValidationView,
        RunPcrContentTargetListView,
        RunPcrContentContaminationWellView,
        RunPcrHeaderContaminationLeftView
    ) {
        'use strict';

        module.exports = {
            getConfiguration: function () {
                return {
                    header: RunPcrHeaderDetailsView,
                    tabs: [{
                        item: {
                            title: _.i18n('runs.table.tabTitle'),
                            mdi: 'mdi-grid'
                        },
                        content: RunPcrContentPlateView,
                        className: 'plate',
                        type: ['PLATE', 'OVAR'],
                        options: {
                            childView: PcrWellView
                        },
                        header: {
                            left: RunPcrHeaderPlateLeftView,
                            right: RunPcrHeaderPlateRightView
                        }
                    }, {
                        item: {
                            title: _.i18n('runs.assays.valview.tabTitle'),
                            mdi: 'mdi-chart-areaspline'
                        },
                        content: RunPcrValidationView,
                        className: 'validation',
                        type: ['VALIDATION', 'OVAR'],
                        enable: function (model) {
                            var valStatusSplitted = _.map(model.get('valStatus').split(), function (val) {
                                return parseInt(val, 10);
                            });
                            return _.any(valStatusSplitted, function (val) {
                                return val >= 1;
                            });
                        }
                    }, {
                        item: {
                            title: _.i18n('runs.details.tabTitle'),
                            mdi: 'mdi-view-list'
                        },
                        content: RunPcrContentWellListView,
                        className: 'well-list',
                        type: ['WELL_LIST', 'OVAR']
                    }, {
                        item: {
                            title: _.i18n('runs.targetview.tabTitle'),
                            mdi: 'mdi-reorder-horizontal'
                        },
                        content: RunPcrContentTargetListView,
                        className: 'target-list',
                        type: ['TARGET_LIST', 'OVAR'],
                        enable: function (model) {
                            var valStatusSplitted = _.map(model.get('valStatus').split(), function (val) {
                                return parseInt(val, 10);
                            });
                            return _.any(valStatusSplitted, function (val) {
                                return val >= 1;
                            });
                        }
                    }, {
                        item: {
                            title: _.i18n('runs.contaminations.tabTitle'),
                            mdi: 'mdi-biohazard'
                        },
                        content: RunPcrContentPlateView,
                        className: 'contamination',
                        type: ['CONTAMINATION', 'OVAR'],
                        options: {
                            childView: RunPcrContentContaminationWellView
                        },
                        header: {
                            left: RunPcrHeaderContaminationLeftView
                        },
                        enable: function (model) {
                            var valStatusSplitted = _.map(model.get('valStatus').split(), function (val) {
                                return parseInt(val, 10);
                            });
                            return _.any(valStatusSplitted, function (val) {
                                return val >= 1;
                            });
                        }
                    }]
                };
            }
        };
    }
);

