
define('template!prepRunExtrKitsLotsView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<h2>' +
((__t = ( _.i18n('run.extractionMethod') )) == null ? '' : __t) +
'</h2>\n<div class="js-details"></div>\n\n<h2>' +
((__t = ( _.i18n('run.extraction.reagents') )) == null ? '' : __t) +
'</h2>\n<div class="js-extractionKitLots"></div>\n\n<h2>' +
((__t = ( _.i18n('run.extraction.ic') )) == null ? '' : __t) +
'</h2>\n<div class="js-pcrKitLots"></div>\n\n<div class="cancelConfirmRow">\n    <button class="mdi-navigation-check btn popupBtn-confirm js-confirm"></button>\n</div>';

}
return __p
};});

