define('kitLotsPcrTargetView',[
    'module',
    'dialogFormView',
    'template!kitLotsPcrTargetView',
    'savingBehavior',
    'underscore',
    'jquery',
    'kitLotsPcrLotView',
    'backbone',
    'kitLotsPcrTargetFilterView'
], function (
    module,
    DialogFormView,
    Tpl,
    SavingBehavior,
    _,
    $,
    KitLotsPcrLotView,
    Backbone,
    KitLotsPcrTargetFilterView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm',
            left: '.js-left',
            filter: '.js-filter',
            otherLot: '.js-other-lot'
        },

        events: {
            'click .js-cancel-popup': 'onCancel',
            'click .js-confirm': 'onConfirm',
            'click .mdi-transfer-left.clickable': 'onTransfer',
            'click @ui.filter': 'onFilterLot'
        },

        regions: {
            currentLot: '.js-current-lot',
            otherLot: '.js-other-lot'
        },

        behaviors: {
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        className: 'd-f gap-10 column',

        modelEvents: {
            sync: 'onSave'
        },

        initialize: function (options) {
            this.current = {assay: undefined, reagent: null};
            if (options.current) {
                this.current = options.current;
            }
            this.otherLots = new Backbone.Collection(this.model.get('kit').get('lots').models);
            this.otherLots.remove(this.model);
            this.enableOtherLot = this.otherLots.length > 0;
        },

        serializeData: function () {
            return {};
        },

        onRender: function () {
            this.showCurrentLot(this.model);
            if (this.enableOtherLot) {
                this.showOtherLot(this.otherLots.first());
                this.ui.left.removeClass('invisible');
                this.ui.otherLot.removeClass('invisible');
            }
            this.filterCollection();
            this.setPermissions(require('services/caccounts/kitlotpcr').canAddOrEdit());
        },

        onFilterLot: function () {
            // open new view with filter
            var model = new Backbone.Model(this.current);
            var view = new KitLotsPcrTargetFilterView({
                kitLot: this.model,
                model: model
            });
            view.show({
                title: _.i18n('kitLotsPcrTargetView.filter'),
                className: 'baseTableEditAddPopup minContent'
            }, _.bind(function () {
                this.current.assay = model.get('assay');
                this.current.reagent = model.get('reagent');
                this.filterCollection();
            }, this));
        },
        showCurrentLot: function (model) {
            this.currentLot = new KitLotsPcrLotView({
                model: model,
                reagent: this.current.reagent,
                assay: this.current.assay,
                editable: true,
                enableLeft: false,
                enableRight: false
            });
            this.getRegion('currentLot').show(this.currentLot);
        },

        showOtherLot: function (model) {
            var currentIndex = this.otherLots.indexOf(model);
            this.otherLotView = new KitLotsPcrLotView({
                model: model,
                reagent: this.current.reagent,
                assay: this.current.assay,
                enableLeft: currentIndex > 0,
                enableRight: currentIndex < this.otherLots.length - 1
            });
            this.getRegion('otherLot').show(this.otherLotView);

            this.listenTo(this.otherLotView, 'left', this.onLeft);
            this.listenTo(this.otherLotView, 'right', this.onRight);
        },

        filterCollection: function () {
            this.currentLot.filterCollection(this.current.assay, this.current.reagent);
            if (this.enableOtherLot) {
                this.otherLotView.filterCollection(this.current.assay, this.current.reagent);
            }
        },

        onConfirm: function () {
            this.model.save();
        },

        onSave: function () {
            this.triggerMethod('saved');
            this.box.modal('hide');
        },

        onCancel: function () {
            this.model.fetch().done(_.bind(function () {
                this.box.modal('hide');
            }, this));
        },

        onTransfer: function () {
            var origin = this.otherLotView.model;
            var target = this.currentLot.model;
            origin.get('assayReagentTargetPcrKitLots').each(function (assayReagentTargetPcrKitLot) {
                var currentAssayReagentTargetPcrKitLot = _.first(target.get('assayReagentTargetPcrKitLots').filter(function (currentARTPKL) {
                    return currentARTPKL.get('refAssayReagent') === assayReagentTargetPcrKitLot.get('refAssayReagent') &&
                        currentARTPKL.get('target') === assayReagentTargetPcrKitLot.get('target');
                }));
                if (currentAssayReagentTargetPcrKitLot) {
                    var data = {
                        val: assayReagentTargetPcrKitLot.get('val'),
                        deltaWarning: assayReagentTargetPcrKitLot.get('deltaWarning'),
                        deltaError: assayReagentTargetPcrKitLot.get('deltaError'),
                        refMbAnaRes: assayReagentTargetPcrKitLot.get('refMbAnaRes')
                    };
                    currentAssayReagentTargetPcrKitLot.set(data, {silent: true});
                }
            });
            this.model.trigger('change');
        },

        onLeft: function (model) {
            var currentIndex = this.otherLots.indexOf(model);
            if (currentIndex > 0) {
                this.showOtherLot(this.otherLots.at(currentIndex - 1));
            }
        },

        onRight: function (model) {
            var currentIndex = this.otherLots.indexOf(model);
            if (currentIndex < this.otherLots.length) {
                this.showOtherLot(this.otherLots.at(currentIndex + 1));
            }
        }
    });
});

