define('runPlateLayoutEditCollection',[
    'module',
    'backbone',
    'backbone.marionette',
    'dialogMixin',
    'underscore',
    'jquery',
    'template!runPlateLayoutEditView',
    'wellEditSmpidView',
    'prepRunWellEditSmpidView',
    'runPlateLayoutEditHeaderView'
], function (
    module,
    Backbone,
    Marionette,
    DialogMixin,
    _,
    $,
    Tpl,
    PCRItemView,
    PrepItemView,
    HeaderItemView
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend(_.extend({
        attributes: function () {
            return {
                style: 'display: grid; grid-template-columns: 0.25fr repeat(' + this.model.getColumn() + ', 1fr); grid-template-rows: 0.25fr 1fr; grid-auto-rows: 1fr;'
            };
        },
        childView: function (item) {
            if (item.get('header') === true) {
                return HeaderItemView;
            }
            switch (this.model.getType()) {
                case 'PcrRun':
                case 'PcrRunTemplate':
                    return PCRItemView;
                case 'PrepRun':
                case 'PrepRunTemplate':
                    return PrepItemView;
            }
        },
        childViewOptions: function (model) {
            return {
                model: model,
                settings: this.options.settings,
                editMode: this.options.mode
            };
        },
        initialize: function () {
            this.collection = new Backbone.Collection();
            this.model.get('wells').each(_.bind(function (well) {
                this.collection.add(well);
            }, this));
            var row = this.model.getLine();
            var col = this.model.getColumn();
            var i, data;
            for (i = 0; i <= col; i++) {
                var column = String(i).padStart(2, '0');
                data = {pos: String.fromCharCode(64) + column, name: column === 0 ? '' : column, header: true};
                switch (this.model.getType()) {
                    case 'PcrRun':
                    case 'PcrRunTemplate':
                        data.refPcrRun = this.model;
                        break;
                    case 'PrepRunTemplate':
                    case 'PrepRun':
                        data.refPrepRun = this.model;
                        break;
                }
                this.collection.add(data);
            }
            for (i = 65; i <= row.charCodeAt(0); i++) {
                var line = String.fromCharCode(i);
                data = {pos: line + '00', name: line, header: true};
                switch (this.model.getType()) {
                    case 'PcrRun':
                    case 'PcrRunTemplate':
                        data.refPcrRun = this.model;
                        break;
                    case 'PrepRun':
                    case 'PrepRunTemplate':
                        data.refPrepRun = this.model;
                        break;
                }
                this.collection.add(data);
            }
            this.collection.comparator = 'pos';
            this.collection.sort();
        }
    }, DialogMixin));
});

