define('kitLotActionPrepWellItemView',[
    'module',
    'backbone.marionette',
    'template!kitLotActionPrepWellItemView',
    'underscore',
    'dynamicCreateEditCustomListToEntity'
], function (
    module,
    Marionette,
    Tpl,
    _,
    DynamicCreateEditCustomListToEntity
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        tagName: 'tr',
        regions: {
            assay: '.js-assay'
        },

        serializeData: function () {
            return {
                runCode: this.model.get('run').get('code'),
                refWellPos: this.model.get('wellReference').get('pos'),
                testWellPos: this.model.get('pos'),
                sampleId: this.model.get('smpId').get('code')
            };
        },
        onRender: function () {
            var service = require('services/caccounts/assay');
            var assays = [];
            assays.push(this.model.get('assayWaiting').models);
            assays.push(this.model.get('assayRunning').models);
            assays.push(this.model.get('assayDone').models);

            assays = service.getCollection(_.chain(assays).uniq(function (assay) {
                return assay.get('id');
            }).value());

            this.showChildView('assay', new DynamicCreateEditCustomListToEntity({
                'field': '',
                'collection': assays,
                service: service
            }));
        }
    });
});
