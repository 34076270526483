define('splitLisAnaView',[
	'module',
	'app',
	'underscore',
	'jquery',
	'dialogFormView',
	'template!splitLisAnaView'
], function (
	module,
	App,
	_,
	$,
	DialogFormView,
	Tpl
) {
	'use strict';

	module.exports = DialogFormView.extend({
		template: Tpl,

		className: 'col-w-all',
		ui: {
			transferRight: '.mdi-transfer-right',
			transferLeft: '.mdi-transfer-left',
			originLisAnas: '#lisAnaOriginSample',
			newLisAnas: '#lisAnaNewSample',
			confirm: '.js-confirm',
			cancel: '.js-cancel-popup'
		},

		regions: {},

		events: {
			'click .mdi-transfer-right': 'onTransferRight',
			'click .mdi-transfer-left': 'onTransferLeft',
			'click @ui.confirm': 'onConfirm',
			'click @ui.cancel': 'onCancel'
		},

		serializeData: function () {
			var templateData = {};
			templateData.lisAnas = this.model.get('lisAnas').map(function (lisAna) {
				return {id: lisAna.get('id'), code: lisAna.get('code')};
			});
			return templateData;
		},

		onRender: function () {
			if (this.ui.originLisAnas[0].options.length > 0) {
				this.ui.transferRight.removeClass('invisible');
			}
		},

		onTransferRight: function () {
			this._transfer(this.ui.originLisAnas[0], this.ui.newLisAnas[0]);
			this.refreshDisplay();
		},

		onTransferLeft: function () {
			this._transfer(this.ui.newLisAnas[0], this.ui.originLisAnas[0]);
			this.refreshDisplay();
		},

		refreshDisplay: function () {
			if (this.ui.originLisAnas[0].options.length === 0) {
				this.ui.transferRight.addClass('invisible');
			} else {
				this.ui.transferRight.removeClass('invisible');
			}
			if (this.ui.newLisAnas[0].options.length === 0) {
				this.ui.transferLeft.addClass('invisible');
				this.ui.confirm.attr('disabled', true);
			} else {
				this.ui.transferLeft.removeClass('invisible');
				this.ui.confirm.attr('disabled', false);
			}
		},

		_transfer: function (originLisAna, newLisAna) {
			for (var i = originLisAna.selectedOptions.length - 1; i >= 0; i--) {
				var option = document.createElement('option');
				option.value = originLisAna.selectedOptions[i].value;
				option.text = originLisAna.selectedOptions[i].text;
				newLisAna.appendChild(option);
				originLisAna.removeChild(originLisAna.selectedOptions[i]);
			}
		},
		onConfirm: function () {
			this.trigger('split', _.map(this.ui.newLisAnas[0].options, function (option) {
				return option.value;
			}));
			this.box.modal('hide');
		}
	});
});
