define('customBootboxMessage',[
    'bootbox',
    'module',
    'settings'
], function (
    Bootbox,
    module,
    Settings
) {
    'use strict';

    module.exports = ({

        /* custom bootBox notification with timeout ------------------------------------------------------------------*/
        customThumbUpNotification: function () {
            var dialog = Bootbox.dialog({
                title: '',
                closeButton: false,
                message:
                    '<div class="col-xs-12"> ' +
                    '<i class="customThumbUpNotificationBootboxIcon ' + 'mdi mdi-check-bold' + ' " id="customSuccessBootbox"></i>' +
                    // '<i class="customThumbUpNotificationBootboxIcon ' + 'mdi mdi-thumb-up' + ' " id="customInfoBootbox"></i>' +
                    // '<span class="iconify customThumbUpNotificationBootboxIconifyIcon" data-icon="icon-park:thumbs-up"></span>' +
                    '</div>',
                className: 'customThumbUpNotificationBootbox'
            });
            setTimeout(function () {
                dialog.modal('hide');
            }, 1500);
        },

        customThumbDownNotification: function () {
            var dialog = Bootbox.dialog({
                title: '',
                closeButton: false,
                message:
                    '<div class="col-xs-12"> ' +
                    '<i class="customThumbDownNotificationBootboxIcon ' + 'mdi mdi-close-thick' + ' " id="customErrorBootbox"></i>' +
                    // '<i class="customThumbUpNotificationBootboxIcon ' + 'mdi mdi-thumb-up' + ' " id="customInfoBootbox"></i>' +
                    // '<span class="iconify customThumbUpNotificationBootboxIconifyIcon" data-icon="icon-park:thumbs-up"></span>' +
                    '</div>',
                className: 'customThumbDownNotificationBootbox'
            });
            setTimeout(function () {
                dialog.modal('hide');
            }, 1500);
        },

        /* custom bootBox Alert --------------------------------------------------------------------------------------*/
        customAlert: function (params) {
            var customType = params.type ? params.type : '',
                customColor = params.color ? params.color : '',
                customIcon = params.icon ? params.icon : '';

            switch (customType) {
                case 'warning':
                    this.showCustomAlertBootbox(params.title, params.message, 'mdi-alert-warning', '', 'customWarningBootbox');
                    break;
                case 'error':
                    this.showCustomAlertBootbox(params.title, params.message, 'mdi-alert-warning', '', 'customErrorBootbox');
                    break;
                case 'info':
                    this.showCustomAlertBootbox(params.title, params.message, 'mdi-action-info', '', 'customInfoBootbox');
                    break;
                default:
                    this.showCustomAlertBootbox(params.title, params.message, customIcon, customColor, '');
                    break;
            }
        },

        showCustomAlertBootbox: function (title, message, icon, color, id) {
            if (title === undefined) {
                title = '';
            }
            Bootbox.alert({
                title: '<img src="' + Settings.get('defaultLogo') + '" ' +
                    'class="customBootbox title">' + title,
                closeButton: false,
                message:
                // '<br class="col-xs-12 m-b-20">' +
                    '<div class="col-xs-2"> ' +
                    '<i class="customAlertConfirmBootboxIcon ' + icon + ' " id="' + id + '" style="color: ' + color + ' ;"></i>' +
                    '</div>' +
                    '<div class="col-xs-10 p-t-15" style="font-weight: bold;">' + message + '</div>',
                className: 'customAlertConfirmBootbox',
                buttons: {
                    ok: {
                        label: '<i></i>',
                        className: 'mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm'
                    }
                }
            });
        },

        /* custom bootBox Confirm ------------------------------------------------------------------------------------*/
        customConfirm: function (params, callback) {
            var customType = params.type ? params.type : '',
                customColor = params.color ? params.color : '',
                customIcon = params.icon ? params.icon : '',
                notaBene = params.notaBene && params.notaBene !== '' ? params.notaBene : null,
                customCallback = callback;

            switch (customType) {
                case 'warning':
                    this.showCustomConfirmBootbox(params.title, params.message, notaBene, 'mdi-alert-warning', '', 'customWarningBootbox', customCallback);
                    break;
                case 'error':
                    this.showCustomConfirmBootbox(params.title, params.message, notaBene, 'mdi-alert-warning', '', 'customErrorBootbox', customCallback);
                    break;
                case 'info':
                    this.showCustomConfirmBootbox(params.title, params.message, notaBene, 'mdi-action-info', '', 'customInfoBootbox', customCallback);
                    break;
                default:
                    this.showCustomConfirmBootbox(params.title, params.message, notaBene, customIcon, customColor, '', customCallback);
                    break;
            }
        },

        showCustomConfirmBootbox: function (title, message, notaBene, icon, color, id, callback) {
            if (title === undefined) {
                title = '';
            }
            Bootbox.confirm({
                title: '<img src="' + Settings.get('defaultLogo') + '" ' +
                    'class="customBootbox title">' + title,
                closeButton: false,
                message:
                // '<br class="col-xs-12 m-b-20">' +
                    '<div class="col-xs-2"> ' +
                    '<i class="customAlertConfirmBootboxIcon ' + icon + ' " id="' + id + '" style="color: ' + color + ' ;"></i>' +
                    '</div>' +
                    '<div class="col-xs-10 p-t-15" style="font-weight: bold;">' +

                    '<div class="col-xs-12">' + message + '</div>' +
                    (notaBene ? '<div class="col-xs-12" style="font-size: 14px;">' + notaBene + '</div>' : '') +
                    '</div>',

                className: 'customAlertConfirmBootbox',
                buttons: {
                    confirm: {
                        label: '<i></i>',
                        className: 'mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm'
                    },
                    cancel: {
                        label: '<i></i>',
                        className: 'mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup'
                    }
                },
                callback: callback
            });
        }
    });
});
