define('runCopyFromRunView',[
    'module',
    'backbone',
    'underscore',
    'jquery',
    'dialogFormView',
    'template!runCopyFromRunView',
    'commonWellSelectionView',
    'commonCancelActionView',
    'autocompleteView',
    'entities/ns'
], function (
    module,
    Backbone,
    _,
    $,
    DialogFormView,
    Tpl,
    CommonWellSelectionView,
    CommonCancelActionView,
    AutocompleteView,
    app
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },

        regions: {
            selectRunRegion: '.js-select-run-region',
            wellSelectionRegion: '.js-well-selection-region',
            wellTargetRegion: '.js-well-target-region',
            cancelActionRegion: '.js-cancel-action-region'
        },

        fieldsToValidate: function () {
            return [
                {name: 'sourceRun', type: 'required'},
                {name: 'wellSelection', type: 'required', subView: this.wellSelectionView},
                {name: 'wellTarget', type: 'required', subView: this.wellTargetView}
            ];
        },

        modelEvents: {
            'change:sourceRun': 'onValidateChange',
            'change:wellSelection': 'onValidateChange',
            'change:wellTarget': 'onValidateChange'
        },

        initialize: function () {
            this.model = new Backbone.Model();
            this.model.set('sourceRun', null);
            this.model.set('wellSelection', null);
            this.model.set('wellTarget', null);
            this.model.set('copySampleTypeCheckbox', true);
            this.model.set('copySampleId', true);
            this.model.set('copyAssay', true);
            this.isPrepRun = this.options.model instanceof app.PrepRun;
            this.model.set('allAssays', false);
            if (!this.options.service) {
                throw new Error('service is required');
            }
        },
        
        serializeData: function () {
            var templateData = DialogFormView.prototype.serializeData.call(this);
            templateData.isPrepRun = this.isPrepRun;
            templateData.fromRunLabel = this.isPrepRun ? _.i18n('run.prep.importPlateLayoutFrom.sourceRun') : _.i18n('run.pcr.importPlateLayoutFrom.sourceRun');
            return templateData;
        },

        onRender: function () {
            var SettingService = require('services/caccounts/setting');
            var setting = SettingService.findByCodeAsync('LAB-SETTINGS');
            var direction = 'C';
            if (setting && setting.get('params')) {
                direction = setting.get('params');
            }
                
            this.wellSelectionView = new CommonWellSelectionView({
                direction: direction,
                start: 'A01',
                to: this.options.model.get('plateSize'),
                model: this.model,
                fieldName: 'wellSelection'
            });
            this.getRegion('wellSelectionRegion').show(this.wellSelectionView);
            this.wellTargetView = new CommonWellSelectionView({
                direction: direction,
                start: 'A01',
                to: this.options.model.get('plateSize'),
                hiddenTo: true,
                model: this.model,
                fieldName: 'wellTarget'
            });
            this.getRegion('wellTargetRegion').show(this.wellTargetView);

            this.commonCancelActionView = new CommonCancelActionView();
            this.listenTo(this.commonCancelActionView, 'action:click', _.bind(this.onActionClick, this));
            this.listenTo(this.commonCancelActionView, 'cancel:click', _.bind(this.onCancel, this));
            this.getRegion('cancelActionRegion').show(this.commonCancelActionView);

            var paramUrl = {
                'sord': 'asc',
                'sidx': 'code'
            };
            var view = new AutocompleteView(
                this._getAutocompleteOptionObject(this.options.service.getAutocompleteParam({
                    modelProperty: 'sourceRun',
                    paramUrl: paramUrl
                }))
            );
            this.getRegion('selectRunRegion').show(view);
            this.commonCancelActionView.enableButtons(false);
        },

        onValidateChange: function () {
            this.commonCancelActionView.enableButtons(this.validate(this.fieldsToValidate()));
        },

        onActionClick: function () {
            var loading = $('.js-global-loader');
            loading.show();
            this.options.model.copyPlateLayoutFrom(this.model)
                .done(_.bind(function () {
                    this.hide();
                }, this))
                .always(_.bind(function () {
                    loading.hide();
                }, this));
        }
    });
});
