define('samplePrepWellView',[
    'module',
    'backbone.marionette',
    'template!samplePrepWellView',
    'wellUtils',
    'backbone',
    'samplePrepWellPcrWellCollection',
    'linkNewTabView'
], function (
    module,
    Marionette,
    WellTargetTpl,
    WellUtils,
    Backbone,
    SamplePrepWellPcrWellCollection,
    LinkNewTabView
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: WellTargetTpl,

        modelEvents: {
            'change': 'render'
        },

        regions: {
            pcrWells: '.js-prepwell-pcrwell-region',
            prepRun: '.js-preprun'
        },

        className: 'col-w-all col-w-100 d-f',
        attributes: {
            style: 'border-bottom: solid 1px #999898;'
        },

        onRender: function () {
            this.getRegion('pcrWells').show(new SamplePrepWellPcrWellCollection({
                collection: this.model.get('pcrWells')
            }));
            var params = {
                model: this.model,
                placement: 'bottom',
                target: this.$el
            };

            WellUtils.showPrepWellPopover(params);
            var PrepRunService = require('services/caccounts/prepruns');
            this.getRegion('prepRun').show(new LinkNewTabView(PrepRunService.parameterForLink(this.model.get('run'), PrepRunService)));
        },

        serializeData: function () {
            var templateData = {};
            templateData.id = this.model.get('id');
            templateData.runName = this.model.get('run').get('name');
            templateData.runSecId = this.model.get('run').get('id');
            templateData.pos = this.model.get('pos');
            templateData.assayWaiting = this.model.getWellAssaysWaitingList();
            templateData.assayRunning = this.model.getWellAssaysRunningList();
            templateData.assayDone = this.model.getWellAssaysDoneList();
            templateData.assayIcons = WellUtils.getPrepWellTooltipAssaysIcons();
            return templateData;
        }
    });
});

