define('services/caccounts/pcrruntracs',[
    'entities/ns',
    'settings',
    'module',
    'dynamic',
    'underscore',
    'entities/caccounts/pcrruntracs'
], function (
    app,
    Settings,
    module,
    Dynamic,
    _
) {
    'use strict';

    module.exports = _.defaults({
        getName: function () {
            return 'pcrRunTracs';
        },
        getNamespace: function () {
            return {
                model: app.PcrRunTrac,
                collection: app.PcrRunTracCollection
            };
        },
        getUrl: function () {
            return 'rest/v2/runtracs/pcr/';
        },
        getCollectionUrl: function (params) {
            return Settings.url('rest/v2/runtracs/pcr/find', params);
        },
        getCollection: function (runJSON) {
            if (!runJSON) {
                runJSON = [];
            }
            return new app.PcrRunTracCollection(runJSON);
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.archivable = false;
            config.selectArchivable = false;
            config.exportable = false;
            config.importable = false;
            config.attachmentable = false;
            config.duplicable = false;
            config.deletable = false;
            config.errorable = false;
            config.selectable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic, options) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('trac.creatDate'),
                name: 'creatDate',
                formatter: viewDynamic.dateTimeFormatterFromSettings, // this formatter need DATEFMT from settings
                search: false,
                index: 'creatDate',
                fixed: true,
                width: 140
            });
            columns.push({
                label: _.i18n('trac.refUserpid'),
                name: 'refUserpid',
                formatter: viewDynamic.userFormatter,
                search: true,
                sortable: true,
                index: 'user',
                fixed: true,
                width: 80
            });
            columns.push({
                label: _.i18n('trac.level'),
                name: 'level',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                sortable: true,
                index: 'level',
                fixed: true,
                width: 80
            });
            columns.push({
                label: _.i18n('trac.action'),
                name: 'action',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                sortable: true,
                index: 'action',
                fixed: true,
                width: 150
            });
            columns.push({
                label: _.i18n('trac.well'),
                name: 'wells',
                formatter: viewDynamic.defaultFormatter,
                search: options ? !options.disablePosition : true,
                sortable: true,
                index: 'position',
                fixed: true,
                width: 200
            });
            columns.push({
                label: _.i18n('trac.mbAna'),
                name: 'mbAnaCode',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                sortable: true,
                index: 'mbAnaCode',
                fixed: true,
                width: 150
            });
            columns.push({
                label: _.i18n('trac.comment'),
                name: 'comment',
                classes: 'well-list-label',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                sortable: true,
                index: 'comment',
                fixed: true,
                width: 350
            });
            return columns;
        },
        getJqGridSidx: function () {
            return 'creatDate';
        },
        getJqGridSord: function () {
            return 'desc';
        },
        showDetails: function (param) {
            if (!param) {
                throw new Error('param is required');
            }
            param.service = this;
            require(['runTracsController'], _.bind(function (RunTracController) {
                RunTracController.showDetails(param);
            }, this));
        }
    }, Dynamic);
});

