define('services/caccounts/fileFormatSampleLists',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'jqGridValue',
    'entities/caccounts/fileFormatSampleLists'
], function (
    app,
    _,
    module,
    Dynamic,
    JqGridValue
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.FileFormatSampleList,
                collection: app.FileFormatSampleListCollection
            };
        },
        getName: function () {
            return 'fileFormatSampleList';
        },
        getUrl: function () {
            return 'rest/v2/fileFormat/sampleLists/';
        },
        getDynamicJson: function () {
            return 'FileFormatSampleListJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('fileFormat.prep'),
                name: 'prep',
                classes: 'dynamic-link',
                formatter: viewDynamic.booleanFormatterNoCheckBox,
                search: true,
                index: 'prep',
                width: 10,
                stype: 'select',
                searchrules: {select: true},
                searchoptions: {sopt: ['eq'], value: JqGridValue.check()}
            }, {
                label: _.i18n('fileFormat.pcr'),
                name: 'pcr',
                classes: 'dynamic-link',
                formatter: viewDynamic.booleanFormatterNoCheckBox,
                search: true,
                index: 'pcr',
                width: 10,
                stype: 'select',
                searchrules: {select: true},
                searchoptions: {sopt: ['eq'], value: JqGridValue.check()}
            }, {
                label: _.i18n('fileFormat.inputSampleList'),
                name: 'inputSampleListService',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'inputSampleListService',
                searchoptions: {size: 10},
                width: 25
            }, {
                label: _.i18n('fileFormat.customInputSampleList'),
                name: 'customInputSampleList',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'customInputSampleList',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('fileFormat.outputSampleList'),
                name: 'outputSampleListService',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'outputSampleListService',
                searchoptions: {size: 10},
                width: 25
            }, {
                label: _.i18n('fileFormat.customOutputSampleList'),
                name: 'customOutputSampleList',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'customOutputSampleList',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore.call(this);
            ignored.push('customInputSampleList', 'customOutputSampleList');
            return ignored;
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push(
                {
                    'field': 'inputSampleListService',
                    'param': {
                        'type': 'SAMPLELIST',
                        'field': 'customInputSampleList',
                        'url': 'restv2/inputSampleListService/'
                    }
                },
                {
                    'field': 'outputSampleListService',
                    'param': {
                        'type': 'SAMPLELIST',
                        'field': 'customOutputSampleList',
                        'url': 'rest/v2/outputSampleListService/'
                    }
                }
            );
            return custom;
        },

        getAutocompleteParamForImport: function (config) {
            config.url = this.getUrl() + 'forImport';
            return Dynamic.getAutocompleteParam(config, this);
        },
        getAutocompleteParamForExport: function (config) {
            config.url = this.getUrl() + 'forExport';
            return Dynamic.getAutocompleteParam(config, this);
        }
    }, Dynamic);
});

