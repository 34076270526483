define('services/caccounts/kitspcr',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/kitspcr'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.KitPcr,
                collection: app.KitPcrCollection
            };
        },
        getName: function () {
            return 'kitspcr';
        },
        getUrl: function () {
            return 'rest/v2/kits/pcr/';
        },
        getDynamicJson: function () {
            return 'PcrKitJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'kit-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 60,
                searchoptions: {
                    sopt: ['cn']
                }
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'kit-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 60,
                searchoptions: {
                    sopt: ['cn']
                }
            }, {
                label: _.i18n('kit.currentLot'),
                name: 'refCurrentLot.code',
                classes: 'kit-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'refCurrentLot.code',
                width: 120
            }, {
                label: _.i18n('kit.supplier'),
                name: 'refSupplier.code',
                classes: 'kit-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'refSupplier.code',
                width: 50,
                searchoptions: {
                    sopt: ['cn']
                }
            }, {
                label: _.i18n('kit.reagentTypes'),
                name: 'reagents',
                classes: 'kit-link',
                formatter: viewDynamic.codeNameListFormatter,
                sortable: false,
                search: true,
                index: 'reagents',
                width: 100
            }, {
                label: _.i18n('kit.assays'),
                name: 'assays',
                formatter: viewDynamic.codeNameListFormatter,
                sortable: false,
                search: true,
                index: 'assays',
                width: 120
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'tags',
                width: 80
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'kit-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description',
                searchoptions: {
                    sopt: ['cn']
                }
            });
            return columns;
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.KitPcrCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 450, 'fields': ['name', 'refCurrentLot.code']};
        },

        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore.call(this);
            ignored.push('refCurrentLot');
            return ignored;
        },
        getCreateEditFieldCustom: function () {
            var SupplierService = require('services/caccounts/suppliers');
            return [
                {
                    'field': 'lots',
                    'param': {
                        'type': 'LIST',
                        config: {
                            add: 'POPUP',
                            enable: function (modelParent) {
                                return !modelParent || !modelParent.isNew();
                            }
                        },
                        'display': {'css': 'overflow-y: auto; max-height: 150px;'}
                    }
                },
                {
                    'field': 'refSupplier',
                    'param': {
                        'type': 'REFERENCE',
                        'config': {'getAutocompleteParam': SupplierService.getAutocompleteParam},
                        'context': 'CACCOUNT',
                        'display': {'class': 'col-xs-2'}
                    }
                },
                {
                    'field': 'assays',
                    'param': {'type': 'LIST2ENTITY'}
                }
            ];
        },
        getAutocompleteParamForAssay: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.valueProperty = config.valueProperty ? config.valueProperty : 'code';
            configuration.queryProperty = config.queryProperty ? config.queryProperty : 'code';
            configuration.urlPath = this.getCollectionUrl();
            configuration.paramUrl = config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'code'
            };
            configuration.values = {
                'assaySecId': config.assaySecId,
                'reagentTypes': config.reagentTypes ? config.reagentTypes : ['QC']
            };
            configuration.valueKey = config.valueKey ? config.valueKey : 'code';
            return configuration;
        }
    }, Dynamic);
});

