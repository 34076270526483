define('assayCustomJqGridView',[
    'module',
    'dialogFormView',
    'underscore',
    'jquery',
    'template!assayCustomJqGridView',
    'assayImportFromResultView',
    'settings',
    'rolesMixin'
], function (
    module,
    DialogFormView,
    _,
    $,
    tpl,
    AssayImportFromResultView,
    Settings,
    RolesMixin
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        events: {
            'click .js-importFromResult': 'onImportFromResult',
            'click .js-clearAssayVersion': 'onClearAssayVersion'
        },

        attributes: {
            style: 'display: flex; column-gap: 5px;'
        },

        serializeData: function () {
            return {
                superUser: Settings.get('currentUserModel').hasRole(RolesMixin.SUPER_USER)
            };
        },

        onImportFromResult: function () {
            var SettingService = require('services/caccounts/setting');
            SettingService.findByCode('IMPORT-RESULT').done(_.bind(function (setting) {
                var view = new AssayImportFromResultView({
                    settingImportResult: setting,
                    assay: this.options.assay
                });
                view.show({
                    title: _.i18n('assay.createFromRunResult'),
                    className: ''
                });
            }, this));
        }
    });
});
