define('servicesGlobal',[
'module',
    'services/global/dynamicDefinitionField',
    'services/global/textSlave',
    'services/global/dataConnectionAll',
    'services/global/routes',
    'services/global/caccounts',
    'services/global/token',
    'services/global/userpids',
    'services/global/logs',
    'services/global/caccounttypes',
    'services/global/dynamicDefinition',
    'services/global/text'
], function (module) {
'use strict';
module.exports = {
    getServices: function() {
        var ret = [];
    ret.push(require('services/global/dynamicDefinitionField'));
    ret.push(require('services/global/textSlave'));
    ret.push(require('services/global/dataConnectionAll'));
    ret.push(require('services/global/routes'));
    ret.push(require('services/global/caccounts'));
    ret.push(require('services/global/token'));
    ret.push(require('services/global/userpids'));
    ret.push(require('services/global/logs'));
    ret.push(require('services/global/caccounttypes'));
    ret.push(require('services/global/dynamicDefinition'));
    ret.push(require('services/global/text'));
        return ret;
    }
};
});
