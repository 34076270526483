/**
 * Created by OLD on 20/08/2015.
 */
define('dateUtils',[
    'module',
    'moment'
], function (module, moment) {
    'use strict';

    module.exports = {
        toDateFormatStringFromSettings: function (inputValue) {
            var SettingService = require('services/caccounts/setting');
            var dateFormat = SettingService.findByCodeAsync('DATEFMT');
            if (dateFormat) {
                dateFormat = dateFormat.get('params');
            }
            if (inputValue === '' || inputValue === null || inputValue === undefined) {
                return '-';
            }
            var format = 'DD-MM-YYYY';
            var newVar = dateFormat ? dateFormat.split(';') : [];
            if (newVar) {
                format = newVar[0];
            }
            return moment(inputValue).format(format);
        },

        toDateTimeFormatStringFromSettings: function (inputValue) {
            var format = 'DD-MM-YYYY HH:mm:ss';
            var SettingService = require('services/caccounts/setting');
            var dateFormat = SettingService.findByCodeAsync('DATEFMT');
            if (dateFormat) {
                dateFormat = dateFormat.get('params');
            }
            if (inputValue === '' || inputValue === null || inputValue === undefined) {
                return '-';
            }
            var newVar = dateFormat ? dateFormat.split(';') : [];
            if (newVar) {
                format = newVar[3];
            }
            return moment(inputValue).format(format);
        },

        toMDYFormat: function (inputValue) {
            var format = new RegExp('^(0?[1-9]|[12][0-9]|3[01])-' +
                    '(0?[1-9]|1[012])-((19|20)\\d\\d)$'),
                dateArr,
                formattedDate;

            if (format.test(inputValue)) {
                dateArr = inputValue.split('/');
                formattedDate = dateArr[1] + '-' + dateArr[0] + '-' + dateArr[2];
            }

            return formattedDate ? formattedDate : undefined;
        },

        toDateFormatString: function (inputValue) {
            if(inputValue === '' || inputValue === null || inputValue === undefined) {
                return '-';
            }
            return moment(inputValue).format('DD/MM/YYYY');
        },

        toDateTimeFormatString: function (inputValue) {
            if(inputValue === '' || inputValue === null || inputValue === undefined) {
                return '-';
            }
            return moment(inputValue).format('DD/MM/YYYY HH:mm');
        },

        toDateTimeShortYearFormatString: function (inputValue) {
            if(inputValue === '' || inputValue === null || inputValue === undefined) {
                return '-';
            }
            return moment(inputValue).format('DD/MM/YY HH:mm');
        },

        toDateTimeSecondeFormatString: function (inputValue) {
            if(inputValue === '' || inputValue === null || inputValue === undefined) {
                return '-';
            }
            return moment(inputValue).format('DD/MM/YYYY HH:mm:ss');
        },

        getDateFormat: function () {
            return 'dd-mm-yy';
        }
    };
});

