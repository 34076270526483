define('services/caccounts/kitsextraction',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/kitsextraction'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.KitExtraction,
                collection: app.KitExtractionCollection
            };
        },
        getName: function () {
            return 'kitsextraction';
        },
        getUrl: function () {
            return 'rest/v2/kits/extraction/';
        },
        getDynamicJson: function () {
            return 'ExtractionKitJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'kit-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 60,
                searchoptions: {
                    sopt: ['cn']
                }
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'kit-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 60,
                searchoptions: {
                    sopt: ['cn']
                }
            }, {
                label: _.i18n('kit.currentLot'),
                name: 'refCurrentLot.code',
                classes: 'kit-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'refCurrentLot.code',
                width: 120
            }, {
                label: _.i18n('kit.supplier'),
                name: 'refSupplier.code',
                classes: 'kit-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'refSupplier.code',
                width: 50,
                searchoptions: {
                    sopt: ['cn']
                }
            }, {
                label: _.i18n('kit.reagentTypes'),
                name: 'reagents',
                classes: 'kit-link',
                formatter: viewDynamic.codeNameListFormatter,
                sortable: false,
                search: true,
                index: 'reagents',
                width: 120
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 80
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'kit-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description',
                searchoptions: {
                    sopt: ['cn']
                }
            });
            return columns;
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.KitExtractionCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 250, 'fields': ['refSupplier.code']};
        },
        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore.call(this);
            ignored.push('refCurrentLot');
            return ignored;
        },

        getCreateEditFieldCustom: function () {
            var SupplierService = require('services/caccounts/suppliers');
            return [
                {
                    'field': 'lots',
                    'param': {
                        'type': 'LIST',
                        config: {
                            add: 'POPUP',
                            enable: function (modelParent) {
                                return !modelParent.isNew();
                            }
                        },
                        'display': {'css': 'overflow-y: auto; max-height: 150px;'}
                    }
                },
                {
                    'field': 'refSupplier',
                    'param': {
                        'type': 'REFERENCE',
                        'config': {'getAutocompleteParam': SupplierService.getAutocompleteParam},
                        'context': 'CACCOUNT',
                        'display': {'class': 'col-xs-2'}
                    }
                }
            ];
        },

        getAutocompleteParamForReagent: function (modelKit, config) {
            var ExtractionReagentService = require('services/caccounts/extractionreagents');
            var configuration = ExtractionReagentService.getAutocompleteParam(config);
            configuration.data = modelKit.get('reagents').toJSON();
            return configuration;
        }
    }, Dynamic);
});

