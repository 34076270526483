define('entities/caccounts/lmbsamplehandlers',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'entities/caccounts/samplehandlers'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.LmbSampleHandler = Backbone.RelationalModel.extend({
        service: 'services/caccounts/lmbsamplehandlers',
        fetch: function () {
            this.url = Settings.url('rest/v2/lmbsamplehandlers/' + this.get('id'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'description': '',
            'identifier': '',
            'location': '',
            'refSampleHandler': null
        },
        idAttribute: 'id',
        relations: [{
            type: Backbone.HasOne,
            key: 'refSampleHandler',
            relatedModel: 'SampleHandler',
            includeInJSON: ['id', 'code', 'name']
        }],

        postUrl: function () {
            return Settings.url('rest/v2/lmbsamplehandlers');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'LmbSampleHandlerJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'LmbSampleHandler',

        importable: true
    });

    app.LmbSampleHandler.role = RolesMixin.LMBSAMPLEHANDLER;

    app.LmbSampleHandlerCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/lmbsamplehandlers');
        },
        model: app.LmbSampleHandler
    });

});

