define('entities/caccounts/assayDisplayConfigurationVersion',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'rolesMixin',
    'entities/caccounts/assayReagentVersion',
    'entities/caccounts/assayResultVersion',
    'entities/caccounts/kitprots',
    'entities/caccounts/assaygroup',
    'entities/caccounts/assay',
    'entities/caccounts/colorCompensations'
], function (
    app,
    Backbone,
    Settings,
    $,
    _,
    RolesMixin
) {
    'use strict';

    app.AssayDisplayConfigurationVersion = Backbone.RelationalModel.extend({
        defaults: {
        },
        idAttribute: 'id',

        relations: [{
            type: Backbone.HasMany,
            key: 'results',
            relatedModel: 'AssayResultVersion',
            collectionType: 'AssayResultVersionCollection'
        }],

        jsonObjectName: 'AssayDisplayConfigurationVersionJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'AssayDisplayConfigurationVersion'
    });

    app.AssayDisplayConfigurationVersion.role = [RolesMixin.ASSAY, RolesMixin.RUN_PCR];

    app.AssayDisplayConfigurationVersionCollection = Backbone.Collection.extend({
        model: app.AssayDisplayConfigurationVersion
    });
});

