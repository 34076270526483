define('entities/caccounts/assayResultVersion',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'entities/caccounts/mbanas',
    'entities/caccounts/assay'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.AssayResultVersion = Backbone.RelationalModel.extend({
        service: 'services/caccounts/assayResultVersion',
        defaults: {
            'algoResult': {type: '', param: ''},
            'algoValidation': {type: 'NO', param: ''},
            'algoExport': {type: 'NO', param: ''}
        },
        idAttribute: 'id',
        relations: [{
            type: Backbone.HasOne,
            key: 'target',
            relatedModel: 'MbAna',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'linkedAssayResultVersions',
            relatedModel: 'AssayResultVersion',
            collectionType: 'AssayResultVersionCollection',
            includeInJSON: false
        }],
        
        save: function () {
            throw new Error('Can\'t save this entity');
        },

        jsonObjectName: 'AssayResultVersionJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },
        jsonObject: 'AssayResultVersion'
    });

    app.AssayResultVersion.role = [RolesMixin.ASSAY, RolesMixin.RUN_PCR];

    app.AssayResultVersionCollection = Backbone.Collection.extend({
        model: app.AssayResultVersion,
        comparator: 'sequence'
    });
});

