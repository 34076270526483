
define('template!assayCustomJqGridView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(superUser) { ;
__p += '\n<div class="js-importFromResult clickable">\n    <span class="mdi mdi-file-chart action header" title="' +
((__t = ( _.i18n('assay.importFromResult') )) == null ? '' : __t) +
'">\n    </span>\n</div>\n';
 } ;


}
return __p
};});

