
define('template!dynamicListHeaderCollectionView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<h2 style="margin-top: 0;">' +
((__t = ( _.i18n(name + '.' + field + '.list') )) == null ? '' : __t) +
'</h2>\n<div class="rounded" style="padding: 10px 0 5px 0;">\n    <div class="col-w-100 d-b p-l-5 p-r-5 tableUnderlineTitle js-header">\n        <div class="col-w-100 d-f" style="align-items: center;">\n            ';
 _.each(fields, function(field) { ;
__p += '\n            <div class="' +
((__t = ( field.param && field.param.display ? field.param.display.class : '')) == null ? '' : __t) +
'">\n                ' +
((__t = ( _.i18n(name + '.' + field.field) )) == null ? '' : __t) +
'\n            </div>\n            ';
 }) ;
__p += '\n        </div>\n    </div>\n    <div class="collection" style="' +
((__t = ( css )) == null ? '' : __t) +
'"></div>\n</div>';

}
return __p
};});

