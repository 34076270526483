define('services/caccounts/lmbcyclers',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/lmbcyclers'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.LmbCycler,
                collection: app.LmbCyclerCollection
            };
        },
        getName: function () {
            return 'lmbcycler';
        },
        getUrl: function () {
            return 'rest/v2/lmbcyclers/';
        },
        getDynamicJson: function () {
            return 'LmbCyclerJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('lmbCycler.refCycler'),
                name: 'refCycler.code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'refCycler.code',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('lmbCycler.identifier'),
                name: 'identifier',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'identifier',
                searchoptions: {size: 10},
                width: 100
            }, {
                label: _.i18n('lmbCycler.location'),
                name: 'location',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'location',
                searchoptions: {size: 10},
                width: 100
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['location', 'identifier']};
        },

        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push({field: 'code', param: {type: 'STRING', display: {'class': 'col-xs-2 p-r-0'}}});
            custom.push({'field': 'identifier', 'param': {'type': 'STRING', 'display': {'class': 'col-xs-2'}}});
            custom.push({'field': 'location', 'param': {'type': 'TEXTAREA', 'display': {'class': 'col-xs-8', rows: 2}}});
            custom.push({'field': 'refCycler', 'param': {'type': 'REFERENCE', 'display': {'class': 'col-xs-2', 'readOnly': true}}});
            return custom;
        },

        getCreateEditLine: function () {
            return [
                {'field': 'code', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-20'}}},
                {'field': 'identifier', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-20'}}},
                {'field': 'location', 'param': {'type': 'TEXTAREA', 'display': {'class': 'col-w-30'}}},
                {'field': 'description', 'param': {'type': 'TEXTAREA', 'display': {'class': 'col-w-30'}}}
            ];
        },

        getAutocompleteParamWithColorCompensation: function (config, context) {
            var configuration = Dynamic.getAutocompleteParam(config, context);
            configuration.callbackAdd = undefined;
            configuration.callbackEdit = undefined;
            configuration.data = this.findAllSync({
                'sord': 'asc',
                'sidx': 'code',
                withColorCompensation: true
            }).map(function (model) {
                return model.toJSON();
            });
            return configuration;
        }
    }, Dynamic);
});

