define('entities/caccounts/standardCurve/standardCurveAssayConfigurationResultValues',[
    'entities/ns',
    'backbone',
    'jquery',
    'settings',
    'rolesMixin',
    'entities/caccounts/pcrwells',
    'entities/caccounts/pcrruns',
    'entities/caccounts/standardCurve/standardCurveAssayConfigurationResultValuePcrWellValues',
    'entities/caccounts/assayConfigurationResult',
    'entities/caccounts/standardCurve/standardCurveAssayConfigurationResults'
], function (
    app,
    Backbone,
    $,
    Settings,
    RolesMixin
) {
    'use strict';

    app.StandardCurveAssayConfigurationResultValue = Backbone.RelationalModel.extend({
        service: 'services/caccounts/standardCurve/standardCurveAssayConfigurationResults/values',
        defaults: {
            'pcrRun': null,
            'status': null,
            'results': [],
            'slope': 0,
            'intercept': 0,
            'efficiency': 0
        },
        idAttribute: 'id',
        relations: [{
            type: Backbone.HasOne,
            key: 'pcrRun',
            relatedModel: 'PcrRun'
        }, {
            type: Backbone.HasMany,
            key: 'results',
            relatedModel: 'StandardCurveAssayConfigurationResultValuePcrWellValue',
            collectionType: 'StandardCurveAssayConfigurationResultValuePcrWellValueCollection'
        }, {
            type: Backbone.HasOne,
            key: 'assayConfigurationResult',
            relatedModel: 'AssayConfigurationResult'
        }, {
            type: Backbone.HasOne,
            key: 'standardCurveAssayConfigurationResult',
            relatedModel: 'StandardCurveAssayConfigurationResult'
        }],
        changeStatus: function () {
            var url = Settings.url('rest/v2/standardCurves/assayConfigurationResults/values/' + this.get('id') + '/changeStatus', {
                    status: this.get('status')
                }),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (response) {
                    defer.resolve(response);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },
        url: function () {
            return Settings.url('rest/v2/standardCurves/assayConfigurationResults/values/' + this.get('id'));
        }
    });

    app.StandardCurveAssayConfigurationResultValue.role = RolesMixin.STANDARDCURVE;

    app.StandardCurveAssayConfigurationResultValueCollection = Backbone.Collection.extend({
        model: app.StandardCurveAssayConfigurationResultValue,
        comparator: function (model1, model2) {
            if (model1.get('pcrRun') && model2.get('pcrRun')) {
                if (model1.get('pcrRun').get('period') === model2.get('pcrRun').get('period')) {
                    return 0;
                }
                if (model1.get('pcrRun').get('period') < model2.get('pcrRun').get('period')) {
                    return 1;
                }
                return -1;
            }
            return model1.id - model2.id;
        },
        fetch: function (options) {
            this.url = Settings.url('rest/v2/standardCurves/assayConfigurationResults/values/fromResult/' + options.standardCurveAssayConfigurationResultId);
            return Backbone.Collection.prototype.fetch.call(this, arguments);
        }
    });
});

