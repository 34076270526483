define('services/caccounts/assayReagentTargetPcrKitLots',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/assayReagentTargetPcrKitLots'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.AssayReagentTargetPcrKitLot,
                collection: app.AssayReagentTargetPcrKitLotCollection
            };
        },
        getName: function () {
            return 'assayreagenttargetpcrkitlot';
        },
        getDynamicJson: function () {
            return 'AssayReagentTargetPcrKitLotJson';
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.AssayReagentTargetPcrKitLotCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 300, 'fields': ['refPcrKitLot.kit.code']};
        },

        getCreateEditFieldIgnore: function () {
            var ignored = Dynamic.getCreateEditFieldIgnore.call(this);
            ignored.push('comment');
            return ignored;
        }
    }, Dynamic);
});

