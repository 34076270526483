define('orderSampleCollection',[
    'module',
    'backbone',
    'backbone.marionette',
    'orderSampleView',
    'underscore',
    'jquery'
], function (
    module,
    Backbone,
    Marionette,
    view,
    _,
    $
) {
    'use strict';
    module.exports = Marionette.CollectionView.extend({
        childView: view,

        collection: new Backbone.Collection(),
        childViewEvents: {
            'item:delete': function (model) {
                $('.js-global-loader').show();
                var SampleService = require('services/caccounts/samples');
                SampleService.deleteEntity(model.get('id')).always(_.bind(function () {
                    this.model.fetch().always(function () {
                        $('.js-global-loader').hide();
                    });
                }, this));
            },
            'item:change': function () {
                this.trigger('childChange');
            }
        }
    });
});
