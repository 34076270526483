define('runPcrResultImportView',[
    'module',
    'backbone',
    'underscore',
    'jquery',
    'dialogFormView',
    'template!runPcrResultImportView',
    'commonCancelUploadView',
    'customBootboxMessage',
    'autocompleteView',
    'settings'
], function (
    module,
    Backbone,
    _,
    $,
    DialogFormView,
    Tpl,
    CommonCancelUploadView,
    CustomBootboxMessage,
    AutocompleteView,
    Settings
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input'
        },

        regions: {
            cancelUploadRegion: '.js-cancel-upload-region',
            cyclerPublic: '.js-cyclerPublic-region',
            selectRespUser: '.js-select-respUser-region'
        },
        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },
        modelEvents: {
            'change:cycler': 'onValidateChange',
            'change:respUser': 'onValidateChange'
        },

        fieldsToValidate: function () {
            return [
                {name: 'cycler', type: 'required'},
                {name: 'respUser', type: 'required'}
            ];
        },

        initialize: function () {
            this.model = new Backbone.Model({cycler: this.model.get('refCyclerPublic'), respUser: Settings.get('currentUserModel')});
        },

        onValidateChange: function () {
            this.commonCancelUploadView.enableButtons(this.validate(this.fieldsToValidate()));
        },

        onRender: function () {
            this.commonCancelUploadView = new CommonCancelUploadView({settingImport: this.options.settingImport, accept: '.xlsx,.xls,.xml,.rdml,.csv,.txt', multiple: true});
            this.listenTo(this.commonCancelUploadView, 'upload:click', _.bind(this.onUpload, this));
            this.listenTo(this.commonCancelUploadView, 'cancel:click', _.bind(this.onCancel, this));
            this.getRegion('cancelUploadRegion').show(this.commonCancelUploadView);
            this.commonCancelUploadView.enableButtons(false);

            var CyclerService = require('services/caccounts/cyclers');
            this.showChildView('cyclerPublic', new AutocompleteView(
                this._getAutocompleteOptionObject(CyclerService.getAutocompleteParamForPublic({
                    modelProperty: 'cycler',
                    model: this.model.get('cyclerPublic')
                }))
            ));

            var UserpidService = require('services/caccounts/userpids');
            this.showChildView('selectRespUser', new AutocompleteView(
                this._getAutocompleteOptionObject(UserpidService.getAutocompleteParam({
                    modelProperty: 'respUser',
                    valueProperty: 'nickName',
                    queryProperty: 'nickName',
                    valueKey: 'nickName',
                    callBackOnChange: this.onRespUserChange
                }))
            ));
            this.onValidateChange();
        },

        onUpload: function (files) {
            var callback = _.bind(function (result) {
                if (result) {
                    this.confirmUpload(files);
                }
            }, this);
            if (_.some(files, _.bind(function (fileInfo) {
                return fileInfo.name.includes(this.options.model.get('name'), 0);
            }, this))) {
                callback(true);
            } else {
                var confirmParams = {
                    message: _.i18n('run.controlRunName'),
                    type: 'warning'
                };
                CustomBootboxMessage.customConfirm(confirmParams, callback);
            }
        },

        confirmUpload: function (files) {
            var loading = $('.js-global-loader');
            loading.show();
            this.model.set('files', files);
            this.options.model.importResult(this.model)
                .done(_.bind(function () {
                    this.hide();
                }, this))
                .fail(_.bind(function (response) {
                    alert(response.statusText + ' -- ' + response.status);
                }, this))
                .always(function () {
                    loading.hide();
                });
        }
    });
});
