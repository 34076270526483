
define('template!load', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="container">\n    <img alt="logo mobiolink" class="compuzz_general_logo" src="' +
((__t = ( folder )) == null ? '' : __t) +
'images/logo.svg">\n    <img alt="logo mobiolink" class="compuzz_general_title" src="' +
((__t = ( folder )) == null ? '' : __t) +
'images/mobiolink.svg">\n\n    <div class="progress-bar-translation">\n        <h3>Translation</h3>\n        <div class="progress">\n            <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width:0">\n            </div>\n        </div>\n    </div>\n\n    <div class="progress-bar-global">\n        <h3>Global</h3>\n        <div class="progress">\n            <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width:0">\n            </div>\n        </div>\n        <div class="details">\n        </div>\n    </div>\n\n    <div class="progress-bar-setting">\n        <h3>Settings</h3>\n        <div class="progress">\n            <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width:0">\n            </div>\n        </div>\n        <div class="details">\n        </div>\n    </div>\n</div>';

}
return __p
};});

