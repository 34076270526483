define('entities/admin/detectionFormat',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.DetectionFormat = Backbone.RelationalModel.extend({
        service: 'services/admin/detectionFormat',
        fetch: function () {
            this.url = Settings.url('rest/public/detectionFormats/' + this.get('id'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': undefined,
            'name': undefined,
            'dyes': [],
            'description': undefined,
            'comment': undefined,
            'cyclers': []
        },
        idAttribute: 'id',
        relations: [{
            type: Backbone.HasMany,
            key: 'dyes',
            relatedModel: 'Dye',
            collectionType: 'DyeCollection',
            includeInJSON: [
                'channel',
                'name',
                'shortName',
                'excitation',
                'emission',
                'comment',
                'color']
        }, {
            type: Backbone.HasMany,
            key: 'cyclers',
            relatedModel: 'CyclerPublic',
            collectionType: 'CyclerPublicCollection',
            includeInJSON: ['id', 'code', 'name']
        }],

        postUrl: function () {
            return Settings.url('rest/public/detectionFormats/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'DetectionFormatJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'DetectionFormat'
    });

    app.DetectionFormatCollection = Backbone.Collection.extend({
        model: app.DetectionFormat
    });

    app.DetectionFormat.role = RolesMixin.SUPER_USER;
});

