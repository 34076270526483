define('jqgridView',[
    'module',
    'jquery',
    'dialogFormView',
    'underscore',
    'app',
    'settings',
    'jqGridFormatter',
    'jqGridValue',
    'fileExplorerController',
    'jqGridSelectFormatterView',
    'dateUtils',
    'jqgridMbl'
], function (
    module,
    $,
    DialogFormView,
    _,
    App,
    Settings,
    JqGridFormatter,
    JqGridValue,
    FileExplorerController,
    JqGridSelectFormatter,
    DateUtils
) {
    'use strict';

    module.exports = DialogFormView.extend(_.extend({
        getTemplate: function () {
            var template = '<table class="jq-grid-list" id="<%= jqGridId %>" role="grid"></table>';
            if (this.options.pager) {
                template += '<div id="<%= jqGridPagerId %>" class="jq-grid-pager"></div>';
            }
            return _.template(template);
        },

        ui: function () {
            return {
                jqGrid: '#' + this.jqGridId.jqGrid,
                jqGridPager: '#' + this.jqGridId.jqGridPager
            };
        },

        attributes: {
            style: 'flex: 1; height: 100%;'
        },

        constructor: function () {
            this.jqGridId = {
                jqGrid: _.uniqueId('jq-grid-list'),
                jqGridPager: _.uniqueId('jq-grid-pager-list')
            };
            DialogFormView.prototype.constructor.apply(this, arguments);
        },

        serializeData: function () {
            return {
                jqGridId: this.jqGridId.jqGrid,
                jqGridPagerId: this.jqGridId.jqGridPager
            };
        },

        initialize: function (options) {
            this.isSuperAdmin = options && options.isSuperAdmin;
            if (this.onInitialize) {
                this.onInitialize();
            }
            this.data = _.clone(options.data);
            this.listenTo(this, 'reloadGrid', this.reloadGrid);
            this.viewFormatter = [];
            this.selectedRowsGlobal = [];
        },

        getGrid: function () {
            return this.ui.jqGrid;
        },

        paginationObject: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: this.data && this.data.rows ? this.data.rows : Settings.get('perPage'),
                currentPage: 1
            };
        },

        // Appliquer les filtres sauvegardés
        applySavedFilters: function () {
            var savedFilters = Settings.getFromMemory(this.findFilterName()) || {};
            if (!_.isEmpty(savedFilters) && savedFilters.filters) {
                this.ui.jqGrid.jqGrid('setGridParam', {postData: savedFilters}).trigger('reloadGrid');
                this.synchronizeToolbar(savedFilters);
            }
        },

        synchronizeToolbar: function (postData) {
            if (!postData) {
                return;
            }
            // synchronize the toolbar with the filters
            var parsedFilters = postData.filters ? JSON.parse(postData.filters) : {rules: []};
            parsedFilters.rules.forEach(_.bind(function (rule) {
                this.$el.find('#gs_' + rule.field).val(rule.data);
            }, this));
            // find all "#gs_" elements and change value with key in filters
            _.each(this.$el.find('[id^=gs_]'), function (element) {
                var id = element.id;
                var key = id.substring(3);
                var rule = _.find(parsedFilters.rules, function (rule) {
                    return rule.field === key;
                });
                if (rule) {
                    element.value = rule.data;
                } else {
                    if (postData[key]) {
                        element.value = postData[key];
                    }
                }
            });
        },

        onRender: function () {
            // add for modal to be able to get marionette view to fix the display grid (need to be rendered and after resizeGrid, event attach not working on modal)
            this.$el.data('view', this);
            this.pagination = this.options.paginationObject ? _.extend({}, this.options.paginationObject()) : _.extend({}, this.paginationObject());
            setTimeout(_.bind(function () {
                this.gridOptionsObj = this.gridOptions(this.data);
                this.displayData();
            }, this), 1);
        },

        findFilterName: function () {
            return typeof this.options.filtersName === 'function' ? this.options.filtersName() : this.options.filtersName;
        },

        gridInitOptions: function (data) {
            this.sidx = this.sidx || '';
            this.sord = this.sord || 'asc';
            var postData = Settings.getFromMemory(this.findFilterName()) || {};
            this.sidx = postData.sidx || this.sidx;
            this.sord = postData.sord || this.sord;
            this.pagination.currentPage = postData.page || 1;

            var rowList = [10, 20, 30, 40, 50];
            if (this.pagination.rows) {
                rowList.push(this.pagination.rows);
                rowList = _.sortBy(rowList, function (num) {
                    return num;
                });
            }

            var opt = data && data.data ? {datatype: 'local', data: data.data} : {
                datatype: 'json',
                url: data.url,
                mtype: data.mtype ? data.mtype : 'POST'
            };

            return _.extend({
                regional: Settings.get('lang'),
                colModel: [],
                viewrecords: true,
                height: '100%',
                shrinkToFit: _.isUndefined(this.options.shrinkToFit) || _.isNull(this.options.shrinkToFit) ? true : this.options.shrinkToFit,
                autowidth: _.isUndefined(this.options.autowidth) || _.isNull(this.options.autowidth) ? true : this.options.autowidth,
                responsive: true,
                multiselect: this.options.selectable,
                rowNum: this.pagination.rows,
                rowList: rowList,
                page: this.pagination.currentPage,
                multiboxonly: true,
                multiselectWidth: 20,
                minColWidth: 0,
                jsonReader: {
                    id: 'id',
                    root: function (obj) {
                        return obj.rows;
                    },
                    page: function (obj) {
                        return obj.page;
                    },
                    total: function (obj) {
                        return obj.totalPage;
                    },
                    records: function (obj) {
                        return obj.records;
                    }
                },
                sortname: this.sidx,
                sortorder: this.sord,
                postData: postData || {rows: this.pagination.rows, first: this.pagination.first},
                pager: this.ui.jqGridPager,
                isSuperAdmin: this.isSuperAdmin,
                search: true,
                gridComplete: _.bind(this.gridComplete, this),
                loadComplete: _.bind(this.loadComplete, this),
                beforeProcessing: _.bind(function (data) {
                    if (data.rows) {
                        data = data.rows;
                    }
                    if (this.options.service) {
                        var model = this.options.service.getNamespace().model;
                        data.forEach(function (item) {
                            var m = model.findOrCreate(item);
                            var json = m.toJSON();
                            _.keys(json).forEach(function (key) {
                                item[key] = json[key];
                            });
                        });
                    }
                    return data;
                }, this),
                beforeSelectRow: function (rowid, e) {
                    return !!$(e.target).is(':checkbox');
                },
                onSortCol: _.bind(this.onSortCol, this),
                onCellSelect: _.bind(this.onCellSelect, this),
                onSelectRow: _.bind(function (rowid, status) {
                    if (status) {
                        if (!_.contains(this.selectedRowsGlobal, rowid)) {
                            this.selectedRowsGlobal.push(rowid);
                        }
                    } else {
                        this.selectedRowsGlobal = _.without(this.selectedRowsGlobal, rowid);
                    }
                }, this),
                onSelectAll: _.bind(function (aRowids, status) {
                    if (status) {
                        aRowids.forEach(_.bind(function (id) {
                            if (!_.contains(this.selectedRowsGlobal, id)) {
                                this.selectedRowsGlobal.push(id);
                            }
                        }, this));
                    } else {
                        aRowids.forEach(_.bind(function (id) {
                            this.selectedRowsGlobal = _.without(this.selectedRowsGlobal, id);
                        }, this));
                    }
                }, this)
            }, opt);
        },

        gridComplete: function () {
            this.attachPopover();
            this.trigger('gridComplete', this);
        },

        loadComplete: function (data) {
            if (data.rows) {
                data = data.rows;
            }
            var currentFilters = this.ui.jqGrid.jqGrid('getGridParam', 'postData');
            this.setFilter(currentFilters);
            this.trigger('loadComplete', this, data);
            this.data = data;
        },

        onCellSelect: function (rowId, iCol, cellContent, e) {
            if ($(e.target).is(':checkbox')) {
                return;
            }
            if (e.target.attributes['data-navigate'] && e.type === 'click') {
                FileExplorerController.show(rowId);
                return;
            }
            if (e.currentTarget.classList.contains('cell-copyForClipboard')) {
                var $cellContent = $(cellContent);
                App.trigger('clipboard', $cellContent.attr('data-copy-value'));
                return;
            }

            var colModel = this.ui.jqGrid.jqGrid('getGridParam', 'colModel');
            if (colModel[iCol].onClick) {
                colModel[iCol].onClick(rowId, iCol, cellContent, e, this);
            } else {
                this.trigger('click', {
                    gridView: this,
                    rowId: rowId,
                    colModel: colModel[iCol],
                    cellContent: cellContent,
                    event: e
                });
            }
        },

        onSortCol: function (sidx, columnIndex, sord) {
            var colModel = this.ui.jqGrid.jqGrid('getGridParam', 'colModel');
            var col = colModel[columnIndex];
            if (col.prefix) {
                // get first column
                // check if show or hide
                var columns;
                columns = _.filter(colModel, function (item) {
                    return item.groupBy === col.prefix;
                });
                var firstColumn = _.first(columns);
                if (firstColumn) {
                    var columnNames = _.pluck(columns, 'name');
                    if (firstColumn.hidden) {
                        // show column from firstColumn to lastColumn
                        this.ui.jqGrid.jqGrid('showCol', columnNames);
                    } else {
                        // hide column from firstColumn to lastColumn
                        this.ui.jqGrid.jqGrid('hideCol', columnNames);
                    }
                }

                this._resizeColumns();
                return 'stop';
            } else {
                if (this.sidx !== sidx || this.sord !== sord) {
                    this.sidx = sidx;
                    this.sord = sord;
                    this.reloadGrid();
                }
            }
        },

        gridOptions: function (data) {
            var options = this.options.gridOptions(data, this);
            options = _.defaults(options, this.gridInitOptions(data));
            this.sidx = options.sortname;
            this.sord = options.sortorder;
            options.menubar = true;
            // disabled clear search for all columns if not specified
            _.each(options.colModel, function (colModel) {
                if ((colModel.search || colModel.search === undefined) && (!colModel.searchoptions || (colModel.searchoptions && !colModel.searchoptions.clearSearch))) {
                    if (!colModel.searchoptions) {
                        colModel.searchoptions = {};
                    }
                    colModel.searchoptions.clearSearch = false;
                    if (colModel.formatter === JqGridFormatter.codeNameFormatter) {
                        if (!colModel.searchoptions) {
                            colModel.searchoptions = {};
                        }
                        colModel.searchoptions.sopt = ['codeNameSearch'];
                    }
                    if (colModel.formatter === JqGridFormatter.codeNameListFormatter) {
                        if (!colModel.searchoptions) {
                            colModel.searchoptions = {};
                        }
                        colModel.searchoptions.sopt = ['codeNameListSearch'];
                    }
                    if (colModel.formatter === JqGridFormatter.booleanFormatterNoCheckBox || colModel.formatter === JqGridFormatter.booleanFormatter || colModel.formatter === JqGridFormatter.simpleBooleanFormatter) {
                        if (!colModel.searchoptions) {
                            colModel.searchoptions = {};
                        }
                        colModel.stype = 'select';
                        if (!colModel.searchrules) {
                            colModel.searchrules = {};
                        }
                        colModel.searchrules.select = true;
                        if (!colModel.searchoptions) {
                            colModel.searchoptions = {};
                        }
                        colModel.searchoptions.sopt = ['eq'];
                        colModel.searchoptions.value = JqGridValue.check();
                        delete colModel.searchoptions.size;
                        colModel.width = 30;
                    }
                }
            });
            return options;
        },

        displayData: function () {
            this.colModel = this.gridOptionsObj.colModel;
            if (this.ui.jqGrid) {
                this.ui.jqGrid.jqGrid(this.gridOptionsObj);
            }
            if (this.options.extend) {
                _.each(this.options.extend, _.bind(function (ext) {
                    _.keys(ext).forEach(_.bind(function (key) {
                        if (this.ui.jqGrid) {
                            this.ui.jqGrid.jqGrid(key, ext[key]());
                        }
                    }, this));
                }, this));
            }

            var activeToolbar = _.any(this.colModel, function (colModel) {
                return colModel.search;
            });
            if (this.ui.jqGrid && activeToolbar) {
                this.ui.jqGrid.jqGrid('filterToolbar', {
                    searchOnEnter: false,
                    stringResult: false,
                    searchOperators: false,
                    beforeClear: this.gridOptionsObj.beforeClear,
                    afterClear: this.gridOptionsObj.afterClear,
                    defaultSearch: 'cn',
                    clearSearch: false
                });
                // Appliquer les filtres sauvegardés après l'affichage de la grille
                this.applySavedFilters();
            }
            if (this.ui.jqGrid && this.options.pager) {
                // We need to have a navigation bar in order to add custom buttons to it
                this.ui.jqGrid.navGrid('#' + this.ui.jqGridPager[0].id,
                    {
                        edit: false,
                        add: false,
                        del: false,
                        search: false,
                        refresh: true,
                        view: false,
                        position: 'left',
                        cloneToTop: false,
                        afterRefresh: _.bind(this._afterRefresh, this)
                    });
                var colModels = this.ui.jqGrid.jqGrid('getGridParam', 'colModel');
                if (_.any(colModels, function (colModel) {
                    return colModel.formatters;
                })) {
                    this.ui.jqGrid.navGrid('#' + this.ui.jqGridPager[0].id).navButtonAdd('#' + this.ui.jqGridPager[0].id, {
                        caption: '',
                        buttonicon: 'ui-icon-calculator',
                        onClickButton: _.bind(function () {
                            var view = new JqGridSelectFormatter({
                                collection: colModels,
                                viewFormatter: this.viewFormatter,
                                formatters: this.gridOptionsObj.formatters
                            });
                            view.show({
                                title: _.i18n('jqGrid.view.select')
                            }, _.bind(function () {
                                this._resizeColumns();
                            }, this));
                        }, this),
                        position: 'last',
                        title: _.i18n('jqGrid.view.edit'),
                        id: '',
                        cursor: 'pointer'
                    });
                }
                if (this.options.service && this.options.service.getJqGridNavButtons) {
                    var navButtons = this.options.service.getJqGridNavButtons(this.gridOptionsObj, this);
                    _.each(navButtons, _.bind(function (navButton) {
                        // add argument to "onClickButton" in navButton
                        navButton.onClickButton = _.bind(navButton.onClickButton, this);
                        this.ui.jqGrid.navGrid('#' + this.ui.jqGridPager[0].id).navButtonAdd('#' + this.ui.jqGridPager[0].id, navButton);
                    }, this));
                }
                if (!this.data.url) {
                    this.ui.jqGrid.navGrid('#' + this.ui.jqGridPager[0].id).navButtonAdd('#' + this.ui.jqGridPager[0].id, {
                        caption: '',
                        buttonicon: 'ui-icon-print',
                        onClickButton: _.bind(function () {
                            var folder = '';
                            if (Settings.get('folder')) {
                                folder = Settings.get('folder');
                                if (!folder.endsWith('/')) {
                                    folder += '/';
                                }
                            }


                            var Bversion = App.information.version;
                            var Bdate = App.information.date ? DateUtils.toDateTimeFormatString(App.information.date) : '';
                            var Fversion = Settings.get('version');
                            var Fdate = DateUtils.toDateTimeFormatString(Settings.get('date'));

                            var label = '';
                            if (Bversion && Bversion.includes('SNAPSHOT')) {
                                label = 'DEV';
                            }
                            if (Bversion && Bversion.includes('RC')) {
                                label = 'RC';
                            }

                            var footer = '<div class="d-f" style="width: 100%;align-items: unset;gap: 10px;justify-content: space-between;">' +
                                '    <div class="d-f" style="align-items: anchor-center; gap: 5px;">\n' +
                                '        <img alt="logo mobiolink" class="compuzz_general_logo" src="' + folder + 'images/logo.svg">\n' +
                                '        <img alt="logo mobiolink" class="compuzz_general_title" src="' + folder + 'images/mobiolink.svg">\n' +
                                (label ? '<span class="label ' + label + '" style="margin-top: 20px;">' + label + '</span>\n' : '') +
                                '    </div>' +
                                '    <div class="d-f column" style="font-size: 13px;justify-content: center;">\n' +
                                '        <div>' + Fversion + ' (' + Fdate + ')</div>\n' +
                                '        <div>' + Bversion + ' (' + Bdate + ')</div>\n' +
                                '    </div>' +
                                '</div>';

                            var title = '';
                            if (this.options.service) {
                                title = 'List - ' + this.options.service.getName();
                            }
                            this.ui.jqGrid.jqGrid('exportToHtml', {
                                title: this.gridOptionsObj.title || title,
                                onBeforeExport: null,
                                includeLabels: true,
                                includeGroupHeader: true,
                                includeFooter: true,
                                includeHeader: true,
                                tableClass: 'jqgridprint',
                                autoPrint: false,
                                topText: this.gridOptionsObj.topText,
                                bottomText: this.gridOptionsObj.bottomText || footer,
                                returnAsString: false,
                                treeindent: '&nbsp;'
                            });
                        }, this),
                        position: 'last',
                        title: _.i18n('jqGrid.view.print'),
                        id: '',
                        cursor: 'pointer'
                    });
                }
            }
        },

        setColumnConfig: function (colModel) {
            var columns = colModel.chain()
                .filter(function (col) {
                    return col.get('visible');
                })
                .map(function (col) {
                    return col.get('code');
                })
                .value();
            this.ui.jqGrid.jqGrid('showCol', columns);
            columns = colModel.chain()
                .filter(function (col) {
                    return !col.get('visible');
                })
                .map(function (col) {
                    return col.get('code');
                })
                .value();
            this.ui.jqGrid.jqGrid('hideCol', columns);
        },

        _resizeColumns: function () {
            var colModels = this.ui.jqGrid.jqGrid('getGridParam', 'colModel');

            if (_.any(colModels, function (colModel) {
                return colModel.formatters;
            })) {
                _.keys(this.viewFormatter).forEach(_.bind(function (key) {
                    var colModel = _.findWhere(colModels, {name: key});
                    if (colModel) {
                        if (colModel.displayGroup && this.viewFormatter[key].ovarFormatter) {
                            colModel.formatter = this.viewFormatter[key].ovarFormatter;
                        } else {
                            colModel.formatter = this.viewFormatter[key].formatter;
                        }
                        if (this.viewFormatter[key].width) {
                            this.ui.jqGrid.jqGrid('resizeColumn', colModel.name, this.viewFormatter[key].width, true);
                        }
                    }
                }, this));
                this.reloadGrid();
            }
        },

        _afterRefresh: function () {
            this.reloadGrid();
        },

        setFilter: function (filters) {
            Settings.setToMemory(this.findFilterName(), filters);
        },

        attachPopover: function () {
            this.$el.find('[data-content]').popover({
                trigger: 'hover',
                placement: 'bottom',
                html: 'true',
                container: 'body'
            });
        },

        getCheckedRowsList: function () {
            var results = [];
            _.each(this.selectedRowsGlobal, _.bind(function (id) {
                var currentCheckedRowData = this.ui.jqGrid.getLocalRow(id);
                results.push(currentCheckedRowData);
            }, this));
            return results;
        },

        reloadGrid: function (newData) {
            var grid = this.ui.jqGrid;

            // Récupérer les filtres existants
            var gridParam = grid.jqGrid('getGridParam');
            var postData = gridParam.postData;
            postData.sidx = this.sidx;
            postData.sord = this.sord;
            gridParam.search = true;

            this.loadData(newData, this.options).done(_.bind(function (data) {
                if (data) {
                    grid.jqGrid('clearGridData');
                    data = _.sortBy(data, function (item) {
                        return item[postData.sidx];
                    });
                    grid.jqGrid('setGridParam', {data: data});
                }

                // Réappliquer les filtres existants
                grid.jqGrid('setGridParam', {
                    postData: postData,
                    sortname: postData.sidx,
                    sortorder: postData.sord
                }, true);

                // Recharger la grille
                grid.trigger('reloadGrid', [{current: true}]);
                // do not sort the grid and will call onSortCol
                grid.jqGrid('sortGrid', postData.sidx, true, postData.sord);
                this.synchronizeToolbar(postData);
            }, this));
        },
        loadData: function (newData, options) {
            var defer = $.Deferred();
            this._loadData(newData, options).done(function (data) {
                defer.resolve(data);
            });
            return defer.promise();
        },
        _loadData: function (newData, options) {
            var defer = $.Deferred();
            if (newData) {
                defer.resolve(newData);
            } else {
                if (_.isFunction(options.data.data)) {
                    var objectReturn = _.bind(options.data.data, options.data.context)();
                    // Si l'objet retourné est un Deferred
                    if (objectReturn && objectReturn.done) {
                        objectReturn.done(function (data) {
                            defer.resolve(data.toJSON());
                        });
                    } else {
                        defer.resolve(objectReturn.toJSON());
                    }
                } else if (options.data.data) {
                    defer.resolve(options.data.data);
                } else {
                    defer.resolve();
                }
            }
            return defer.promise();
        },

        columnsForAssayConfiguration: function (assayConfiguration, type, locationName) {
            if (!type) {
                type = 'WELL_LIST';
            }
            if (!locationName) {
                locationName = 'WellList';
            }
            var options = {colModel: []};
            var results = assayConfiguration.get('displayConfigurations').chain()
                .filter(function (displayConfiguration) {
                    return displayConfiguration.get('type') === type;
                })
                .map(function (displayConfiguration) {
                    return displayConfiguration.get('results').models;
                })
                .flatten()
                .value();
            var displayMode = assayConfiguration.get('refAssay').getDisplayMode();
            var max = results.length;
            var width = 20;
            var location = displayMode.findParameterForLocation(locationName);
            var paramSplitted = location.get('parameter') && location.get('parameter').param ? location.get('parameter').param.split(';') : [];
            var isHorizontal = location.get('parameter') && location.get('parameter').type === 'HISTOGRAM' && paramSplitted.length > 6 && paramSplitted[6] === 'HORIZONTAL';
            if (!location.get('parameter') || location.get('parameter').type === 'DOT') {
                width = 24;
            } else {
                if (isHorizontal) {
                    if (max < 8) {
                        width = 60;
                    } else {
                        width = 40;
                    }
                }
            }

            var result = assayConfiguration.get('displayConfigurations').chain()
                .filter(function (displayConfiguration) {
                    return displayConfiguration.get('type') === 'OVAR';
                })
                .map(function (displayConfiguration) {
                    return displayConfiguration.get('results').models;
                })
                .flatten()
                .first()
                .value();

            if (result) {
                var target = result.get('assayConfigurationResult').get('target');
                var refMbAnaResGr = target.get('refMbAnaResGr');
                var mbAnaRes = ':' + _.i18n('common.any') + ';' + refMbAnaResGr.get('mbAnaRes').map(function (mbAnaRes) {
                    return mbAnaRes.get('code') + ':' + mbAnaRes.get('code');
                }).join(';');

                options.colModel.push({
                    label: _.i18n('ovar'),
                    name: 'ovar',
                    labelClasses: 'rotated-text',
                    formatter: JqGridFormatter.ovarResultFormatter,
                    search: true,
                    sortable: false,
                    index: 'target-' + target.id,
                    targetId: target.id,
                    stype: 'select',
                    width: width + 10,
                    fixed: true,
                    searchrules: {select: true},
                    searchoptions: {sopt: ['eq'], value: mbAnaRes}
                });
            }

            _.each(results, _.bind(function (result) {
                var target = result.get('assayConfigurationResult').get('target');
                var refMbAnaResGr = target.get('refMbAnaResGr');
                var mbAnaResValues = refMbAnaResGr.get('mbAnaRes').map(function (mbAnaRes) {
                    return mbAnaRes.get('code') + ':' + mbAnaRes.get('code');
                });

                // Prépare la valeur des options avec une valeur "any" au début
                var mbAnaRes = ':' + _.i18n('common.any') + ';' + mbAnaResValues.join(';');
                var prefix = result.get('assayConfigurationResult').get('linkedAssayConfigurationResults').isEmpty() ? null : target.get('code') + '_';

                // Ajout d'une colonne avec des options spécifiques
                var colModel = {
                    label: target.get('code'),
                    name: target.get('code'),
                    labelClasses: 'rotated-text',
                    formatter: JqGridFormatter.displayModeFormatter,
                    formatters: 'result',
                    search: true,
                    sortable: true,
                    width: width,
                    fixed: true,
                    index: 'target-' + target.id,
                    targetId: target.id,
                    stype: 'select',
                    searchrules: {select: true},
                    searchoptions: {
                        sopt: ['eq'],
                        value: mbAnaRes
                    },
                    prefix: prefix,
                    location: locationName
                };
                options.colModel.push(colModel);
                if (!result.get('assayConfigurationResult').get('linkedAssayConfigurationResults').isEmpty()) {
                    result.get('assayConfigurationResult').get('linkedAssayConfigurationResults').each(_.bind(function (res) {
                        var target = result.get('assayConfigurationResult').get('target');
                        var refMbAnaResGr = target.get('refMbAnaResGr');
                        var mbAnaResValues = refMbAnaResGr.get('mbAnaRes').map(function (mbAnaRes) {
                            return mbAnaRes.get('code') + ':' + mbAnaRes.get('code');
                        });

                        // Prépare la valeur des options avec une valeur "any" au début
                        var mbAnaRes = ':' + _.i18n('common.any') + ';' + mbAnaResValues.join(';');

                        options.colModel.push({
                            label: res.get('target').get('code'),
                            name: colModel.prefix + res.get('target').get('code'),
                            formatter: JqGridFormatter.displayModeFormatter,
                            formatters: 'result',
                            labelClasses: 'rotated-text extend',
                            search: true,
                            sortable: false,
                            classes: '',
                            width: width,
                            fixed: true,
                            frozen: true,
                            resizable: false,
                            index: 'target-' + res.get('target').id,
                            targetId: res.get('target').id,
                            linkedTo: result.get('assayConfigurationResult').get('target').get('code'),
                            hidden: true,
                            stype: 'select',
                            searchrules: {select: true},
                            searchoptions: {
                                sopt: ['eq'],
                                value: mbAnaRes
                            },
                            groupBy: colModel.prefix,
                            location: locationName
                        });
                    }, this));
                }
            }, this));

            options.formatters = {
                curve: assayConfiguration.get('results').any(function (result) {
                    return result.get('type') === 'CURVE';
                })
            };

            return options;
        },

        columnsForAssayVersion: function (assayVersion, type, locationName) {
            if (!type) {
                type = 'WELL_LIST';
            }
            if (!locationName) {
                locationName = 'WellList';
            }
            var options = {colModel: []};
            var displayMode = assayVersion.get('assay').getDisplayMode();
            var results = assayVersion.get('displayConfigurations').chain()
                .filter(function (displayConfiguration) {
                    return displayConfiguration.get('type') === type;
                })
                .map(function (displayConfiguration) {
                    return displayConfiguration.get('results').models;
                })
                .flatten()
                .value();
            var max = results.length;
            var width = 20;
            var location = displayMode.findParameterForLocation(locationName);
            var paramSplitted = location.get('parameter') && location.get('parameter').param ? location.get('parameter').param.split(';') : [];
            var isHorizontal = location.get('parameter') && location.get('parameter').type === 'HISTOGRAM' && paramSplitted.length > 6 && paramSplitted[6] === 'HORIZONTAL';
            if (!location.get('parameter') || location.get('parameter').type === 'DOT') {
                width = 24;
            } else {
                if (isHorizontal) {
                    if (max < 8) {
                        width = 60;
                    } else {
                        width = 40;
                    }
                }
            }

            var result = assayVersion.get('displayConfigurations').chain()
                .filter(function (displayConfiguration) {
                    return displayConfiguration.get('type') === 'OVAR';
                })
                .map(function (displayConfiguration) {
                    return displayConfiguration.get('results').models;
                })
                .flatten()
                .first()
                .value();

            if (result) {
                var target = result.get('target');
                var refMbAnaResGr = target.get('refMbAnaResGr');
                var mbAnaRes = ':' + _.i18n('common.any') + ';' + refMbAnaResGr.get('mbAnaRes').map(function (mbAnaRes) {
                    return mbAnaRes.get('code') + ':' + mbAnaRes.get('code');
                }).join(';');

                options.colModel.push({
                    label: _.i18n('ovar'),
                    name: 'target-' + target.id,
                    labelClasses: 'rotated-text',
                    formatter: JqGridFormatter.ovarResultFormatter,
                    search: true,
                    sortable: false,
                    index: 'target-' + target.id,
                    targetId: target.id,
                    stype: 'select',
                    width: width + 10,
                    fixed: true,
                    searchrules: {select: true},
                    searchoptions: {sopt: ['eq'], value: mbAnaRes}
                });
            }

            _.each(results, _.bind(function (result) {
                var target = result.get('target');
                var refMbAnaResGr = target.get('refMbAnaResGr');
                var mbAnaResValues = refMbAnaResGr.get('mbAnaRes').map(function (mbAnaRes) {
                    return mbAnaRes.get('code') + ':' + mbAnaRes.get('code');
                });

                // Prépare la valeur des options avec une valeur "any" au début
                var mbAnaRes = ':' + _.i18n('common.any') + ';' + mbAnaResValues.join(';');
                var prefix = result.get('linkedAssayResultVersions').isEmpty() ? null : target.get('code') + '_';

                // Ajout d'une colonne avec des options spécifiques
                var colModel = {
                    label: target.get('code'),
                    name: target.get('code'),
                    labelClasses: 'rotated-text',
                    formatter: JqGridFormatter.displayModeFormatter,
                    formatters: 'result',
                    search: true,
                    sortable: true,
                    width: width,
                    fixed: true,
                    index: 'target-' + target.id,
                    targetId: target.id,
                    stype: 'select',
                    searchrules: {select: true},
                    searchoptions: {
                        sopt: ['eq'],
                        value: mbAnaRes
                    },
                    prefix: prefix,
                    location: locationName
                };
                options.colModel.push(colModel);
                if (!result.get('linkedAssayResultVersions').isEmpty()) {
                    result.get('linkedAssayResultVersions').each(_.bind(function (res) {
                        var target = result.get('target');
                        var refMbAnaResGr = target.get('refMbAnaResGr');
                        var mbAnaResValues = refMbAnaResGr.get('mbAnaRes').map(function (mbAnaRes) {
                            return mbAnaRes.get('code') + ':' + mbAnaRes.get('code');
                        });

                        // Prépare la valeur des options avec une valeur "any" au début
                        var mbAnaRes = ':' + _.i18n('common.any') + ';' + mbAnaResValues.join(';');

                        options.colModel.push({
                            label: res.get('target').get('code'),
                            name: colModel.prefix + res.get('target').get('code'),
                            formatter: JqGridFormatter.displayModeFormatter,
                            formatters: 'result',
                            labelClasses: 'rotated-text extend',
                            search: true,
                            sortable: false,
                            width: width,
                            fixed: true,
                            frozen: true,
                            resizable: false,
                            index: 'target-' + res.get('target').id,
                            targetId: res.get('target').id,
                            linkedTo: result.get('target').get('code'),
                            hidden: true,
                            stype: 'select',
                            searchrules: {select: true},
                            searchoptions: {
                                sopt: ['eq'],
                                value: mbAnaRes
                            },
                            groupBy: colModel.prefix,
                            location: locationName
                        });
                    }, this));
                }
            }, this));


            options.formatters = {
                curve: assayVersion.get('results').any(function (result) {
                    return result.get('type') === 'CURVE';
                })
            };

            return options;
        }
    }, JqGridFormatter));
});
