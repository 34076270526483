define('tabsCollectionPopup',[
    'module',
    'dialogFormView',
    'underscore'
], function (
    module,
    DialogFormView,
    _
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: _.template('<div class="js-collection"></div>'),
        regions: {
            content: '.js-collection'
        },
        serializeData: function () {
            return {};
        },
        onRender: function () {
            this.showChildView('content', this.options.tabsCollection);
        }
    });
});
