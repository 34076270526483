/**
 * Created by GSP on 7/01/2016.
 */
define('itemPagesNameMixin',['module'], function (module) {
    'use strict';

    module.exports = {
        ACCOUNT: 'account',
        PROCESS_CTRL: 'process_control',
        MY_ACCOUNT: 'account_account',
        GRAPHICAL_ID: 'account_graphicalid',
        SAMPLE_MANAGMENT: 'sample_managment',
        PREANALYTIC: 'preanalytic',

        //DASHBOARD
        DASHBOARD: 'dashboard',

        // PRODUCTS
        PRODUCTS: 'products',
        MY_PRODUCTS: 'products_products',
        PRODUCTS_PRICELISTS: 'products_pricelists',
        PRODUCTS_CATALOGUES: 'products_catalogues',

        //CLIENTS
        CLIENTS: 'customers',
        MY_CLIENTS: 'customers_customers',
        //SUPPLIERS
        SUPPLIERS: 'suppliers',
        SUPPLIERS_CATALOGUES: 'suppliers_catalogues',

        //GRAPHICAL REQUESTS
        GRAPHICALREQUESTS: 'graphicalRequests',
        GRAPHICALREQUESTS_ALL_IN_ONE: 'graphicalRequests_all_In_One',
        GRAPHICALREQUESTS_VECTORIZATION: 'graphicalRequests_vectorization',
        GRAPHICALREQUESTS_FILECONVERSION: 'graphicalRequests_fileConversion',
        GRAPHICALREQUESTS_REMOVEBACKGROUND: 'graphicalRequests_RemoveBackground',

        //ADMIN
        ADMIN: 'admin',
        USERCODELISTS: 'admin_userCodeLists',
        ADMIN_TIMETRACKING: 'admin_timeTracking',
        KITS_PCR: 'admin_kits_pcr',
        KITS_PCR_LOT: 'admin_kits_pcr_lot',
        KITS_EXTRACTION: 'admin_kits_extraction',
        KITS_EXTRACTION_LOT: 'admin_kits_extraction_lot',
        COLORCOMPENSATION: 'admin_colorCompensation',
        CYCLERS: 'admin_cyclers',
        LMBCYCLERS: 'admin_lmbCyclers',
        EXTRACTORS: 'admin_extractors',
        LMBEXTRACTOR: 'admin_lmbExtractor',
        SAMPLEHANDLERS: 'admin_sampleHandlers',
        LMBSAMPLEHANDLER: 'admin_lmbSampleHandler',
        ADMIN_LMBANARES: 'admin_lmbanares',
        MBANAS: 'admin_mbAnas',
        MBANARESGRS: 'admin_mbAnaResGrs',
        KITPROTS: 'admin_kitProts',
        ADMIN_ASSAYS: 'admin_assay',
        ADMIN_ASSAYVERSIONS: 'admin_assayVersion',
        ADMIN_ASSAYDEFINITION: 'admin_assayDefinition',
        ASSAYGROUP: 'admin_assayGroup',
        ROUTINGGROUP: 'admin_routingGroup',
        ROUTINGACTION: 'admin_routingAction',
        PREPROCESS: 'admin_preProcess',
        ROUTING: 'sample_routing',
        FILEFORMAT_PLATELAYOUT: 'admin_fileFormat_plateLayout',
        FILEFORMAT_SAMPLELIST: 'admin_fileFormat_sampleList',
        LIS_MENU: 'admin_lis_menu',
        LIS: 'admin_lis',
        LISANA: 'admin_lisana',
        LISBIOGROUP: 'admin_lisBioGroup',
        LISSPECIE: 'admin_lisSpecie',
        ADMIN_SUBACCOUNTS: 'subAccountCreator',
        SETTING: 'admin_setting',
        ADMIN_EXPORT: 'admin_export',
        ADMIN_PCRREAGENT: 'admin_PCRREAGENT',
        ADMIN_PCRREAGENTTYPE: 'admin_PCRREAGENTTYPE',
        EXTRACTIONMETHOD: 'admin_EXTRACTIONMETHOD',
        SUPPLIER: 'admin_supplier',
        ADMIN_INSTRUMENTS: 'admin_instruments',
        ADMIN_TARGETDEFINITION: 'admin_targetDefinition',
        ADMIN_ENTITYERROR: 'admin_entityError',
        BIOGROUPS: 'admin_biogroups',
        ADMIN_SAMPLERESULTDEFINITION: 'admin_sampleResultDefinition',
        DISPLAYMODE: 'admin_displayMode',
        PRINTERS: 'admin_printers',
        REPORTS: 'admin_reports',
        SPECIES: 'admin_species',
        STANDARDCURVES: 'admin_standardCurves',
        SAMPLECLASS: 'admin_sampleClass',
        ADMIN_AUTHORITYGROUP: 'admin_authorityGroup',
        ADMIN_USERGROUP: 'admin_userGroup',
        ACCOUNTCREATOR: 'ACCOUNTCREATOR',
        ACCOUNTSHARECREATOR: 'ACCOUNTSHARECREATOR',
        TIMETRACKER: 'TIMETRACKER',
        SUPERADMIN_STATS: 'SUPERADMIN_STATS',
        COLLECTIONS: 'COLLECTIONS',
        ADMIN_USERS: 'admin_users',
        ADMIN_PROFILE: 'admin_profile',
        DATACONNECTION: 'admin_dataConnection',

        CONNECTOR_HL7_TRACING: 'connector_hl7_tracing',

        //LAB
        RUN: 'run',
        RUNS: 'runs',
        PREPRUNS: 'prepruns',
        PCRRUNTEMPLATES: 'pcrRunTemplates',
        PREPRUNTEMPLATES: 'prepRunTemplates',
        RACKS: 'racks',
        SAMPLEIDQUERY: 'sampleIdQuery',
        EXTRACTRUNS: 'extractruns',
        RUNTEMPLATES: 'runTemplates',
        EXTRACTTEMPLATES: 'extractRunTemplates',
        SAMPLES: 'samples',
        ORDERS: 'orders',
        ATTACHMENTS: 'attachments',
        PCR_SETUP: 'pcrSetup',
        TRASH: 'trash',

        //SUPER ADMIN
        SUPERADMIN: 'SUPERADMIN',
        SUPERADMIN_CACCOUNTS: 'superAdmin_cAccounts',
        SUPERADMIN_USERS: 'superAdmin_users',
        SUPERADMIN_DYNAMICDEFINITIONS: 'superAdmin_dynamicDefinitions',
        SUPERADMIN_LOGS: 'superAdmin_logs',
        SUPERCYCLERS: 'superAdmin_cyclers',
        SUPERADMIN_DETECTIONFORMATS: 'superAdmin_detectionFormats',
        SUPERADMIN_CODELISTS: 'superAdmin_codeLists',
        SUPERADMIN_ARRAYS: 'superAdmin_arrays',
        SUPERADMIN_DOCGLOSSARIES: 'superAdmin_docGlossaries',
        SUPERADMIN_APPLICATIONOBJECTDOCS: 'superAdmin_ApplicationObjectDocs',
        SUPERADMIN_DOCUMENTS: 'superAdmin_Documents',
        SUPERADMIN_MENUS: 'superAdmin_Menus',
        SUPERADMIN_GODMODE: 'superAdmin_GodMode',
        SUPERADMIN_TRANSLATIONS: 'superAdmin_Translations',
        SUPERADMIN_ROUTES: 'superAdmin_Routes',
        SUPERADMIN_MODULES: 'superAdmin_Modules',
        SUPERADMIN_DATACONNECTIONS: 'superAdmin_DataConnections',
        SUPERADMIN_CACHE: 'superAdmin_Caches'
    };
});

