define('pcrKitLotForValidationItem',[
    'module',
    'backbone.marionette',
    'template!pcrKitLotForValidationItem',
    'underscore'
], function (
    module,
    Marionette,
    Tpl,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        tagName: 'tr',

        ui: {
            pcrKitLot: '.pcrKitLot'
        },

        initialize: function () {
            this.pcrReagentService = require('services/caccounts/pcrreagents');
            this.pcrKitService = require('services/caccounts/kitspcr');
        },

        serializeData: function () {
            var templateData = {
                pcrReagent: _.map(this.model.get('pcrReagents'), _.bind(function (pcrReagent) {
                    return this.pcrReagentService.getModel(pcrReagent).get('code');
                }, this)).join(', ')
            };
            var pcrKit = this.pcrKitService.getModel(this.model.get('kit'));
            templateData.kit = {
                code: pcrKit.get('code'),
                id: pcrKit.id,
                name: pcrKit.get('name')
            };
            templateData.canUpdate = this.model.get('canUpdate');

            templateData.pcrKitLots = pcrKit.get('lots').chain()
                .filter(function (pcrKitLot) {
                    return ['TEST'].includes(pcrKitLot.get('status'));
                }).map(function (pcrKitLot) {
                    return {
                        code: pcrKitLot.get('code'),
                        id: pcrKitLot.id
                    };
                }).value();
            if (this.model.get('pcrKitLot')) {
                templateData.pcrKitLots.unshift(this.model.get('pcrKitLot'));
                templateData.pcrKitLots = _.uniq(templateData.pcrKitLots, false, function (pcrKitLot) {
                    return pcrKitLot.id;
                });
            }
            templateData.currentKitLotCode = pcrKit.get('refCurrentLot') ? pcrKit.get('refCurrentLot').get('code') : null;

            return templateData;
        },
        getSelected: function () {
            var selectedOption = this.ui.pcrKitLot.find('option:selected');
            if (selectedOption.hasClass('current')) {
                return null;
            }
            return selectedOption.val();
        },

        onRender: function () {
            if (!this.model.get('canUpdate')) {
                this.ui.pcrKitLot.prop('disabled', true);
                this.ui.pcrKitLot.addClass('disabled');
            }
            if (this.model.get('kit').refCurrentLot) {
                this.ui.pcrKitLot.find('option[value="' + this.model.get('kit').refCurrentLot.id + '"]').addClass('current');
            }

            var pcrKitLot = this.options.well.get('pcrKitLotsForValidation').chain().filter(_.bind(function (pcrKitLot) {
                return pcrKitLot.get('kit').get('id') === this.model.get('kit').id;
            }, this)).first().value();
            if (!this.model.get('canUpdate') && !pcrKitLot) {
                pcrKitLot = this.options.well.get('pcrKitLots').chain().filter(_.bind(function (pcrKitLot) {
                    return pcrKitLot.get('kit').get('id') === this.model.get('kit').id;
                }, this)).first().value();
            }
            if (pcrKitLot) {
                this.ui.pcrKitLot.find('option[value="' + pcrKitLot.get('id') + '"]').prop('selected', true);
            }
        }
    });
});

