/**
 * Created by RKL on 10/09/2015.
 */
define('dialogFormView',[
    'module',
    'underscore',
    'jquery',
    'formView',
    'dialogMixin',
    'settings',
    'backbone'
], function (
    module,
    _,
    $,
    FormView,
    DialogMixin,
    Settings,
    Backbone
) {
    'use strict';

    module.exports = FormView.extend(
        _.extend({
            events: {},
            _getAutocompleteOptionObject: function (paramObject) {
                return this._getAutocompleteOptions(paramObject.modelProperty, paramObject.valueProperty, paramObject.queryProperty, paramObject.urlPath, paramObject.placeHolderLabel,
                    paramObject.valueKey, paramObject.callback, null, paramObject.childTemplate, paramObject.fieldsToDisplay,
                    paramObject.callbackAdd, paramObject.callbackEdit, paramObject.acceptDirectInput, paramObject.dropdownWidth, paramObject.data, paramObject.value, paramObject.service, paramObject.canAddOrEdit, paramObject.readOnly, paramObject.values);
            },

            _getAutocompleteOptions: function (modelProperty, valueProperty, queryProperty, urlPath, placeHolderLabel,
                                               valueKey, callback, acceptNewValue, childTemplate, fieldsToDisplay,
                                               callbackAdd, callbackEdit, acceptDirectInput, dropdownWidth, data, value, service, canAddOrEdit, readOnly,
                                               values) {

                var object = this.model ? this.model.get(modelProperty) : null;
                if (typeof object === 'string') {
                    object = {};
                    object[valueProperty] = this.model.get(modelProperty);
                }
                if (object && object instanceof Backbone.Model) {
                    object = object.toJSON();
                }

                if (this.model) {
                    object = this.model;
                    var modelPropertiesSplitted = modelProperty.split('.');
                    _.each(modelPropertiesSplitted, function (modelPropertySplitted) {
                        if (object instanceof Backbone.Model) {
                            object = object.get(modelPropertySplitted);
                        } else if (object instanceof Object) {
                            object = object[modelPropertySplitted];
                        } else if (typeof object === 'string') {
                            var objectTemp = object;
                            object = {};
                            if (object instanceof Backbone.Model) {
                                object = objectTemp.get(modelPropertySplitted);
                            } else if (typeof object === 'object') {
                                object = objectTemp[modelPropertySplitted];
                            } else {
                                object = null;
                            }
                        }
                    });
                }

                if (typeof object === 'string') {
                    object = {};
                    object[valueProperty] = this.model.get(modelProperty);
                }
                if (object && object instanceof Backbone.Model) {
                    object = object.toJSON();
                }


                if (acceptDirectInput && !callback) {
                    callback = _.bind(callback ? callback : this.onChangeDirectInput, this);
                }

                var directInput = false;
                if (acceptDirectInput) {
                    directInput = true;
                }

                var remote;
                if (!data) {
                    remote = Settings.url(urlPath);
                }
                return {
                    method: 'POST',
                    type: !data ? 'remote' : 'dataset',
                    remote: remote,
                    data: data,
                    valueKey: valueKey,
                    apiKey: 'id',
                    placeholder: '',
                    classname: 'inputBackground',
                    value: value ? value : (object ? object[valueProperty] : null),
                    name: modelProperty,
                    onPressKey: true,
                    lazyLoad: true,
                    keys: {
                        query: queryProperty,
                        limit: 'rows'
                    },
                    callback: _.bind(callback ? callback : this.onChangeAutoComplete, this),
                    fieldsToDisplay: fieldsToDisplay,
                    callbackAdd: callbackAdd ? _.bind(callbackAdd, this) : null,
                    callbackEdit: callbackEdit ? _.bind(callbackEdit, this) : null,
                    acceptDirectInput: directInput,
                    valueProperty: valueProperty,
                    dropdownWidth: dropdownWidth,
                    service: service,
                    model: this.model ? this.model.get(modelProperty) : (this.model.getRelation(modelProperty) ? this.model.getRelation(modelProperty).findOrCreate({}) : new Backbone.Model()),
                    canAddOrEdit: canAddOrEdit,
                    readOnly: readOnly,
                    values: values || {}
                };
            },

            onChangeDirectInput: function (fieldName, model, that) {
                var value = null;
                if (model) {
                    model.unset('autocompleteValue', {silent: true});

                    if (model.get(that.options.valueProperty)) {
                        value = model.get(that.options.valueProperty);
                    } else {
                        value = model;
                    }
                }
                this.onChange(fieldName, value);
                this.triggerMethod('enable:cancel:confirm');
            },

            onChangeAutoComplete: function (fieldName, model) {
                if (model) {
                    model.unset('autocompleteValue', {silent: true});
                }
                this.onChange(fieldName, model);
                this.triggerMethod('enable:cancel:confirm');
            }
        }, DialogMixin)
    );
});

