define('runPrepHeaderPlateLeftView',[
    'module',
    'backbone.marionette',
    'backbone',
    'template!runPrepHeaderPlateLeftView',
    'underscore',
    'jquery',
    'settings',
    'customBootboxMessage',
    'prepRunCreateBeadPooling',
    'prepRunCreatePrimaryPooling',
    'prepRunExtractionStart',
    'prepRunExtractionStop',
    'prepRunExtrKitsLotsView',
    'prepRunCreatePcrFromPrepView',
    'prepRunCreatedPcrFromPrepView',
    'bootbox',
    'menuGroup',
    'entities/menuGroup',
    'rolesMixin',
    'privileges',
    'jqGridController'
], function (
    module,
    Marionette,
    Backbone,
    template,
    _,
    $,
    Settings,
    CustomBootboxMessage,
    CreateBeadPoolingView,
    CreatePrimaryPoolingView,
    ExtractionStart,
    ExtractionStop,
    PrepRunExtrKitsLotsView,
    CreatePcrFromPrepView,
    CreatedPcrFromPrepView,
    bootbox,
    MenuGroupView,
    MenuGroup,
    RolesMixin,
    Privileges,
    JqGridController
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: template,
        className: 'left',
        modelEvents: {
            'change': 'render'
        },
        regions: {
            'plate-menu': '.js-plate-menu',
            'extraction-menu': '.js-extraction-menu',
            'pcr-menu': '.js-pcr-menu',
            'pool-menu': '.js-pool-menu',
            'other-menu': '.js-other-menu'
        },
        initialize: function () {
            this.RunPrepController = require('runPrepController');
            this.plateMenu = new MenuGroup({
                nameCode: 'common.plateLayout',
                items: new Backbone.Collection([
                    {
                        nameCode: 'prep.platelayout.import',
                        click: _.bind(this.onPlateLayoutImport, this),
                        disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                    }, {
                        nameCode: 'prep.platelayout.from.other.run',
                        click: _.bind(this.onPlateLayoutImportFromOtherRun, this),
                        disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                    }, {
                        nameCode: 'prep.sampleList.import',
                        click: _.bind(this.onSampleListImport, this),
                        disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                    }, {
                        nameCode: 'prep.platelayout.export',
                        click: _.bind(this.onPlateLayoutExport, this)
                    }, {
                        nameCode: 'prep.sampleList.export',
                        click: _.bind(this.onSampleListExport, this)
                    }, {
                        nameCode: 'prep.platelayout.ctrl',
                        click: _.bind(this.onPlateLayoutControl, this)
                    }, {
                        nameCode: 'prep.platelayout.scan',
                        click: _.bind(this.onPlateLayoutScan, this),
                        disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                    }, {
                        nameCode: 'prep.platelayout.edit',
                        click: _.bind(this.onPlateLayoutEdit, this),
                        disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                    }, {
                        nameCode: 'prep.platelayout.generate.smpId',
                        click: _.bind(this.onGenerateSmpIds, this),
                        disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                    }, {
                        nameCode: 'prep.platelayout.remove.smpId',
                        click: _.bind(this.onRemoveSmpIds, this),
                        disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                    }, {
                        nameCode: 'prep.platelayout.add.assay',
                        click: _.bind(this.onAddAssay, this),
                        disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                    }, {
                        nameCode: 'prep.platelayout.clear.assay',
                        click: _.bind(this.onClearAssay, this),
                        disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                    }, {
                        nameCode: 'prep.platelayout.replace.assay',
                        click: _.bind(this.onReplaceAssay, this),
                        disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                    }, {
                        nameCode: 'prep.platelayout.add.test',
                        click: _.bind(this.onAddTest, this),
                        disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                    }, {
                        nameCode: 'prep.platelayout.remove.test',
                        click: _.bind(this.onRemoveTest, this),
                        disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                    }, {
                        nameCode: 'pcr.platelayout.clear.wells',
                        click: _.bind(this.onClearWells, this),
                        disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                    }]),
                quickActions: new Backbone.Collection([{
                    nameCode: 'prep.platelayout.import',
                    click: _.bind(this.onPlateLayoutImport, this),
                    mdi: 'mdi-import',
                    disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                }, {
                    nameCode: 'prep.platelayout.scan',
                    click: _.bind(this.onPlateLayoutScan, this),
                    mdi: 'mdi-barcode-scan',
                    disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                }, {
                    items: new Backbone.Collection([{
                        nameCode: 'prep.platelayout.add.assay',
                        click: _.bind(this.onAddAssay, this),
                        mdi: 'mdi-plus-circle',
                        disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                    }, {
                        nameCode: 'prep.platelayout.clear.assay',
                        click: _.bind(this.onClearAssay, this),
                        mdi: 'mdi-minus-circle',
                        disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                    }, {
                        nameCode: 'prep.platelayout.replace.assay',
                        click: _.bind(this.onReplaceAssay, this),
                        mdi: 'mdi-rotate-3d-variant',
                        disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                    }, {
                        nameCode: 'prep.platelayout.edit',
                        click: _.bind(this.onPlateLayoutEdit, this),
                        mdi: 'mdi-pencil',
                        disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                    }])
                }, {
                    nameCode: 'prep.platelayout.ctrl',
                    click: _.bind(this.onPlateLayoutControl, this),
                    mdi: 'mdi-shield-check'
                }])
            });

            this.extractionMenu = new MenuGroup({
                nameCode: 'preprun.extraction',
                items: new Backbone.Collection([{
                    nameCode: 'prep.extraction.start',
                    click: _.bind(this.onStartExtractionClick, this),
                    disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                }, {
                    nameCode: 'prep.extraction.end',
                    click: _.bind(this.onStopExtractionClick, this),
                    disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                }, {
                    nameCode: 'prep.extraction.view',
                    click: _.bind(this.onShowExtractionClick, this)
                }, {
                    nameCode: 'prep.extraction.undo',
                    click: _.bind(this.onUndoExtractionClick, this),
                    disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                }]),
                quickActions: new Backbone.Collection([{
                    nameCode: 'prep.extraction.start',
                    click: _.bind(this.onStartExtractionClick, this),
                    mdi: 'mdi-play',
                    visible: _.bind(function () {
                        return _.isEmpty(this.model.get('extractionDetails').get('refExtractionMethod'));
                    }, this),
                    disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                }, {
                    nameCode: 'prep.extraction.undo',
                    click: _.bind(this.onUndoExtractionClick, this),
                    mdi: 'mdi-cancel',
                    visible: _.bind(function () {
                        return !_.isEmpty(this.model.get('extractionDetails').get('refExtractionMethod'));
                    }, this),
                    disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                }, {
                    nameCode: 'prep.extraction.end',
                    click: _.bind(this.onStopExtractionClick, this),
                    mdi: 'mdi-play-pause',
                    disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                }, {
                    nameCode: 'prep.extraction.view',
                    click: _.bind(this.onShowExtractionClick, this),
                    mdi: 'mdi-eye-outline'
                }])
            });

            this.pcrMenu = new MenuGroup({
                nameCode: 'preprun.pcrsetup',
                items: new Backbone.Collection([{
                    nameCode: 'common.pcrFromPrep',
                    click: _.bind(this.onCheckBeforeCreatePcrFromPrep, this),
                    disabled: !(Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.PCR_SETUP))
                }]),
                quickActions: new Backbone.Collection([{
                    nameCode: 'common.pcrFromPrep',
                    click: _.bind(this.onCheckBeforeCreatePcrFromPrep, this),
                    mdi: 'mdi-cog',
                    disabled: !(Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.PCR_SETUP))
                }])
            });

            this.poolMenu = new MenuGroup({
                nameCode: 'preprun.pooling',
                items: new Backbone.Collection([{
                    nameCode: 'prep.pooling.start',
                    click: _.bind(this.onCreateBeadPoolingClick, this),
                    disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                }, {
                    nameCode: 'pooling.primary.menu',
                    click: _.bind(this.onCreatePrimaryPoolingClick, this),
                    disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                }]),
                quickActions: new Backbone.Collection([{
                    nameCode: 'prep.pooling.start',
                    click: _.bind(this.onCreateBeadPoolingClick, this),
                    mdi: 'mdi-google-circles-communities',
                    disabled: !Settings.get('currentUserModel').hasType(RolesMixin.RUN_PREP, Privileges.WRITE)
                }])
            });

            this.otherMenu = new MenuGroup({
                nameCode: 'common.miscaelenous',
                items: new Backbone.Collection([{
                    nameCode: 'prep.generate-starter-sheet',
                    click: _.bind(this.onGenerateStarterSheet, this)
                }, {
                    nameCode: 'prep.generate-extract-setup',
                    click: _.bind(this.onGenerateExtractSetup, this)
                }, {
                    nameCode: 'run.tracs',
                    click: _.bind(this.onShowTracsView, this)
                }]),
                quickActions: new Backbone.Collection([{
                    nameCode: 'prep.run.tracs',
                    click: _.bind(this.onShowTracsView, this),
                    mdi: 'mdi-gnome'
                }])
            });
        },
        serializeData: function () {
            return {};
        },
        onRender: function () {
            this.showChildView('plate-menu', new MenuGroupView({
                model: this.plateMenu
            }));
            this.showChildView('extraction-menu', new MenuGroupView({
                model: this.extractionMenu
            }));
            this.showChildView('pcr-menu', new MenuGroupView({
                model: this.pcrMenu
            }));
            this.showChildView('pool-menu', new MenuGroupView({
                model: this.poolMenu
            }));
            this.showChildView('other-menu', new MenuGroupView({
                model: this.otherMenu
            }));

            var status = this.model.get('status');

            var displayStart = status === 1 || status > 2;
            _.each(this.plateMenu.findItemsBy('prep.extraction.start'), function (item) {
                item.trigger('visible:quickAction', displayStart);
            });
            _.each(this.plateMenu.findItemsBy('prep.extraction.undo'), function (item) {
                item.trigger('visible:quickAction', !displayStart);
            });

            this.allowActionButtons();
        },
        onEditWellsSmpId: function () {
            this.RunPrepController.editPlateLayout(this.model);
        },

        onPlateLayoutExport: function () {
            this.RunPrepController.exportPlateLayout(this.model, 'prep');
        },

        onSampleListExport: function () {
            this.RunPrepController.exportSampleList(this.model, 'prep');
        },

        onPlateLayoutImport: function () {
            this.RunPrepController.importPlateLayout(this.model);
        },

        onSampleListImport: function () {
            this.RunPrepController.importSampleList(this.model);
        },

        onPlateLayoutImportFromOtherRun: function () {
            var PrepRunService = require('services/caccounts/prepruns');
            this.RunPrepController.copyFromRun(PrepRunService, this.model);
        },

        onPlateLayoutScan: function () {
            this.RunPrepController.scanPlateLayout(this.model);
        },

        onPlateLayoutEdit: function () {
            if (this.checkBeforeSampleIdEdit()) {
                this.warmOnWrongStatusBeforeEdit(this.RunPrepController.editPlateLayout, _.i18n('prep.platelayout.edit'));
            }
        },

        onGenerateSmpIds: function () {
            if (this.checkBeforeSampleIdEdit()) {
                this.warmOnWrongStatusBeforeEdit(this.RunPrepController.generateSampleId, _.i18n('prep.platelayout.generate.smpId'));
            }
        },

        onRemoveSmpIds: function () {
            if (this.checkBeforeSampleIdEdit()) {
                this.warmOnWrongStatusBeforeEdit(this.RunPrepController.removeSampleId, _.i18n('prep.platelayout.remove.smpId'));
            }
        },
        warmOnWrongStatusBeforeEdit: function (mode) {
            if (this.model.get('status') >= 3) {
                var callback = _.bind(function (result) {
                    if (result) {
                        if (mode instanceof Function) {
                            mode(this.model);
                        }
                    }
                }, this);
                var confirmParams = {
                    message: _.i18n('pcrWell.extraction.already.done'),
                    type: 'warning'
                };
                CustomBootboxMessage.customConfirm(confirmParams, callback);
            } else {
                if (mode instanceof Function) {
                    mode(this.model);
                }
            }
        },
        onPlateLayoutControl: function () {
            this.RunPrepController.controlSample(this.model);
        },
        onAddAssay: function () {
            this.RunPrepController.assignAssay(this.model);
        },

        statusChangeIcAlert: function (callback) {
            var confirmParams = {
                message: _.i18n('prepRun.st2+.sid.warning.message'),
                type: 'warning'
            };
            CustomBootboxMessage.customConfirm(confirmParams, callback);
        },

        onClearAssay: function () {
            var callback = _.bind(function (result) {
                if (result) {
                    this.RunPrepController.removeAssay(this.model);
                }
            }, this);
            if (this.model.get('status') > 3) {
                this.statusChangeIcAlert(callback);
            } else {
                callback(true);
            }
        },

        onReplaceAssay: function () {
            var callback = _.bind(function (result) {
                if (result) {
                    this.RunPrepController.replaceAssay(this.model);
                }
            }, this);
            if (this.model.get('status') > 3) {
                this.statusChangeIcAlert(callback);
            } else {
                callback(true);
            }
        },
        onAddTest: function () {
            this.RunPrepController.defineTestWell(this.model);
        },
        onRemoveTest: function () {
            this.RunPrepController.removeTestWell(this.model);
        },
        onClearWells: function () {
            this.RunPrepController.clearWell(this.model);
        },

        checkBeforeSampleIdEdit: function () {
            var allowed = true;
            var assaysRunning = [];
            var assaysDone = [];
            var message = '';

            this.model.get('wells').each(_.bind(function (well) {
                assaysRunning.push(well.getWellAssaysRunningList());
                assaysDone.push(well.getWellAssaysDoneList());
            }, this));

            assaysRunning = _.uniq(_.flatten(assaysRunning));
            assaysDone = _.uniq(_.flatten(assaysDone));

            if (this.model.get('pcrRuns') && this.model.get('pcrRuns').length > 0) {
                var messageToAdd = [];
                this.model.get('pcrRuns').each(_.bind(function (pcrRun) {
                    messageToAdd.push(pcrRun.get('name'));
                }, this));
                messageToAdd = messageToAdd.slice(0, -1);

                allowed = false;
                message = this.addToAlertMessage(message, _.i18n('prepRun.linked.with.pcrRun'), messageToAdd);
            }

            if (assaysRunning.length > 0) {
                allowed = false;
                message = this.addToAlertMessage(message, _.i18n('prepRun.assaysRunning.found'), assaysRunning);
            }

            if (assaysDone.length > 0) {
                allowed = false;
                message = this.addToAlertMessage(message, _.i18n('prepRun.assaysDone.found'), assaysDone);
            }

            if (!allowed) {
                var params = {
                    title: _.i18n('prepRun.with.constrains'),
                    message: message,
                    type: 'info'
                };
                CustomBootboxMessage.customAlert(params);
            }
            return allowed;
        },

        addToAlertMessage: function (message, messageToAdd, arrayToAdd) {
            message += messageToAdd;
            message += '<br> <textarea style="width: 100%; resize: vertical; background-color: white;" rows="1" wrap="on">';
            message += arrayToAdd.join(',');
            message += '</textarea>';
            message += '<br>';
            return message;
        },

        onCreatePrimaryPoolingClick: function () {
            this.createPooling = new CreatePrimaryPoolingView({
                model: this.model
            });
            this.createPooling.show({
                title: _.i18n('run.createPooling.primary'),
                className: 'actionPopupCss popupFitContent'
            });
        },
        onCreateBeadPoolingClick: function () {
            this.createPooling = new CreateBeadPoolingView({
                model: this.model
            });
            this.createPooling.show({
                title: _.i18n('run.createPooling.bead'),
                className: 'actionPopupCss popupFitContent'
            });
        },
        onCheckBeforeCreatePcrFromPrep: function () {
            if (this.model.get('status') < 3) {
                var confirmParams = {
                    title: _.i18n('exraction.notFinished.warning.title'),
                    message: _.i18n('exraction.notFinished.warning.message'),
                    type: 'warning'
                };
                var callback = _.bind(function (result) {
                    if (result) {
                        this.onCreatePcrFromPrepClick();
                    }
                }, this);
                CustomBootboxMessage.customConfirm(confirmParams, callback);
            } else {
                this.onCreatePcrFromPrepClick();
            }
        },

        onCreatePcrFromPrepClick: function () {
            this.createPcrFromPrep = new CreatePcrFromPrepView({
                runModel: this.model
            });
            this.createPcrFromPrep.show({
                title: _.i18n('run.createPcrFromPrep'),
                className: 'actionPopupCss popupFitContent'
            });

            this.createPcrFromPrep.listenTo(this.createPcrFromPrep, 'run.created.from.prep', _.bind(function (result) {
                var DefaultCheckBoxService = require('services/caccounts/setting/defaultCheckbox');
                var setting = DefaultCheckBoxService.getModel();
                setting.fetch().done(_.bind(function () {
                    var SettingService = require('services/caccounts/setting');
                    SettingService.findByCode('EXPORT-PLATELAYOUT').done(_.bind(function (data) {
                        var model = new Backbone.Model();
                        model.set(result);
                        model.set('pcrRun', require('services/caccounts/pcrruns').getModel(model.get('pcrRun')));
                        model.set('prepRun', require('services/caccounts/prepruns').getModel(model.get('prepRun')));
                        _.each(model.get('assayLines'), function (assayLine) {
                            assayLine.assay = require('services/caccounts/assay').getModel(assayLine.assay);
                        });
                        var exportPlateLayoutSetted = data && data.params && data.params.split(';')[0];
                        var view = new CreatedPcrFromPrepView({
                            model: model,
                            exportPlateLayoutSetted: !!exportPlateLayoutSetted,
                            pcrExportPlateLayout: setting.get('pcrExportPlateLayout'),
                            printPcrSetup: setting.get('printPcrSetup'),
                            printPcrSetupMMX: setting.get('printPcrSetupMMX')
                        });
                        view.show({
                            title: _.i18n('run.created.from.prep'),
                            className: 'actionPopupCss popupFitContent'
                        }, _.bind(function () {
                            $('.js-global-loader').show();
                            this.model.fetch({wells: true}).always(function () {
                                $('.js-global-loader').hide();
                            });
                        }, this));
                    }, this));
                }, this));
            }, this));
        },

        onStartExtractionClick: function () {
            if (this.model.get('status') === 0) {
                this.plateLayoutNotFinishedAlert();
            } else if (!_.isEmpty(this.model.get('refExtractionMethod')) && this.model.get('status') > 2) {
                this.extractionAlreadyDoneAlert();
            } else {
                this.onStartExtraction();
            }
        },

        onUndoExtractionClick: function () {
            bootbox.confirm(_.i18n('preprun.extraction.undo'), _.bind(function (result) {
                    if (result) {
                        this.model.undoExtraction();
                    }
                }, this)
            );
        },

        extractionAlreadyDoneAlert: function () {
            var params = {
                title: _.i18n('prepRun.extraction.finished.warning.title'),
                message: _.i18n('prepRun.extraction.finished.warning.message'),
                type: 'warning'
            };
            CustomBootboxMessage.customAlert(params);
        },

        plateLayoutNotFinishedAlert: function () {
            var confirmParams = {
                title: _.i18n('prepRun.notFinished.warning.title'),
                message: _.i18n('prepRun.notFinished.warning.message'),
                type: 'warning'
            };

            var callback = _.bind(function (result) {
                if (result) {
                    this.onStartExtraction();
                }
            }, this);
            CustomBootboxMessage.customConfirm(confirmParams, callback);
        },

        onStartExtraction: function () {
            var DefaultCheckBoxService = require('services/caccounts/setting/defaultCheckbox');
            var setting = DefaultCheckBoxService.getModel();
            setting.fetch().done(_.bind(function () {
                this.extraction = new ExtractionStart({
                    runModel: this.model,
                    currentUser: Settings.get('currentUserModel').toJSON(),
                    printExtractionSetup: setting.get('prepExtractionSetup')
                });

                this.extraction.listenTo(this.extraction, 'run:prep:extraction:update:buttons', _.bind(function () {
                    this.updateExtractionButtons();
                }, this));

                this.extraction.show({
                    title: _.i18n('run.extraction.start'),
                    className: 'actionPopupCss popupFitContent'
                });
            }, this));
        },

        onStopExtractionClick: function () {
            this.extraction = new ExtractionStop({
                runModel: this.model,
                currentUser: Settings.get('currentUserModel').toJSON()
            });

            this.extraction.listenTo(this.extraction, 'run:prep:extraction:update:buttons', _.bind(function () {
                this.updateExtractionButtons();
            }, this));

            this.extraction.show({
                title: _.i18n('run.extraction.stop'),
                className: 'actionPopupCss popupFitContent'
            });
        },

        onShowExtractionClick: function () {
            this.model.getKitLots()
                .done(_.bind(function (result) {
                    this.prepRunExtrKitsLotsView = new PrepRunExtrKitsLotsView({
                        model: this.model,
                        kitLots: result
                    });
                    this.prepRunExtrKitsLotsView.show({
                        title: _.i18n('prepRun.extr.kits'),
                        className: 'runShowKitsPopup'
                    });
                }, this));
        },

        updateExtractionButtons: function () {
            $('.js-global-loader').show();
            this.model.fetch().always(function () {
                $('.js-global-loader').hide();
            });
        },
        onGenerateStarterSheet: function () {
            var PrepRunService = require('services/caccounts/prepruns');
            PrepRunService.generateRunSheet(this.model.get('id'));
        },

        onGenerateExtractSetup: function () {
            var PrepRunService = require('services/caccounts/prepruns');
            PrepRunService.generateRunExtractSetup(this.model.get('id'));
        },
        allowActionButtons: function () {
            var status = this.model.get('status');
            var items = [];
            if (status >= 4) {
                items = items.concat(this.plateMenu.findItemsBy('prep.platelayout.import'));
            }
            if (this.model.get('toBePooled') || status === 0) {
                items = items.concat(this.extractionMenu.findItemsBy('prep.extraction.start'));
                items = items.concat(this.extractionMenu.findItemsBy('prep.extraction.end'));
                items = items.concat(this.extractionMenu.findItemsBy('prep.extraction.view'));
                items = items.concat(this.extractionMenu.findItemsBy('prep.extraction.undo'));
                items = items.concat(this.otherMenu.findItemsBy('prep.generate-extract-setup'));
            } else {
                if (!this.model.get('extractionDetails').get('refExtractionMethod')) {
                    items = items.concat(this.extractionMenu.findItemsBy('prep.extraction.end'));
                    items = items.concat(this.extractionMenu.findItemsBy('prep.extraction.view'));
                    items = items.concat(this.extractionMenu.findItemsBy('prep.extraction.undo'));
                    items = items.concat(this.otherMenu.findItemsBy('prep.generate-extract-setup'));
                } else {
                    if (status > 2) {
                        items = items.concat(this.extractionMenu.findItemsBy('prep.extraction.end'));
                    }
                    items = items.concat(this.extractionMenu.findItemsBy('prep.extraction.start'));
                }
            }

            if (status < 1 || status >= 5 || !this.model.get('extractionDetails').get('refExtractionMethod')) {
                items = items.concat(this.pcrMenu.findItemsBy('common.pcrFromPrep'));
            }
            if (status >= 4) {
                items = items.concat(this.plateMenu.findItemsBy('prep.platelayout.import'));
                items = items.concat(this.plateMenu.findItemsBy('prep.platelayout.from.other.run'));
                items = items.concat(this.plateMenu.findItemsBy('prep.platelayout.scan'));
                items = items.concat(this.plateMenu.findItemsBy('prep.platelayout.import'));
                items = items.concat(this.extractionMenu.findItemsBy('prep.extraction.undo'));
            }

            if (status > 1) {
                items = items.concat(this.plateMenu.findItemsBy('prep.platelayout.scan'));
            }

            if (!this.model.canAssignSID()) {
                items = items.concat(this.plateMenu.findItemsBy('prep.platelayout.edit'));
                items = items.concat(this.plateMenu.findItemsBy('prep.platelayout.generate.smpId'));
                items = items.concat(this.plateMenu.findItemsBy('prep.platelayout.remove.smpId'));
            }
            if (!this.model.canClearAssay()) {
                items = items.concat(this.plateMenu.findItemsBy('prep.platelayout.clear.assay'));
                items = items.concat(this.plateMenu.findItemsBy('prep.platelayout.replace.assay'));
            }
            _.each(items, function (item) {
                item.trigger('enable', false);
            });
        },


        onShowTracsView: function () {
            var service = require('services/caccounts/prepruntracs');
            var data = {
                url: service.getCollectionUrl(),
                getJqGridColumnConfig: service.getJqGridColumnConfig()
            };

            var filterName = 'tracTableListFilters_PCRRUN_' + this.model.id;
            var storedFilters = Settings.getFromMemory(filterName);
            if (!storedFilters || !storedFilters.sidx) {
                storedFilters = {
                    runSecId: this.model.id
                };
                Settings.setToMemory(filterName, storedFilters);
            }

            var jqGridView = JqGridController.showPopup({
                pager: true,
                filtersName: filterName,
                gridOptions: _.bind(function () {
                    return {
                        colModel: service.getJqGridColumn(jqGridView, null),
                        sortname: service.getJqGridSidx(this),
                        sortorder: service.getJqGridSord(this)
                    };
                }, this),
                data: data,
                selectable: false,
                service: service
            }, {
                title: _.i18n('run.tracs'),
                className: 'runShowTracsPopup'
            });
            jqGridView.listenTo(jqGridView, 'click', function (obj) {
                var model = service.getModel({id: obj.rowId});
                service.showDetails({model: model});
            });
        }
    });
});

