define('load',[
    'module',
    'jquery',
    'backbone.marionette',
    'template!load',
    'underscore',
    'services/global/text',
    'services/global/token',
    'settings',
    'backbone',
    'app',
    'polyglotInstance',
    'bootbox',
    'services/caccounts/userpids'
], function (
    module,
    $,
    Marionette,
    Tpl,
    _,
    Translation,
    TokenService,
    Settings,
    Backbone,
    App,
    Polyglot,
    bootbox
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        ui: {
            translation: '.progress-bar-translation',
            global: '.progress-bar-global',
            settings: '.progress-bar-setting',
            progressTranslation: '.progress-bar-translation .progress-bar',
            progressGlobal: '.progress-bar-global .progress-bar',
            progressSettings: '.progress-bar-setting .progress-bar',
            progressTranslationText: '.progress-bar-translation h3',
            progressGlobalText: '.progress-bar-global h3',
            progressSettingsText: '.progress-bar-setting h3',
            progressGlobalDetails: '.progress-bar-global .details',
            progressSettingsDetails: '.progress-bar-setting .details'
        },

        className: 'application-load',

        serializeData: function () {
            var templateData = {};
            templateData.folder = '';
            if (Settings.get('folder')) {
                templateData.folder = Settings.get('folder');
                if (!templateData.folder.endsWith('/')) {
                    templateData.folder += '/';
                }
            }
            return templateData;
        },

        onRender: function () {
            this.ui.translation.hide();
            this.ui.global.hide();
            this.ui.settings.hide();
            if (this.options.translate) {
                this.ui.translation.show();
                this.ui.global.show();
                setTimeout(_.bind(this.loadTranslate, this), 10);
            } else if (this.options.services) {
                this.ui.settings.show();
                setTimeout(_.bind(this.loadCAccountServices, this), 10);
            }
        },

        loadTranslate: function () {
            this.ui.settings.show();
            this.ui.progressTranslation.attr('aria-valuenow', 0);
            this.ui.progressTranslation.css('width', '0');
            Polyglot.prefix = Settings.get('application');
            var translationObj = {};
            App.services = [];
            App.services.push(Translation);
            Translation.findAll().done(_.bind(function (data) {
                data.each(_.bind(function (item) {
                    this._setTranslation(translationObj, item);
                }, this));
                Polyglot.extend(translationObj);

                bootbox.addLocale(Settings.get('lang'), {
                    OK: _.i18n('common.ok'),
                    CANCEL: _.i18n('common.cancel'),
                    CONFIRM: _.i18n('common.confirm')
                });
                bootbox.setLocale(Settings.get('lang'));
                this.ui.progressTranslation.attr('aria-valuenow', 100);
                this.ui.progressTranslation.css('width', '100%');
                this.ui.progressTranslation.text('100%');

                if (!$.jgrid.hasOwnProperty('regional')) {
                    $.jgrid.regional = {};
                }
                $.jgrid.regional.labels = {
                    defaults: {
                        recordtext: _.i18n('jqgrid.view') + ' {0} - {1} ' + _.i18n('jqgrid.of') + ' {2}',
                        emptyrecords: _.i18n('jqgrid.emptyrecords'),
                        loadtext: _.i18n('jqgrid.loadtext'),
                        pgtext: _.i18n('jqgrid.pgtext') + ' {0} ' + _.i18n('jqgrid.of') + ' {1}',
                        savetext: _.i18n('jqgrid.savetext'),
                        pgfirst: _.i18n('jqgrid.pgfirst'),
                        pglast: _.i18n('jqgrid.pglast'),
                        pgnext: _.i18n('jqgrid.pgnext'),
                        pgprev: _.i18n('jqgrid.pgprev'),
                        pgrecs: _.i18n('jqgrid.pgrecs'),
                        selectAllLines: _.i18n('jqgrid.selectAllLines'),
                        selectLine: _.i18n('jqgrid.selectLine')
                    },
                    search: {
                        resetTitle: _.i18n('jqgrid.resetTitle'),
                        odata: [{
                            oper: 'eq', text: 'equal'
                        }, {
                            oper: 'ne', text: 'not equal'
                        }, {
                            oper: 'lt', text: 'less'
                        }, {
                            oper: 'le', text: 'less or equal'
                        }, {
                            oper: 'gt', text: 'greater'
                        }, {
                            oper: 'ge', text: 'greater or equal'
                        }, {
                            oper: 'bw', text: 'begins with'
                        }, {
                            oper: 'bn', text: 'does not begin with'
                        }, {
                            oper: 'in', text: 'is in'
                        }, {
                            oper: 'ni', text: 'is not in'
                        }, {
                            oper: 'ew', text: 'ends with'
                        }, {
                            oper: 'en', text: 'does not end with'
                        }, {
                            oper: 'cn', text: 'contains'
                        }, {
                            oper: 'nc', text: 'does not contain'
                        }, {
                            oper: 'nu', text: 'is null'
                        }, {
                            oper: 'nn', text: 'is not null'
                        }, {
                            oper: 'bt', text: 'between'
                        }]
                    },
                    formatter: {
                        integer: {thousandsSeparator: ',', defaultValue: '0'}
                    },
                    nav: {
                        edittext: '',
                        edittitle: 'Edit selected row',
                        addtext: '',
                        addtitle: 'Add new row',
                        deltext: '',
                        deltitle: 'Delete selected row',
                        searchtext: '',
                        searchtitle: 'Find records',
                        refreshtext: '',
                        refreshtitle: _.i18n('reset.filter'),
                        alertcap: 'Warning',
                        alerttext: 'Please, select row',
                        viewtext: '',
                        viewtitle: 'View selected row',
                        savetext: '',
                        savetitle: 'Save row',
                        canceltext: '',
                        canceltitle: 'Cancel row editing',
                        selectcaption: 'Actions...'
                    }
                };

                $.extend($.jgrid, $.jgrid.regional.labels);

                TokenService.findBy().done(_.bind(function (model) {
                    require(['router', 'servicesGlobal', 'servicesAdmin'], _.bind(function (router, servicesGlobal, servicesAdmin) {
                        setTimeout(_.bind(function () {
                            Settings.set('currentCAccount', model.caccount);
                            Settings.set('loginTo', model.loginTo);
                            if (!model.caccount) {
                                this.navigate('select/caccount', {trigger: true});
                            } else {
                                var globalServices = this.filterService(servicesGlobal.getServices().concat(servicesAdmin.getServices()));
                                this.loadGlobalServices(globalServices);
                            }
                        }, this), 1000);
                    }, this));
                }, this));
            }, this));
        },

        _setTranslation: function (translationObj, tObject) {
            var code, patt;

            if (tObject && tObject.get('code') && tObject.get('values')) {
                patt = Settings.get('application') + '.';
                code = tObject.get('code').replace(patt, '');

                tObject.get('values').each(function (text) {
                    if (!translationObj[text.get('locale')]) {
                        translationObj[text.get('locale')] = {};
                    }
                    if (text.get('value')) {
                        translationObj[text.get('locale')][code] = text.get('value');
                    }
                });

                //if the localized is not available for a text we add the localized with the english translation
                var localeText = tObject.get('values').find(function (text) {
                    return text.get('locale') === Settings.get('lang');
                });
                if (!localeText || !localeText.get('value')) {
                    if (!translationObj[Settings.get('lang')]) {
                        translationObj[Settings.get('lang')] = {};
                    }
                    var enText = tObject.get('values').find(function (text) {
                        return text.get('locale') === 'en';
                    });
                    if (enText) {
                        translationObj[Settings.get('lang')][code] = enText.get('value');
                    }
                }
            }
        },
        filterService: function (services) {
            // remove all service doesn't contain the function "findAll"
            services = _.filter(services, function (service) {
                return service.findAll;
            });
            // remove all service have the name with "run" or "well" or "attachment"
            services = _.filter(services, function (service) {
                try {
                    var name = service.getName();
                    if (name.toLowerCase().match(/file|connectorhl7tracing|caccount|platesize|lisrecord|run|well/) ||
                        name.toLowerCase().match(/attachment|order|routes|text|sample$/) ||
                        name.toLowerCase().match(/arrayelement|codelistelement|assayreagent|assayreagenttargetpcrkitlot/) ||
                        name.toLowerCase().match(/displaymodeconfiguration|extractionreagent|kitprotconfiguration|pcrreagent|dynamic|textslave/) ||
                        name.toLowerCase().match(/routingactionroutinggroup|routingactionstate|notification/) ||
                        name.toLowerCase().match(/dataconnectionall|cyclerpublicsfromcycler|log/) ||
                        name.toLowerCase().match(/assayresultversion|colorcompensationmatrice|curve|extractionmethodreagent|tracs/)) {
                        return false;
                    }
                } catch (e) {
                    var extra;
                    if (service.getNamespace) {
                        extra = service.getNamespace().model.prototype.service;
                    }
                    console.log('Error name for ' + extra);
                    return false;
                }
                try {
                    service.getUrl();
                } catch (e) {
                    console.log('Error URL for ' + service.getName());
                    return false;
                }
                try {
                    service.getNamespace();
                } catch (e) {
                    console.log('Error namespace for ' + service.getName());
                    return false;
                }
                try {
                    var namespace = service.getNamespace();
                    if (!namespace) {
                        throw new Error('namespace is not defined for ' + service.getName());
                    }
                    if (!namespace.model) {
                        throw new Error('model is not defined for ' + service.getName());
                    }
                    if (!namespace.collection) {
                        throw new Error('collection is not defined for ' + service.getName());
                    }
                } catch (e) {
                    console.log(e);
                    return false;
                }

                if (!service.findAll) {
                    throw new Error('findAll is not defined for ' + service.getName());
                }
                return true;
            });
            return services;
        },

        loadGlobalServices: function (services) {
            this.loadService('Global', services, _.bind(this.loadCAccountServices, this));
        },

        loadCAccountServices: function () {
            require(['services', 'servicesCaccounts'], _.bind(function (services, servicesCaccounts) {
                var caccountServices = this.filterService(services.getServices().concat(servicesCaccounts.getServices()));
                this.loadService('Settings', caccountServices, this.navigate);
            }, this));
        },

        loadService: function (name, services, callback) {
            if (!App.services) {
                App.services = [];
            }
            // add services to App.services if not already present
            _.each(services, function (service) {
                if (!App.services[service.getName()]) {
                    App.services[service.getName()] = service;
                }
            });
            if (!this.originalWarn) {
                this.originalWarn = console.warn;
            }

            console.warn = _.bind(function () {
                this.originalWarn.apply(console, arguments);
                var message = arguments[0];
                var user = Settings.get('currentUserModel');
                if (message.startsWith('WARNING: Missing translation for key:')) {
                    var key = message.replaceAll('WARNING: Missing translation for key:', '').replaceAll('"', '').substring(4).trim();
                    if (user && user.get('superUser')) {
                        user.get('notifications').push({
                            id: 'missing_translation#' + key,
                            context: 'translation',
                            message: message,
                            action: function (jqGrid) {
                                require(['translationsTableController'], _.bind(function (Controller) {
                                    Controller.getLanguages().done(_.bind(function (languages) {
                                        var values = languages
                                            .chain()
                                            .filter(function (language) {
                                                return language.get('checked');
                                            })
                                            .map(function (language) {
                                                return {locale: language.get('abbreviation'), value: ''};
                                            }).value();
                                        var service = require('services/global/text');
                                        var model = service.getModel({code: key, values: values});
                                        Controller.showDetails({
                                            model: model,
                                            languages: languages,
                                            callBackOnClose: function () {
                                                user.get('notifications').remove(user.get('notifications').get('missing_translation#' + key));
                                                jqGrid.reloadGrid();
                                            }
                                        });
                                    }, this));
                                }, this));
                            }
                        });
                    }
                }
            }, this);

            var progressName = 'progress' + name;
            var progressNameDetails = 'progress' + name + 'Details';

            this.ui[progressName].attr('aria-valuenow', 0);
            this.ui[progressName].css('width', '0');

            var progress = 0;
            var step = 100 / (services.length - 1);
            var i = 0;
            this.ui[progressNameDetails].append('Loading data...<br/>');


            _.each(services, _.bind(function (service) {
                var collection = Backbone.Relational.store.getCollection(service.getNamespace().model);
                collection.reset();
                service.findAll()
                    .done(_.bind(function (items) {
                        this.ui[progressNameDetails].append(service.getName() + ' : loaded (' + items.length + ')<br/>');
                    }, this))
                    .fail(_.bind(function () {
                        this.ui[progressNameDetails].append('<span style="color: red;">' + service.getName() + ' : ERROR<br/></span>');
                    }, this))
                    .always(_.bind(function () {
                        progress = step * i++;
                        this.ui[progressName].attr('aria-valuenow', progress);
                        this.ui[progressName].css('width', progress + '%');
                        this.ui[progressName].text(Math.floor(progress) + '%');
                        if (i === services.length) {
                            this.ui[progressNameDetails].append('Data loaded');
                            this.ui[progressNameDetails].scrollTop(this.ui[progressNameDetails][0].scrollHeight);
                            if (callback) callback.call(this);
                        } else {
                            this.ui[progressNameDetails].scrollTop(this.ui[progressNameDetails][0].scrollHeight);
                        }
                    }, this));
            }, this));
        },

        navigate: function () {
            TokenService.findBy().done(_.bind(function (model) {
                Settings.set('currentUserModel', require('services/caccounts/userpids').getModel(model.userpid));
                if (!Backbone.History.started) {
                    Backbone.history.start();
                } else {
                    App.regions.getRegion('main').empty();
                    App.navigate('', {trigger: true});
                }
            }, this));
        }
    });
});

