define('prepRunDisplayKitsLotsView',[
    'module',
    'backbone.marionette',
    'backbone',
    'dialogFormView',
    'underscore',
    'jquery',
    'app',
    'savingBehavior',
    'template!prepRunDisplayKitsLotsView',
    'tableView'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    _,
    $,
    App,
    SavingBehavior,
    WellCommentTpl,
    TableView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: WellCommentTpl,

        behaviors: {
            Saving: {behaviorClass: SavingBehavior}
        },

        ui: {
            cancel: '.js-cancel-popup'
        },

        className: 'd-f column',

        events: {
            'click .js-cancel-popup': 'onCancel'
        },

        regions: {
            extractionKitLot: '.js-extractionKitLot',
            kitLotByKits: '.js-kitLotByKits'
        },

        serializeData: function () {
            var templateData = {
                pos: this.model.get('pos'),
                smpId: this.model.get('smpId') ? {
                    name: this.model.get('smpId').get('name'),
                    code: this.model.get('smpId').get('code')
                } : {name: '', code: ''}
            };
            return templateData;
        },

        onRender: function () {
            var extractionKitLots = new Backbone.Collection(this.model.get('run').get('extractionDetails').getExtractionKitLots());

            var extractionKitLotColumns = [
                {attribute: 'reagent.type', label: _.i18n('extraction.reagent.reagentType'), className: 'col-xs-2'},
                {attribute: 'kitCode', label: _.i18n('extraction.reagent.kit'), className: 'col-xs-3'},
                {attribute: 'kitLotCode', label: _.i18n('extraction.reagent.litlot'), className: 'col-xs-3'},
                {attribute: 'volumeByTest', label: _.i18n('extraction.reagent.vol.test'), className: 'col-xs-2'},
                {attribute: 'volumeDeath', label: _.i18n('extraction.reagent.vol.death'), className: 'col-xs-2'}
            ];

            this.showChildView('extractionKitLot', new TableView({
                collection: extractionKitLots,
                columns: extractionKitLotColumns
            }));


            var kits = _.uniq(this.model.get('pcrKitLots').pluck('kit'), false, function (kit) {
                return kit.get('id');
            });
            var kitLotByKits = [];
            _.each(kits, _.bind(function (kit) {
                var pcrKitLots = this.model.get('pcrKitLots').chain().filter(function (pcrKitLot) {
                    return pcrKitLot.get('kit') === kit;
                }).map(function (pcrKitLot) {
                    return pcrKitLot.get('code');
                }).value();
                kitLotByKits.push({
                    pcrKit: kit.get('code'),
                    pcrKitLots: pcrKitLots
                });
            }, this));

            var kitLotByKitsColumns = [
                {attribute: 'pcrKit', label: _.i18n('kitLotByKits.pcrKits'), className: 'col-xs-2'},
                {attribute: 'pcrKitLots', label: _.i18n('kitLotByKits.pcrKitLots'), className: 'col-xs-10'}
            ];

            this.showChildView('kitLotByKits', new TableView({
                collection: new Backbone.Collection(kitLotByKits),
                columns: kitLotByKitsColumns
            }));

            this.triggerMethod('enable:cancel:confirm');
        },

        onSave: function () {
            this.triggerMethod('saved');
            this.box.modal('hide');
        },

        onCancel: function () {
            this.box.modal('hide');
        }
    });
});
