define('savingBehavior',[
    'module',
    'backbone.marionette',
    'underscore',
    'bootbox'
], function (
    module,
    Marionette,
    _,
    Bootbox
) {
    'use strict';

    module.exports = Marionette.Behavior.extend({
        ui: {
            cancel: '.js-cancel',
            confirm: '.js-confirm',
            label: '.saving-label',
            errorLabel: '.error-label',
            errorMsg: '.error-msg'
        },

        events: {
            'keyup @ui.input': 'onEnableCancelConfirm'
        },

        modelEvents: {
            request: 'onSaving',
            error: 'onError'
        },

        initialize: function () {
            this.listenTo(this.view, 'render', this.onRenderCompleteOnce);
        },
        _getFieldsToValidate: function () {
            var fieldsToValidate = this.view.fieldsToValidate;
            if (_.isFunction(fieldsToValidate)) {
                fieldsToValidate = fieldsToValidate();
            }
            return fieldsToValidate;
        },
        onRenderCompleteOnce: function () {
            var fieldsToValidate = this._getFieldsToValidate();
            _.each(fieldsToValidate, _.bind(function (field) {
                if (field.type === 'required') {
                    this.listenTo(this.view.model, 'change:' + field.name, _.bind(this.onEnableCancelConfirm, this));
                }
            }, this));
            this.stopListening(this.view, 'render');
        },

        onSaved: function () {
            this.ui.label.text(_.i18n('common.saved'));
            setTimeout(_.bind(function () {
                this.onHideLabel();
            }, this), 1000);
        },

        onHideLabel: function () {
            // We need this check in case when label element was destroyed
            // For example redirection from the page.
            if (this.ui.label.fadeOut) {
                this.ui.label.fadeOut();
            }
        },

        onEnableCancelConfirm: function (dontShowError) {
            if (!this.ui.cancel || this.ui.cancel.length === 0) {
                console.warn('Cancel button not found');
            }
            if (!this.ui.confirm || this.ui.confirm.length === 0) {
                console.warn('Confirm button not found');
            }
            var fieldsToValidate = this._getFieldsToValidate();
            if (this.view.validate(fieldsToValidate, dontShowError)) {
                this.ui.cancel.prop('disabled', false);
                this.ui.confirm.prop('disabled', false);
            }
        },

        onSaving: function () {
            this.ui.errorLabel.hide();
            this.ui.errorMsg.empty();
            this._showMessage(_.i18n('common.saving'));
            this._disableCancelConfirm();
        },

        onError: function (model, response) {
            this.onEnableCancelConfirm();
            if (response.status === 405) {
                this.onHideLabel();
            } else {
                this.onHideLabel();
                Bootbox.alert({
                    title: response.status + ' - ' + response.statusText,
                    message: response.responseText ? response.responseText : _.i18n('common.error')
                });
            }
        },

        _showMessage: function (message) {
            this.ui.label.text(message);
            this.ui.label.fadeIn();
        },

        _disableCancelConfirm: function () {
            this.ui.cancel.prop('disabled', true);
            this.ui.confirm.prop('disabled', true);
        }
    });
});

