define('entities/caccounts/lisRecords',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'entities/caccounts/lisRecordQueries'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.LisRecord = Backbone.RelationalModel.extend({
        service: 'services/caccounts/lisRecords',
        fetch: function () {
            this.url = Settings.url('rest/v2/export/result/ASTM/' + this.get('id'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            namePCRPlate: '',
            createdDate: '',
            server: {},
            wellSecId: '',
            sampleId: '',
            type: null,
            queries: [],
            targetCode: ''
        },
        idAttribute: 'id',
        relations: [{
            type: Backbone.HasMany,
            key: 'queries',
            relatedModel: 'LisRecordQuery',
            collectionType: 'LisRecordQueryCollection',
            reverseRelation: {
                key: 'lisRecord',
                includeInJSON: false
            }
        }],


        postUrl: function () {
            return Settings.url('rest/v2/export');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'LisRecordJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'LisRecord',

        importable: false
    });
    
    app.LisRecordCollection = Backbone.Collection.extend({
        model: app.LisRecord
    });

    app.LisRecord.role = RolesMixin.LIS;
});

