define('pcrSetupAssayCollection',[
    'module',
    'backbone',
    'backbone.marionette',
    'pcrSetupAssayItem',
    'underscore',
    'template!pcrSetupAssayCollection'
], function (
    module,
    Backbone,
    Marionette,
    Item,
    _,
    Tpl
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        template: Tpl,
        childView: Item,
        attributes: {
            style: 'width: 100%;'
        },
        clearSelection: function () {
            _.each(this.children._views, function (view) {
                view.clearSelection();
            });
        },
        childViewOptions: function () {
            return {
                pcrSetup: this.model
            };
        }
    });
});
