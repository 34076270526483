define('orderCreateEditLayoutView',[
    'module',
    'backbone.marionette',
    'backbone',
    'dialogFormView',
    'template!orderCreateEditLayoutTpl',
    'savingBehavior',
    'bootbox',
    'moment',
    'orderSampleCollection',
    'underscore'
], function (
    module,
    Marionette,
    Backbone,
    DialogFormView,
    createEditLayoutTpl,
    SavingBehavior,
    bootbox,
    moment,
    OrderSampleCollection,
    _
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: createEditLayoutTpl,

        ui: {
            confirm: '.js-confirm'
        },
        events: {
            'click @ui.confirm': 'onConfirm',
            'click .js-cancel-popup': 'onCancel'
        },
        regions: {
            samples: '.js-samples'
        },

        modelEvents: {
            sync: 'onSave',
            change: 'render'
        },

        fieldsToValidate: [
            {name: 'code', type: 'required'}
        ],

        serializeData: function () {
            var templateData = {};
            templateData.code = this.model.get('code');
            templateData.created = moment(this.model.get('creatDate')).format('YYYY-MM-DD HH:mm');
            templateData.specieCode = this.model.get('specie') ? this.model.get('specie').get('code') : '-';
            templateData.untilValidated = this.model.get('expirationDate') ? moment(this.model.get('expirationDate')).format('YYYY-MM-DD HH:mm') : '-';
            templateData.patient = this.model.get('patientInformation') ? this.model.get('patientInformation') : '-';
            return templateData;
        },

        onRender: function () {
            var view = new OrderSampleCollection({
                collection: this.model.get('samples'),
                model: this.model
            });
            this.getRegion('samples').show(view);
            view.listenTo(view, 'childChange', _.bind(this.onChangeChild, this));
            this.triggerMethod('enable:cancel:confirm');
        },
        onChangeChild: function () {
            this.ui.confirm.prop('disabled', false);
        },

        onConfirm: function () {
            this.model.save();
        },

        onSave: function () {
            this.box.modal('hide');
        },

        onCancel: function () {
            this.box.modal('hide');
        }
    });
});
