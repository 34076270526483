define('prepRunCreatedPcrFromPrepView',[
    'module',
    'backbone',
    'app',
    'dialogFormView',
    'template!prepRunCreatedPcrFromPrepTpl',
    'underscore',
    'jquery',
    'bootbox'
], function (
    module,
    Backbone,
    App,
    DialogFormView,
    tpl,
    _,
    $,
    Bootbox
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        ui: {
            checkboxPrintPcrSetup: '.js-created-pcrRun-printPcrSetup',
            checkboxPrintPcrSetupMMX: '.js-created-pcrRun-printPcrSetupMMX',
            checkboxExportPlateLayout: '.js-created-pcrRun-exportPlateLayout',
            checkboxExportPlateLayoutLocal: '.js-created-pcrRun-exportPlateLayout-local'
        },

        events: {
            'click .js-confirm': 'onConfirm',
            'click @ui.checkboxExportPlateLayout': 'onCheckboxExportPlateLayoutClick'
        },

        onCheckboxExportPlateLayoutClick: function (e) {
            if (e.currentTarget.checked) {
                this.ui.checkboxExportPlateLayoutLocal.prop('disabled', false);
            } else {
                this.ui.checkboxExportPlateLayoutLocal.prop('disabled', true);
                this.ui.checkboxExportPlateLayoutLocal.attr('checked', false);
            }
        },

        serializeData: function () {
            var templateData = {};
            templateData.name = this.model.get('pcrRun').get('code');
            templateData.prepRunName = this.model.get('prepRun').get('code');
            templateData.plateId = this.model.get('pcrRun').get('plateIdPC');
            templateData.protocol = this.model.get('pcrRun').get('refKitProt').get('code');
            templateData.cycler = this.model.get('pcrRun').get('refCyclerPublic').get('code');
            templateData.assays = _.map(this.model.get('assayLines'), function (assayLine) {
                return {
                    assay: {
                        code: assayLine.assay.get('code')
                    },
                    countByType: assayLine.countByType,
                    volumeBySample: assayLine.volumeBySample
                };
            });
            templateData.displayLocalDownload = this.options.exportPlateLayoutSetted;
            templateData.pcrExportPlateLayout = this.options.pcrExportPlateLayout;
            templateData.printPcrSetup = this.options.printPcrSetup;
            templateData.printPcrSetupMMX = this.options.printPcrSetupMMX;
            return templateData;
        },

        onRender: function () {
            this.triggerMethod('enable:cancel:confirm');
        },

        onConfirm: function () {
            var PcrRunService = require('services/caccounts/pcrruns');
            if (this.ui.checkboxExportPlateLayout.prop('checked')) {
                var pcrRunModel = PcrRunService.getModel(this.model.get('pcrRun'));
                var downloadFile = this.ui.checkboxExportPlateLayoutLocal.prop('checked');
                pcrRunModel.exportPlateLayout({downloadFile: downloadFile}).done(function (isFile, fileName, fileContent) {
                    if (isFile) {
                        var element = document.createElement('a');
                        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(fileContent));
                        element.setAttribute('download', fileName);

                        element.style.display = 'none';
                        document.body.appendChild(element);

                        element.click();

                        document.body.removeChild(element);
                    } else {
                        Bootbox.alert({
                            message: _.i18n('pcrSetupCreated.plateLayoutExported'),
                            backdrop: true,
                            className: 'success'
                        });
                    }
                });
            }
            if (this.ui.checkboxPrintPcrSetup.prop('checked')) {
                PcrRunService.generatePcrSetup(this.model.get('pcrRun').id);
            }
            if (this.ui.checkboxPrintPcrSetupMMX.prop('checked')) {
                PcrRunService.generatePcrSetupMMX(this.model.get('pcrRun').id);
            }
            this.box.modal('hide');
        }
    });
});
