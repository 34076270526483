define('entities/caccounts/cyclers',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'entities/admin/cyclers',
    'entities/caccounts/setting',
    'entities/caccounts/assay',
    'entities/caccounts/lmbcyclers'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.Cycler = Backbone.RelationalModel.extend({
        service: 'services/caccounts/cyclers',
        fetch: function () {
            this.url = Settings.url('rest/v2/cyclers/' + this.get('id'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': null,
            'name': null,
            'description': null,
            'comment': null,
            tags: [],
            'refCycler': null,
            'colorCompensation': false,
            'assays': [],
            'lmbCyclers': []
        },
        idAttribute: 'id',
        initialize: function () {
            this.set('tags', []);
        },
        relations: [{
            type: Backbone.HasMany,
            key: 'lmbCyclers',
            relatedModel: 'LmbCycler',
            collectionType: 'LmbCyclerCollection'
        }, {
            type: Backbone.HasMany,
            key: 'assays',
            relatedModel: 'Assay',
            collectionType: 'AssayCollection',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'refCycler',
            relatedModel: 'CyclerPublic',
            includeInJSON: ['id', 'code', 'name']
        }],
        
        postUrl: function () {
            return Settings.url('rest/v2/cyclers/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'CyclerJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Cycler',

        importable: true
    });

    app.Cycler.role = RolesMixin.CYCLER;

    app.CyclerCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/cyclers/');
        },
        model: app.Cycler
    });

});

