define('services/global/caccounts',[
    'entities/ns',
    'jquery',
    'underscore',
    'settings',
    'module',
    'dynamic',
    'bootbox',
    'backbone',
    'backboneRelational',
    'entities/global/caccounts'
], function (
    app,
    $,
    _,
    Settings,
    module,
    Dynamic,
    Bootbox,
    Backbone
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.CAccount,
                collection: app.CAccountCollection
            };
        },
        getName: function () {
            return 'caccount';
        },
        getUrl: function () {
            return 'rest/v2/caccounts/';
        },
        getDynamicJson: function () {
            return 'CAccountJson';
        },
        getTypeDynamicJson: function () {
            return 'GLOBAL';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.exportable = false;
            config.archivable = false;
            config.selectArchivable = false;
            config.importable = false;
            config.attachmentable = false;
            config.selectable = false;
            config.deletable = false;
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.delete'),
                name: 'delete',
                classes: 'dynamic-link',
                formatter: viewDynamic.deleteIconFormatter,
                width: 10,
                search: false,
                onClick: _.bind(this.deleteCaccountClick, this)
            }, {
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.id'),
                name: 'id',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 15
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            }, {
                label: _.i18n('common.modules'),
                name: 'modules',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultListFormatter,
                search: false
            }, {
                label: _.i18n('caccount.hl7port'),
                name: 'hl7port',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 20
            }, {
                label: _.i18n('caccount.databaseStatus'),
                name: 'databaseMigrated',
                classes: 'dynamic-link',
                formatter: _.bind(this.databaseStatus, this),
                search: false,
                width: 20
            }, {
                label: _.i18n('caccount.databaseSizeInBytes'),
                name: 'databaseSizeInBytes',
                classes: 'dynamic-link',
                formatter: _.bind(this.databaseSizeInBytes, this),
                search: false,
                width: 20
            }, {
                label: _.i18n('caccount.createDatabase'),
                name: 'databaseExist',
                classes: 'dynamic-link',
                formatter: _.bind(this.createDatabase, this),
                search: false,
                width: 20,
                onClick: _.bind(this.createDatabaseClick, this)
            }, {
                label: _.i18n('caccount.exportDatabase'),
                name: 'databaseExist',
                classes: 'dynamic-link',
                formatter: _.bind(this.exportDatabase, this),
                search: false,
                width: 20,
                onClick: _.bind(this.exportDatabaseClick, this)
            }, {
                label: _.i18n('caccount.importDatabase'),
                name: 'databaseExist',
                classes: 'dynamic-link',
                formatter: _.bind(this.importDatabase, this),
                search: false,
                width: 20,
                onClick: _.bind(this.importDatabaseClick, this)
            }, {
                label: _.i18n('caccount.deleteDatabase'),
                name: 'databaseExist',
                classes: 'dynamic-link',
                formatter: _.bind(this.deleteDatabase, this),
                search: false,
                width: 20,
                onClick: _.bind(this.deleteDatabaseClick, this)
            });
            return columns;
        },

        databaseStatus: function (cellValue, options) {
            if (cellValue) {
                cellValue = '<span class="mdi mdi-check"></span>';
            } else {
                cellValue = '<span class="mdi mdi-close-circle"></span>';
            }

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },

        createDatabase: function (cellValue, options) {
            if (!cellValue) {
                cellValue = '<span class="mdi mdi-database-plus clickable"></span>';
            } else {
                cellValue = '';
            }

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },

        exportDatabase: function (cellValue, options) {
            if (cellValue) {
                cellValue = '<span class="mdi mdi-database-export clickable"></span>';
            } else {
                cellValue = '';
            }

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },

        importDatabase: function (cellValue, options) {
            cellValue = '<span class="mdi mdi-database-import clickable"></span>';

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },

        deleteDatabase: function (cellValue, options) {
            if (cellValue) {
                cellValue = '<span class="mdi mdi-database-remove clickable"></span>';
            } else {
                cellValue = '';
            }

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },

        databaseSizeInBytes: function (cellValue, options) {
            if (cellValue) {
                cellValue = this._formatBytes(cellValue);
            } else {
                cellValue = 'N/A';
            }

            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + cellValue + '</span>';
        },

        _formatBytes: function (bytes) {
            if (bytes === 0) return '0 Bytes';

            var k = 1024;
            var dm = 2;

            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            var i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },

        createDatabaseClick: function (rowId, columnId, cellValue, event, jqGrid) {
            var loader = $('.js-global-loader');
            loader.show();
            var model = this.getModel({id: rowId});
            model.createDatabase().done(function () {
                jqGrid.trigger('reloadGrid');
            }).always(function () {
                loader.hide();
            });
        },

        exportDatabaseClick: function (rowId, columnId, cellValue, event, jqGrid) {
            var loader = $('.js-global-loader');
            loader.show();
            var model = this.getModel({id: rowId});
            model.exportDatabase().done(function () {
                jqGrid.trigger('reloadGrid');
            }).always(function () {
                loader.hide();
            });
        },
        deleteDatabaseClick: function (rowId, columnId, cellValue, event, jqGrid) {
            Bootbox.prompt({
                title: 'Write DELETE to confirm',
                centerVertical: true,
                callback: _.bind(function (result) {
                    if (result === 'DELETE') {
                        var loader = $('.js-global-loader');
                        loader.show();
                        var model = this.getModel({id: rowId});
                        model.deleteDatabase().done(function () {
                            jqGrid.trigger('reloadGrid');
                        }).always(function () {
                            loader.hide();
                        });
                    } else {
                        Bootbox.alert('!!! Database NOT deleted !!!');
                    }
                }, this)
            });
        },
        deleteCaccountClick: function (rowId, columnId, cellValue, event, jqGrid) {
            Bootbox.prompt({
                title: 'Write DELETE to confirm',
                centerVertical: true,
                callback: _.bind(function (result) {
                    if (result === 'DELETE') {
                        var loader = $('.js-global-loader');
                        loader.show();
                        this.deleteEntity(rowId).done(function () {
                            jqGrid.trigger('reloadGrid');
                        }).always(function () {
                            loader.hide();
                        });
                    } else {
                        Bootbox.alert('!!! CAccount NOT deleted !!!');
                    }
                }, this)
            });
        },
        importDatabaseClick: function (rowId) {

            // Créer dynamiquement un input de type fichier
            var fileInput = document.createElement('input');
            fileInput.type = 'file';
            fileInput.style.display = 'none';
            fileInput.accept = '.zip';

            // Ajouter le champ de fichier à votre body
            document.body.appendChild(fileInput);

            // Attacher un gestionnaire d'événements pour le changement de fichier sélectionné
            fileInput.addEventListener('change', function (event) {
                var selectedFile = event.target.files[0];

                // Supprimer l'élément input après avoir traité le fichier
                document.body.removeChild(fileInput);

                // Vérifier si un fichier a été sélectionné
                if (selectedFile) {
                    var model = this.getModel({id: rowId});
                    model.importDatabase(selectedFile);
                }
            }.bind(this)); // Assurez-vous que "this" fait référence à votre objet actuel

            // Simuler un clic sur le champ de fichier
            fileInput.click();

        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },

        getCountries: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/countries/');

            $.ajax({
                url: url,
                success: function (data) {
                    defer.resolve(new Backbone.Collection(data));
                }
            });

            return defer.promise();
        },

        getModules: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/caccounts/modules');

            $.ajax({
                url: url,
                success: function (datas) {
                    defer.resolve(_.map(datas, function (data) {
                        return data.code;
                    }));
                }
            });

            return defer.promise();
        },
        getCAccountEntities: function () {
            var cAccounts = new app.CAccountCollection({}),
                defer = $.Deferred();

            cAccounts.url = Settings.url('rest/v2/caccounts/logged');
            cAccounts.fetch({
                success: function (data) {
                    defer.resolve(data);
                }
            });

            return defer.promise();
        },

        postCAccount: function (id) {
            var url = Settings.url('rest/v2/sessions.json/token/byuser/' + id),
                defer = $.Deferred();

            $.ajax({
                type: 'POST',
                url: url,
                success: function (data) {
                    defer.resolve(data);
                }
            });

            return defer.promise();
        },
        canAddOrEdit: function () {
            return Settings.get('currentUserModel') && Settings.get('currentUserModel').hasRole(this.getNamespace().model.role);
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push({'field': 'hl7port', 'param': {'type': 'NUMBER'}});
            return custom;
        },
        showDetails: function (param) {
            param.service = this;
            require(['caccountsController'], _.bind(function (Controller) {
                param.controller = Controller;
                this._showDetails(param);
            }, this));
        }
    }, Dynamic);
});

