define('qcChartPopup',[
    'module',
    'backbone.marionette',
    'jquery',
    'assayReagentMbAnaPcrKitLotByPcrWell',
    'wellUtils'
], function (
    module,
    Marionette,
    $,
    ItemView,
    WellUtils
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        childView: ItemView,
        serializeData: function () {
            return {};
        },

        attributes: function () {
            return {
                style: 'overflow-y: auto; overflow-x: hidden; max-height:' + ($(window).height() - 150) + 'px; padding-right: 10px;'
            };
        },
        childViewOptions: function () {
            return {
                wellModel: this.options.wellModel,
                smpTypeIcon: WellUtils.getPcrWellSmpTypeIcon(this.options.wellModel.get('smpType'))
            };
        }
    });
});
