define('entities/caccounts/displayModes',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.DisplayMode = Backbone.RelationalModel.extend({
        service: 'services/caccounts/displayModes',
        fetch: function () {
            this.url = Settings.url('rest/v2/displayModes/' + this.get('id'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'displayTooltip': 'LIST',
            'displayValidation': 'TARGET',
            'byDefault': false,
            'configurations': [],
            'kitProts': [],
            'assays': []
        },
        idAttribute: 'id',
        relations: [{
            type: Backbone.HasMany,
            key: 'configurations',
            relatedModel: 'DisplayModeConfiguration',
            collectionType: 'DisplayModeConfigurationCollection',
            reverseRelation: {
                key: 'displayMode',
                includeInJSON: ['id', 'code', 'name']
            }
        }, {
            type: Backbone.HasMany,
            key: 'kitProts',
            relatedModel: 'KitProt',
            collectionType: 'KitProtCollection',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'assays',
            relatedModel: 'Assay',
            collectionType: 'AssayCollection',
            includeInJSON: ['id', 'code', 'name']
        }],

        postUrl: function () {
            return Settings.url('rest/v2/displayModes/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'DisplayModeJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'DisplayMode',
        importable: true,
        findParameterForLocation: function (location) {
            switch (location) {
                case 'ValMini':
                    return app.DisplayModeConfiguration.findOrCreate({
                        'parameter': {
                            'type': 'DOT'
                        },
                        'location': 'ValMini',
                        'id': location
                    });
                case 'TooltipDot':
                    return app.DisplayModeConfiguration.findOrCreate({
                        'parameter': {
                            'type': 'DOT'
                        },
                        'location': 'TooltipDot',
                        'id': location
                    });
                default:
                    return this.get('configurations').findWhere({location: location});
            }
        },
        findDotForTooltip: function () {
            var configuration = this.get('configurations').find(function (configuration) {
                return configuration.get('location') === 'TooltipHistogram' && configuration.get('parameter') && configuration.get('parameter').type === 'DOT';
            });
            if (configuration) {
                return configuration;
            }
            return app.DisplayModeConfiguration.findOrCreate({
                'parameter': {
                    'type': 'DOT',
                    'param': 'true'
                },
                'location': 'TooltipHistogram',
                'id': location
            });
        }
    });

    app.DisplayMode.role = RolesMixin.DISPLAYMODE;

    app.DisplayModeCollection = Backbone.Collection.extend({
        model: app.DisplayMode,
        comparator: 'id'
    });
});

