define('runSampleListExportView',[
    'module',
    'backbone',
    'underscore',
    'jquery',
    'dialogFormView',
    'template!runSampleListExportView',
    'commonCancelExportView',
    'customBootboxMessage',
    'autocompleteView'
], function (
    module,
    Backbone,
    _,
    $,
    DialogFormView,
    Tpl,
    CommonCancelExportView,
    CustomBootboxMessage,
    AutocompleteView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input'
        },

        regions: {
            cancelExportRegion: '.js-cancel-export-region',
            selectFileFormat: '.js-select-fileFormat-region'
        },
        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },
        modelEvents: {
            'change:fileFormat': 'onValidateChange',
            'change:wellSelection': 'onValidateChange'
        },

        fieldsToValidate: function () {
            return [
                {name: 'fileFormat', type: 'required'}
            ];
        },

        initialize: function () {
            this.model = new Backbone.Model({fileFormat: null, wellSelection: null});
        },

        onValidateChange: function () {
            this.commonCancelExportView.enableButtons(this.validate(this.fieldsToValidate()));
        },

        onRender: function () {
            this.commonCancelExportView = new CommonCancelExportView({settingImport: this.options.settingImport});
            this.listenTo(this.commonCancelExportView, 'localExport:click', _.bind(this.onLocalExport, this));
            this.listenTo(this.commonCancelExportView, 'remoteExport:click', _.bind(this.onRemoteExport, this));
            this.listenTo(this.commonCancelExportView, 'cancel:click', _.bind(this.onCancel, this));
            this.getRegion('cancelExportRegion').show(this.commonCancelExportView);
            this.commonCancelExportView.enableButtons(false);

            var FileFormatSampleListService = require('services/caccounts/fileFormatSampleLists');
            var view = new AutocompleteView(
                this._getAutocompleteOptionObject(FileFormatSampleListService.getAutocompleteParamForExport({
                    modelProperty: 'fileFormat',
                    values: {
                        'prep': this.options.type === 'prep' ? true : null,
                        'pcr': this.options.type === 'pcr' ? true : null
                    }
                }))
            );
            this.getRegion('selectFileFormat').show(view);
        },

        onLocalExport: function () {
            this.model.set('downloadFile', true);
            this._export();
        },
        onRemoteExport: function () {
            this.model.set('downloadFile', false);
            this._export();
        },

        _export: function () {
            this.options.model.exportFileSampleList(this.model)
                .done(function (display) {
                    if (display) {
                        CustomBootboxMessage.customThumbUpNotification();
                    }
                })
                .fail(function () {
                    CustomBootboxMessage.customThumbDownNotification();
                })
                .always(_.bind(function () {
                    this.hide();
                }, this));
        }
    });
});
