/**
 * Created by RKL on 20/08/2015.
 */
define('autocompleteView',[
    'module',
    'backbone.marionette',
    'marionetteAutocomplete',
    'underscore',
    'jquery',
    'settings',
    'template!autocompleteView',
    'cookies',
    'bootbox',
    'backbone'
], function (
    module,
    Marionette,
    MarionetteAutocomplete,
    _,
    $,
    Settings,
    viewTpl,
    Cookies,
    Bootbox,
    Backbone
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: viewTpl,
        className: 'autocomplete-dropdown-container-css',
        attributes: {
            style: 'display: flex'
        },
        ui: {
            autocomplete: 'input',
            editRecord: '.js-edit-record',
            showRecord: '.js-show-record'
        },
        events: {
            'click @ui.editRecord': 'onEditRecord',
            'click @ui.showRecord': 'onEditRecord'
        },

        initialize: function () {
            this.listenTo(this, 'autocomplete:selected', _.bind(this.onAutocompleteSelect, this));
            this.listenTo(this, 'autocomplete:shown', _.bind(this.onAutocompleteShown, this));
            this.listenTo(this, 'autocomplete:active', _.bind(this.onAutocompleteActive, this));
        },

        onAutocompleteActive: function () {
            var element = this._behaviors[0].collectionView.$el;
            var elementActive = element.find('li.active');
            if (elementActive.length !== 0) {
                element.animate({
                    scrollTop: element.find('li.active').position().top - element.find('li:first').position().top
                }, 100);
            }
        },

        onAutocompleteShown: function () {
            this.ui.editRecord.hide();
        },
        behaviors: function () {
            var that = this,
                type = this.options.type || 'dataset',
                remote = this.options.remote || null,
                keys = this.options.keys || {},
                limit = this.options.limit || 20,
                values = this.options.values || {},
                rateLimit = limit;
            if (this.options.lazyLoad && this.options.data) {
                limit = this.options.data.length;
                rateLimit = limit;
            }
            _.each(values, function (value, key) {
                keys[key] = key;
            });
            values.apiKey = this.options.apiKey;
            values.limit = limit;

            if (this.options.readOnly) {
                return {};
            }

            var behavior = {
                AutoComplete: {
                    behaviorClass: MarionetteAutocomplete.Behavior,
                    rateLimit: rateLimit,
                    collection: {
                        options: {
                            minLength: -1,
                            type: type,
                            remote: remote,
                            method: this.options.method || 'GET',
                            keys: keys,
                            inputType: this.options.inputType,
                            data: this.options.data,
                            valueKey: this.options.valueKey,
                            lazyLoad: true,
                            values: values
                        }
                    }
                }
            };

            if (this.options.service && this.options.service.getModel) {
                behavior.AutoComplete.collection.model = this.options.service.getModel();
            }

            behavior.AutoComplete.collectionView = {
                'class': MarionetteAutocomplete.CollectionView.extend({
                    getTemplate: function () {
                        if (that.options.canAddOrEdit) {
                            return _.template('<div class="js-add add-content clickable border-bottom"><span class="mdi mdi-plus"></span>' + _.i18n('autocomplete.add') + '</div><ul></ul>');
                        }
                        return _.template('<ul></ul>');
                    },
                    events: {
                        'click .js-add': function () {
                            that.newEntity();
                        }
                    },
                    emptyView: Marionette.View.extend({
                        tagName: 'li',
                        template: _.template('<span>' + _.i18n('autocomplete.noSuggestionsAvailable') + '</span>')
                    }),
                    attributes: function () {
                        var attributes;
                        if (MarionetteAutocomplete.CollectionView.prototype.attributes) {
                            if (_.isFunction(MarionetteAutocomplete.CollectionView.prototype.attributes)) {
                                attributes = _.clone(MarionetteAutocomplete.CollectionView.prototype.attributes.apply(this, arguments));
                            } else {
                                attributes = _.clone(MarionetteAutocomplete.CollectionView.prototype.attributes);
                            }
                        }

                        if (that.options.fieldsToDisplay && that.options.fieldsToDisplay.fieldsWidth) {
                            // if attributes.style already exist, search and replace width else add width
                            if (attributes.style) {
                                attributes.style = attributes.style.replace(/width: [0-9]+(px|%);/, 'width: ' + that.options.fieldsToDisplay.fieldsWidth + 'px;');
                            } else {
                                attributes.style = 'width: ' + that.options.fieldsToDisplay.fieldsWidth + 'px;';
                            }
                        }
                        // add border to style
                        attributes.style += 'border: 1px solid #c6c6c6;';
                        return attributes;
                    }
                })
            };

            if (this.options.childTemplate) {
                behavior.AutoComplete.childView = {
                    'class': MarionetteAutocomplete.ChildView.extend({
                        getTemplate: function () {
                            if (!this.model.get('autocompleteValue')) {
                                this.model.set({autocompleteValue: 'Loading'});
                            }
                            return that.options.childTemplate;
                        },

                        serializeData: function () {
                            if (that.options.childViewSerializeData) {
                                return _.bind(that.options.childViewSerializeData, this)();
                            }
                            var jsonData = this.model.toJSON();

                            // Traitement pour échapper les caractères spéciaux
                            _.each(jsonData, function (value, key) {
                                if (_.isString(value)) {
                                    jsonData[key] = _.escape(value);
                                }
                            });

                            return jsonData;
                        }
                    })
                };
            }
            if (this.options.fieldsToDisplay) {
                behavior.AutoComplete.childView = {
                    'class': MarionetteAutocomplete.ChildView.extend({
                        getTemplate: function () {
                            if (this.model.get('autocompleteValue') === 'Loading') {
                                return _.template(
                                    '<a href="#"><%= autocompleteValue %></a>'
                                );
                            }

                            // prevent html display issue like for pattern userCodeList (ex: <D><P><G><S>)
                            var str = this.model.get('autocompleteValue');
                            if (str && (str.includes('<') || str.includes('>'))) {
                                str = str.replace(new RegExp(/</g, 'g'), '&lt;');
                                str = str.replace(new RegExp(/>/g, 'g'), '&gt;');
                            }
                            this.model.set('autocompleteValueToDisplay', str);

                            var label = '<span style="font-weight: bolder;color:black"><%= ' + that.options.valueProperty + ' %></span>';
                            var separator = '|';
                            var count = 0;
                            var colorClass;
                            var template = '';

                            this.$el.attr('title', this.model.get('description'));
                            this.$el.css('display', 'flex');

                            _.each(that.options.fieldsToDisplay.fields, _.bind(function (field, index) {
                                colorClass = (count % 2 === 0) ? 'autocompleteHighlightValueOdd' : 'autocompleteHighlightValue';
                                if (index !== 0) {
                                    label += '<span style="font-weight: bolder;color:black">' + separator + '</span>';
                                }

                                label += '<span class="' + colorClass + '">  ';

                                if (!field.type) {
                                    var subFields = field.split('.'), fullField = '', parent = '';
                                    if (subFields.length === 1) {
                                        fullField = field;
                                    } else {
                                        _.each(subFields, function (subField, index) {
                                            if (index > 0) {
                                                fullField += '.' + subField;
                                            } else {
                                                fullField = subField;
                                                parent = subField;
                                            }
                                        });
                                    }

                                    if (parent !== '') {
                                        label += '<%= (typeof ' + parent + ' !== undefined && ' + parent + ' !== null) ? ' + fullField + ': \'\'%>  ';
                                    } else {
                                        label += '<%= ' + fullField + ' %>';
                                    }
                                }

                                if (field.type) {
                                    switch (field.type) {
                                        case 'array[object]':
                                            var arrayObjFields, arrayObjFullField = '', arrayObjParentField = '';
                                            if (field.fields) {
                                                arrayObjFields = field.fields.split('.');
                                                _.each(arrayObjFields, function (field, index) {
                                                    if (index > 0) {
                                                        arrayObjFullField += '.' + field;
                                                    } else {
                                                        arrayObjFullField = field;
                                                        arrayObjParentField = field;
                                                    }
                                                });
                                                label +=
                                                    '<% _.each(' + field.property + ', function(obj, index) { %> ' +
                                                    '<% if(index !== 0){ %>' + ';' + '<% } %>' +
                                                    '<% if(obj.' + arrayObjParentField + '){ %>' +
                                                    '<%= obj.' + arrayObjFullField + '%>' +
                                                    '<% } %>' +
                                                    '<% }); %>';
                                            }
                                            break;

                                        case 'array[customAssayObject]':
                                            label +=
                                                '<% _.each(' + field.property + ', function(obj, index) { %> ' +
                                                '<% if(index !== 0){ %>' + ';&nbsp;' + '<% } %>' +
                                                '<% if(obj.assay){ %>' +
                                                '<%= obj.assay.code %>' +
                                                '<% if(obj.count){ %>' +
                                                '<%= \'&nbsp;(\' + obj.count + \')\'  %>' +
                                                '<% } %>' +
                                                '<% } %>' +
                                                '<% }); %>';
                                            break;

                                        case 'string':
                                            if (field.property &&
                                                field.property !== '') {
                                                label += '<%=' + field.property + '?' + field.property + ': \'\'%>  ';
                                            }
                                            break;

                                        case 'object.string':
                                            if (field.property && field.property !== '' &&
                                                field.fields) {
                                                var objStrPropertyFields = field.fields.split('.'),
                                                    objStrFields = '', request;
                                                _.each(objStrPropertyFields, function (field, index) {
                                                    if (index > 0) {
                                                        objStrFields += '.' + field;
                                                    } else {
                                                        objStrFields = field;
                                                    }
                                                });
                                                request = field.property + (objStrFields ? '.' + objStrFields : '');
                                                label += '<%= ' + field.property + ' ? ' + request + ': \'\'%>  ';
                                            }
                                            break;

                                        default:
                                    }
                                }

                                label += '</span>';
                                count++;
                            }, this));

                            template += '<span style="overflow:hidden;white-space:nowrap;text-overflow:ellipsis;"> ' +
                                label + ' </span>';

                            return _.template(template);
                        },

                        serializeData: function () {
                            if (that.options.childViewSerializeData) {
                                return _.bind(that.options.childViewSerializeData, this)();
                            }
                            var jsonData = this.model.toJSON();

                            // Traitement pour échapper les caractères spéciaux
                            _.each(jsonData, function (value, key) {
                                if (_.isString(value)) {
                                    jsonData[key] = _.escape(value);
                                }
                            });

                            return jsonData;
                        }
                    })
                };
            }
            return behavior;
        },

        serializeData: function () {
            return {
                placeholder: this.options.placeholder || null,
                value: this.options.value || this.model instanceof Backbone.Model && this.model.get(this.options.valueKey) || null,
                fieldName: this.options.name || null
            };
        },

        newEntity: function () {
            this.addNewEntity = true;
            this._onChange();
        },

        _onChange: function () {
            if (this.addNewEntity) {
                this.addNewEntity = false;
                if (!this.options.service) {
                    console.log('autocomplete : no entity on ' + this.ui.autocomplete.data('field-name'));
                    return;
                }
                this.options.service.onAdd(this.options.service, _.bind(function () {
                    if (this.options.callbackAdd) {
                        this.options.callbackAdd(arguments);
                    }
                    this._behaviors[0].collectionView.collection.reset();
                    this.ui.autocomplete.dropdown('toggle');
                    this.render();
                }, this));
                return;
            }
            this.options.value = this.ui.autocomplete.val();
            var callback = this.options.callback;
            if (!callback) {
                console.log('autocomplete : no callback on ' + this.ui.autocomplete.data('field-name'));
                return;
            }

            if (this.options.service && this.options.service.getModel && this.model) {
                this.model = this.options.service.getModel(this.model.toJSON());
            }

            callback(this.ui.autocomplete.data('field-name'), this.model, this, false);
            if (!this.isDestroyed()) {
                if (this.model instanceof Backbone.Model && this.options.canAddOrEdit && (!this.options.service || this.options.service.showEditAdd)) {
                    this.ui.editRecord.show();
                } else {
                    this.ui.editRecord.hide();
                }
            }
            this.renderTitle();
            this.renderIcon();
        },
        renderIcon: function () {
            var canEdit = this.model instanceof Backbone.Model && (this.options.service && this.options.service.canAddOrEdit && this.options.service.canAddOrEdit());
            var canShow = this.model instanceof Backbone.Model && (this.options.service && this.options.service.canRead && this.options.service.canRead());
            this.ui.showRecord.hide();
            this.ui.editRecord.hide();
            if (canEdit && !this.options.readOnly) {
                this.ui.editRecord.show();
            } else if (canShow) {
                this.ui.showRecord.show();
            }
        },
        onAutocompleteSelect: function (suggestion) {
            this.model = suggestion;
            if (this.model instanceof Backbone.Model) {
                this.ui.autocomplete.val(this.model.get(this.options.valueKey));
            } else {
                this.ui.autocomplete.val('');
            }
            this._onChange();
        },
        onRender: function () {
            if (this.options.remote && !!Cookies.get('gm-autocomplete')) {
                Bootbox.alert({
                    title: _.i18n('menu.superadmin.godMode'),
                    message: this.options.remote + '&' + this.options.valueKey + '=' + this.ui.autocomplete.val()
                });
            }
            if (this.model instanceof Backbone.Model) {
                if (this.canEdit && !this.options.readOnly) {
                    this.ui.editRecord.show();
                } else if (this.canShow) {
                    this.ui.showRecord.show();
                }
            }
            this.ui.autocomplete.attr('readOnly', this.options.readOnly);
            if (this.options.readOnly) {
                this.ui.autocomplete.attr('disabled', 'disabled');
            }
            this.renderTitle();
            this.renderIcon();
        },
        renderTitle: function () {
            if (this.isDestroyed()) {
                return;
            }
            var title = '';
            if (this.model instanceof Backbone.Model) {
                if (this.model.get('code')) {
                    title = this.model.get('code');
                }
                if (this.model.get('name')) {
                    if (title !== '') {
                        title += ' - ';
                    }
                    title += this.model.get('name');
                }
            }
            this.ui.autocomplete.attr('title', title);
        },
        onEditRecord: function () {
            this.options.service.onEdit(this.options.callbackEdit, this.model.get('id'));
        }
    });
});

