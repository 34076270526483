
define('template!assayReagentQCItemTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-xs-1 p-r-2 p-l-2"><span class="mdi mdi-delete delete-row js-icon clickable" style="line-height: 26px;"></span></div>\n<div class="col-xs-7 p-r-2 p-l-2 js-kit"></div>\n<div class="col-xs-3 p-r-2 p-l-2 js-reagent"></div>\n<div class="col-xs-1 p-r-2 p-l-2">\n    <span class="mdi mdi-eye clickable show-row js-icon invisible" style="line-height: 22px; font-size: 25px;" title="' +
((__t = ( _.i18n('kit.currentLot') )) == null ? '' : __t) +
'"></span>\n</div>';

}
return __p
};});

