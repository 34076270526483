define('prepRunTemplateController',[
    'module',
    'underscore',
    'createEditLayoutPrepTemplatesView'
], function (
    module,
    _,
    CreateEditLayoutView
) {
    'use strict';

    module.exports = {
        /**
         * Show the details of the model.
         * @param param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Object} param.service - The service object.
         * @param {Function} param.callBackOnClose - The callback function to be called after the CreateEditLayoutView is shown.
         */
        showDetails: function (param) {
            if (!param.model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            param.service = require('services/caccounts/prepruntemplates');
            var DefaultCheckBoxService = require('services/caccounts/setting/defaultCheckbox');
            var setting = DefaultCheckBoxService.getModel();
            setting.fetch().done(_.bind(function () {
                var createEditView = new CreateEditLayoutView({
                    model: param.model,
                    printRunSheet: setting.get('prepPrintRunSheet'),
                    service: param.service
                });
                createEditView.show({
                    title: _.i18n(param.service.getName() + '.new'),
                    className: 'baseTableEditAddPopup',
                    service: param.service
                }, _.bind(function () {
                    if (param.callBackOnClose) {
                        param.callBackOnClose();
                    }
                }, param.callBackOnClose ? param.callBackOnClose.context : undefined));
            }, this));
        }
    };
});

