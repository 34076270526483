define('pcrSetupPrepRunCollection',[
    'module',
    'backbone',
    'backbone.marionette',
    'pcrSetupPrepRunItem',
    'underscore',
    'template!pcrSetupPrepRunCollection'
], function (
    module,
    Backbone,
    Marionette,
    Item,
    _,
    Tpl
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        template: Tpl,
        className: 'runList rounded filter height-100',
        childView: Item,
        childViewContainer: '.js-container',
        modelEvents: {
            'change:currentAssay': 'applyFilter',
            'change:currentProtocol': 'applyFilter',
            'change:history': 'render'
        },
        attributes: function () {
            return {
                'style': 'background-color: #FFD8C2;'
            };
        },
        initialize: function () {
            this.sortType = 'periodDesc';
        },
        viewFilter: function (view) {
            return view.model.canDisplay();
        },
        applyFilter: function () {
            if (!this.model.get('currentAssay')) {
                _.each(this.children._views, function (view) {
                    view.clearSelection();
                });
            }
            this.render();
        },
        ui: {
            sortPeriodAsc: '.mdi-sort-ascending',
            sortPeriodDesc: '.mdi-sort-descending'
        },
        events: {
            'click .mdi-sort-ascending.clickable': 'onSortPeriodAscClick',
            'click .mdi-sort-descending.clickable': 'onSortPeriodDescClick'
        },
        childViewEvents: {
            'enableButtons': function (enable) {
                this.trigger('enableButtons', enable);
            }
        },
        onSortPeriodAscClick: function () {
            this.collection.comparator = function (model1, model2) {
                if (model1.get('period') === model2.get('period')) {
                    return 0;
                }
                if (model1.get('period') < model2.get('period')) {
                    return -1;
                }
                return 1;
            };
            this.sortType = 'periodAsc';
            this.collection.sort();
        },
        onSortPeriodDescClick: function () {
            this.collection.comparator = function (model1, model2) {
                if (model1.get('period') === model2.get('period')) {
                    return 0;
                }
                if (model1.get('period') < model2.get('period')) {
                    return 1;
                }
                return -1;
            };
            this.sortType = 'periodDesc';
            this.collection.sort();
        },
        _disable: function (ui) {
            ui.removeClass('clickable');
            ui.addClass('disabled');
            ui.attr('disabled', true);
        },
        _clickable: function (ui) {
            ui.addClass('clickable');
            ui.removeClass('disabled');
            ui.removeClass('disabled');
        },
        onRender: function () {
            if (this.sortType === 'periodDesc') {
                this._disable(this.ui.sortPeriodDesc);
                this._clickable(this.ui.sortPeriodAsc);
            }
            if (this.sortType === 'periodAsc') {
                this._disable(this.ui.sortPeriodAsc);
                this._clickable(this.ui.sortPeriodDesc);
            }
        },
        childViewOptions: function () {
            return {
                pcrSetup: this.model
            };
        }
    });
});
