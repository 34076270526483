define('assayReagentMbAnaPcrKitLotByPcrWell',[
    'module',
    'backbone.marionette',
    'underscore',
    'jquery',
    'template!assayReagentMbAnaPcrKitLotByPcrWell',
    'dialogFormView',
    'dateUtils',
    'qcChartView'
], function (
    module,
    Marionette,
    _,
    $,
    Tpl,
    DialogFormView,
    DateUtils,
    QcChartView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input-child'
        },
        regions: {
            result: '.js-result',
            chart: '.js-show-graphics-region'
        },

        events: {
            'change @ui.input': 'onInputChange'
        },
        className: 'rounded col-xs-12 m-b-5 m-l-5',

        serializeData: function () {
            var templateData = this.model;
            var assayReagentTargetPcrKitLot = this.model.get('assayReagentTargetPcrKitLot');
            templateData.displayedKitCode = assayReagentTargetPcrKitLot.get('refPcrKitLot').get('kit').get('code');
            templateData.displayedKitLotId = assayReagentTargetPcrKitLot.get('refPcrKitLot').get('code');
            templateData.delivery = assayReagentTargetPcrKitLot.get('refPcrKitLot').get('delivery') ? DateUtils.toDateFormatStringFromSettings(assayReagentTargetPcrKitLot.get('refPcrKitLot').get('delivery')) : '';
            templateData.beginUse = assayReagentTargetPcrKitLot.get('refPcrKitLot').get('beginUse') ? DateUtils.toDateFormatStringFromSettings(assayReagentTargetPcrKitLot.get('refPcrKitLot').get('beginUse')) : '';
            templateData.endUse = assayReagentTargetPcrKitLot.get('refPcrKitLot').get('endUse') ? DateUtils.toDateFormatStringFromSettings(assayReagentTargetPcrKitLot.get('refPcrKitLot').get('endUse')) : '';
            templateData.expiration = assayReagentTargetPcrKitLot.get('refPcrKitLot').get('expiration') ? DateUtils.toDateFormatStringFromSettings(assayReagentTargetPcrKitLot.get('refPcrKitLot').get('expiration')) : '';
            var first = _.first(this.model.get('values'));
            templateData.firstRun = first ? first.pcrWell.get('run').get('name') : '';
            var last = _.last(this.model.get('values'));
            templateData.lastRun = last ? last.pcrWell.get('run').get('name') : '';
            templateData.title = assayReagentTargetPcrKitLot.get('refAssayReagent').get('refAssay').get('code') + ' - ' + assayReagentTargetPcrKitLot.get('target').get('code');
            templateData.target = ' (' + assayReagentTargetPcrKitLot.get('target').get('name') + ')';
            templateData.smpTypeIcon = this.options.smpTypeIcon;
            return templateData;
        },
        onRender: function () {

            var values = this.model.get('values');
            values = _.map(values, function (value) {
                return {
                    value: value.value,
                    pcrWell: value.pcrWell.toJSON()
                };
            });

            var control = {
                val: this.model.get('assayReagentTargetPcrKitLot').get('val'),
                deltaError: this.model.get('assayReagentTargetPcrKitLot').get('deltaError'),
                deltaWarning: this.model.get('assayReagentTargetPcrKitLot').get('deltaWarning')
            };
            var graphView = new QcChartView({
                type: 'line',
                pcrKitLotValueRuns: values,
                controls: control,
                title: this.model.get('assayReagentTargetPcrKitLot').get('target').get('code') + ' (' + this.model.get('assayReagentTargetPcrKitLot').get('target').get('name') + ')',
                height: 300
            });
            this.getRegion('chart').show(graphView);
        }
    });
});
