define('dynamicCreateEditCustomLisConfiguration',[
    'module',
    'dialogFormView',
    'template!dynamicCreateEditCustomLisConfiguration',
    'arrayInputView',
    'backbone',
    'underscore',
    'jquery',
    'autocompleteView'
], function (
    module,
    DialogFormView,
    tpl,
    ArrayListView,
    Backbone,
    _,
    $,
    AutocompleteView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        ui: {
            actions: '.js-select-action',
            input: '.js-info-input'
        },

        events: {
            'focus @ui.fieldCustom': 'onFocusCustom',
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },

        regions: {
            dataConnection: '.js-select-dataConnection-region'
        },

        modelEvents: {
            change: 'render',
            'change:action': 'onActionChange',
            'change:dataConnection': 'onDataConnectionChange'
        },

        className: 'col-w-all col-w-100 d-f',

        initialize: function () {
            this.model = new Backbone.Model();
            this.model.set('action', this.options.model.get('action'));
            this.model.set('dataConnection', this.options.model.get('dataConnection'));
        },

        serializeData: function () {
            var templateData = {};
            templateData.model = this.model.toJSON();
            templateData.actions = ['QUERY_SID', 'EXPORT_RESULT', 'EXPORT_RESULT_TEST', 'EXPORT_QUALITY_CONTROL', 'EXPORT_REPEAT'];
            templateData.tpl = {};
            templateData.tpl.field = this.options.field;
            templateData.tpl.fieldCustom = this.options.fieldCustom;
            return templateData;
        },

        onRender: function () {
            this.renderDataConnection();
        },
        renderDataConnection: function () {
            if (!this.model.get('action')) {
                this.getRegion('dataConnection').empty();
            } else {
                var DataConnectionService = require('services/caccounts/dataConnection');
                this.showChildView('dataConnection', new AutocompleteView(
                    this._getAutocompleteOptionObject(DataConnectionService.getAutocompleteParam({
                        modelProperty: 'dataConnection',
                        values: {
                            action: this.model.get('action')
                        }
                    }))
                ));
            }
        },
        onActionChange: function () {
            this.model.set({dataConnection: null}, {silent: true});
            this.renderDataConnection();
            this.options.model.set('action', this.model.get('action'));
        },
        onDataConnectionChange: function () {
            this.options.model.set('dataConnection', this.model.get('dataConnection'));
        }

    });
});
