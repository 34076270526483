define('entities/caccounts/pcrwelltemplates',[
    'entities/ns',
    'backbone',
    'settings',
    'underscore',
    'jquery',
    'rolesMixin',
    'entities/caccounts/samples',
    'entities/caccounts/pcrruntemplates'
], function (
    app,
    Backbone,
    Settings,
    _,
    $,
    RolesMixin
) {
    'use strict';

    app.PcrWellTemplate = Backbone.RelationalModel.extend({
        service: 'services/caccounts/pcrwelltemplates',
        fetch: function () {
            this.url = Settings.url('rest/v2/runtemplates/pcr/wells/' + this.get('id'));
            return Backbone.Model.prototype.fetch.call(this);
        },

        defaults: {
            'pos': '',
            'smpType': 'E',
            'refAssay': null,
            'wellsPooled': [],
            'wellsPool': [],
            'pcrKits': [],
            'smpId': null
        },
        idAttribute: 'id',
        relations: [{
            type: Backbone.HasOne,
            key: 'smpId',
            relatedModel: 'Sample',
            includeInJSON: ['id','code','name']
        }, {
            type: Backbone.HasOne,
            key: 'refAssay',
            relatedModel: 'Assay',
            includeInJSON: ['id','code','name']
        }, {
            type: Backbone.HasOne,
            key: 'run',
            relatedModel: 'PcrRunTemplate',
            includeInJSON: ['id','code','name']
        }],

        saveHeader: function (request) {
            var url = Settings.url('rest/v2/runtemplates/pcr/wells/' + this.get('id') + '/header'),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(request),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch()
                        .done(function () {
                            defer.resolve();
                        });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        getPDF: function () {
            return Settings.getDocUrl('PrepWellTemplateJson.pdf');
        },
        saveSmpid: function () {
            var url = Settings.url('rest/v2/runtemplates/pcr/wells/' + this.get('id') + '/sampleId'),
                defer = $.Deferred();

            var body = this.get('smpId').get('code');
            $.ajax({
                url: url,
                type: 'PUT',
                data: body,
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: _.bind(function (err) {
                    this.fetch().done(function () {
                        defer.reject(err);
                    });
                }, this)
            });
            return defer.promise();
        }
    });

    app.PcrWellTemplate.role = RolesMixin.RUN_PCR_TEMPLATE;

    app.PcrWellTemplateCollection = Backbone.Collection.extend({
        fetch: function (param) {
            this.url = Settings.url('rest/v2/runtemplates/pcr/' + param.run.get('id') + '/wells');
            return Backbone.Collection.prototype.fetch.apply(this);
        },
        model: app.PcrWellTemplate
    });

});

