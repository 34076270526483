
define('template!runTracsEditPopupView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="detailed-info-form-container">\n    <div class="saving-label"></div>\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            <div class="row p-r-15">\n                <div class="col-xs-4 dataLabelContainer">\n                    <div class="form-group" data-field-name="creatDate">\n                        <label class="control-label dataLabel">' +
((__t = ( _.i18n('trac.creatDate') )) == null ? '' : __t) +
'</label>\n                    </div>\n                </div>\n                <div class="col-xs-3 p-l-0">\n\n                    <!--<div class="form-group" data-field-name="">\n                        <input type="text"\n                               class="form-control floating-label js-info-input inputBackground"\n                               data-field-name="" placeholder=""\n                               value="%= createDateFormatted?createDateFormatted:\'\' %" autocomplete="off"\n                               data-placement="top">\n                    </div>-->\n                    <div style="font-size: 18px; margin-top: 4px;">' +
((__t = ( createDateFormatted?createDateFormatted:'' )) == null ? '' : __t) +
'\n                    </div>\n\n                </div>\n            </div>\n\n            <!--<div class="row p-r-15">\n                <div class="col-xs-4 dataLabelContainer">\n                    <div class="form-group" data-field-name="creatDate">\n                        <label class="control-label dataLabel">%= _.i18n(\'trac.creatDate\') %</label>\n                    </div>\n                </div>\n                <div class="col-xs-8">\n                    <input type="datepicker"\n                           class="form-control js-datePicker date-creatDate inputBackground"\n                           data-field-name="creatDate"\n                           style="width: auto;"\n                           placeholder="" value="%= creatDate %">\n                </div>\n            </div>-->\n\n            <div class="row p-r-15">\n                <div class="col-xs-4 dataLabelContainer">\n                    <div class="form-group" data-field-name="refUserpid">\n                        <label class="control-label dataLabel">' +
((__t = ( _.i18n('trac.refUserpid') )) == null ? '' : __t) +
'</label>\n                    </div>\n                </div>\n                <div class="col-xs-1 p-l-0">\n\n                    <!--<div class="js-select-respUser-region"></div>-->\n                    <div style="font-size: 18px; margin-top: 4px;">' +
((__t = ( refUserpid.nickName?refUserpid.nickName:'' )) == null ? '' : __t) +
'\n                    </div>\n\n                </div>\n            </div>\n\n            <div class="row p-r-15">\n                <div class="col-xs-4 dataLabelContainer">\n                    <div class="form-group" data-field-name="level">\n                        <label class="control-label dataLabel">' +
((__t = ( _.i18n('trac.level') )) == null ? '' : __t) +
'</label>\n                    </div>\n                </div>\n                <div class="col-xs-2">\n                    <div class="form-group" data-field-name="level">\n\n                        <!--<input type="text"\n                               class="form-control floating-label js-info-input inputBackground"\n                               data-field-name="level" placeholder=""\n                               value="%= level?level:\'\' %" autocomplete="off"\n                               data-placement="top">-->\n                        <div style="font-size: 18px; margin-top: 4px;">' +
((__t = ( level?level:'' )) == null ? '' : __t) +
'</div>\n\n                    </div>\n                </div>\n            </div>\n\n            <div class="row p-r-15">\n                <div class="col-xs-4 dataLabelContainer">\n                    <div class="form-group" data-field-name="action">\n                        <label class="control-label dataLabel">' +
((__t = ( _.i18n('trac.action') )) == null ? '' : __t) +
'</label>\n                    </div>\n                </div>\n                <div class="col-xs-3">\n                    <div class="form-group" data-field-name="action">\n                        <div style="font-size: 18px; margin-top: 4px;">' +
((__t = ( action?action:'' )) == null ? '' : __t) +
'</div>\n\n                    </div>\n                </div>\n            </div>\n\n            <div class="row p-r-15">\n                <div class="col-xs-4 dataLabelContainer">\n                    <div class="form-group" data-field-name="wells">\n                        <label class="control-label dataLabel">' +
((__t = ( _.i18n('trac.well') )) == null ? '' : __t) +
'</label>\n                    </div>\n                </div>\n                <div class="col-xs-8">\n                    <div class="form-group" data-field-name="wells">\n                           <textarea disabled\n                                     class="form-control floating-label inputBackground js-info-input"\n                                     style="resize: vertical; background-color: white;"\n                                     placeholder="' +
((__t = ( _.i18n('common.empty.placeholder') )) == null ? '' : __t) +
'"\n                                     title="' +
((__t = ( wells )) == null ? '' : __t) +
'" rows="3" wrap="on"\n                                     data-field-name="wells">' +
((__t = ( wells?wells:'' )) == null ? '' : __t) +
'</textarea>\n                    </div>\n                </div>\n            </div>\n\n            <div class="row p-r-15">\n                <div class="col-xs-4 dataLabelContainer">\n                    <div class="form-group" data-field-name="comment">\n                        <label class="control-label dataLabel">' +
((__t = ( _.i18n('trac.comment') )) == null ? '' : __t) +
'</label>\n                    </div>\n                </div>\n                <div class="col-xs-8">\n                    <div class="form-group">\n                        <prep disabled\n                              class="form-control floating-label inputBackground js-info-input"\n                              style="resize: vertical; background-color: white; height: 100px; overflow: auto;white-space: pre-line;">' +
((__t = ( comment?comment:'' )) == null ? '' : __t) +
'\n                        </prep>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

