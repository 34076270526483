/**
 * Created by GSP on 16/07/2015.
 */
define('services/caccounts/system',[
    'jquery',
    'settings',
    'module'
], function (
    $,
    Settings,
    module
) {
    'use strict';

    module.exports = {
        clearCache: function () {
            var defer = $.Deferred();
            var url = Settings.url('rest/v2/system/clear/cache');

            $.ajax({
                type: 'GET',
                url: url,
                success: function () {
                    defer.resolve();
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        }
    };
});

