define('dynamicListItemCollectionView',[
    'module',
    'backbone',
    'backbone.marionette',
    'underscore',
    'dynamicListItemView'
], function (
    module,
    Backbone,
    Marionette,
    _,
    ItemView
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        childView: ItemView,
        childViewOptions: function (model) {
            var collection = new Backbone.Collection();
            this.updateCollection(collection, model, this.options.dynamicConfigurationField);
            return {
                model: model,
                collection: collection,
                updateCollection: _.bind(this.updateCollection, this, collection),
                dynamicConfigurationField: this.options.dynamicConfigurationField
            };
        },
        updateCollection: function (collection, model, dynamicConfigurationField) {
            var configurations = dynamicConfigurationField.service.getCreateEditLine(model);
            _.each(configurations, _.bind(function (configuration) {
                configuration.name = dynamicConfigurationField.service.getName();
            }, this));
            var enableAdd = true;
            if (dynamicConfigurationField && dynamicConfigurationField.config) {
                if (_.isFunction(dynamicConfigurationField.config.enable)) {
                    enableAdd = dynamicConfigurationField.config.enable(this.model);
                } else if(!_.isUndefined(dynamicConfigurationField.config.enable)) {
                    enableAdd = dynamicConfigurationField.config.enable;
                }
            }
            if (enableAdd) {
                var enable = true;
                if (dynamicConfigurationField && dynamicConfigurationField.config) {
                    if (_.isFunction(dynamicConfigurationField.config.enableDelete)) {
                        enable = dynamicConfigurationField.config.enableDelete(this.model);
                    } else if(!_.isUndefined(dynamicConfigurationField.config.enableDelete)) {
                        enable = dynamicConfigurationField.config.enableDelete;
                    }
                }
                configurations.unshift({button: true, enable: enable});
            }
            _.each(configurations, function (configuration) {
                var model = _.first(collection.filter(function (model) {
                    return model.get('field') === configuration.field;
                }));
                if (model instanceof Backbone.Model) {
                    model.set(configuration, {silent: true});
                } else {
                    collection.add(configuration);
                }
            });
        },
        viewFilter: function (view) {
            var dynamicConfigurationField = view.options.dynamicConfigurationField;
            if (dynamicConfigurationField.display && dynamicConfigurationField.display.condition) {
                switch (dynamicConfigurationField.display.condition.type) {
                    case 'EQUAL':
                        return this.model.get(dynamicConfigurationField.display.condition.key) === dynamicConfigurationField.display.condition.value;
                    case 'NOT_EQUAL':
                        return this.model.get(dynamicConfigurationField.display.condition.key) !== dynamicConfigurationField.display.condition.value;
                    case 'EMPTY':
                        if (this.model.get(dynamicConfigurationField.display.condition.key) instanceof Backbone.Collection) {
                            return !this.model.get(dynamicConfigurationField.display.condition.key).isEmpty();
                        }
                        return _.isEmpty(this.model.get(dynamicConfigurationField.display.condition.key));
                    case 'NOT_EMPTY':
                        if (this.model.get(dynamicConfigurationField.display.condition.key) instanceof Backbone.Collection) {
                            return this.model.get(dynamicConfigurationField.display.condition.key).isEmpty();
                        }
                        return !_.isEmpty(this.model.get(dynamicConfigurationField.display.condition.key));
                    case 'FUNCTION': {
                        return dynamicConfigurationField.display.condition.func(this.model);
                    }
                }
            }
            return true;
        }
    });
});
