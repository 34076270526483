define('dynamicListItemView',['module',
        'backbone.marionette',
        'underscore',
        'jquery',
        'backbone',
        'dynamicListItemBoolean',
        'dynamicListItemColor',
        'dynamicListItemDate',
        'dynamicListItemDateTime',
        'dynamicListItemLabel',
        'dynamicListItemNumber',
        'dynamicListItemPopup',
        'dynamicListItemTextArea',
        'dynamicListItemRadio',
        'arrayCustomFromTypeView',
        'dynamicListItemText',
        'dynamicListItemAutocomplete',
        'dynamicListItemCodeListView',
        'arrayInputView',
        'dynamicListItemSelect',
        'dynamicCreateEditCustomLisConfiguration',
        'dynamicCreateEditCustomAssayConfigurationResultFromAssay',
        'dynamicListItemButton',
        'dynamicListItemEmpty',
        'dynamicCreateEditCustomListToList'
    ],
    function (module,
              Marionette,
              _,
              $,
              Backbone,
              BooleanView,
              ColorView,
              DateView,
              DateTimeView,
              LabelView,
              NumberView,
              PopupView,
              TextAreaView,
              RadioView,
              TypeParamView,
              TextView,
              AutocompleteView,
              CodeListView,
              ArrayListView,
              DynamicCreateEditSelect,
              DynamicCreateEditCustomLisConfiguration,
              DynamicCreateEditCustomAssayConfigurationResultFromAssay,
              ButtonView,
              EmptyView,
              DynamicCreateEditCustomListToList
    ) {
        'use strict';

        module.exports = Marionette.CollectionView.extend({
            className: 'col-w-100 d-f p-l-5 p-r-5 m-b-3',

            initialize: function () {
                _.each(this.options.dynamicConfigurationField.service.getCreateEditLine(this.model), _.bind(function (f) {
                    if (f.param.defaultValue) {
                        this.model.set(f.field, f.param.defaultValue(this.model));
                    }
                }, this));
            },

            collectionEvents: {
                'renderModel': 'testRender'
            },

            testRender: function (model, dynamicConfigurationField) {
                this.options.updateCollection(model, dynamicConfigurationField);
                this.render();
            },

            serializeData: function () {
                return {};
            },

            childViewOptions: function (model) {
                if (model.get('button')) {
                    return {
                        model: this.options.model,
                        field: model,
                        callBackOnClick: _.bind(this.onDelete, this, this.options.model),
                        dynamicConfigurationField: this.options.dynamicConfigurationField
                    };
                }

                switch (model.get('param').type) {
                    case 'TEXT':
                    case 'LABEL':
                    case 'POPUP':
                    case 'DATETIME':
                    case 'DATE':
                    case 'ARRAYLIST':
                    case 'BOOLEAN':
                    case 'COLOR':
                    case 'NUMBER':
                    case 'RADIO':
                    case 'TEXTAREA':
                    case 'REFERENCE':
                    case 'SELECT':
                    case 'USERCODELIST':
                    case 'CODELIST':
                    case 'EMPTY':
                        return {
                            model: this.options.model,
                            field: model,
                            dynamicConfigurationField: this.options.dynamicConfigurationField,
                            service: this.options.dynamicConfigurationField.service
                        };
                    case 'LISTTOLIST':
                        return {
                            model: this.options.model,
                            field: model.get('field'),
                            display: model.get('param').display,
                            items: {items: model.get('param').items},
                            service: this.options.dynamicConfigurationField.service
                        };
                    case 'LISCONFIGURATION':
                        return {
                            'field': model.get('field'),
                            'model': this.model,
                            dynamicConfigurationField: this.options.dynamicConfigurationField
                        };
                    case 'ASSAYCONFIGURATIONRESULT_FROMASSAY':
                        return {
                            'field': model.get('field'),
                            'model': this.model,
                            dynamicConfigurationField: this.options.dynamicConfigurationField
                        };
                    case 'TYPEPARAM':
                        return {
                            fieldName: 'parameter',
                            fieldNameCodePrefix: 'DM_',
                            'fieldNameParam': model.get('field'),
                            'model': this.model,
                            service: this.options.dynamicConfigurationField.service
                        };
                }
            },
            childView: function (model) {
                if (model.get('button')) {
                    return ButtonView;
                }

                switch (model.get('param').type) {
                    case 'TEXT':
                        return TextView;
                    case 'LABEL':
                        return LabelView;
                    case 'POPUP':
                        return PopupView;
                    case 'REFERENCE':
                        return AutocompleteView;
                    case 'SELECT':
                        return DynamicCreateEditSelect;
                    case 'CODELIST':
                    case 'USERCODELIST':
                        return CodeListView;
                    case 'DATE':
                        return DateView;
                    case 'DATETIME':
                        return DateTimeView;
                    case 'ARRAYLIST':
                        return ArrayListView;
                    case 'LISCONFIGURATION':
                        return DynamicCreateEditCustomLisConfiguration;
                    case 'COLOR':
                        return ColorView;
                    case 'NUMBER':
                        return NumberView;
                    case 'BOOLEAN':
                        return BooleanView;
                    case 'TEXTAREA':
                        return TextAreaView;
                    case 'RADIO':
                        return RadioView;
                    case 'TYPEPARAM':
                        return TypeParamView;
                    case 'ASSAYCONFIGURATIONRESULT_FROMASSAY':
                        return DynamicCreateEditCustomAssayConfigurationResultFromAssay;
                    case 'EMPTY':
                        return EmptyView;
                    case 'LISTTOLIST':
                        return DynamicCreateEditCustomListToList;
                    default:
                        throw new Error('[DYNAMIC] no implementation for "' + model.get('param').type + '"');
                }
            },

            childViewEvents: {
                'item:change': function (view, e) {
                    view.onChange(e.currentTarget.attributes['data-field-name'].value, e.target.value);
                }
            },
            
            onDelete: function (model) {
                model.collection.remove(model);
            },
            onChange: function (fieldName, value) {
                var data = {};
                data[fieldName] = value;
                this.options.model.set(data, {silent: true});
                this.options.model.trigger('change:' + fieldName);
            }
        });
    });

