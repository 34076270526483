define('services/caccounts/pcrruntemplates',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'dynamic',
    'module',
    'entities/caccounts/pcrruntemplates'
], function (
    app,
    Settings,
    $,
    _,
    Dynamic,
    module
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.PcrRunTemplate,
                collection: app.PcrRunTemplateCollection
            };
        },
        getName: function () {
            return 'pcrruntemplates';
        },
        getUrl: function () {
            return 'rest/v2/runtemplates/pcr/';
        },
        getDynamicJson: function () {
            return 'PcrRunTemplateJson';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('sortSeq'),
                name: 'seq',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'seq',
                width: 20
            }, {
                label: _.i18n('runtemplate.cyclerPublic'),
                name: 'cyclerPublic',
                formatter: viewDynamic.codeNameFormatter,
                index: 'cyclerPublicCode',
                width: 20,
                search: false
            }, {
                label: _.i18n('runtemplate.cycler'),
                name: 'cycler',
                formatter: viewDynamic.codeNameFormatter,
                index: 'cycler.code',
                width: 20,
                search: false
            }, {
                label: _.i18n('runtemplate.plateSize'),
                name: 'plateSize',
                formatter: viewDynamic.defaultFormatter,
                index: 'plateSizeCode',
                width: 20
            }, {
                label: _.i18n('runList.department'),
                name: 'department',
                formatter: viewDynamic.defaultFormatter,
                index: 'department',
                width: 25
            }, {
                label: '0',
                name: 'departmentHyphen',
                search: false,
                width: 2
            }, {
                label: _.i18n('runList.group'),
                name: 'group',
                formatter: viewDynamic.defaultFormatter,
                index: 'group',
                width: 20
            }, {
                label: '0',
                name: 'groupHyphen',
                search: false,
                width: 2
            }, {
                label: _.i18n('runList.sequence'),
                name: 'sequenceFormat',
                formatter: viewDynamic.defaultFormatter,
                index: 'sequenceFormat',
                width: 22
            }, {
                label: _.i18n('runTemplate.pattern'),
                name: 'pattern',
                formatter: viewDynamic.rawFormatter,
                search: true,
                index: 'pattern',
                width: 100
            }, {
                label: _.i18n('runTemplate.printRunSheet'),
                name: 'printRunSheet',
                formatter: viewDynamic.booleanFormatter,
                search: false,
                index: 'printRunSheet',
                width: 25
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'comment'
            });
            return columns;
        },

        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.fieldsToDisplay = config.fieldsToDisplay ? config.fieldsToDisplay : this.getFieldsToDisplay();
            configuration.values = {'sidx': 'seq', 'sord': 'asc'};
            return configuration;
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.PcrRunTemplateCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 450, fields: ['name']};
        },
        showDetails: function (param) {
            param.service = this;
            require(['pcrRunTemplateController'], _.bind(function (Controller) {
                param.controller = Controller;
                this._showDetails(param);
            }, this));
        }
    }, Dynamic);
});

