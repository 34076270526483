define('services/caccounts/userCodelist',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'bootbox',
    'cookies',
    'backbone',
    'backboneRelational',
    'entities/caccounts/userCodelist'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic,
    Bootbox,
    Cookies,
    Backbone
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.UserCodeList,
                collection: app.UserCodeListCollection
            };
        },
        getName: function () {
            return 'usercodelist';
        },
        getUrl: function () {
            return 'rest/v2/userCodeLists/';
        },
        getDynamicJson: function () {
            return 'UserCodeListJson';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.archivable = false;
            config.selectArchivable = false;
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: '',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 80
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'assay-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            });
            return columns;
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push(
                {
                    'field': 'code',
                    'param': {'type': 'CODELIST', 'code': 'USERCODELISTS', 'display': {'class': 'col-xs-12'}}
                });
            return custom;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 550, 'fields': ['name', 'description']};
        },
        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.callbackAdd = undefined;
            configuration.callbackEdit = undefined;
            configuration.urlPath = 'rest/v2/userCodeLists/code/' + config.codeListCode + '/elements';
            configuration.values = config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'code'
            };
            return configuration;
        },
        getAutocompleteParamForCode: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.callbackAdd = undefined;
            configuration.callbackEdit = undefined;
            configuration.urlPath = 'rest/v2/userCodeLists/code/' + config.codeListCode + '/elements?' + Settings.generateParamUrl((config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'code'
            }));
            configuration.canAddOrEdit = false;
            return configuration;
        },
        findItemsByCode: function (code) {
            var deferred = $.Deferred();
            var findAll = new Backbone.Collection();
            findAll.push(Backbone.Relational.store.getCollection(app.UserCodeList).models);
            // merge with app.CodeList
            findAll.push(Backbone.Relational.store.getCollection(app.CodeList).models);
            var result = findAll.chain().filter(function (model) {
                return model.get('code') === code;
            }).first().value();
            if (result) {
                var ret = result.get('codeListElements').map(function (model) {
                    return {code: model.get('code'), name: model.get('name')};
                });
                deferred.resolve(ret);
            } else {
                deferred.reject();
            }
            return deferred.promise();
        },
        checkExists: function (code) {
            var url = Settings.url(this.getUrl() + 'code/' + code + '/exists'),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (data) {
                    defer.resolve(data);
                }
            });

            return defer.promise();
        }
    }, Dynamic);
});

