define('editWellView',[
    'module',
    'backbone',
    'dialogFormView',
    'template!editWellView',
    'savingBehavior',
    'bootbox',
    'underscore',
    'jquery',
    'app',
    'settings',
    'colorUtils',
    'autocompleteView',
    'wellDisplaySampleView',
    'rolesMixin',
    'privileges',
    'pcrWellResultController',
    'colorUtils'
], function (
    module,
    Backbone,
    DialogFormView,
    EditWellTpl,
    SavingBehavior,
    bootbox,
    _,
    $,
    App,
    Settings,
    colorUtils,
    AutocompleteView,
    WellDisplaySampleView,
    RolesMixin,
    Privileges,
    PcrWellResultController,
    ColorUtils
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: EditWellTpl,
        ui: {
            input: '.js-info-input',
            cancel: '.js-cancel-popup',
            confirm: '.js-confirm',
            tags: '.js-tags',
            wellComment: '.js-wellComment',
            copyParameters: '.js-copyParameters',
            cts: '.js-ui-cts',
            quantifications: '.js-ui-quantifications',
            resultLine: '.js-result-line'
        },

        events: {
            'change @ui.tags': 'onTagsChange',
            'change @ui.wellComment': 'onSampleCommentChange',
            'change @ui.input': 'onInputChange',
            'click .js-cancel-popup': 'onCancel',
            'click .js-confirm': 'onConfirm',
            'change @ui.cts': 'onChangeCts',
            'change @ui.quantifications': 'onChangeQuanfitications'
        },

        behaviors: {
            Saving: {behaviorClass: SavingBehavior}
        },

        modelEvents: {
            'change:result': 'render',
            'change:cts': 'onChangeValue',
            'change:quantifications': 'onChangeValue'
        },

        regions: {
            wellTargetRegion: '.js-wellTarget-region',
            wellDisplaySample: '.js-well-sample',
            codeErrRegion: '.js-select-codeErr-region',
            mbAnaResRegion: '.js-mbAnaRes',
            resultRegion: '.js-result'
        },
        fieldsToValidate: [],

        initialize: function (options) {
            this.popupMode = options.popupMode;
            this.canEdit = (this.model.get('valSt') < 4 || !!this.model.isOvar()) && Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.MRES);
        },

        serializeData: function () {
            var templateData = {
                smpType: this.model.get('pcrWell').get('smpType'),
                pooled: this.model.get('pcrWell').get('pooled'),
                pos: this.model.get('pcrWell').get('pos'),
                assayCode: this.model.get('pcrWell').get('refAssay') ? this.model.get('pcrWell').get('refAssay').get('code') : '',
                smpId: {
                    code: this.model.get('pcrWell').get('smpId').get('code')
                },
                runName: this.model.get('pcrWell').get('run').get('name'),
                valWst: this.model.get('pcrWell').get('valWst'),
                repeatStatus: this.model.get('pcrWell').get('repeatStatus'),
                tags: this.model.get('pcrWell').get('tags')
            };

            templateData.errorWarning = this.model.get('pcrWell').getDisplay('WELL').any(_.bind(function (r) {
                return r.get('codeErr') && r.get('codeErr') !== null && r.get('codeErr') !== '';
            }, this));
            templateData.popupMode = this.popupMode;

            templateData = _.extend(templateData, this.model.get('pcrWell').getErrorObject());

            var tags = this.model.get('pcrWell').get('tags');
            if (tags.length === 1 && tags[0] !== '' || tags.length > 1) {
                templateData.tags = tags.join(';');
            }

            templateData.mbAnaCode = this.model.get('refAssayResult').get('target').get('code');
            templateData.resCode = this.model.get('result') ? this.model.get('result').get('code') : null;
            templateData.quantification = this.model.get('quantification');
            templateData.comment = this.model.get('comment');
            templateData.codeErr = this.model.get('codeErr');
            templateData.allowToNeg = templateData.propC ? Object.keys(templateData.propC).some(function (k) {
                return ~k.indexOf('Amp');
            }) : false;
            templateData = _.extend(templateData, this.model.getResultIcon());
            templateData.propC = '';
            if (this.options.properties) {
                var amp1 = _.findWhere(this.options.properties, {name: 'Amp1'});
                var amp2 = _.findWhere(this.options.properties, {name: 'Amp2'});
                var c1 = _.findWhere(this.options.properties, {name: 'C1'});
                var c2 = _.findWhere(this.options.properties, {name: 'C2'});
                var slopeIndex = _.findWhere(this.options.properties, {name: 'SI'});
                var json = {};
                if (amp1) {
                    json.Amp1 = amp1.value;
                }
                if (amp2) {
                    json.Amp2 = amp2.value;
                }
                if (c1) {
                    json.C1 = c1.value;
                }
                if (c2) {
                    json.C2 = c2.value;
                }
                if (slopeIndex) {
                    json.SI = slopeIndex.value;
                }
                templateData.propC = JSON.stringify(json);
            }

            templateData.isOVAR = this.model.isOvar();
            templateData.canEdit = this.canEdit;

            templateData.css = 'gap: 2px;align-items: center;';

            return templateData;
        },

        onRender: function () {
            var view = new WellDisplaySampleView({
                model: this.model.get('pcrWell').get('smpId'),
                test: this.model.get('pcrWell').get('test')
            });
            this.getRegion('wellDisplaySample').show(view);
            App.trigger('forClipboard', this.ui.copyParameters);

            if (this.canEdit && !this.model.isOvar()) {
                var PcrWellService = require('services/caccounts/pcrwells');
                var codeErrView = new AutocompleteView(
                    this._getAutocompleteOptions('codeErr', 'code', 'code',
                        'rest/public/codeLists/code/ERRTYPE/elements?sord=asc&sidx=code',
                        'common.empty.placeholder', 'code',
                        this.onChangeCodeErr,
                        null, '',
                        PcrWellService.getErrCodeFieldsToDisplay()
                    )
                );
                this.getRegion('codeErrRegion').show(codeErrView);
            }
            if (this.canEdit) {
                var MbAnaResService = require('services/caccounts/mbanares');
                this.resGroupView = new AutocompleteView(
                    this._getAutocompleteOptionObject(MbAnaResService.getAutocompleteParam({
                        modelProperty: 'result',
                        callBackOnChange: this.onChangeResult,
                        values: {
                            mbAnaResGrSecId: this.model.get('refAssayResult').get('target').get('refMbAnaResGr').get('id')
                        },
                        canAddOrEdit: false
                    })));
                this.getRegion('mbAnaResRegion').show(this.resGroupView);
            }
            PcrWellResultController.show(this.model, 'Well', this.getRegion('resultRegion'));
            if (this.model.isOvar() && this.model.get('result')) {
                this.ui.resultLine.css('background-color', ColorUtils.rgbaString(ColorUtils.hexToRgba(ColorUtils.defineAlpha(this.model.get('result').get('color'), '30'))));
            }
            if (this.model.get('result') && this.model.get('result').get('code') === 'P') {
                if (!_.any(this.fieldsToValidate, function (field) {
                    return field.name === 'cts';
                })) {
                    this.fieldsToValidate.push({name: 'cts', type: 'required'});
                }
            } else {
                this.fieldsToValidate = _.reject(this.fieldsToValidate, function (field) {
                    return field.name === 'cts';
                });
            }
            this.setPermissions(Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.MRES));
        },

        onTagsChange: function (e) {
            var $target = $(e.currentTarget),
                tags = $target.val();

            var model = {
                'tags': tags.split(/[,;\s]/)
            };
            this.model.get('pcrWell').set(model, {silent: true});
            this.triggerMethod('enable:cancel:confirm');
        },

        onSampleCommentChange: function (e) {
            var $target = $(e.currentTarget),
                sampleComment = $target.val();

            var model = {
                'sampleComment': sampleComment
            };
            this.model.get('pcrWell').set(model, {silent: true});
            this.triggerMethod('enable:cancel:confirm');
        },

        onConfirm: function () {
            $('.js-global-loader').show();
            this.model.save()
                .done(_.bind(this.hide, this))
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        hide: function () {
            this.model.fetch().always(_.bind(function () {
                this.model.get('pcrWell').fetch().always(_.bind(function () {
                    this.box.modal('hide');
                    $('.js-global-loader').hide();
                }, this));
            }, this));
        },

        onChangeCodeErr: function (fieldName, model) {
            if (model) {
                model = model.get('code');
            }
            this.onChange(fieldName, model);
        },

        onChangeResult: function (fieldName, model) {
            if (model) {
                if (model.get('code') === 'N') {
                    this.model.set({cts: [], quantifications: []}, {silent: true});
                }
                var MbAnaResService = require('services/caccounts/mbanares');
                var errorParameter = MbAnaResService.getErrorParameter(model);
                model.codeErr = errorParameter.code;
                model.commentErrAuto = errorParameter.message;
                model.justificatory = errorParameter.message;
                if (this.model.isOvar()) {
                    this.ui.resultLine.css('background-color', ColorUtils.rgbaString(ColorUtils.hexToRgba(ColorUtils.defineAlpha(model.get('color'), '30'))));
                }
            }
            this.onChange(fieldName, model);
        },

        onChangeValue: function () {
            var mbAnaRes;
            if ((this.model.get('cts') || this.model.get('quantifications')) && this.model.get('result').get('code') === 'N') {
                mbAnaRes = this.model.get('result').get('refMbAnaResGr').get('mbAnaRes').findWhere({code: 'P'});
                if (mbAnaRes) {
                    this.model.set({'result': mbAnaRes}, {silent: true});
                }
            } else if (_.isEmpty(this.model.get('cts')) && (!this.model.get('result') || this.model.get('result').get('code') !== 'N')) {
                mbAnaRes = this.model.get('result').get('refMbAnaResGr').get('mbAnaRes').findWhere({code: 'N'});
                if (mbAnaRes) {
                    this.model.set({'result': mbAnaRes}, {silent: true});
                }
            }
            this.render();
        },

        onChangeCts: function (e) {
            var $target = $(e.currentTarget);
            var cts = $target.val().split(';');
            if (cts.length === 1 && cts[0] === '') {
                cts = [];
            }
            this.model.set('cts', cts);
        },

        onChangeQuanfitications: function (e) {
            var $target = $(e.currentTarget);
            var quantifications = $target.val().split(';');
            if (quantifications.length === 1) {
                quantifications[0] = parseFloat(quantifications[0]);
            }
            this.model.set('quantifications', quantifications);
        }
    });
});

