define('assayResultView',[
    'module',
    'backbone.marionette',
    'template!assayResultTpl',
    'underscore',
    'jquery',
    'autocompleteView',
    'dialogFormView',
    'arrayCustomCurveView',
    'arrayCriCurveView',
    'arrayCustomAssayResultView',
    'arrayInputView',
    'dynamicCreateEditTypeParam'
], function (
    module,
    Marionette,
    Tpl,
    _,
    $,
    AutocompleteView,
    DialogFormView,
    ArrayCustomCurveView,
    ArrayCriCurveView,
    ArrayCustomAssayResultView,
    ArrayInputView,
    TypeParamView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input',
            dropDownInput: '.js-dropDownRegion-input',
            ic: '.js-ic'
        },

        className: 'assayResult',

        events: {
            'focus @ui.input': 'onFocus',
            'focus @ui.dropDownInput': 'onDropdownFocus',
            'change @ui.input': 'onInputChange',
            'click .delete-row': 'onDelete',
            'click .js-radio': 'onClickRadioButton',
            'change .js-radio': 'onChangeRadioButton'
        },

        regions: {
            selectMbAna: '.js-select-mbana-region',
            selectAlgoResult: '.js-algoResult',
            selectAlgoValidation: '.js-algoValidation',
            selectAlgoExport: '.js-algoExport'
        },

        modelEvents: {
            'change:target': 'renderMbAna',
            'change:ic': 'render',
            'change:algoResult': 'renderAlgoResult',
            'change:algoExport': 'renderAlgoExport',
            'change:algoValidation': 'renderAlgoValidation',
            'change:sequence': 'render'
        },

        triggers: {
            'change .js-sequence': 'changeSequence'
        },

        initialize: function () {
            this.listenTo(this.model.get('assayConfiguration'), 'change:cyclerPublic', _.bind(this.renderAlgoResult, this));
        },
        serializeData: function () {
            var templateData = this.model.toJSON();
            templateData.code = this.model.get('assayConfiguration').get('code');
            templateData.id = this.model.get('id') ? this.model.get('id') : '';
            return templateData;
        },

        onClickRadioButton: function (e) {
            if (e.currentTarget.attributes['data-field-name'].value === 'ic' && this.model.get('ic') && e.currentTarget.checked) {
                this.model.set('ic', false);
            }
        },
        onChangeRadioButton: function (e) {
            if (e.currentTarget.attributes['data-field-name'].value === 'ic') {
                this.model.get('assayConfiguration').get('results').each(function (model) {
                    var data = [];
                    data.ic = false;
                    var previousVar = model.get('ic');
                    model.set(data, {silent: true});
                    if (previousVar !== model.get('ic')) {
                        model.trigger('change:ic');
                    }
                });
                this.model.set('ic', e.currentTarget.checked);
            }
        },

        onRender: function () {
            this.renderMbAna();
            this.renderAlgoResult();
        },
        renderAlgoValidation: function () {
            var CodeListService = require('services/admin/codelist');
            var selectAlgoValidationtView = new TypeParamView({
                fieldName: 'algoValidation',
                model: this.model,
                getAutocompleteParam: CodeListService.getAutocompleteParamForCode({codeListCode: 'ALGO-VALAUT'}),
                readOnly: this.model.get('algoResult') && ['SPO_OVAR', 'COMB', 'COMBCUTOFF', 'SCID'].includes(this.model.get('algoResult').type),
                codeListCode: 'ALGO-VALAUT'
            });
            this.getRegion('selectAlgoValidation').show(selectAlgoValidationtView);
        },
        renderAlgoExport: function () {
            var CodeListService = require('services/admin/codelist');
            var selectAlgoExportView = new TypeParamView({
                fieldName: 'algoExport',
                model: this.model,
                getAutocompleteParam: CodeListService.getAutocompleteParamForCode({codeListCode: 'ALGOTEXPORT'}),
                readOnly: this.model.get('algoResult') && this.model.get('algoResult').type === 'SPO_OVAR',
                codeListCode: 'ALGOTEXPORT'
            });
            this.getRegion('selectAlgoExport').show(selectAlgoExportView);
        },
        renderMbAna: function () {
            var MbAnaService = require('services/caccounts/mbanas');
            this.autocompleteMbAnaView = new AutocompleteView(
                this._getAutocompleteOptionObject(MbAnaService.getAutocompleteParam({
                    modelProperty: 'target',
                    callBackAdd: this.onAddMbAna,
                    callBackEdit: this.onEditMbANa,
                    model: this.model.get('target')
                })));
            this.getRegion('selectMbAna').show(this.autocompleteMbAnaView);
        },
        renderAlgoResult: function () {
            var CodeListService = require('services/admin/codelist');
            var selectAlgoResultView = new TypeParamView({
                fieldName: 'algoResult',
                model: this.model,
                getAutocompleteParam: CodeListService.getAutocompleteParamForCode({codeListCode: 'ALGO-RES'}),
                customInput: _.bind(this.onShowAlgoRes, this),
                display: {
                    type: {
                        'class': 'col-w-20'
                    },
                    param: {
                        'class': 'col-w-80'
                    }
                },
                codeListCode: 'ALGO-RES'
            });
            this.getRegion('selectAlgoResult').show(selectAlgoResultView);
            if (this.model.get('algoResult')) {
                if (this.model.get('algoResult').type === 'SPO_OVAR') {
                    this.model.set({
                        algoExport: {type: 'NO', param: null},
                        algoValidation: {type: 'AUT', param: '2'}
                    }, {silent: true});
                } else if (['COMB', 'COMBCUTOFF', 'SCID'].includes(this.model.get('algoResult').type)) {
                    this.model.set({
                        algoValidation: {type: 'AUT', param: '2'}
                    }, {silent: true});
                }
            }
            this.renderAlgoExport();
            this.renderAlgoValidation();
        },

        onShowAlgoRes: function () {
            switch (this.model.get('algoResult').type) {
                case 'MUTV':
                case 'AMP':
                case 'AMP_IC':
                    return new ArrayCustomCurveView({
                        model: this.model,
                        cyclerPublicSecId: this.model.get('assayConfiguration').get('cyclerPublic') ? this.model.get('assayConfiguration').get('cyclerPublic').get('id') : undefined
                    });
                case 'CRI':
                    return new ArrayCriCurveView({
                        model: this.model,
                        cyclerPublicSecId: this.model.get('assayConfiguration').get('cyclerPublic') ? this.model.get('assayConfiguration').get('cyclerPublic').get('id') : undefined
                    });
                default:
                    return new ArrayCustomAssayResultView({
                        model: this.model,
                        fieldName: 'algoResult',
                        fieldNameCode: this.model.get('algoResult').type
                    });
            }
        },

        onEditMbANa: function (id) {
            var MbAnaService = require('services/caccounts/mbanas');
            var model = MbAnaService.getModel({id: id});
            model.fetch().done(_.bind(function () {
                this.showEditAddMbAnaView(model, false);
            }, this));
        },

        onAddMbAna: function () {
            var MbAnaService = require('services/caccounts/mbanas');
            var model = MbAnaService.getModel();
            this.showEditAddMbAnaView(model, true);
        },

        showEditAddMbAnaView: function (model, newItem) {
            require(['mbAnaCreateEditLayoutView'], _.bind(function (MbAnaCreateEditLayoutView) {
                var createEditView = new MbAnaCreateEditLayoutView({
                    model: model,
                    navigateAfterSaving: false,
                    newItem: newItem
                });
                createEditView.on('saved', _.bind(function (model) {
                    this.model.set('target', model.attributes);
                    this.autocompleteMbAnaView.options.value = model.get('code');
                    this.autocompleteMbAnaView.render();
                }, this));
                createEditView.show({
                    title: _.i18n('menu.admin.mbanas'),
                    className: 'baseTableEditAddPopup'
                });
            }, this));
        },

        onDelete: function () {
            this.trigger('item:delete', this.model);
        }
    });
});

