define('adminEntityErrorView',[
    'module',
    'backbone.marionette',
    'underscore',
    'jquery',
    'template!adminEntityErrorView',
    'settings',
    'rolesMixin',
    'privileges',
    'jqGridController',
    'jqGridFormatter'
], function (
    module,
    Marionette,
    _,
    $,
    Tpl,
    Settings,
    rolesMixin,
    privileges,
    JqGridController,
    jqGridFormatter
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,
        regions: {
            grid: '.js-grid'
        },

        className: 'dynamic entityError d-f column',

        attributes: {
            style: 'background-color: white; padding: 10px;'
        },

        events: {
            'click .js-confirm': 'onConfirm'
        },

        serializeData: function () {
            return {readOnly: !Settings.get('currentUserModel').hasType(rolesMixin.SETTING, privileges.WRITE)};
        },

        paginationObject: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: 999,
                currentPage: 1
            };
        },

        onRender: function () {
            var data = this.options.collection.map(function (model) {
                return {id: model.id, code: model.get('code'), name: _.i18n('entityError.' + model.get('code'))};
            });
            this.gridView = JqGridController.show({
                pager: true,
                filtersName: 'entityError',
                gridOptions: _.bind(this.gridOptions, this),
                data: {data: data},
                selectable: true,
                paginationObject: this.paginationObject
            }, this.getRegion('grid'));
            this.listenTo(this.gridView, 'gridComplete', _.bind(this.onGridComplete, this));
        },
        onGridComplete: function (gridView) {
            this.options.collection.forEach(function (model) {
                if (model.get('enable')) {
                    gridView.getGrid().jqGrid('setSelection', model.id, true);
                }
            });
        },
        onConfirm: function () {
            var rowsChecked = _.pluck(this.gridView.getCheckedRowsList(), 'id');
            this.options.collection.forEach(function (model) {
                model.set({enable: _.contains(rowsChecked, model.id)});
            });
            this.options.collection.save();
        },
        gridOptions: function () {
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'id',
                        key: true,
                        hidden: true
                    }, {
                        label: _.i18n('code'),
                        name: 'code',
                        formatter: jqGridFormatter.defaultFormatter,
                        sortable: true,
                        search: true,
                        index: 'code',
                        width: 45
                    }, {
                        label: _.i18n('description'),
                        name: 'name',
                        formatter: jqGridFormatter.defaultFormatter,
                        sortable: false,
                        search: true,
                        index: 'name'
                    }
                ],
                sortname: this.sortOn ? this.sortOn : 'code'
            };
            return options;
        }
    });
});

