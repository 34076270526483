define('pcrWellToolTipDotItemView',[
    'module',
    'backbone.marionette',
    'template!pcrWellToolTipDotItemView',
    'pcrWellResultController'
], function (
    module,
    Marionette,
    Tpl,
    PcrWellResultController
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,
        regions: {
            result: '.js-result'
        },

        className: 'd-f m-b-3 col-w-100',
        attributes: {
            style: 'color: white;'
        },

        serializeData: function () {
            var errMessComToDisplayList = '';
            var commentsList = [];

            if (this.model.get('codeErr') && this.model.get('codeErr') !== '') {
                commentsList.push(this.model.get('codeErr'));
            }
            if (this.model.get('justificatory') && this.model.get('justificatory') !== '') {
                commentsList.push(this.model.get('justificatory'));
            }
            if (this.model.get('comment') && this.model.get('comment') !== '') {
                commentsList.push(this.model.get('comment'));
            }

            if (commentsList.length > 0) {
                errMessComToDisplayList = commentsList.join(' - ');
            }
            return {
                targetCode: this.model.get('refAssayResult') && this.model.get('refAssayResult').get('target').get('code'),
                resCode: this.model.get('result') ? this.model.get('result').get('code') : '',
                cts: this.model.get('cts'),
                quantifications: this.model.get('quantifications'),
                errMessComToDisplayList: errMessComToDisplayList
            };
        },
        onRender: function () {
            PcrWellResultController.show(this.model, 'TooltipDot', this.getRegion('result'));
        }
    });
});
