/**
 * Created by Matteo on 11/07/2015.
 */
define('sidebarShowView',[
    'module',
    'backbone.marionette',
    'underscore',
    'settings',
    'template!sidebarShowView',
    'bootbox',
    'jquery',
    'menuFooter',
    'menuView',
    'app'
], function (
    module,
    Marionette,
    _,
    Settings,
    viewTpl,
    Bootbox,
    $,
    MenuFooter,
    MenuView,
    App
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: viewTpl,

        ui: {
            quickSearchSample: '.js-quickSearch-sample',
            quickSearchSampleValue: '.js-quickSearch-sample-value'
        },

        events: {
            'click @ui.quickSearchSample': 'onQuickSearchSampleClick',
            'keydown @ui.quickSearchSampleValue': 'onQuickSearchSampleKeyDown'
        },

        regions: {
            menu: '.js-menu-region',
            footer: '.js-footer-region'
        },

        className: 'sidebar',

        attributes: function () {
            return {
                style: 'height: 100%; display: flex; flex-direction: column;'
            };
        },

        serializeData: function () {
            return {};
        },

        onQuickSearchSampleKeyDown: function (e) {
            if ((e.keyCode === 13 || e.keyCode === 9) && this.ui.quickSearchSampleValue.val()) {
                e.preventDefault();
                e.stopPropagation();
                this.onQuickSearchSampleClick();
            }
        },

        onQuickSearchSampleClick: function () {
            $('.js-global-loader').show();
            var sampleSearch = this.ui.quickSearchSampleValue.val();
            var SampleService = require('services/caccounts/samples');
            SampleService.findByStartSample(sampleSearch).done(_.bind(function (samples) {
                if (samples.isEmpty()) {
                    Bootbox.alert(_.i18n('quickSearch.sample.notFound'));
                } else if (samples.size() === 1) {
                    var sampleModel = samples.first();
                    sampleModel.fetch().done(_.bind(function () {
                        require(['samplesController'], _.bind(function (Controller) {
                            Controller.showDetails({model: sampleModel});
                            this.ui.quickSearchSampleValue.val('');
                        }, this));
                    }, this));
                } else {
                    var postData = {filters: '{"groupOp":"AND","rules":[]}'};
                    postData.code = sampleSearch;
                    Settings.setToMemory(SampleService.getJqGridFilterName(), postData);
                    App.navigate('samples', {trigger: true});
                }
            }, this))
                .always(function () {
                    $('.js-global-loader').hide();
                });
        },

        onRender: function () {
            var region = this.getRegion('menu');
            this.menuView = new MenuView({
                model: this.options.menu,
                collection: this.options.menu.get('subMenu'),
                menuView: MenuView,
                settings: this.options.settings
            });
            region.show(this.menuView);

            var SystemService = require('services/system');
            SystemService.getInformation().done(_.bind(function (information) {
                if (!this.isDestroyed()) {
                    this.getRegion('footer').show(new MenuFooter({information: information}));
                }
            }, this));
        },

        setActive: function (name) {
            var model = this.menuView.findByName(name);
            this.menuView.setActive(model);
        }
    });
});

