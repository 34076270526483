define('services/caccounts/assayVersion',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'settings',
    'jquery',
    'entities/caccounts/assayVersion'
], function (
    app,
    _,
    module,
    Dynamic,
    Settings,
    $
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.AssayVersion,
                collection: app.AssayVersionCollection
            };
        },
        getName: function () {
            return 'assayversion';
        },
        getUrl: function () {
            return 'rest/v2/assayVersions/';
        },
        getDynamicJson: function () {
            return 'AssayVersionJson';
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name', 'refKitProt.code']};
        },
        findByWell: function (well) {
            var deferred = $.Deferred();

            $.ajax({
                url: Settings.url('rest/v2/assayVersions/byWell/' + well.id),
                success: _.bind(function (data) {
                    this.getModel(data);
                    deferred.resolve();
                }, this)
            });
            return deferred.promise();
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('assay'),
                name: 'assay.code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'assay.code',
                width: 50
            }, {
                label: _.i18n('id'),
                name: 'id',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'id',
                width: 20
            }, {
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 70
            }, {
                label: _.i18n('assayVersion.configurationCode'),
                name: 'configurationCode',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'configurationCode',
                width: 70
            }, {
                label: _.i18n('creatDate'),
                name: 'creatDate',
                formatter: viewDynamic.dateFormatterFromSettings,
                search: true,
                index: 'creatDate',
                width: 40
            }, {
                label: _.i18n('run.prot'),
                name: 'kitProt.code',
                classes: 'kitc-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'kitProt.code',
                width: 40
            }, {
                label: _.i18n('assay.groupName'),
                name: 'assayGroup.code',
                classes: 'assay-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'assayGroup.code',
                width: 40
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'assay-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                classes: 'assay-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'comment'
            });
            return columns;
        },
        getJqGridSidx: function () {
            return 'code, configurationCode, creatDate';
        }
    }, Dynamic);
});

