define('linkNewTabView',[
    'module',
    'backbone.marionette',
    'underscore'
], function (
    module,
    Marionette,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<%= code %><span class="mdi mdi-open-in-new js-run clickable"></span>'),

        className: 'clickable openNewTab',
        tagName: 'div',

        events: {
            'click': 'onClick'
        },
        attributes: function () {
            return {
                'data-id': this.options.id,
                title: this.options.name
            };
        },

        initialize: function (options) {
            if (!options.service) {
                throw new Error('service is required');
            }
        },

        serializeData: function () {
            return {code: this.options.code};
        },

        onClick: function () {
            window.open(window.location.origin + window.location.pathname + this.options.service.getRoute() + '/' + this.options.id, '_blank');
        }
    });
});
