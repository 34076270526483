define('entities/admin/cyclers',[
    'entities/ns',
    'settings',
    'backbone',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Settings,
    Backbone,
    RolesMixin
) {
    'use strict';

    app.CyclerPublic = Backbone.RelationalModel.extend({
        service: 'services/admin/cyclers',
        fetch: function () {
            this.url = Settings.url('rest/public/cyclers/' + this.get('id'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': undefined,
            'name': undefined,
            'plateSize': undefined,
            'colorCompensation': false,
            'plateFillDirection': 'C',
            'inputPlateLayoutService': '',
            'outputPlateLayoutService': '',
            'inputResultService': '',
            'customInputPlateLayout': '',
            'customOutputPlateLayout': '',
            'customInputResult': '',
            'description': undefined
        },
        idAttribute: 'id',
        relations: [{
            type: Backbone.HasOne,
            key: 'refDetectionFormat',
            relatedModel: 'DetectionFormat',
            includeInJSON: ['id', 'code', 'name']
        }],


        postUrl: function () {
            return Settings.url('rest/public/cyclers/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'CyclerPublicJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Cycler'
    });

    app.CyclerPublic.role = RolesMixin.SUPER_USER;

    app.CyclerPublicCollection = Backbone.Collection.extend({
        model: app.CyclerPublic
    });
});

