define('entities/caccounts/pcrruns',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'moment',
    'rolesMixin',
    'app',
    'bootbox',
    'customBootboxMessage',
    'entities/caccounts/curves',
    'entities/caccounts/assay',
    'entities/caccounts/assayVersion',
    'entities/caccounts/pcrwells',
    'entities/caccounts/cyclers',
    'entities/caccounts/kitprots',
    'entities/caccounts/displayModes',
    'entities/caccounts/userpids',
    'entities/admin/dye',
    'entities/caccounts/mbanaresgrs'
], function (
    app,
    Backbone,
    Settings,
    $,
    _,
    moment,
    RolesMixin,
    App,
    bootbox,
    CustomBootboxMessage
) {
    'use strict';

    app.PcrRun = Backbone.RelationalModel.extend({
        service: 'services/caccounts/pcrruns',
        fetch: function (options) {
            this.url = Settings.url('rest/v2/runs/pcr/' + this.get('id'));
            if (!options) {
                return Backbone.Model.prototype.fetch.call(this);
            }
            var defer = $.Deferred();
            Backbone.Model.prototype.fetch.call(this).done(_.bind(function () {
                var promise;
                if (options.wells) {
                    promise = this.get('wells').fetch({pcrRunSecId: this.get('id')});
                } else {
                    promise = $.Deferred().resolve();
                }
                promise.done(function () {
                    defer.resolve();
                });
            }, this));
            return defer.promise();
        },

        defaults: {
            'name': '',
            'creatDate': null,
            'modDate': null,
            'plateSize': 'H12',
            'comment': '',
            'wells': [],
            'refModUser': null,
            'refCreatUser': null,
            'refRespUser': null,
            'toBePooled': true,
            'period': '',
            'periodFormatted': '',
            'group': '',
            'sequence': 0,
            'sequenceFormat': '',
            'pattern': '',
            'suffix': '',
            'assays': [],
            'plateIdPC': '',
            'refLmbCycler': null,
            'refKitProt': null,
            'status': 0,
            'valStatus': '',
            'exported': true,
            'type': '',
            'department': '',
            'archived': false,
            'archivedDate': '',
            'prepRuns': [],
            'hasColorCompensation': false,
            'assayVersions': [],
            'displayMode': null
        },
        idAttribute: 'id',

        relations: [{
            type: Backbone.HasMany,
            key: 'wells',
            relatedModel: 'PcrWell',
            collectionType: 'PcrWellCollection',
            includeInJSON: false
        }, {
            type: Backbone.HasMany,
            key: 'prepRuns',
            relatedModel: 'PrepRun',
            collectionType: 'PrepRunCollection',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'assays',
            relatedModel: 'Assay',
            collectionType: 'AssayCollection',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'assayVersions',
            relatedModel: 'AssayVersion',
            collectionType: 'AssayVersionCollection',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'refCycler',
            relatedModel: 'Cycler',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'refCyclerPublic',
            relatedModel: 'CyclerPublic',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'refKitProt',
            relatedModel: 'KitProt',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'refRespUser',
            relatedModel: 'UserPid',
            includeInJSON: ['id', 'firstName', 'lastName', 'email', 'nickName', 'logonId']
        }, {
            type: Backbone.HasOne,
            key: 'displayMode',
            relatedModel: 'DisplayMode',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'dyes',
            relatedModel: 'Dye',
            collectionType: 'DyeCollection',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'mbAnaResGrs',
            relatedModel: 'MbAnaResGr',
            collectionType: 'MbAnaResGrCollection',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'refLmbCycler',
            relatedModel: 'LmbCycler',
            includeInJSON: ['id', 'code', 'name']
        }],
        getUrl: function () {
            return 'rest/v2/runs/pcr/';
        },

        getType: function () {
            return 'PcrRun';
        },

        getLine: function () {
            return this.get('plateSize').substring(0, 1);
        },
        getColumn: function () {
            return parseInt(this.get('plateSize').substring(1), 10);
        },

        postUrl: function () {
            return Settings.url('rest/v2/runs/pcr/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        saveHeader: function () {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/header'),
                defer = $.Deferred();

            var rubKitProtSecId = this.get('refKitProt') ? this.get('refKitProt').id : null;
            var runLmbCyclerSecId = this.get('refLmbCycler') ? this.get('refLmbCycler').id : null;
            var cyclerSecId = this.get('refCycler') ? this.get('refCycler').id : null;
            var cyclerPublicSecId = this.get('refCyclerPublic') ? this.get('refCyclerPublic').id : null;
            var refRespUserSecId = this.get('refRespUser') ? this.get('refRespUser').id : null;

            var body = {
                name: this.get('name'),
                status: this.get('status'),
                period: this.get('period'),
                department: this.get('department'),
                group: this.get('group'),
                sequence: this.get('sequence'),
                suffix: this.get('suffix'),
                pattern: this.get('pattern'),
                plateId: this.get('plateIdPC'),
                kitProtId: rubKitProtSecId,
                lmbCyclerId: runLmbCyclerSecId,
                comment: this.get('comment'),
                respUserId: refRespUserSecId,
                cyclerId: cyclerSecId,
                cyclerPublicId: cyclerPublicSecId
            };

            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(body),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        copyPlateLayoutFrom: function (model) {
            var request = {
                sourceSecId: model.get('sourceRun').get('id'),
                sourceStart: model.get('wellSelection').get('start'),
                sourceEnd: model.get('wellSelection').get('to'),
                sourcePlateFillDirection: model.get('wellSelection').get('direction'),
                copySampleType: model.get('copySampleType'),
                copySampleId: model.get('copySampleId'),
                copyAssay: model.get('copyAssay'),
                targetStart: model.get('wellTarget').get('start'),
                targetPlateFillDirection: model.get('wellTarget').get('direction')
            };
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/copyPlateLayoutFrom'),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                contentType: 'application/json',
                data: JSON.stringify(request),
                success: _.bind(function () {
                    this.fetch({wells: true}).done(function () {
                        defer.resolve();
                    });
                }, this),
                error: _.bind(function (err) {
                    this.fetch().done(function () {
                        defer.reject(err);
                    });
                }, this)
            });
            return defer.promise();
        },

        importFilePlateLayout: function (model) {
            var param;

            param = {
                fileFormatSecId: model.get('fileFormat').get('id')
            };

            var defer = $.Deferred(),
                formdata = new FormData(),
                url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/plateLayout/import', param);

            formdata.append('file', model.get('files')[0]);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch({wells: true}).done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        importFileSampleList: function (model) {
            var param = {
                fileFormatSecId: model.get('fileFormat').get('id'),
                startPos: model.get('wellSelection').get('start'),
                plateFillDirection: model.get('wellSelection').get('direction')
            };

            var defer = $.Deferred(),
                formdata = new FormData(),
                url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/sampleList/import', param);

            formdata.append('file', model.get('files')[0]);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: _.bind(function () {
                    this.fetch({wells: true}).done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        exportFilePlateLayout: function (model) {
            var defer = $.Deferred();
            var url;
            if (model.get('fileFormat')) {
                url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/plateLayout/export', {
                    fileFormatSecId: model.get('fileFormat').get('id'),
                    downloadFile: model.get('downloadFile')
                });
            } else {
                url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/plateLayout/exportByCycler', {
                    downloadFile: model.get('downloadFile')
                });
            }
            if (model.get('downloadFile')) {
                this._downloadFile(url);
                defer.resolve(false);
            } else {
                $.ajax({
                    type: 'GET',
                    url: url,
                    success: function () {
                        defer.resolve(true);
                    },
                    error: function (error) {
                        defer.reject(error);
                    }
                });
            }
            return defer.promise();
        },

        exportFileSampleList: function (model) {
            var defer = $.Deferred();
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/sampleList/export', {
                fileFormatSecId: model.get('fileFormat').get('id'),
                downloadFile: model.get('downloadFile')
            });

            if (model.get('downloadFile')) {
                this._downloadFile(url);
                defer.resolve(false);
            } else {
                $.ajax({
                    type: 'GET',
                    url: url,
                    success: function () {
                        defer.resolve(true);
                    },
                    error: function (error) {
                        defer.reject(error);
                    }
                });
            }
            return defer.promise();
        },

        importResult: function (model) {
            var param = {
                cyclerSecId: model.get('cycler').get('id'),
                userpidSecId: model.get('respUser').get('id')
            };

            var defer = $.Deferred(),
                formdata = new FormData(),
                url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/result/import', param);

            formdata.append('file', model.get('files')[0]);

            $.ajax({
                type: 'POST',
                url: url,
                data: formdata,
                processData: false,
                contentType: false,
                success: _.bind(function (logs) {
                    var content = '<ul>';
                    logs = _.filter(logs, function (log) {
                        return log.action !== 'RES_IMP' && !(log.action === 'RUN_RECALC' && !log.comment);
                    });
                    _.map(logs, function (log) {
                        content += '<li><ul>' +
                            '<li> LEVEL : ' + log.level + '</li>' +
                            '<li> ACTION : ' + log.action + '</li>' +
                            '<li> WELLS : ' + log.wells.join(', ') + '</li>' +
                            '<li> COMMENT : ' + log.comment + '</li>' +
                            '</ul></li>';
                    });
                    content += '</ul>';
                    if (logs.length > 0) {
                        bootbox.alert({
                            title: _.i18n('pcr.result.import') + ' - ' + this.get('code'),
                            message: content
                        });
                    } else {
                        CustomBootboxMessage.customThumbUpNotification();
                    }

                    this.fetch({wells: true}).done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        _downloadFile: function (url) {
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'blob';
            xhr.setRequestHeader('Authorization', 'Bearer ' + App.keycloak.token);

            xhr.onload = function () {
                if (xhr.status === 200) {
                    // Extraire le nom de fichier du header Content-Disposition
                    var disposition = xhr.getResponseHeader('Content-Disposition');
                    var matches = /filename="?([^"]+)"?;?/i.exec(disposition);
                    var filename = matches !== null && matches.length > 1 ? matches[1] : 'file';

                    // Créer un lien de téléchargement
                    var blob = new Blob([xhr.response], {type: 'application/octet-stream'});
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = filename;

                    // Déclencher le téléchargement
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            };

            xhr.send();
        },

        exportResult: function (json) {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/export/result'),
                defer = $.Deferred();

            $.ajax({
                method: 'post',
                url: url,
                data: json ? JSON.stringify(json) : null,
                contentType: 'application/json',
                dataType: 'json',
                success: function (response) {
                    defer.resolve(response);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        validation2: function () {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/validation2'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch({wells: true}).done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        exportList: function (type) {
            var params = {
                type: type
            };

            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/export/list', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (response) {
                    defer.resolve(response);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },

        getGeneratePeriodFormatted: function (int64Date) {
            var params = {
                period: int64Date
            };
            var url = Settings.url('rest/v2/runs/pcr/generatePeriodFormatted', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: function (result) {
                    defer.resolve(result);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        availableRunName: function () {
            var params = {
                runName: this.get('name')
            };

            var url = Settings.url('rest/v2/runs/pcr/availableRunName', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        generateRunName: function () {
            var params = {
                pattern: this.get('pattern'),
                department: this.get('department'),
                group: this.get('group'),
                period: this.get('period') ? Number(moment(this.get('period')).format('x')) : null,
                sequenceFormat: this.get('sequenceFormat'),
                sequence: this.get('sequence'),
                suffix: this.get('suffix')
            };
            var url = Settings.url('rest/v2/runs/pcr/generateRunName', params),
                defer = $.Deferred();

            $.ajax({
                type: 'GET',
                url: url,
                success: _.bind(function (resp) {
                    // this.set('name', resp);
                    defer.resolve(resp);
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        refreshData: function () {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/refreshResult'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function (logs) {
                    var content = '<ul>';
                    logs = _.filter(logs, function (log) {
                        return !(log.action === 'RUN_RECALC' && !log.comment);
                    });
                    _.map(logs, function (log) {
                        content += '<li><ul>' +
                            '<li> LEVEL : ' + log.level + '</li>' +
                            '<li> ACTION : ' + log.action + '</li>' +
                            '<li> WELLS : ' + log.wells.join(', ') + '</li>' +
                            '<li> COMMENT : ' + log.comment + '</li>' +
                            '</ul></li><br/>';
                    });
                    content += '</ul>';
                    if (logs.length > 0) {
                        bootbox.alert({
                            title: _.i18n('pcr.run.refreshData') + ' - ' + this.get('code'),
                            message: content
                        });
                    } else {
                        CustomBootboxMessage.customThumbUpNotification();
                    }

                    this.fetch({wells: true}).done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },

        reloadCurves: function () {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/reloadCurves'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch({wells: true}).done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },

        applyAssayWells: function (model) {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/wells/assay/assign', {
                assaySecId: model.get('assay').get('id'),
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch({wells: true}).done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        replaceAssayWells: function (model) {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/wells/assay/replace', {
                assayReplaceSecId: model.get('assay').get('id'),
                assayNewSecId: model.get('newAssay').get('id'),
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch({wells: true}).done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        applyTestWells: function (model) {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/wells/test/add"', {
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            }), defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch({wells: true}).done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        clearAssay: function (model) {
            var params = {
                assaySecId: model.get('assay').get('id'),
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            };

            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/wells/assay/clear', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch({wells: true}).done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        removeTest: function (model) {
            var params = {
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            };

            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/wells/test/remove', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch({wells: true}).done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        clearWells: function (model) {
            var params = {
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            };

            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/wells/clear', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch({wells: true}).done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });

            return defer.promise();
        },

        generateSampleId: function (sampleIds) {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/wells/generateSampleId'),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                contentType: 'application/json',
                data: JSON.stringify(sampleIds),
                success: _.bind(function () {
                    this.fetch({wells: true}).done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        clearSampleId: function (model) {
            var params = {
                from: model.get('wellSelection').get('start'),
                to: model.get('wellSelection').get('to'),
                direction: model.get('wellSelection').get('direction')
            };

            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/wells/clearSampleId', params),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function () {
                    this.fetch({wells: true}).done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },
        getPreviousRun: function () {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/previous'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function (resp) {
                    defer.resolve(resp);
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        getNextRun: function () {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/next'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: _.bind(function (resp) {
                    defer.resolve(resp);
                }, this),
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        controlSample: function () {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/samples/control'),
                defer = $.Deferred();

            $.ajax({
                method: 'get',
                url: url,
                success: function (controlSampleResponse) {
                    defer.resolve(controlSampleResponse);
                },
                error: function (error) {
                    defer.reject(error);
                }
            });
            return defer.promise();
        },

        /**
         * Find pcrKitLot for a pcr runs
         * @returns {*|jQuery}
         */
        getPcrKitLots: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/kitLots');

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function (responses) {
                    var AssayService = require('services/caccounts/assay');
                    var PcrKitService = require('services/caccounts/kitspcr');
                    var PcrReagentService = require('services/caccounts/pcrreagents');
                    var PcrKitLotService = require('services/caccounts/kitlotpcr');
                    responses = _.map(responses, function (response) {
                        return {
                            refAssay: AssayService.getModel(response.refAssay),
                            assayReagents: _.map(response.assayReagents, function (assayReagent) {
                                return {
                                    kit: PcrKitService.getModel(assayReagent.kit),
                                    reagent: PcrReagentService.getModel(assayReagent.reagent),
                                    pcrKitLots: _.map(assayReagent.pcrKitLots, function (pcrKitLot) {
                                        return {
                                            refKitLot: PcrKitLotService.getModel(pcrKitLot.refKitLot),
                                            count: pcrKitLot.count,
                                            countInPrepWell: pcrKitLot.countInPrepWell,
                                            volumeByWell: pcrKitLot.volumeByWell
                                        };
                                    })
                                };
                            })
                        };
                    });

                    defer.resolve(responses);
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        exportPlateLayout: function (params) {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/plateLayout/exportByCycler', params),
                defer = $.Deferred();
            $.ajax({
                method: 'GET',
                url: url,
                contentType: 'application/json',
                success: function (response, ignored, header) {
                    defer.resolve(header.getAllResponseHeaders().includes('filename'), header.getResponseHeader('content-disposition') ? header.getResponseHeader('content-disposition').substring(header.getResponseHeader('content-disposition').indexOf('filename=') + 9) : null, response);
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },

        canAssignSID: function () {
            return this.get('wells').filter(function (pcrWell) {
                return pcrWell.canChangeSmpId();
            }).length > 0;
        },
        canClearAssay: function () {
            return !this.get('assays').isEmpty() && this.get('status') !== 5;
        },
        findAssayLines: function () {
            var assayLines = new Backbone.Collection();
            _.each(_.uniq(_.filter(this.get('wells').pluck('refAssay'), function (assay) {
                return assay;
            })), _.bind(function (assay) {
                var pcrW = this.get('wells').filter(function (well) {
                    return well.get('refAssay') === assay;
                });
                var volumeMmx = 0;
                assay.get('reagents')
                    .chain()
                    .filter(function (reagent) {
                        return reagent.get('reagent').get('type') === 'MMX';
                    })
                    .map(function (reagent) {
                        return reagent.get('volumeByTest');
                    }).forEach(function (volumeByTest) {
                    volumeMmx += volumeByTest;
                });
                var assayLine = {
                    assay: {code: assay.get('code')},
                    countByType: {
                        U: 0,
                        D: 0,
                        PC: 0,
                        NC: 0,
                        RC: 0,
                        OC: 0
                    },
                    volumeBySample: assay.get('volumeBySample'),
                    volumeMmx: volumeMmx
                };
                _.each(_.keys(assayLine.countByType), function (type) {
                    assayLine.countByType[type] = _.filter(pcrW, function (well) {
                        return _.isEqual(well.get('smpType'), type);
                    }).length;
                });
                assayLines.push(assayLine);
            }, this));
            return assayLines;
        },
        generateSummary: function () {
            var url = Settings.url('rest/v2/runs/pcr/' + this.get('id') + '/report');
            App.downloadFile(url);
        },
        loadProperties: function () {
            var defer = $.Deferred();
            $.ajax({
                url: Settings.url('rest/v2/properties/findByRun/pcr/' + this.get('id')),
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function (response) {
                    defer.resolve(response);
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        getDisplayMode: function () {
            var displayMode = this.get('refKitProt') && this.get('refKitProt').get('displayMode') ? this.get('refKitProt').get('displayMode') : null;
            if (!displayMode) {
                var DisplayModeService = require('services/caccounts/displayModes');
                displayMode = DisplayModeService.getDefaultDisplayMode();
            }
            return displayMode;
        },
        getColorByAssay: function () {
            var defer = $.Deferred();
            $.ajax({
                url: Settings.url(this.getUrl() + this.get('id') + '/plateLayout/colorByAssay'),
                type: 'GET',
                contentType: 'application/json',
                success: function (response) {
                    var AssayService = require('services/caccounts/assay');
                    var assayColors = _.map(response, function (assayColor) {
                        return {
                            assay: AssayService.getModel(assayColor.assay),
                            color: assayColor.color
                        };
                    });
                    defer.resolve(assayColors);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    });

    app.PcrRun.role = RolesMixin.RUN_PCR;

    app.PcrRunCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/runs/pcr/');
        },
        model: app.PcrRun
    });
});

