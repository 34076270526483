
define('template!sidebarShowView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<!-- quick search -->\n<form class="sidebar-header quick-search">\n    <input class="form-control col-w-83 js-quickSearch-sample-value inputBackground" placeholder="' +
((__t = ( _.i18n('quickSearch.sample') )) == null ? '' : __t) +
'"\n           type="text">\n    <button class="clickable" style title="' +
((__t = ( _.i18n('quickSearch.sample') )) == null ? '' : __t) +
'" type="button">\n        <span class="mdi mdi-magnify icon-sub open-hide-sidebar js-quickSearch-sample clickable"></span>\n    </button>\n</form>\n<div style="height: 100%; position: relative; max-height: 100%;">\n    <div class="js-menu-region sidebar-main" id="sideBarMenu" style="position: absolute; top: 0; bottom: 0; left: 0; right: 0;">\n    </div>\n</div>\n<div class="js-footer-region sidebar-footer">\n</div>';

}
return __p
};});

