define('runPcrOtherPlateLayoutCollectionView',[
    'module',
    'backbone',
    'underscore',
    'backbone.marionette',
    'runPcrOtherPlateLayoutItemView',
    'runPcrOtherPlateLayoutItemHeaderView',
    'runPcrOtherPlateLayoutItemMenuView'
], function (
    module,
    Backbone,
    _,
    Marionette,
    ItemView,
    ItemHeaderView,
    ItemMenuView
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        className: 'grid-wells',
        attributes: function () {
            var column = Number.parseInt(this.model.get('plateSize').substring(1));
            var line = this.model.get('plateSize').substring(0, 1).charCodeAt(0) - 64;
            var size = '30px';
            return {
                'style': 'display: grid; grid-template-columns: ' + size + ' repeat(' + column + ', ' + size + '); grid-template-rows: ' + size + ' repeat(' + line + ', ' + size + ');'
            };
        },
        childView: function (model) {
            if (model.get('type') === 'header') {
                return ItemHeaderView;
            } else if (model.get('type') === 'menu') {
                return ItemMenuView;
            } else {
                return ItemView;
            }
        },
        initialize: function () {
            this.collection.comparator = 'pos';
            this.collection.sort();

            var plateSize = this.model.get('plateSize');
            this.column = Number.parseInt(plateSize.substring(1));
            var row = plateSize.substring(0, 1).charCodeAt(0);
            this.collection.push(new Backbone.Model({pos: '000', type: 'menu'}));
            for (var i = 1; i <= this.column; i++) {
                this.collection.push(new Backbone.Model({
                    pos: '0' + String(i).padStart(2, '0'),
                    type: 'header',
                    name: String(i).padStart(2, '0')
                }));
            }
            for (var j = 65; j <= row; j++) {
                this.collection.push(new Backbone.Model({
                    pos: String.fromCharCode(j) + '00',
                    type: 'header',
                    name: String.fromCharCode(j)
                }));
            }
            this.collection.sort();
        },
        highlightAssay: function (assay) {
            this.children.each(function (view) {
                if (assay && view.model.get('assay') === assay) {
                    view.$el.addClass('blink');
                } else {
                    view.$el.removeClass('blink');
                }
            });
        }
    });
});

