
define('template!wellPcrFilter', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="header">\n    <div class="header-left filter">\n        <div>\n            <label>' +
((__t = ( _.i18n('assay') )) == null ? '' : __t) +
'</label>\n            <div class="js-assay-region" style="width: 150px;"></div>\n        </div>\n        <div class="js-configuration">\n            <label>' +
((__t = ( _.i18n('assay.assayConfiguration') )) == null ? '' : __t) +
'</label>\n            <div class="js-assayConfiguration-region" style="width: 100px;"></div>\n        </div>\n\n    </div>\n    <div class="header-center">\n        <h1>' +
((__t = ( _.i18n('wellPcrFilter.title') )) == null ? '' : __t) +
'</h1>\n    </div>\n\n    <div class="header-right d-f column" style="align-items: flex-end;">\n    </div>\n</div>\n\n<div class="js-grid-region"></div>\n';

}
return __p
};});

