define('kitLotsPcrController',[
    'module',
    'underscore',
    'backbone',
    'kitLotsPcrTargetView',
    'qcChartPopup',
    'tabs',
    'tabsCollectionPopup',
    'jquery',
    'qcListView'
], function (
    module,
    _,
    Backbone,
    View,
    QCChartPopup,
    Tabs,
    TabsCollectionPopup,
    $,
    QcListView
) {
    'use strict';

    module.exports = {
        /**
         * Show the details of the model.
         * @param param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Function} param.callBackOnClose - The callback function to be called after the CreateEditLayoutView is shown.
         * @param {Object} param.options - The options object.
         */
        editValues: function (param) {
            if (!param.model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            var createEditView = new View(param);
            var KitLotPcrService = require('services/caccounts/kitlotpcr');
            createEditView.show({
                title: _.i18n(KitLotPcrService.getName() + '.new') + ' - ' + param.model.get('kit').get('code'),
                className: 'baseTableEditAddPopup kitLotPcrsEditAddPopup',
                service: KitLotPcrService
            }, _.bind(function () {
                if (param.callBackOnClose) {
                    param.callBackOnClose();
                }
            }, param.callBackOnClose ? param.callBackOnClose.context : undefined));
        },
        showDetails: function (param) {
            require(['kitLotsController'], function (Controller) {
                Controller.showDetails(param);
            });
        },
        showQCFromWell: function (model) {
            $('.js-global-loader').show();
            var defers = [];

            defers.push(model.getControlKitLot());
            defers.push(model.fetch());

            $.when.apply($, defers)
                .done(_.bind(function (controlKits) {
                    controlKits = _.filter(controlKits, function (controlKit) {
                        return controlKit.assayReagentTargetPcrKitLot.get('refMbAnaRes') && controlKit.assayReagentTargetPcrKitLot.get('refMbAnaRes').get('code') !== 'N';
                    });
                    var collection = new Backbone.Collection(controlKits);
                    var qcChartPopupLayoutView = new QCChartPopup({
                        wellModel: model,
                        qcTargetModel: null,
                        collection: collection
                    });

                    collection = new Backbone.Collection();
                    collection.push(new Backbone.Model({
                        content: qcChartPopupLayoutView,
                        name: _.i18n('qcChartPopup.title')
                    }));
                    var dataForPcrKitLot = require('services/caccounts/pcrwells').getDataForPcrKitLot(model);
                    var qcListView = new QcListView({
                        model: model,
                        dataForPcrKitLot: dataForPcrKitLot
                    });

                    collection.push(new Backbone.Model({
                        content: qcListView,
                        name: _.i18n('qcListChartPopup.title')
                    }));
                    var tabsCollection = new Tabs({collection: collection, className: 'qcChartPopup'});
                    var tabsCollectionPopup = new TabsCollectionPopup({tabsCollection: tabsCollection});
                    tabsCollectionPopup.show({
                        title: _.i18n('qcChartPopup.title'),
                        className: 'baseTableEditAddPopup qcChartPopup'
                    });
                }, this))
                .always(function () {
                    $('.js-global-loader').hide();
                });
        },
        showQCFromLot: function (model) {
            $('.js-global-loader').show();
            model.getControlKitLot()
                .done(_.bind(function (controlKits) {
                    controlKits = _.filter(controlKits, function (controlKit) {
                        return controlKit.assayReagentTargetPcrKitLot.get('refMbAnaRes') && controlKit.assayReagentTargetPcrKitLot.get('refMbAnaRes').get('code') !== 'N';
                    });
                    var collection = new Backbone.Collection(controlKits);
                    var qcChartPopupLayoutView = new QCChartPopup({
                        wellModel: model,
                        qcTargetModel: null,
                        collection: collection
                    });

                    collection = new Backbone.Collection();
                    collection.push(new Backbone.Model({
                        content: qcChartPopupLayoutView,
                        name: _.i18n('qcChartPopup.title')
                    }));

                    var dataForPcrKitLot = require('services/caccounts/kitlotpcr').getDataForPcrKitLot(model);
                    var qcListView = new QcListView({
                        model: model,
                        dataForPcrKitLot: dataForPcrKitLot
                    });
                    collection.push(new Backbone.Model({
                        content: qcListView,
                        name: _.i18n('qcListChartPopup.title')
                    }));
                    var tabsCollection = new Tabs({collection: collection, className: 'qcChartPopup'});
                    var tabsCollectionPopup = new TabsCollectionPopup({tabsCollection: tabsCollection});
                    tabsCollectionPopup.show({
                        title: _.i18n('qcChartPopup.title'),
                        className: 'baseTableEditAddPopup qcChartPopup'
                    });
                }, this))
                .always(function () {
                    $('.js-global-loader').hide();
                });
        }
    };
});

