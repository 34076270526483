
define('template!pcrWellResultHistogramNormalView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="js-chart" style="flex: 1;"></div>\n<img class="js-image" style="" alt=""/>\n<div class="js-result">' +
((__t = ( code )) == null ? '' : __t) +
'</div>\n<div class="js-ct">' +
((__t = ( ct )) == null ? '' : __t) +
'</div>\n<div class="js-quantification">' +
((__t = ( quantification )) == null ? '' : __t) +
'</div>';

}
return __p
};});

