define('services/caccounts/lisRecords',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'exportCustomJqGridView',
    'entities/caccounts/lisRecords'
], function (
    app,
    _,
    module,
    Dynamic,
    ExportCustomJqGridView
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.LisRecord,
                collection: app.LisRecordCollection
            };
        },
        getName: function () {
            return 'lisrecord';
        },
        getUrl: function () {
            return 'rest/v2/export/result/ASTM/';
        },
        getDynamicJson: function () {
            return 'LisRecordJson';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.creatable = false;
            config.archivable = false;
            config.selectArchivable = true;
            config.exportable = false;
            config.importable = false;
            config.attachmentable = false;
            config.selectable = false;
            config.deletable = false;
            config.customLeft = new ExportCustomJqGridView();
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('lisRecord.namePCRPlate'),
                name: 'namePCRPlate',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'namePCRPlate',
                width: 50
            }, {
                label: _.i18n('lisRecord.position'),
                name: 'position',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'position',
                width: 10
            }, {
                label: _.i18n('lisRecord.sampleId'),
                name: 'sampleId',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'sampleId',
                width: 40
            }, {
                label: _.i18n('lisRecord.targetCode'),
                name: 'targetCode',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'targetCode',
                width: 40
            }, {
                label: _.i18n('lisRecord.created'),
                name: 'createdDate',
                formatter: viewDynamic.dateTimeFormatter,
                search: true,
                index: 'createdDate',
                width: 40
            }, {
                label: _.i18n('lisRecord.lastQuery.responseDate'),
                name: 'lastQuery.responseDate',
                formatter: viewDynamic.dateTimeFormatter,
                search: false,
                index: 'lastQuery.responseDate',
                width: 40
            }, {
                label: _.i18n('lisRecord.lastQuery.responseType'),
                name: 'lastQuery.responseType',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                index: 'lastQuery.responseType',
                width: 40
            }, {
                label: _.i18n('lisRecord.server'),
                name: 'server',
                classes: 'dynamic-link',
                formatter: _.bind(this.serverFormatter, this),
                search: false,
                index: 'server',
                width: 150
            });
            return columns;
        },
        getJqGridSidx: function () {
            return 'createdDate';
        },
        getJqGridSord: function () {
            return 'desc';
        },
        serverFormatter: function (cellvalue) {
            if (!cellvalue) {
                return '';
            }
            return '<span>' + cellvalue.uriString + '</span>';
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        }
    }, Dynamic);
});

