define('entities/caccounts/sampleClass',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'entities/caccounts/lis'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.SampleClass = Backbone.RelationalModel.extend({
        service: 'services/caccounts/sampleClass',
        fetch: function () {
            this.url = Settings.url('rest/v2/sampleClass/' + this.get('id'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'sequence': null,
            'sampleType': null,
            'validityDays': undefined,
            'regexSampleId': undefined,
            'regexShortSampleId': undefined,
            'regexOrderId': undefined,
            'regexSequenceSample': undefined,
            'refLIS': null,
            'description': null,
            'comment': null
        },
        idAttribute: 'id',
        
        postUrl: function () {
            return Settings.url('rest/v2/sampleClass/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'SampleClassJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'SampleClass'
    });

    app.SampleClass.role = RolesMixin.SAMPLECLASS;

    app.SampleClassCollection = Backbone.Collection.extend({
        model: app.SampleClass
    });

});

