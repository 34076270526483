define('services/caccounts/reportProperties',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/reportProperties'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ReportProperty,
                collection: app.ReportPropertyCollection
            };
        },
        getName: function () {
            return 'reportproperty';
        },
        getUrl: function () {
            return 'rest/v2/reports/';
        },
        getDynamicJson: function () {
            return 'ReportPropertyJson';
        },
        getCreateEditLine: function () {
            return [
                {'field': 'key', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-12'}}},
                {'field': 'value', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-12'}}}
            ];
        }
    }, Dynamic);
});

