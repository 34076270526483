define('wellPopupDisplayGraphView',[
    'module',
    'dialogFormView',
    'template!wellPopupDisplayGraphView',
    'underscore',
    'jquery',
    'wellsPopupGraphView',
    'pcrWellResultController',
    'entities/caccounts/runats'
], function (
    module,
    DialogFormView,
    createEditLayoutTpl,
    _,
    $,
    WellsGraphView,
    PcrWellResultController
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: createEditLayoutTpl,

        models: [],

        className: 'container-fluid',

        ui: {
            loader: '.empty-page-loader'
        },

        events: {
            'change .chart-legend-change': 'onLegendChange',
            'change .rangeSmooth': 'onRangeSmoothChange'
        },

        regions: {
            runGraphRaw: '#run-graphRaw-region-graphPopup',
            runGraph: '#run-graph-region-graphPopup',
            runGraph1: '#run-graph1-region-graphPopup',
            runGraph2: '#run-graph2-region-graphPopup',
            runAmplGraphRaw: '#run-ampl-graphRaw-region-graphPopup',
            runAmplGraph: '#run-ampl-graph-region-graphPopup',
            runAmplGraph1: '#run-ampl-graph1-region-graphPopup',
            runAmplGraph2: '#run-ampl-graph2-region-graphPopup',
            result: '.js-result'
        },

        onLegendChange: function (event) {
            this.displayLegend = event.target.checked;
            this.renderGraphics(this.currentModels, true);
        },
        onRangeSmoothChange: function (event) {
            this.rangeSmooth = event.target.checked ? 1 : 0;
            this.renderGraphics(this.currentModels, true);
        },

        initialize: function (options) {
            this.models = options.models;
            this.currentModels = this.models;
            this.legend = true;
            this.withoutCC = false;
            this.fixedColor = true;
            this.displayLegend = false;
            this.rangeSmooth = 1;
        },

        serializeData: function () {
            var templateData = {};
            this.results = [];
            templateData.newItem = this.options.newItem;
            templateData.displayAmpl = this.graphToDisplay.indexOf('A') !== -1;
            templateData.displayMelt = this.graphToDisplay.indexOf('M') !== -1;
            templateData.results = this.results;
            templateData.statuss = this.statuss;
            templateData.ctrls = this.ctrls;
            templateData.displayCC = this.model.get('pcrWell').get('assayVersion').get('colorCompensation');
            templateData.runNameToDisplay = this.model.get('pcrWell').get('run').get('name');
            templateData.assayToDisplay = this.model.get('pcrWell').get('refAssay').get('code');
            templateData.wellPosToDisplay = this.model.get('pcrWell').get('pos');
            templateData.targetToDisplay = this.model.get('refAssayResult').get('code');
            templateData.rangeSmooth = this.rangeSmooth;

            return templateData;
        },

        onRender: function () {
            $('#main-region').addClass('hidden-print');
            this.renderGraphics(this.models);
            this.triggerMethod('enable:cancel:confirm');
            PcrWellResultController.show(this.model, 'WellCurve', this.getRegion('result'));
        },


        renderGraphics: function (models) {
            var region;

            if (this.graphToDisplay.includes('M')) {
                this.graphRawView = new WellsGraphView({
                    type: 'line',
                    displayLegend: this.displayLegend,
                    rangeSmooth: this.rangeSmooth,
                    algoType: 'MELT',
                    hideExtraPoint: this.options.hideExtraPoint
                });
                this.graphRawView.models = models;
                this.graphRawView.modelRun = this.model.get('pcrWell').get('run');
                this.graphRawView.slopeEnable = true;
                this.graphRawView.brutEnable = false;
                this.graphRawView.legend = this.legend;
                this.graphRawView.withoutCC = this.withoutCC;
                this.graphRawView.raw = true;
                this.graphRawView.fixedColor = this.fixedColor;
                this.graphRawView.highlight = this.model.get('pcrWell');
                this.graphRawView.targetColor = this.targetColor;
                this.graphRawView.onCurveClick = this.onCurveClick;

                region = this.getRegion('runGraphRaw');
                if (region) {
                    region.show(this.graphRawView);
                }

                this.graphView = new WellsGraphView({
                    type: 'line',
                    displayLegend: this.displayLegend,
                    rangeSmooth: this.rangeSmooth,
                    algoType: 'MELT',
                    hideExtraPoint: this.options.hideExtraPoint
                });
                this.graphView.models = models;
                this.graphView.modelRun = this.model.get('pcrWell').get('run');
                this.graphView.slopeEnable = true;
                this.graphView.brutEnable = false;
                this.graphView.legend = this.legend;
                this.graphView.withoutCC = this.withoutCC;
                this.graphRawView.raw = false;
                this.graphView.fixedColor = this.fixedColor;
                this.graphView.highlight = this.model.get('pcrWell');
                this.graphView.targetColor = this.targetColor;
                this.graphView.onCurveClick = this.onCurveClick;

                region = this.getRegion('runGraph');
                if (region) {
                    region.show(this.graphView);
                }

                this.graph1View = new WellsGraphView({
                    type: 'line',
                    displayLegend: this.displayLegend,
                    rangeSmooth: this.rangeSmooth,
                    algoType: 'MELT',
                    hideExtraPoint: this.options.hideExtraPoint
                });
                this.graph1View.models = models;
                this.graph1View.modelRun = this.model.get('pcrWell').get('run');
                this.graph1View.slopeEnable = true;
                this.graph1View.brutEnable = false;
                this.graph1View.deriv1Enable = true;
                this.graph1View.legend = this.legend;
                this.graph1View.withoutCC = this.withoutCC;
                this.graphRawView.raw = false;
                this.graph1View.fixedColor = this.fixedColor;
                this.graph1View.highlight = this.model.get('pcrWell');
                this.graph1View.targetColor = this.targetColor;
                this.graph1View.onCurveClick = this.onCurveClick;


                region = this.getRegion('runGraph1');
                if (region) {
                    region.show(this.graph1View);
                }

                this.graph2View = new WellsGraphView({
                    type: 'line',
                    displayLegend: this.displayLegend,
                    rangeSmooth: this.rangeSmooth,
                    algoType: 'MELT',
                    hideExtraPoint: this.options.hideExtraPoint
                });
                this.graph2View.models = models;
                this.graph2View.modelRun = this.model.get('pcrWell').get('run');
                this.graph2View.slopeEnable = false;
                this.graph2View.brutEnable = true;
                this.graph2View.deriv2Enable = true;
                this.graph2View.legend = this.legend;
                this.graph2View.withoutCC = this.withoutCC;
                this.graph2View.raw = false;
                this.graph2View.fixedColor = this.fixedColor;
                this.graph2View.highlight = this.model.get('pcrWell');
                this.graph2View.targetColor = this.targetColor;
                this.graph2View.onCurveClick = this.onCurveClick;

                region = this.getRegion('runGraph2');
                if (region) {
                    region.show(this.graph2View);
                }
            }

            if (this.graphToDisplay.includes('A')) {
                this.amplGraphRawView = new WellsGraphView({
                    type: 'line',
                    displayLegend: this.displayLegend,
                    rangeSmooth: this.rangeSmooth,
                    algoType: 'AMP',
                    hideExtraPoint: this.options.hideExtraPoint
                });
                this.amplGraphRawView.models = models;
                this.amplGraphRawView.modelRun = this.model.get('pcrWell').get('run');
                this.amplGraphRawView.slopeEnable = false;
                this.amplGraphRawView.brutEnable = true;
                this.amplGraphRawView.amplGraph = true;
                this.amplGraphRawView.legend = this.legend;
                this.amplGraphRawView.withoutCC = this.withoutCC;
                this.amplGraphRawView.raw = true;
                this.amplGraphRawView.fixedColor = this.fixedColor;
                this.amplGraphRawView.highlight = this.model.get('pcrWell');
                this.amplGraphRawView.targetColor = this.targetColor;
                this.amplGraphRawView.minXVal = this.minVal;
                this.amplGraphRawView.maxXVal = this.maxVal;
                this.amplGraphRawView.minYVal = this.minValY;
                this.amplGraphRawView.maxYVal = this.maxValY;
                this.amplGraphRawView.onCurveClick = this.onCurveClick;

                region = this.getRegion('runAmplGraphRaw');
                if (region) {
                    region.show(this.amplGraphRawView);
                }

                this.amplGraphView = new WellsGraphView({
                    type: 'line',
                    displayLegend: this.displayLegend,
                    rangeSmooth: this.rangeSmooth,
                    algoType: 'AMP',
                    hideExtraPoint: this.options.hideExtraPoint
                });
                this.amplGraphView.models = models;
                this.amplGraphView.modelRun = this.model.get('pcrWell').get('run');
                this.amplGraphView.slopeEnable = false;
                this.amplGraphView.brutEnable = true;
                this.amplGraphView.amplGraph = true;
                this.amplGraphView.legend = this.legend;
                this.amplGraphView.withoutCC = this.withoutCC;
                this.amplGraphRawView.raw = false;
                this.amplGraphView.fixedColor = this.fixedColor;
                this.amplGraphView.highlight = this.model.get('pcrWell');
                this.amplGraphView.targetColor = this.targetColor;
                this.amplGraphView.minXVal = this.minVal;
                this.amplGraphView.maxXVal = this.maxVal;
                this.amplGraphView.minYVal = this.minValY;
                this.amplGraphView.maxYVal = this.maxValY;
                this.amplGraphView.onCurveClick = this.onCurveClick;

                if (this.runAt) {
                    this.amplGraphView.baseline = this.runAt.baseline;
                    this.amplGraphView.threshold = this.runAt.threshold;
                }

                region = this.getRegion('runAmplGraph');
                if (region) {
                    region.show(this.amplGraphView);
                }

                this.amplGraph1View = new WellsGraphView({
                    type: 'line',
                    displayLegend: this.displayLegend,
                    rangeSmooth: this.rangeSmooth,
                    algoType: 'AMP',
                    hideExtraPoint: this.options.hideExtraPoint
                });
                this.amplGraph1View.models = models;
                this.amplGraph1View.modelRun = this.model.get('pcrWell').get('run');
                this.amplGraph1View.slopeEnable = true;
                this.amplGraph1View.brutEnable = false;
                this.amplGraph1View.deriv1Enable = true;
                this.amplGraph1View.amplGraph = true;
                this.amplGraph1View.legend = this.legend;
                this.amplGraph1View.withoutCC = this.withoutCC;
                this.amplGraph1View.raw = false;
                this.amplGraph1View.fixedColor = this.fixedColor;
                this.amplGraph1View.highlight = this.model.get('pcrWell');
                this.amplGraph1View.targetColor = this.targetColor;
                this.amplGraph1View.onCurveClick = this.onCurveClick;

                region = this.getRegion('runAmplGraph1');
                if (region) {
                    region.show(this.amplGraph1View);
                }

                this.amplGraph2View = new WellsGraphView({
                    type: 'line',
                    displayLegend: this.displayLegend,
                    rangeSmooth: this.rangeSmooth,
                    algoType: 'AMP',
                    hideExtraPoint: this.options.hideExtraPoint
                });
                this.amplGraph2View.models = models;
                this.amplGraph2View.modelRun = this.model.get('pcrWell').get('run');
                this.amplGraph2View.slopeEnable = true;
                this.amplGraph2View.brutEnable = false;
                this.amplGraph2View.deriv2Enable = true;
                this.amplGraph2View.amplGraph = true;
                this.amplGraph2View.legend = this.legend;
                this.amplGraph2View.withoutCC = this.withoutCC;
                this.amplGraphRawView.raw = false;
                this.amplGraph2View.fixedColor = this.fixedColor;
                this.amplGraph2View.highlight = this.model.get('pcrWell');
                this.amplGraph2View.targetColor = this.targetColor;
                this.amplGraph2View.onCurveClick = this.onCurveClick;

                region = this.getRegion('runAmplGraph2');
                if (region) {
                    region.show(this.amplGraph2View);
                }
            }
        },

        // added todo test
        onCurveClick: function (well) {
            require(['pcrWellController'], _.bind(function (PcrWellController) {
                PcrWellController.showDetails({model: well, cSelection: well.get('pos')});
            }, this));
        },

        hide: function () {
            $('#main-region').removeClass('hidden-print');
            this.triggerMethod('hide');
            this.box.modal('hide');
            this.destroy();
        }
    });
});

