define('entities/caccounts/suppliers',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.Supplier = Backbone.RelationalModel.extend({
        service: 'services/caccounts/suppliers',
        fetch: function () {
            this.url = Settings.url('rest/v2/suppliers/' + this.get('id'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': null,
            'name': null,
            'description': null,
            comment: null,
            tags: [],
            'zipCode': '',
            'city': '',
            'countryCode': ''
        },
        idAttribute: 'id',

        postUrl: function () {
            return Settings.url('rest/v2/suppliers/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('SupplierJson.pdf');
        }
    });

    app.Supplier.role = RolesMixin.SUPPLIER;

    app.SupplierCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/suppliers/');
        },
        model: app.Supplier
    });
});

