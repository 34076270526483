define('services/caccounts/extractionMethod',[
    'entities/ns',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/extractionMethod'
], function (
    app,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.ExtractionMethod,
                collection: app.ExtractionMethodCollection
            };
        },
        getName: function () {
            return 'extractionMethod';
        },
        getUrl: function () {
            return 'rest/v2/extractionMethods/';
        },
        getDynamicJson: function () {
            return 'ExtractionMethodJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                width: 50
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                width: 100
            }, {
                label: _.i18n('extractor.model'),
                name: 'refExtractors',
                formatter: viewDynamic.codeNameListFormatter,
                sortable: false,
                search: true,
                index: 'refExtractors'
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'comment'
            });
            return columns;
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.ExtractionMethodCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 450, 'fields': ['name']};
        },

        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push(
                {'field': 'refExtractors', 'param': {'type': 'LIST2ENTITY'}}
            );
            return custom;
        },
        getCreateEditLine: function () {
            return [
                {
                    'field': 'code',
                    'param': {
                        'type': 'REFERENCE',
                        'config': {'getAutocompleteParam': this.getAutocompleteParam},
                        'self': true,
                        'context': this,
                        'display': {'class': 'col-xs-6'}
                    }
                }
            ];
        }
    }, Dynamic);
});

