/**
 * Created by Matteo on 14/07/2015.
 */
define('selectCaccountListController',[
    'require',
    'backbone',
    'module',
    'logger',
    'jquery',
    'underscore',
    'app',
    'settings',
    'selectCaccountListView',
    'services/global/token',
    'load'
], function (
    require,
    Backbone,
    module,
    Logger,
    $,
    _,
    App,
    Settings,
    ListView,
    TokenService,
    Load
) {
    'use strict';

    module.exports = {

        listCAccounts: function () {
            var CAccountService = require('services/global/caccounts');
            if (!Settings.get('currentCAccount')) {
                var cAccountCount = CAccountService.getCAccountEntities(),
                    listView;

                this.view = listView;
                cAccountCount.done(_.bind(function (collection) {
                    if (collection.length === 1) {
                        this.skipSelectionPage();
                        return;
                    }

                    listView = new ListView();

                    this.view = listView;

                    listView.listenTo(listView, 'onSignin', _.bind(this.onSignin, this));

                    App.showView(listView);
                }, this));
            } else {
                App.navigate('', {trigger: true});
            }
        },

        onSignin: function () {
            var CAccountService = require('services/global/caccounts');
            var cAccount = this.view.selectedAccount,
                postCAccount;

            if (cAccount) {
                this.view.ui.signin.addClass('disabled');
                postCAccount = CAccountService.postCAccount(
                    cAccount.get('id')
                );
                postCAccount.done(_.bind(function () {
                    TokenService.findBy().done(_.bind(function (model) {
                        Settings.set('currentCAccount', model.caccount);
                        Settings.set('currentUserModel', require('services/global/caccounts').getModel(model.userpid));
                        Settings.set('loginTo', model.loginTo);
                        if (!Backbone.History.started) {
                            Backbone.history.history.back();
                        }
                        this.callLoadServices();
                    }, this));
                }, this));
            } else {
                this.view.onError();
            }
        },

        skipSelectionPage: function () {
            var CAccountService = require('services/global/caccounts');
            var fetchCAccounts = CAccountService.getCAccountEntities(),
                postCAccount;

            fetchCAccounts.done(_.bind(function (cAccounts) {
                postCAccount = CAccountService.postCAccount(
                    cAccounts.at(0).get('id')
                );

                postCAccount.done(_.bind(function () {
                    Settings.set('currentCAccount', cAccounts.at(0).toJSON());
                    this.callLoadServices();
                }, this));
            }, this));
        },

        callLoadServices: function () {
            var view = new Load({services: true});
            App.regions.getRegion('main').show(view);
        }
    };
});
