define('rolePrivilegeItemView',[
    'module',
    'backbone.marionette',
    'underscore',
    'settings',
    'rolesMixin'
], function (
    module,
    Marionette,
    _,
    Settings,
    RolesMixin
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<label><input type="checkbox" value="<%= code %>"><%= name %></label><% if(superuser) { %><span class="mdi mdi-filter js-privilege clickable"></span><% } %>'),
        tagName: 'div',
        ui: {
            input: 'input'
        },
        events: {
            'change input': 'onChange',
            'click label': 'onLabelClick',
            'click input': 'onClickInput',
            'click .js-privilege': 'onPrivilegeClick'
        },
        attributes: function () {
            var key;
            var code = this.model.get('code');
            if (_.any(['$ALL', '$REFERENCE', '$READ', '$WRITE', '$ARCHIVE'], function (suffix) {
                return code.endsWith(suffix);
            })) {
                // split the code with "$" and get the last part
                key = 'privilege.' + code.split('$').pop();
            } else {
                key = 'privilege.' + code;
            }
            return {
                'title': _.i18n(key)
            };
        },
        
        className: function () {
            return this.options.readOnly ? 'disabled' : '';
        },
        initialize: function () {
            this.listenTo(this.options.parentModel.get('depends'), 'reset', this.render);
        },
        serializeData: function () {
            var superUser = Settings.get('currentUserModel').hasRole(RolesMixin.SUPER_USER);
            return {
                name: this.model.get('name'),
                code: this.model.get('code'),
                superuser: superUser
            };
        },
        onRender: function () {
            var checked = this.options.parentModel.get('privileges').indexOf(this.model.get('code')) !== -1;
            this.ui.input.prop('checked', checked);

            if (this.options.parentModel.get('depends').findWhere({code: this.model.get('code')})) {
                this.ui.input.prop('checked', true);
                this.ui.input.prop('disabled', true);
            } else {
                if (this.ui.input.prop('disabled')) {
                    this.ui.input.prop('disabled', false);
                }
            }

            if (this.options.readOnly) {
                this.ui.input.prop('disabled', true);
            }
        },
        onClickInput: function (e) {
            e.preventDefault();
            e.stopPropagation();
            // // trigger event change input
            this.ui.input.trigger('change');
        },
        setCheck: function (checked) {
            // force check the input
            this.ui.input.prop('checked', checked);
            if (checked) {
                this.options.parentModel.get('privileges').push(this.model.get('code'));
            } else {
                this.options.parentModel.set('privileges', _.without(this.options.parentModel.get('privileges'), this.model.get('code')));
            }
            return this.options.findDepends();
        },
        onChange: function (e) {
            // if disabled ignore the change
            if (this.ui.input.prop('disabled')) {
                return;
            }
            var checked = e.target.checked;
            if (checked) {
                this.options.parentModel.get('privileges').push(this.model.get('code'));
            } else {
                this.options.parentModel.set('privileges', _.without(this.options.parentModel.get('privileges'), this.model.get('code')));
            }
            this.options.findDepends();
        },
        /**
         * Toggle the checked input when click on label and generate the change event
         */
        onLabelClick: function (e) {
            if (this.ui.input.prop('disabled')) {
                return;
            }
            e.preventDefault();
            e.stopPropagation();
            // trigger event change input
            this.ui.input.prop('checked', !this.ui.input.prop('checked')).trigger('change');
        },
        onPrivilegeClick: function () {
            // open new tab with role url and parameter
            var url;
            if (this.model.get('code').endsWith('$READ')) {
                url = '#superAdmin/routes?role=' + this.model.get('code').split('$')[0];
            } else {
                url = '#superAdmin/routes?privilege=' + this.model.get('code');
            }
            window.open(url, '_blank');
        }
    });
});

