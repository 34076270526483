define('assayVersionCreateEditLayout',[
    'module',
    'backbone',
    'dialogFormView',
    'template!assayVersionCreateEditLayout',
    'assayVersionLotCollection',
    'dynamicCreateEditDescriptionCommentTags',
    'assayResultCollectionVersion'
], function (
    module,
    Backbone,
    DialogFormView,
    Tpl,
    AssayVersionLotCollection,
    DynamicCreateEditDescriptionCommentTags,
    AssayResultCollectionVersion
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        events: {
            'click .js-cancel-popup': 'onCancel'
        },

        className: 'assay-table',

        regions: {
            descriptionRegion: '.js-description',
            selectAlgoValidation: '.js-select-algo-deathVolume-region',
            kitLots: '.js-kitlots',
            colorCompensationSelect: '.js-select-colorCompensation-region',
            assayResultRegion: '.js-assayResult-region'
        },

        serializeData: function () {
            return {
                code: this.model.get('code'),
                name: this.model.get('name'),
                assayGroupCode: this.model.get('assayGroup') ? this.model.get('assayGroup').get('code') : null,
                kitProtCode: this.model.get('kitProt') ? this.model.get('kitProt').get('code') : null,
                protocolVariant: this.model.get('protocolVariant'),
                volumeBySample: this.model.get('volumeBySample'),
                configurationCode: this.model.get('configurationCode'),
                colorCompensationCode: this.model.get('colorCompensation') ? this.model.get('colorCompensation').get('code') : null
            };
        },
        onRender: function () {
            var collection = new Backbone.Collection(this.options.lots);
            var lotView = new AssayVersionLotCollection({
                model: this.model,
                collection: this.model.get('reagents'),
                lots: collection,
                targetValueFiltered: this.options.targetValueFiltered
            });
            this.getRegion('kitLots').show(lotView);
            this.setPermissions(false);

            var AssayVersionService = require('services/caccounts/assayVersion');
            this.getRegion('descriptionRegion').show(new DynamicCreateEditDescriptionCommentTags({
                model: this.model,
                service: AssayVersionService
            }));
            var assayResultCollectionView = new AssayResultCollectionVersion({
                collection: this.model.get('results'),
                targetValueFiltered: this.options.targetValueFiltered
            });

            this.getRegion('assayResultRegion').show(assayResultCollectionView);

            this.triggerMethod('enable:cancel:confirm');
        }
    });
});

