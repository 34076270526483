define('runPcrOtherPlateLayoutView',[
    'module',
    'dialogFormView',
    'template!runPcrOtherPlateLayoutView',
    'backbone',
    'runPcrOtherPlateLayoutCollectionView',
    'backbone.marionette',
    'underscore'
], function (
    module,
    DialogFormView,
    Tpl,
    Backbone,
    RunPcrOtherPlateLayoutCollectionView,
    Marionette,
    _
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        regions: {
            plateLayout: '.js-plateLayout',
            assays: '.js-assays'
        },

        className: 'd-f',

        attributes: {
            'style': 'column-gap: 30px; justify-content: space-around;'
        },

        events: {
            'click .js-print': 'onPrint'
        },

        initialize: function () {
            this.collection = new Backbone.Collection();
            this.collection.comparator = function (m1, m2) {
                if (!m1 || !m1.get('pos')) {
                    return -1;
                }
                if (!m2 || !m2.get('pos')) {
                    return 1;
                }
                return (m1.get('pos').substring(1) + m1.get('pos').charCodeAt(0)).localeCompare(m2.get('pos').substring(1) + m2.get('pos').charCodeAt(0));
            };
            this.model.get('wells').each(_.bind(function (model) {
                this.collection.add({
                    pos: model.get('pos'),
                    run: model.get('run'),
                    assay: model.get('refAssay'),
                    smpType: model.get('smpType')
                });
            }, this));

            this.assays = _.map(this.options.colorByAssay, function (colorByAssay) {
                return {
                    assay: colorByAssay.assay,
                    backgroundColor: colorByAssay.color
                };
            });

            this.collection.each(_.bind(function (model) {
                if (model.get('assay')) {
                    var colorByAssay = _.find(this.options.colorByAssay, function (colorByAssay) {
                        return colorByAssay.assay === model.get('assay');
                    });
                    model.set('background-color', colorByAssay.color);
                }
            }, this));
        },

        onRender: function () {
            this.showChildView('plateLayout', new RunPcrOtherPlateLayoutCollectionView({
                model: this.model,
                collection: this.collection
            }));

            var CollectionView = Marionette.CollectionView.extend({
                tagName: 'div',
                template: _.template('<h2 style="margin-top: 0">' + _.i18n('assays') + '</h2>'),
                attributes: {
                    'style': 'display: flex; flex-direction: column; gap: 10px;'
                },
                childView: Marionette.View.extend({
                    tagName: 'div',
                    template: _.template('<%= assayCode %>'),
                    attributes: function () {
                        return {
                            'style': 'cursor: pointer; background-color: ' + this.model.get('backgroundColor')
                        };
                    },
                    serializeData: function () {
                        return {
                            backgroundColor: this.model.get('backgroundColor'),
                            assayCode: this.model.get('assay').get('code')
                        };
                    },
                    events: {
                        'mouseover': function () {
                            this.options.onHover(this.model.get('assay'));
                        },
                        'mouseout': function () {
                            this.options.onHover(null);
                        }
                    }
                }),
                childViewOptions: function () {
                    return {
                        onHover: this.options.onHover
                    };
                }
            });
            this.showChildView('assays', new CollectionView({
                collection: new Backbone.Collection(this.assays),
                onHover: _.bind(function (assay) {
                    this.getChildView('plateLayout').highlightAssay(assay);
                }, this)
            }));
        },
        onPrint: function () {
            var PcrRunService = require('services/caccounts/pcrruns');
            PcrRunService.generatePlateLayout(this.model.id);
        }
    });
});
