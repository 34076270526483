define('entities/admin/array',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.Array = Backbone.RelationalModel.extend({
        service: 'services/admin/array',
        fetch: function () {
            this.url = Settings.url('rest/public/arrays/' + this.get('id'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            code: '',
            name: '',
            description: '',
            arrayType: null,
            arrayElements: []
        },
        idAttribute: 'id',
        relations: [{
            type: Backbone.HasMany,
            key: 'arrayElements',
            relatedModel: 'ArrayElement',
            collectionType: 'ArrayElementCollection',
            reverseRelation: {
                key: 'array',
                includeInJSON: false
            }
        }],

        postUrl: function () {
            return Settings.url('rest/public/arrays/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        }
    });
    
    app.ArrayCollection = Backbone.Collection.extend({
        model: app.Array
    });
    
    app.Array.role = RolesMixin.SUPER_USER;
});

