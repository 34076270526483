
define('template!pcrSetupAssayCollection', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="assayItem">\n    <div></div>\n    <label>' +
((__t = ( _.i18n('prepRun.assay') )) == null ? '' : __t) +
'</label>\n    <div>\n        <span class="mdi mdi mdi-human-male" style="color: #ff0000;"></span>\n    </div>\n</div>\n';

}
return __p
};});

