define('wellPcrFilter',[
    'module',
    'backbone',
    'underscore',
    'jquery',
    'dialogFormView',
    'autocompleteView',
    'services/caccounts/assay',
    'services/caccounts/assayconfiguration',
    'commonSelect',
    'template!wellPcrFilter',
    'dynamicCreateEditDate',
    'jqGridController',
    'settings',
    'jqGridFormatter',
    'services/caccounts/pcrwells',
    'services/caccounts/pcrwellresults',
    'pcrWellResultController',
    'moment',
    'wellUtils',
    'backboneRelational'
], function (
    module,
    Backbone,
    _,
    $,
    DialogFormView,
    AutocompleteView,
    AssayService,
    AssayConfigurationService,
    CommonSelect,
    Tpl,
    DynamicCreateEditDate,
    JqGridController,
    Settings,
    JqGridFormatter,
    PcrWellService,
    PcrWellResultService,
    PcrWellResultController,
    moment,
    WellUtils
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        regions: {
            assayRegion: '.js-assay-region',
            assayConfigurationRegion: '.js-assayConfiguration-region',
            gridRegion: '.js-grid-region'
        },

        className: 'wellPcrFilter dynamic jq-grid-table-page',

        ui: {
            input: '.js-info-input',
            configuration: '.js-configuration'
        },

        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },

        attributes: {
            style: 'background-color: white; padding: 10px;'
        },

        modelEvents: {
            'change:assayConfiguration': 'onFilter'
        },

        initialize: function () {
            var M = Backbone.RelationalModel.extend({
                relations: [{
                    type: Backbone.HasOne,
                    key: 'assay',
                    relatedModel: 'Assay',
                    includeInJSON: ['id', 'code', 'name']
                }, {
                    type: Backbone.HasOne,
                    key: 'assayConfiguration',
                    relatedModel: 'AssayConfiguration',
                    includeInJSON: ['id', 'code', 'name']
                }]
            });
            this.model = new M({dot: true});
            this.filtersName = 'wellPcrListFilters';
            var storedFilters = Settings.getFromMemory(this.filtersName);
            if (!storedFilters || !storedFilters.sidx) {
                storedFilters = {
                    sidx: 'creatDate',
                    sord: 'desc',
                    'type': 'U',
                    'fromDate': moment().subtract(3, 'months').format('DD/MM/YYYY'),
                    'toDate': moment().format('DD/MM/YYYY')
                };
                Settings.setToMemory(this.filtersName, storedFilters);
            }
            this.model.set('type', storedFilters.type);
        },

        onRender: function () {
            this.showChildView('assayRegion', new AutocompleteView(
                this._getAutocompleteOptionObject(AssayService.getAutocompleteParam({
                    modelProperty: 'assay',
                    callBackOnChange: _.bind(this.onAssayChange, this)
                }))));
            this.ui.configuration.hide();
            if (this.model.get('assay')) {
                this.ui.configuration.show();
            }
        },

        onClick: function (obj) {
            var data = {};
            data[PcrWellService.getNamespace().model.prototype.idAttribute] = obj.rowId;
            var model = PcrWellService.getModel(data);
            PcrWellService.showDetails({model: model, service: PcrWellService});
        },

        gridOptions: function (data, jqGridView) {
            var types = ['U', 'D', 'PC', 'NC', 'RC', 'OC', 'P', 'C'];

            var typeSearch = ':' + _.i18n('common.any') + ';' + types.map(function (type) {
                return type + ':' + type;
            }).join(';');

            var options = {
                colModel: [{
                    label: '',
                    name: 'id',
                    key: true,
                    hidden: true
                }, {
                    label: _.i18n('run'),
                    name: 'run.code',
                    classes: '',
                    formatter: JqGridFormatter.defaultFormatter,
                    search: true,
                    sortable: true,
                    searchoptions: {sopt: ['cn']},
                    index: 'refPcrRun.code',
                    fixed: true,
                    width: 150
                }, {
                    label: _.i18n('well.pos'),
                    name: 'pos',
                    classes: 'well-list-pos-label displayPopover',
                    formatter: JqGridFormatter.defaultFormatter,
                    index: 'pos',
                    search: true,
                    searchoptions: {sopt: ['cn']},
                    sortable: true,
                    fixed: true,
                    width: 40
                }, {
                    label: _.i18n('well.sample'),
                    name: 'smpId.code',
                    classes: 'displayPopover displayPopover',
                    formatter: JqGridFormatter.defaultFormatter,
                    index: 'smpId.code',
                    searchoptions: {sopt: ['cn']},
                    search: true,
                    sortable: true,
                    fixed: true,
                    width: 150
                }, {
                    label: _.i18n('run.refCyclerPublic'),
                    name: 'run.refCyclerPublic.code',
                    classes: 'displayPopover',
                    formatter: JqGridFormatter.defaultFormatter,
                    search: true,
                    sortable: true,
                    index: 'refPcrRun.refCyclerPublic.code',
                    searchoptions: {sopt: ['cn']},
                    fixed: true,
                    width: 100
                }, {
                    label: _.i18n('run.refCycler'),
                    name: 'run.refCycler.code',
                    classes: 'displayPopover',
                    formatter: JqGridFormatter.defaultFormatter,
                    search: true,
                    sortable: true,
                    index: 'refPcrRun.refCycler.code',
                    searchoptions: {sopt: ['cn']},
                    fixed: true,
                    width: 100
                }, {
                    label: _.i18n('run.refLmbCycler'),
                    name: 'run.refLmbCycler.code',
                    classes: 'displayPopover',
                    formatter: JqGridFormatter.defaultFormatter,
                    search: true,
                    sortable: true,
                    index: 'refPcrRun.refLmbCycler.code',
                    searchoptions: {sopt: ['cn']},
                    fixed: true,
                    width: 100
                }, {
                    label: _.i18n('smpType'),
                    name: 'smpType',
                    classes: '',
                    formatter: this.smptypeFormater,
                    search: true,
                    index: 'smpType',
                    sortable: true,
                    stype: 'select',
                    searchrules: {select: true},
                    searchoptions: {sopt: ['eq'], value: typeSearch},
                    fixed: true,
                    width: 30
                }, {
                    label: _.i18n('sample.bioGroup'),
                    name: 'sample.bioGroup.code',
                    formatter: JqGridFormatter.codeNameFormatter,
                    search: true,
                    index: 'sample.bioGroup.code',
                    searchoptions: {sopt: ['cn']},
                    fixed: true,
                    width: 40
                }, {
                    label: _.i18n('test'),
                    name: 'test',
                    classes: '',
                    formatter: JqGridFormatter.booleanFormatterNoCheckBox,
                    search: true,
                    index: 'test',
                    sortable: false,
                    stype: 'select',
                    searchrules: {select: true},
                    searchoptions: {sopt: ['eq'], value: ':' + _.i18n('common.any') + ';true:' + _.i18n('tested') + ';false:' + _.i18n('untested')},
                    fixed: true,
                    width: 30
                }
                ],
                sortname: this.sortOn ? this.sortOn : 'creatDate',
                sortorder: this.sortOrder ? this.sortOrder : 'desc',
                autowidth: false
            };

            var extraOptions = jqGridView.columnsForAssayConfiguration(this.model.get('assayConfiguration'), 'PLATE', 'Well');
            options.colModel = options.colModel.concat(extraOptions.colModel);
            options.formatters = extraOptions.formatters;

            return options;
        },

        onFilter: function () {
            if (!this.model.get('assay') || !this.model.get('assayConfiguration')) {
                this.getRegion('gridRegion').empty();
                return;
            }
            this.displayMode = this.model.get('assay').getDisplayMode();
            var storedFilters = Settings.getFromMemory(this.filtersName);
            storedFilters.type = this.model.get('type');
            storedFilters.fromDate = this.model.get('fromDate');
            storedFilters.toDate = this.model.get('toDate');
            storedFilters['refAssay.id'] = this.model.get('assay').id;
            storedFilters['refCycler.id'] = this.model.get('cycler') && this.model.get('cycler').get ? this.model.get('cycler').id : null;
            storedFilters['bioGroup.id'] = this.model.get('bioGroup') && this.model.get('bioGroup').get ? this.model.get('bioGroup').id : null;
            storedFilters.type = this.model.get('type');
            storedFilters['sample.biogroup.id'] = this.model.get('bioGroup') ? this.model.get('bioGroup').id : null;
            storedFilters.details = true;
            Settings.setToMemory(this.filtersName, storedFilters);

            var data = {
                url: PcrWellService.getCollectionUrl(),
                getJqGridColumnConfig: PcrWellService.getJqGridColumnConfig()
            };

            var view = JqGridController.show({
                data: data,
                pager: true,
                filtersName: this.filtersName,
                gridOptions: _.bind(this.gridOptions, this),
                paginationObject: this.paginationObject,
                service: require('services/caccounts/pcrwells')
            }, this.getRegion('gridRegion'));
            this.listenTo(view, 'click', _.bind(this.onClick, this));
            this.listenTo(view, 'loadComplete', _.bind(this.onLoadComplete, this));
        },

        onLoadComplete: function (gridView, datas) {
            var loader = $('.js-global-loader');
            loader.show();
            this.setWellListPopover(gridView, datas);
            loader.hide();
        },

        onAssayChange: function (fieldName, model) {
            this.model.set('assay', model);
            var configuration = null;
            if (this.model.get('assay') && this.model.get('assay').get('configurations').length === 1) {
                configuration = this.model.get('assay').get('configurations').first();
            }
            this.model.set('assayConfiguration', configuration);
            this.showChildView('assayConfigurationRegion', new AutocompleteView(
                this._getAutocompleteOptionObject(AssayConfigurationService.getAutocompleteParam({
                    modelProperty: 'assayConfiguration',
                    values: {
                        assaySecId: this.model.get('assay') ? this.model.get('assay').id : null
                    }
                }))));
            if (this.model.get('assay') && !this.model.get('assayConfiguration')) {
                this.ui.configuration.show();
            } else {
                this.ui.configuration.hide();
            }
        },

        setWellListPopover: function (jqGridView, datas) {
            var wellSecIds = _.pluck(datas, 'id');
            var wells = PcrWellService.getCollection(wellSecIds);
            wells.each(_.bind(function (data) {
                var well = wells.get(data.id);
                if (!well) {
                    return;
                }
                var popovers = jqGridView.$el.find('tr#' + data.id + ' .displayPopover');
                if (popovers.length < 1) {
                    return;
                }

                var params = {
                    model: well
                };

                params.targets = popovers;

                var placement = 'right';
                var index = _.indexOf(datas, data);
                if (index < 3) {
                    placement = 'bottom';
                } else if (index > datas.length - 4) {
                    placement = 'top';
                }
                params.placement = placement;
                WellUtils.showPcrWellPopover(params);
            }, this));
        }
    });
});
