define('routingGroupCollection',['module',
        'backbone',
        'backbone.marionette',
        'routingGroupItemCollection',
        'underscore'
    ],
    function (module,
              Backbone,
              Marionette,
              RoutingGroupItemCollection,
              _
    ) {
        'use strict';

        module.exports = Marionette.CollectionView.extend({
            childView: RoutingGroupItemCollection,
            attributes: function () {
                return {style: 'display: grid; grid-template-columns: repeat(' + this.options.countByLine + ', 1fr); grid-auto-rows: 1fr; gap: 10px;'};
            },
            childViewOptions: function () {
                return {
                    settingMode: this.options.settingMode,
                    width: 100 / this.options.countByLine
                };
            },
            clearSelection: function () {
                _.each(this.children._views, function (view) {
                    view.select(true, false);
                });
            },
            select: function (id) {
                _.each(this.children._views, function (view) {
                    view.select(view.model.get('id') === id, view.model.get('id') === id);
                });
            },
            childViewEvents: {
                'waitProcessValid': function (view, ui) {
                    this.trigger('waitProcessValid', ui);
                },
                'onProcessValidClick': function (view, ui) {
                    this.trigger('onProcessValidClick', ui);
                }
            }
        });
    });
