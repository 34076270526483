define('runPcrValidationMainView',[
    'module',
    'backbone',
    'backbone.marionette',
    'underscore',
    'template!runPcrValidationMainView',
    'runPcrValidationMainTargetsView',
    'runPcrValidationMainWellView',
    'menuGroup',
    'entities/menuGroup',
    'commonSelect',
    'menuCheck',
    'settings',
    'rolesMixin',
    'privileges',
    'jquery'
], function (
    module,
    Backbone,
    Marionette,
    _,
    Tpl,
    TargetsView,
    WellView,
    MenuGroupView,
    MenuGroup,
    CommonSelect,
    MenuCheck,
    Settings,
    RolesMixin,
    Privileges,
    $
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,
        className: 'content',
        regions: {
            content: '.content-region',
            validationMenu: '.validation-menu-region',
            otherMenu: '.other-menu-region',
            selectAssay: '.select-assay-region',
            selectTarget: '.select-target-region',
            warningMenu: '.warning-menu-region',
            resultMenu: '.result-menu-region',
            ovarMenu: '.ovar-menu-region'
        },
        ui: {
            leftButton: '.header .header .previous',
            rightButton: '.header .header .next'
        },
        events: {
            'click .js-reset-filters': 'onResetFilters',
            'click .header .previous.clickable': 'onPreviousAssayResultVersionClick',
            'click .header .next.clickable': 'onNextAssayResultVersionClick',
            'click .mdi-flask-outline': 'onKitClick'
        },
        modelEvents: {
            'change:assayResultVersion': 'renderChangeAssayResultVersion'
        },
        initialize: function () {
            this.filters = [];

            this.itemRepeat = new Backbone.Model({
                nameCode: 'common.select.repeat',
                click: _.bind(this.onSelectFilters, this),
                mdi: 'mdi-triangle mdi-flip-v mdi-rotate-90',
                attributes: {
                    name: 'select-repeat'
                }
            });

            this.listenTo(this.model.get('wellSelected'), 'add remove reset', _.bind(this.renderGraph, this));

            this.assayResultVersions = this.model.get('resultsByAssay').chain().map(function (resultByAssay) {
                return resultByAssay.get('results').models;
            }).flatten().value();

            this.valViewFilter = Settings.get('valViewFilter');
            if (!this.valViewFilter) {
                this.valViewFilter = {ovar: []};
                Settings.set('valViewFilter', this.valViewFilter);
            }
        },
        renderMenu: function () {
            this.validationMenu = new MenuGroup({
                nameCode: 'valView.val',
                items: new Backbone.Collection([
                    {
                        nameCode: 'well.edit.validation.1',
                        click: _.bind(this.onValidateClick, this),
                        disabled: !(Settings.get('currentUserModel').hasType(RolesMixin.RUN_PCR, Privileges.VAL1)) || !this.model.get('wellSelected').any(_.bind(function (well) {
                            return well.getDisplay('VALIDATION').chain()
                                .filter(_.bind(function (result) {
                                    return result.get('refAssayResult') === this.model.get('assayResultVersion') && !result.get('codeErr');
                                }, this))
                                .map(function (result) {
                                    return parseInt(result.get('valSt'), 10);
                                })
                                .value().includes(2);
                        }, this))
                    }, {
                        nameCode: 'well.edit.validation.2',
                        click: _.bind(this.onValidate2Click, this),
                        disabled: !(Settings.get('currentUserModel').hasType(RolesMixin.RUN_PCR, Privileges.VAL2)) || !this.model.get('wellSelected').any(_.bind(function (well) {
                            return well.getDisplay('VALIDATION').chain()
                                .filter(_.bind(function (result) {
                                    return result.get('refAssayResult') === this.model.get('assayResultVersion') && !result.get('codeErr');
                                }, this))
                                .map(function (result) {
                                    return parseInt(result.get('valSt'), 10);
                                })
                                .value().includes(3);
                        }, this))
                    }, {
                        nameCode: 'well.edit.validation.1.2',
                        click: _.bind(this.onValidate12Click, this),
                        disabled: !(Settings.get('currentUserModel').hasType(RolesMixin.RUN_PCR, Privileges.VAL12)) || !this.model.get('wellSelected').any(_.bind(function (well) {
                            return well.getDisplay('VALIDATION').chain()
                                .filter(_.bind(function (result) {
                                    return result.get('refAssayResult') === this.model.get('assayResultVersion') && !result.get('codeErr');
                                }, this))
                                .map(function (result) {
                                    return parseInt(result.get('valSt'), 10);
                                })
                                .value().some(function (r) {
                                    return [2, 3].includes(r);
                                });
                        }, this))
                    }]),
                quickActions: new Backbone.Collection([
                    {
                        nameCode: 'well.edit.validation.1',
                        click: _.bind(this.onValidateClick, this),
                        disabled: !(Settings.get('currentUserModel').hasType(RolesMixin.RUN_PCR, Privileges.VAL1)) || !this.model.get('wellSelected').any(_.bind(function (well) {
                            return well.getDisplay('VALIDATION').chain()
                                .filter(_.bind(function (result) {
                                    return result.get('refAssayResult') === this.model.get('assayResultVersion') && !result.get('codeErr');
                                }, this))
                                .map(function (result) {
                                    return parseInt(result.get('valSt'), 10);
                                })
                                .value().includes(2);
                        }, this)),
                        mdi: 'mdi-check',
                        mdiAttributes: {
                            style: 'color: red'
                        }
                    }, {
                        nameCode: 'well.edit.validation.2',
                        click: _.bind(this.onValidate2Click, this),
                        disabled: !(Settings.get('currentUserModel').hasType(RolesMixin.RUN_PCR, Privileges.VAL2)) || !this.model.get('wellSelected').any(_.bind(function (well) {
                            return well.getDisplay('VALIDATION').chain()
                                .filter(_.bind(function (result) {
                                    return result.get('refAssayResult') === this.model.get('assayResultVersion') && !result.get('codeErr');
                                }, this))
                                .map(function (result) {
                                    return parseInt(result.get('valSt'), 10);
                                })
                                .value().includes(3);
                        }, this)),
                        custom: {
                            code: 'val2',
                            className: 'validation'
                        }
                    }, {
                        nameCode: 'well.edit.validation.1.2',
                        click: _.bind(this.onValidate12Click, this),
                        disabled: !(Settings.get('currentUserModel').hasType(RolesMixin.RUN_PCR, Privileges.VAL12)) || !this.model.get('wellSelected').any(_.bind(function (well) {
                            return well.getDisplay('VALIDATION').chain()
                                .filter(_.bind(function (result) {
                                    return result.get('refAssayResult') === this.model.get('assayResultVersion') && !result.get('codeErr');
                                }, this))
                                .map(function (result) {
                                    return parseInt(result.get('valSt'), 10);
                                })
                                .value().some(function (r) {
                                    return [2, 3].includes(r);
                                });
                        }, this)),
                        custom: {
                            code: 'val12',
                            className: 'validation'
                        }
                    }])
            });
            this.otherMenu = new MenuGroup({
                nameCode: 'common.miscaelenous',
                items: new Backbone.Collection([
                    {
                        nameCode: 'repeat.pcr',
                        click: _.bind(this.onValViewRepeatPcr, this)
                    }, {
                        nameCode: 'repeat.pcr.control',
                        click: _.bind(this.onValViewRepeatPcrControl, this)
                    }, {
                        nameCode: 'repeat.extract',
                        click: _.bind(this.onValViewRepeatExtraction, this),
                        select: [{
                            name: 'No dil.',
                            value: 'No dil.'
                        }, {
                            name: '1:2',
                            value: '1:2'
                        }, {
                            name: '1:5',
                            value: '1:5'
                        }]
                    }, {
                        nameCode: 'repeat.extract.control',
                        click: _.bind(this.onValViewRepeatExtractionControl, this),
                        select: [{
                            name: 'No dil.',
                            value: 'No dil.'
                        }, {
                            name: '1:2',
                            value: '1:2'
                        }, {
                            name: '1:5',
                            value: '1:5'
                        }]
                    }, {
                        nameCode: 'update.confirmResult',
                        click: _.bind(this.onConfirmResultClick, this)
                    }, {
                        nameCode: 'update.changeToNeg',
                        click: _.bind(this.onChangeToNegClick, this)
                    }])
            });

            this.warningMenu = new Backbone.Model({
                nameCode: 'common.status',
                items: new Backbone.Collection([{
                    nameCode: 'common.select.warning',
                    click: _.bind(this.onSelectFilters, this),
                    mdi: 'mdi-alert',
                    attributes: {
                        name: 'select-warning'
                    },
                    mdiAttributes: {
                        style: 'color: #ff5e00'
                    }
                }
                ])
            });

            if (this.model.get('assayResultVersion').get('assayVersion').get('assay').getDisplayMode().get('displayValidation') === 'WELL') {
                this.ovarMenu = new Backbone.Model({
                    nameCode: 'common.ovar',
                    items: new Backbone.Collection()
                });
            }

            this.resultMenu = new Backbone.Model({
                nameCode: '',
                items: new Backbone.Collection()
            });

            this.showChildView('validationMenu', new MenuGroupView({
                model: this.validationMenu
            }));
            this.showChildView('otherMenu', new MenuGroupView({
                model: this.otherMenu
            }));

            if (this.model.get('wellSelected').any(function (well) {
                return well.get('repeatStatus');
            })) {
                if (!this.warningMenu.get('items').any(_.bind(function (item) {
                    return item === this.itemRepeat;
                }, this))) {
                    this.warningMenu.get('items').add(this.itemRepeat);
                }
            } else {
                this.warningMenu.get('items').remove(this.itemRepeat);
            }

            this.showChildView('warningMenu', new MenuCheck({
                model: this.warningMenu
            }));

            this.renderGraph();
        },
        onRender: function () {
            var assays = new Backbone.Collection(this.model.get('resultsByAssay').chain().map(function (result) {
                return {
                    name: result.get('assayVersion').get('code') + ' (' + result.get('assayVersion').id + ')',
                    value: result.get('assayVersion').id
                };
            }).value());
            var selectAssayView = new CommonSelect({
                collection: assays
            });
            this.listenTo(selectAssayView, 'select:change', _.bind(this.changeAssayVersion, this));
            this.showChildView('selectAssay', selectAssayView);
            this.renderChangeAssayResultVersion();
        },
        renderGraph: function () {
            var displayMode = this.model.get('assayResultVersion').get('assayVersion').get('assay').getDisplayMode();
            switch (displayMode.get('displayValidation')) {
                case 'TARGET':
                    this.showChildView('content', new TargetsView({model: this.model}));
                    break;
                case 'WELL':
                    this.showChildView('content', new WellView({model: this.model}));
                    break;
            }
        },

        renderChangeAssayResultVersionFromSelect: function (view, event) {
            var id = event.currentTarget.value;
            var assayResultVersion = _.chain(this.assayResultVersions).filter(function (assayResult) {
                return assayResult.id === id;
            }).first().value();
            this.model.set('assayResultVersion', assayResultVersion);
        },

        renderChangeAssayResultVersion: function () {
            this.filters = [];
            if (this.assayResultVersions.indexOf(this.model.get('assayResultVersion')) === 0) {
                this.ui.leftButton.removeClass('clickable');
                this.ui.leftButton.attr('disabled', true);
                this.ui.leftButton.addClass('disabled');
            } else {
                this.ui.leftButton.addClass('clickable');
                this.ui.leftButton.removeAttr('disabled');
                this.ui.leftButton.removeClass('disabled');
            }
            if (this.model.get('assayResultVersion') === this.assayResultVersions[this.assayResultVersions.length - 1]) {
                this.ui.rightButton.removeClass('clickable');
                this.ui.rightButton.attr('disabled', true);
                this.ui.rightButton.addClass('disabled');
            } else {
                this.ui.rightButton.addClass('clickable');
                this.ui.rightButton.removeAttr('disabled');
                this.ui.rightButton.removeClass('disabled');
            }

            this.renderMenu();

            var displayMode = this.model.get('assayResultVersion').get('assayVersion').get('assay').getDisplayMode();
            switch (displayMode.get('displayValidation')) {
                case 'TARGET':
                    this.resultMenu.set('nameCode', 'result');
                    this.resultMenu.set('items', new Backbone.Collection(this.model.get('run').get('wells')
                        .chain()
                        .map(_.bind(function (well) {
                            var result = well.getDisplay('VALIDATION')
                                .chain()
                                .filter(_.bind(function (result) {
                                    return result.get('refAssayResult') === this.model.get('assayResultVersion');
                                }, this))
                                .first()
                                .value();
                            return result ? result.get('result') : null;
                        }, this))
                        .filter(function (result) {
                            return result;
                        })
                        .uniq()
                        .map(_.bind(function (result) {
                            return {
                                nameCode: result.get('code'),
                                title: result.get('resText'),
                                click: _.bind(this.onSelectFilters, this),
                                mdi: 'mdi-circle',
                                attributes: {
                                    name: 'select-result-' + result.get('code')
                                },
                                mdiAttributes: {
                                    style: 'color: ' + result.get('color')
                                },
                                id: result.id
                            };
                        }, this))
                        .sort(function (a, b) {
                            return a.nameCode.localeCompare(b.nameCode);
                        })
                        .value()));
                    this.showChildView('resultMenu', new MenuCheck({
                        model: this.resultMenu
                    }));
                    break;
                case 'WELL':
                    this.ovarMenu.set('nameCode', 'ovaRes');
                    this.ovarMenu.set('items', new Backbone.Collection(this.model.get('wellSelected')
                        .chain()
                        .filter(function (well) {
                            return !well.getDisplay('OVAR').isEmpty();
                        })
                        .map(_.bind(function (well) {
                            return well.getDisplay('OVAR').first().get('result');
                        }, this))
                        .filter(function (result) {
                            return result;
                        })
                        .uniq()
                        .map(_.bind(function (result) {
                            return {
                                nameCode: result.get('code'),
                                title: result.get('resText'),
                                click: _.bind(this.onSelectFilters, this),
                                mdi: 'mdi-circle',
                                attributes: {
                                    name: 'select-wellres-' + result.get('code')
                                },
                                mdiAttributes: {
                                    style: 'color: ' + result.get('color')
                                },
                                id: result.id
                            };
                        }, this))
                        .value()));
                    if (!this.ovarMenu.get('items').isEmpty() && displayMode.get('displayValidation') === 'TARGET') {
                        this.showChildView('ovarMenu', new MenuCheck({
                            model: this.ovarMenu,
                            linkFilter: this.valViewFilter.ovar
                        }));
                    }

                    this.resultMenu.set('nameCode', 'result');
                    this.resultMenu.set('items', new Backbone.Collection(this.model.get('wellSelected')
                        .chain()
                        .map(_.bind(function (well) {
                            return well.getDisplay('VALIDATION').findWhere({refAssayResult: this.model.get('assayResultVersion')}).get('result');
                        }, this))
                        .filter(function (result) {
                            return result;
                        })
                        .uniq()
                        .map(_.bind(function (result) {
                            return {
                                nameCode: result.get('code'),
                                title: result.get('resText'),
                                click: _.bind(this.onSelectFilters, this),
                                mdi: 'mdi-circle',
                                attributes: {
                                    name: 'select-result-' + result.get('code')
                                },
                                mdiAttributes: {
                                    style: 'color: ' + result.get('color')
                                },
                                id: result.id
                            };
                        }, this))
                        .value()));
                    if (!this.resultMenu.get('items').isEmpty() && displayMode.get('displayValidation') === 'TARGET') {
                        this.showChildView('resultMenu', new MenuCheck({
                            model: this.resultMenu
                        }));
                    }
                    break;
            }

            var viewRegion = this.getChildView('selectAssay');
            if (viewRegion) {
                viewRegion.trigger('change:select', this.model.get('assayResultVersion').get('assayVersion').id);
            }
            var targets = new Backbone.Collection(this.model.get('resultsByAssay').chain().map(function (result) {
                return {
                    type: 'group',
                    name: result.get('assayVersion').get('code') + ' (' + result.get('assayVersion').id + ')',
                    items: result.get('results').map(function (target) {
                        var dye = target.get('type') === 'CURVE' && target.get('algoResult') && target.get('algoResult') && target.get('algoResult').param ? target.get('algoResult').param.split(';')[0] : '';
                        return {name: target.get('target').get('code') + (dye ? ' (' + dye + ')' : ''), value: target.id};
                    })
                };
            }).value());
            var selectTargetView = new CommonSelect({
                collection: targets
            });
            this.listenTo(selectTargetView, 'select:change', _.bind(this.renderChangeAssayResultVersionFromSelect, this));
            this.showChildView('selectTarget', selectTargetView);
            viewRegion = this.getChildView('selectTarget');
            if (viewRegion) {
                viewRegion.trigger('change:select', this.model.get('assayResultVersion').id);
            }
        },
        onValidateClick: function () {
            $('.js-global-loader').show();
            this.model.findResult().validation()
                .always(_.bind(function () {
                    this.renderMenu();
                    $('.js-global-loader').hide();
                }, this));
        },

        onValidate2Click: function () {
            $('.js-global-loader').show();
            this.model.findResult().validation2()
                .always(_.bind(function () {
                    this.renderMenu();
                    $('.js-global-loader').hide();
                }, this));
        },

        onValidate12Click: function () {
            $('.js-global-loader').show();
            this.model.findResult().validation12()
                .always(_.bind(function () {
                    this.renderMenu();
                    $('.js-global-loader').hide();
                }, this));
        },
        onValViewRepeatPcr: function () {
            $('.js-global-loader').show();
            this.model.get('wellSelected').repeatPCR()
                .always(_.bind(function () {
                    this.renderMenu();
                    $('.js-global-loader').hide();
                }, this));
        },

        onValViewRepeatPcrControl: function () {
            $('.js-global-loader').show();
            this.model.get('wellSelected').repeatPCRControl()
                .always(_.bind(function () {
                    this.renderMenu();
                    $('.js-global-loader').hide();
                }, this));
        },

        onValViewRepeatExtraction: function (value) {
            $('.js-global-loader').show();
            this.model.get('wellSelected').repeatExtraction(value)
                .always(_.bind(function () {
                    this.renderMenu();
                    $('.js-global-loader').hide();
                }, this));
        },

        onValViewRepeatExtractionControl: function (value) {
            $('.js-global-loader').show();
            this.model.get('wellSelected').repeatExtractionControl(value)
                .always(_.bind(function () {
                    this.renderMenu();
                    $('.js-global-loader').hide();
                }, this));
        },
        onConfirmResultClick: function () {
            $('.js-global-loader').show();
            this.model.findResult().confirmResult()
                .always(_.bind(function () {
                    this.renderMenu();
                    $('.js-global-loader').hide();
                }, this));
        },

        onChangeToNegClick: function () {
            $('.js-global-loader').show();
            this.model.findResult().negativeResult()
                .always(_.bind(function () {
                    this.renderMenu();
                    $('.js-global-loader').hide();
                }, this));
        },

        onResetFilters: function () {
            this.filters = [];
            this.getChildView('warningMenu').reset();
            this.getChildView('resultMenu').reset();
            this._filter();
        },
        _filter: function () {
            var wells;
            var chain = this.model.get('run').get('wells').chain().filter(_.bind(function (well) {
                return well.getDisplay('VALIDATION').some(_.bind(function (result) {
                    return result && result.get('refAssayResult') === this.model.get('assayResultVersion');
                }, this));
            }, this));
            if (_.isEmpty(this.filters)) {
                wells = chain.value();
            } else {
                wells = chain.filter(_.bind(function (well) {
                    return _.some(this.filters, _.bind(function (filter) {
                        switch (filter) {
                            case 'select-warning':
                                return well.getDisplay('VALIDATION').some(function (result) {
                                    return result.get('codeErr');
                                });
                            case 'select-repeat':
                                return well.get('repeatStatus');
                            default:
                                if (filter.startsWith('select-wellres-')) {
                                    return well.getDisplay('OVAR').chain()
                                        .some(function (result) {
                                            var code = filter.substring('select-wellres-'.length);
                                            return result.get('result') && result.get('result').get('code') === code;
                                        })
                                        .value();
                                }
                                if (filter.startsWith('select-result-')) {
                                    var code = filter.substring('select-result-'.length);
                                    return well.getDisplay('VALIDATION').chain()
                                        .some(_.bind(function (result) {
                                            return result && result.get('refAssayResult') === this.model.get('assayResultVersion') && result.get('result') && result.get('result').get('code') === code;
                                        }, this))
                                        .value();
                                }
                        }
                    }, this));
                }, this)).value();
            }
            this.model.get('wellSelected').reset(wells);
        },
        onSelectFilters: function (e) {
            this._selectFilters(e).done(_.bind(function () {
                this._filter();
            }, this));
        },
        _selectFilters: function (e) {
            var defer = $.Deferred();

            var attribute = e.currentTarget.attributes.name.value;
            if (this.filters.includes(attribute)) {
                this.filters = _.reject(this.filters, function (filter) {
                    return filter === attribute;
                });
                defer.resolve();
            } else {
                this.filters.push(attribute);
                defer.resolve();
            }
            return defer.promise();
        },
        changeAssayVersion: function (view, event) {
            var id = event.currentTarget.value;
            var resultByAssay = this.model.get('resultsByAssay').chain().filter(function (resultByAssay) {
                return resultByAssay.get('assayVersion').id === id;
            }).first().value();
            this.model.set('assayResultVersion', resultByAssay.get('results').first());
        },
        onPreviousAssayResultVersionClick: function () {
            var index = this.assayResultVersions.indexOf(this.model.get('assayResultVersion'));
            if (index > 0) {
                this.model.set('assayResultVersion', this.assayResultVersions[index - 1]);
            }
        },
        onNextAssayResultVersionClick: function () {
            var index = this.assayResultVersions.indexOf(this.model.get('assayResultVersion'));
            if (index < this.assayResultVersions.length - 1) {
                this.model.set('assayResultVersion', this.assayResultVersions[index + 1]);
            }
        },
        onKitClick: function () {
            require(['assayVersionController'], _.bind(function (AssayVersionController) {
                var defers = [];
                this.model.get('wellSelected').fetch().done(_.bind(function () {
                    this.model.get('wellSelected').each(function (well) {
                        defers.push(well.getPcrKitLots());
                    });
                    $.when.apply($, defers).done(_.bind(function () {
                        var lots = [];
                        _.map(arguments, function (arg) {
                            _.each(arg, function (v) {
                                if (lots.length === 0) {
                                    lots.push(v);
                                } else if (!_.any(lots, function (lot) {
                                    return lot.lotId === v.lotId;
                                })) {
                                    lots.push(v);
                                }
                            });
                        });
                        AssayVersionController.showDetails({
                            model: this.model.get('wellSelected').first().get('assayVersion'),
                            options: {
                                lots: lots
                            }
                        });
                    }, this));
                }, this));
            }, this));
        }
    });
});

