define('services/caccounts/mbanares',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'bootbox',
    'entities/caccounts/mbanares'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic,
    bootbox
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.MbAnaRes,
                collection: app.MbAnaResCollection
            };
        },
        getName: function () {
            return 'mbanares';
        },
        getUrl: function () {
            return 'rest/v2/mbanares/';
        },
        getDynamicJson: function () {
            return 'MbAnaResJson';
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.MbAnaResCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['resText', 'refMbAnaResGr.code']};
        },

        getCreateEditFieldValidate: function () {
            var ignored = Dynamic.getCreateEditFieldValidate();
            ignored.push({name: 'refMbAnaResGr', type: 'required'});
            return ignored;
        },
        getCreateEditLine: function () {
            return [
                {'field': 'code', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-5'}}},
                {'field': 'resText', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-15'}}},
                {'field': 'color', 'param': {'type': 'COLOR', 'display': {'class': 'col-w-12'}}},
                {'field': 'lisCode', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-10'}}},
                {'field': 'lisText', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-20'}}},
                {'field': 'lisVariant', 'param': {'type': 'TEXT', 'display': {'class': 'col-w-10'}}},
                {
                    'field': 'errorParameter',
                    'param': {'type': 'ARRAYLIST', 'arrayCode': 'RESCODE-ERROR', 'display': {'class': 'col-w-17'}}
                }
            ];
        },
        getErrorParameter: function (model) {
            var ret = {code: null, message: null};
            if (!model.get('errorParameter')) {
                return ret;
            }
            var splitted = model.get('errorParameter').split(';');
            if (splitted.length > 0) {
                ret.code = splitted[0];
            }
            if (splitted.length > 1) {
                ret.message = splitted[1];
            }
            return ret;
        },
        findByCode: function (code, mbAnaResGrSecId) {
            var deferred = $.Deferred();
            $.ajax({
                url: Settings.url('rest/v2/mbanares/find'),
                method: 'POST',
                data: $.param({
                    code: code,
                    mbAnaResGrSecId: mbAnaResGrSecId
                }),
                contentType: 'application/x-www-form-urlencoded',
                success: _.bind(function (data) {
                    if (data.length >= 1) {
                        deferred.resolve(this.getModel(data[0]));
                    }
                    deferred.reject();
                }, this),
                error: function () {
                    deferred.reject();
                }
            });
            return deferred.promise();
        },

        onAdd: function () {
            bootbox.alert('Not implemented');
        },
        onEdit: function (callBack, id) {
            var model = this.getModel({id: id});
            model.fetch().done(_.bind(function () {
                model.get('refMbAnaResGr').fetch().done(function () {
                    var mbAnaResGrService = require('services/caccounts/mbanaresgrs');
                    mbAnaResGrService.showDetails({model: model.get('refMbAnaResGr'), callBackRefresh: callBack});
                });
            }, this));
        }
    }, Dynamic);
});

