/**
 * Created by OBL on 14/01/2016.
 */
define('runTracsController',[
    'module',
    'underscore',
    'runTracsEditPopupView'
], function (
    module,
    _,
    View
) {
    'use strict';

    module.exports = {
        /**
         *
         * @param param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Function} param.callBackOnClose - The callback function to be called after the CreateEditLayoutView is shown.
         * @throws {Error} If the model does not exist.
         */
        showDetails: function (param) {
            if (!param.model) {
                alert('Can\'t create a new model without entity, need to define empty model or existing model.');
                throw new Error('Can\'t create a new model without entity, need to define empty model or existing model.');
            }
            var createEditView = new View({
                model: param.model
            });
            createEditView.show({
                title: _.i18n(param.service.getName() + '.new'),
                className: 'baseTableEditAddPopup ' + param.service.getName() + 'EditAddPopup',
                service: param.service
            }, _.bind(function () {
                if (param.callBackOnClose) {
                    param.callBackOnClose();
                }
            }, param.callBackOnClose ? param.callBackOnClose.context : undefined));
        }
    };
});

