define('entities/caccounts/colorCompensations',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'rolesMixin',
    'entities/caccounts/assay'
], function (
    app,
    Backbone,
    Settings,
    $,
    _,
    RolesMixin
) {
    'use strict';

    app.ColorCompensation = Backbone.RelationalModel.extend({
        service: 'services/caccounts/colorCompensations',
        fetch: function () {
            this.url = Settings.url('rest/v2/colorCompensations/' + this.get('id'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'comment': '',
            'temperature': null,
            'sampleIdWater': null,
            'sampleIds': '',
            'matrices': [],
            'assays': []
        },
        idAttribute: 'id',
        relations: [{
            type: Backbone.HasMany,
            key: 'matrices',
            relatedModel: 'ColorCompensationMatrice',
            collectionType: 'ColorCompensationMatriceCollection',
            reverseRelation: {
                key: 'colorCompensation',
                includeInJSON: false
            }
        }, {
            type: Backbone.HasMany,
            key: 'assays',
            relatedModel: 'Assay',
            collectionType: 'AssayCollection',
            includeInJSON: ['id', 'code', 'name']
        }],

        initialize: function () {
            this.on('change:sampleIds', this.onSampleIdsChange, this);
            this.listenTo(this.get('matrices'), 'add', _.bind(this.onSampleIdsChange, this));
        },

        postUrl: function () {
            return Settings.url('rest/v2/colorCompensations/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'ColorCompensationJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'ColorCompensation',

        importable: true,
        onSampleIdsChange: function () {
            var sampleIds = this.get('sampleIds').split(';');
            var index = 0;
            var max = 0;
            _.each(sampleIds, function (sample) {
                if (sample) {
                    max = index;
                }
                index++;
            });
            this.get('matrices').each(function (matrice) {
                matrice.maxValue(max);
                matrice.trigger('change:values');
            });
        }
    });

    app.ColorCompensationCollection = Backbone.Collection.extend({
        model: app.ColorCompensation
    });

    app.ColorCompensation.role = RolesMixin.COLORCOMPENSATION;
});

