define('prepRunExtrKitsLotsView',[
    'module',
    'dialogFormView',
    'template!prepRunExtrKitsLotsView',
    'underscore',
    'dateUtils',
    'tableView',
    'backbone'
], function (
    module,
    DialogFormView,
    prepRunExtrKitsLotsTpl,
    _,
    dateConverter,
    TableView,
    Backbone
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: prepRunExtrKitsLotsTpl,

        className: 'extractionMethodDetails',

        regions: {
            details: '.js-details',
            pcrKitLots: '.js-pcrKitLots',
            extractionKitLots: '.js-extractionKitLots'
        },
        
        ui: {
            confirm: '.js-confirm'
        },
        
        events: {
            'click @ui.confirm': 'hide'
        },

        serializeData: function () {
            return {};
        },

        onRender: function () {
            var details = [];
            var item = {};
            if (this.model.get('extractionDetails').get('refExtractionMethod')) {
                item = {
                    extractionMethodCode: this.model.get('extractionDetails').get('refExtractionMethod').get('code'),
                    date: dateConverter.toDateTimeFormatString(this.model.get('extractionDetails').get('startDate')),
                    user: this.model.get('extractionDetails').get('startUserpid') ? this.model.get('extractionDetails').get('startUserpid').toString() : '',
                    comment: this.model.get('extractionDetails').get('startComment')
                };
            }
            item.type = 'START';
            details.push(item);
            item = {};
            if (this.model.get('extractionDetails').get('refLmbExtractor')) {
                item = {
                    lmbExtractorCode: this.model.get('extractionDetails').get('refLmbExtractor').get('code'),
                    date: dateConverter.toDateTimeFormatString(this.model.get('extractionDetails').get('endDate')),
                    user: this.model.get('extractionDetails').get('endUserpid') ? this.model.get('extractionDetails').get('endUserpid').toString() : '',
                    comment: this.model.get('extractionDetails').get('endComment')
                };
            }
            item.type = 'END';
            details.push(item);

            var detailsColumns = [
                {attribute: 'type', label: '', className: 'col-xs-1'},
                {attribute: 'extractionMethodCode', label: _.i18n('run.extractionMethod'), className: 'col-xs-2'},
                {attribute: 'lmbExtractorCode', label: _.i18n('common.lmbExtractor'), className: 'col-xs-2'},
                {attribute: 'date', label: _.i18n('date'), className: 'col-xs-2'},
                {attribute: 'user', label: _.i18n('user'), className: 'col-xs-2'},
                {attribute: 'comment', label: _.i18n('comment'), className: 'col-xs-3'}
            ];

            this.showChildView('details', new TableView({
                collection: new Backbone.Collection(details),
                columns: detailsColumns
            }));

            var pcrKitLots = [];

            _.each(this.options.kitLots.pcrKitLots, function (pcrKitLot) {
                pcrKitLots.push(pcrKitLot);
            });

            var pcrKitLotColumns = [
                {attribute: 'refKitLot.kit.code', label: _.i18n('extraction.reagent.kit'), className: 'col-xs-2'},
                {attribute: 'refKitLot.code', label: _.i18n('extraction.reagent.litlot'), className: 'col-xs-2'},
                {attribute: 'count', label: _.i18n('extraction.reagent.qty'), className: 'col-xs-2'},
                {attribute: 'volumeByTest', label: _.i18n('extraction.reagent.vol.test'), className: 'col-xs-2'},
                {attribute: 'volumeDeath', label: _.i18n('extraction.reagent.vol.death'), className: 'col-xs-2'},
                {attribute: 'volumeTotal', label: _.i18n('extraction.reagent.vol.tot'), className: 'col-xs-2'}
            ];
            this.showChildView('pcrKitLots', new TableView({
                collection: new Backbone.Collection(pcrKitLots),
                columns: pcrKitLotColumns
            }));
            
            var extractionKitLots = [];
            _.each(this.options.kitLots.extractionKitLots, function (extractionKitLot) {
                extractionKitLots.push(extractionKitLot);
            });

            var extractionKitLotsColumns = [
                {attribute: 'kit.code', label: _.i18n('extraction.reagent.kit'), className: 'col-xs-2'},
                {attribute: 'refKitLot.code', label: _.i18n('extraction.reagent.litlot'), className: 'col-xs-2'},
                {attribute: 'count', label: _.i18n('extraction.reagent.qty'), className: 'col-xs-2'},
                {attribute: 'volumeByTest', label: _.i18n('extraction.reagent.vol.test'), className: 'col-xs-2'},
                {attribute: 'volumeDeath', label: _.i18n('extraction.reagent.vol.death'), className: 'col-xs-2'},
                {attribute: 'volumeTotal', label: _.i18n('extraction.reagent.vol.tot'), className: 'col-xs-2'}
            ];

            this.showChildView('extractionKitLots', new TableView({
                collection: new Backbone.Collection(extractionKitLots),
                columns: extractionKitLotsColumns
            }));
        }
    });
});

