define('dynamicCreateEditCustomListToEntity',[
    'module',
    'backbone.marionette',
    'dynamicCreateEditCustomListToEntityItem',
    'underscore'
], function (
    module,
    Marionette,
    DynamicCreateEditCustomListToEntityItem,
    _
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        template: _.template('<div class="show-moreLess js-show-more clickable mdi mdi-arrow-down-circle"></div><div class="show-moreLess js-show-less clickable mdi mdi-arrow-up-circle"></div>'),
        childView: DynamicCreateEditCustomListToEntityItem,
        attributes: {
            style: 'display: flex; flex-direction: row;flex-wrap: wrap; gap: 3px; max-height: 50px; overflow: hidden; position: relative;'
        },
        childViewOptions: function () {
            return {
                service: this.options.service
            };
        },
        ui: {
            showMore: '.js-show-more',
            showLess: '.js-show-less'
        },
        events: {
            'click @ui.showMore': 'showMore',
            'click @ui.showLess': 'showLess'
        },
        serializeData: function () {
            return {};
        },
        
        onRender: function () {
            this.ui.showMore.hide();
            this.ui.showLess.hide();

            setTimeout(_.bind(function () {
                if (this.$el.height() < this.$el[0].scrollHeight) {
                    this.ui.showMore.show();
                    this.$el.css('height', '50px');
                    this.$el.css('max-height', '50px');
                }
            }, this), 500);
        },
        showMore: function () {
            this.ui.showMore.hide();
            this.ui.showLess.show();
            this.$el.css('height', 'auto');
            this.$el.css('max-height', 'none');
        },
        showLess: function () {
            this.ui.showMore.show();
            this.ui.showLess.hide();
            this.$el.css('height', '50px');
            this.$el.css('max-height', '50px');
        }
    });
});
