
define('template!orderSampleTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col-w-all col-w-100 d-f p-t-5 p-b-5" style="border-bottom: lightgrey solid 1px;">\n	<div class="col-w-5">\n		<span class="mdi-action-delete delete-row js-icon clickable"></span>\n	</div>\n	<div class="col-w-25">\n		<span class="clickable js-sample">' +
((__t = ( sample )) == null ? '' : __t) +
'</span>\n	</div>\n	<div class="col-w-10">\n		' +
((__t = ( bioGroupCode )) == null ? '' : __t) +
'\n	</div>\n	<div class="col-w-40 p-r-2 p-l-2">\n		<input type="text" value="' +
((__t = ( lisAnas )) == null ? '' : __t) +
'" data-field-name="lisAnas" class="col-w-all col-w-100 js-lisAnas"/>\n	</div>\n	<div class="col-w-20">\n		' +
((__t = ( assayGroups )) == null ? '' : __t) +
'\n	</div>\n	<div class="col-w-20">\n		';
 _.each(wells, function(well) { ;
__p += '\n		<span class="clickable js-well"\n			  data-wellSecId="' +
((__t = ( well.id )) == null ? '' : __t) +
'">' +
((__t = ( well.pos )) == null ? '' : __t) +
' (' +
((__t = ( well.runName )) == null ? '' : __t) +
')</span><br/>\n		';
 }) ;
__p += '\n	</div>\n</div>';

}
return __p
};});

