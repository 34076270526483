
define('template!pcrWellGraphCurveView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<ul class="nav nav-tabs">\n    <li class="nav-tab tab-melt rawData clickable" data-tab="MC-Raw">\n        <a data-toggle="tab">MC-Raw</a>\n    </li>\n    <li class="nav-tab tab-melt rawData clickable" data-tab="MC">\n        <a data-toggle="tab">MC</a>\n    </li>\n    <li class="nav-tab tab-melt rawData clickable" data-tab="MC-S">\n        <a data-toggle="tab">MC-S</a>\n    </li>\n    <li class="nav-tab tab-melt rawData clickable" data-tab="MC-D1">\n        <a data-toggle="tab">MC-D1</a>\n    </li>\n    <li class="nav-tab first-tab-melt tab-melt clickable" data-tab="MC-D1-S">\n        <a data-toggle="tab">MC-D1-S</a>\n    </li>\n    <li class="nav-tab tab-ampl rawData clickable" data-tab="AC-Raw">\n        <a data-toggle="tab">AC-Raw</a>\n    </li>\n    <li class="nav-tab tab-ampl rawData clickable" data-tab="AC">\n        <a data-toggle="tab">AC</a>\n    </li>\n    <li class="nav-tab first-tab-ampl tab-ampl clickable" data-tab="AC-S">\n        <a data-toggle="tab">AC-S</a>\n    </li>\n    <li class="nav-tab tab-ampl rawData clickable" data-tab="AC-D1">\n        <a data-toggle="tab">AC-D1</a>\n    </li>\n    <li class="nav-tab tab-ampl rawData clickable" data-tab="AC-D1-S">\n        <a data-toggle="tab">AC-D1-S</a>\n    </li>\n    <li class="nav-tab tab-ampl rawData clickable" data-tab="AC-D2">\n        <a data-toggle="tab">AC-D2</a>\n    </li>\n    <li class="nav-tab tab-ampl rawData clickable" data-tab="AC-D2-S">\n        <a data-toggle="tab">AC-D2-S</a>\n    </li>\n</ul>\n<div class="tab-content" style="display: flex; justify-content: space-between;">\n    <div class="js-graph" id="graph" style="flex-grow: 1;"></div>\n    <div class="curve-action" style="max-width: 40px;">\n        <div class="valViewHeaderFilterButton clickable hidden-print js-colorCompensation ' +
((__t = ( withCC ?
                    'selected' : '' )) == null ? '' : __t) +
'"\n             style="margin: 2px;">\n                                        <span class="mdi  mdi-palette js-colorCompensation-btn clickable"\n                                              style="line-height: 19px;font-size: 22px;"\n                                              title="' +
((__t = ( _.i18n('valView.withColorCompensation') )) == null ? '' : __t) +
'"></span>\n        </div>\n        <div class="valViewHeaderFilterButton clickable hidden-print ' +
((__t = ( legendChecked ?
                    'selected' : '' )) == null ? '' : __t) +
'"\n             style="margin: 2px;">\n                                        <span class="mdi mdi-notification-clear-all js-chartLegend-btn clickable"\n                                              style="line-height: 19px;font-size: 22px;"\n                                              title="' +
((__t = ( _.i18n('valView.withLegend') )) == null ? '' : __t) +
'"></span>\n        </div>\n        <div class="valViewHeaderFilterButton clickable hidden-print ' +
((__t = ( rangeSmooth ?
                    'selected' : '' )) == null ? '' : __t) +
'"\n             style="margin: 2px;">\n                                        <span class="mdi mdi-iron-outline js-smoothing-btn clickable"\n                                              style="line-height: 19px;font-size: 22px;"\n                                              title="' +
((__t = ( _.i18n('valView.withSmoothing') )) == null ? '' : __t) +
'"></span>\n        </div>\n        <div class="valViewHeaderFilterButton clickable hidden-print ' +
((__t = ( rawData ?
                    'selected' : '' )) == null ? '' : __t) +
'"\n             style="margin: 2px;">\n                                        <span class="mdi mdi-raw js-rawData-btn clickable"\n                                              style="line-height: 19px;font-size: 22px;"\n                                              title="' +
((__t = ( _.i18n('valView.withRawData') )) == null ? '' : __t) +
'"></span>\n        </div>\n        <div class="valViewHeaderFilterButton clickable hidden-print ' +
((__t = ( hideStartEndPoint ?
                    'selected' : '' )) == null ? '' : __t) +
'"\n             style="margin: 2px;">\n                                        <span class="mdi mdi-arrow-expand-horizontal js-hideStartEndPoint-btn clickable"\n                                              style="line-height: 19px;font-size: 22px;"\n                                              title="' +
((__t = ( _.i18n('valView.startEndPoint') )) == null ? '' : __t) +
'"></span>\n        </div>\n    </div>\n</div>';

}
return __p
};});

