define('commonSelect',[
    'module',
    'backbone',
    'backbone.marionette',
    'underscore',
    'commonGenericSelectView'
], function (
    module,
    Backbone,
    Marionette,
    _,
    Select
) {
    'use strict';

    module.exports = Marionette.View.extend({
        getTemplate: function () {
            return _.template('<div class="js-list d-f" style="flex-grow: 1">');
        },
        regions: {
            list: '.js-list'
        },
        className: 'select-component d-f column',
        attributes: {
            style: 'align-items: stretch;'
        },

        initialize: function () {
            if (_.isArray(this.options.collection)) {
                this.options.collection = new Backbone.Collection(this.options.collection);
            } else if (this.options.collection instanceof Backbone.Collection) {
                this.options.collection = new Backbone.Collection(this.options.collection.models);
            } else {
                throw new Error('Collection must be an array or a Backbone collection');
            }
            this.listenTo(this, 'change:select', _.bind(this.onChangeSelect, this));
        },

        onRender: function () {
            this.view = new Select({
                collection: this.collection,
                attr: this.options.attr,
                model: this.options.model,
                modelProperty: this.options.modelProperty,
                value: this.options.value,
                displaySize: 1,
                classNameSelect: this.options.classNameSelect
            });
            this.showChildView('list', this.view);
            this.listenTo(this.view, 'select:change', _.bind(function (selectView, e) {
                var values = selectView.$el.val();
                if (_.isArray(values) && values.length > 1) {
                    // disable
                    this.ui.up.prop('disabled', true);
                    this.ui.up.removeClass('clickable');
                    this.ui.up.addClass('disabled');
                    this.ui.down.prop('disabled', true);
                    this.ui.down.removeClass('clickable');
                    this.ui.down.addClass('disabled');
                    return;
                }
                var value;
                if (_.isArray(values)) {
                    value = _.first(values);
                } else {
                    value = values;
                }
                if (this.options.model && this.options.modelProperty) {
                    this.options.model.set(this.options.modelProperty, value);
                }
                this.trigger('select:change', selectView, e);
            }, this));
        },
        getSelected: function () {
            return this.getChildView('list').$el.val();
        },
        getValues: function () {
            return this.collection.map(function (model) {
                return model.get('value');
            });
        },
        onChangeSelect: function (value) {
            this.view.trigger('change:select', value);
        },
        previous: function () {
            this.view.previous();
        },
        next: function () {
            this.view.next();
        }
    });
});
