define('runPrepHeaderDetailsView',[
    'module',
    'backbone.marionette',
    'underscore',
    'template!runPrepHeaderDetailsView',
    'pcrRunCollectionView',
    'prepRunEditLayoutView',
    'prepRunExtrKitsLotsView',
    'app',
    'renderer',
    'template!prepRunStatusTpl',
    'fieldUtils',
    'wellUtils',
    'template!runPrepTooltip',
    'commonSelect',
    'jquery'
], function (
    module,
    Marionette,
    _,
    Tpl,
    PcrRunCollectionView,
    CreateEditLayoutView,
    PrepRunExtrKitsLotsView,
    App,
    Renderer,
    PrepRunStatusTpl,
    FieldUtils,
    WellUtils,
    RunPrepTooltipTpl,
    CommonSelect,
    $
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,
        className: 'details prepRunHeader',
        regions: {
            'pcrRuns': '.js-pcrruns',
            'prepRuns': '.js-runName-div-container'
        },
        ui: {
            copyParameters: '.js-copyParameters',
            statusToolTip: '.js-status-tooltip',
            mainHeaderRunBox: '.pop-mainHeaderRunBox',
            prepRuns: '.js-runName-div-container'
        },
        modelEvents: {
            'change': 'renderSafe'
        },
        events: {
            'click .btn-edit-preprun': 'onEditPrepRun',
            'click .btn-show-kits': 'onShowKits',
            'click .btn-show-attachments': 'onShowAttachments',
            'click .js-run-refresh': 'onRunRefresh'
        },
        serializeData: function () {
            return {
                code: this.model.get('code'),
                status: this.model.get('status')
            };
        },
        renderSafe: function () {
            if (!this.isDestroyed) {
                setTimeout(_.bind(this.render, this), 50);
            }
        },
        onRender: function () {
            this.showChildView('pcrRuns', new PcrRunCollectionView({
                collection: this.model.get('pcrRuns'),
                model: this.model
            }));
            if (this.options.runs && !this.options.runs.isEmpty()) {
                var items = this.options.runs.map(function (run) {
                    return {
                        value: run.id,
                        name: run.get('code')
                    };
                });
                this.showChildView('prepRuns', new CommonSelect({
                    collection: items,
                    value: this.model.id,
                    classNameSelect: 'select-run'
                }));
                this.listenTo(this.getChildView('prepRuns'), 'select:change', function (selectView, e) {
                    var id = e.currentTarget.value;
                    App.navigate('runs/prep/' + id + '?fromSelect=true', {trigger: true});
                });
                this.ui.prepRuns.css('padding-left', 0);
            } else {
                App.navigate('runs/prep/' + this.model.id, {trigger: false});
            }
            App.trigger('forClipboard', this.ui.copyParameters);
            this.showPrepRunPopover();
            this.showStatusPrepRunPopover();
        },
        onEditPrepRun: function () {
            var view = new CreateEditLayoutView({
                model: this.model
            });
            view.show({
                title: _.i18n('preprun.edit'),
                className: 'runEditAddPopup'
            }, _.bind(function () {
                this.render();
            }, this));
        },
        onShowKits: function () {
            this.model.getKitLots()
                .done(_.bind(function (result) {
                    this.prepRunExtrKitsLotsView = new PrepRunExtrKitsLotsView({
                        model: this.model,
                        kitLots: result
                    });
                    this.prepRunExtrKitsLotsView.show({
                        title: _.i18n('prepRun.extr.kits'),
                        className: 'runShowKitsPopup'
                    });
                }, this));
        },
        onShowAttachments: function () {
            var PrepRunService = require('services/caccounts/prepruns');
            var AttachmentService = require('services/caccounts/attachment');
            AttachmentService.openAttachment(PrepRunService, this.model);
        },
        onRunRefresh: function () {
            $('.js-global-loader').show();
            this.model.fetch({wells: true}).always(function () {
                $('.js-global-loader').hide();
            });
        },
        showPrepRunPopover: function () {
            if (this.isDestroyed()) {
                return;
            }
            var templateData = {
                id: this.model.get('id'),
                name: this.model.get('name'),
                code: this.model.get('code'),
                status: this.model.get('status'),
                department: this.model.get('department'),
                periodFormatted: this.model.get('periodFormatted'),
                group: this.model.get('group'),
                sequenceFormatted: FieldUtils.getSequenceFormatted(this.model.get('sequence'), this.model.get('sequenceFormat')),
                extractionMethod: this.model.get('extractionDetails').get('refExtractionMethod') ? this.model.get('extractionDetails').get('refExtractionMethod').get('code') : '',
                extractor: this.model.get('extractionDetails').get('refLmbExtractor') ? this.model.get('extractionDetails').get('refLmbExtractor').get('code') : '',
                respUser: this.model.get('refRespUser') ? this.model.get('refRespUser').get('nickName') ? this.model.get('refRespUser').get('nickName') : this.model.get('refRespUser').get('email') : '',
                comment: this.model.get('comment')
            };
            templateData.formatedPcrRuns = this.getFormatedPcrRunsFromPrepRun();
            templateData.wellsSizePlateIcon = WellUtils.getWellsSizePlateIcon(this.model.get('wells').size(), true);
            templateData.assaysToDisplay = this.model.getFormattedRunAssayStatus();
            var renderer = new Renderer({
                template: RunPrepTooltipTpl, 
                templateData: templateData
            });
            renderer.render();
            var content = renderer.$el.html();
            this.ui.mainHeaderRunBox.attr('data-content', content);
            this.ui.mainHeaderRunBox.popover({
                trigger: 'hover',
                placement: 'bottom',
                html: 'true',
                container: 'body'
            }).data('bs.popover').tip().addClass('run-prep-popover');
        },

        getFormatedPcrRunsFromPrepRun: function () {
            var formatedPcrRuns = '';
            this.model.get('pcrRuns').each(_.bind(function (runName) {
                formatedPcrRuns = formatedPcrRuns + runName.get('name') + '; ';
            }, this));
            formatedPcrRuns = formatedPcrRuns.substring(0, formatedPcrRuns.length - 2);
            return formatedPcrRuns;
        },

        showStatusPrepRunPopover: function () {
            if (this.isDestroyed()) {
                return;
            }
            if (this.ui.statusToolTip && this.ui.statusToolTip.length > 0) {
                var renderer = new Renderer({
                    template: PrepRunStatusTpl, templateData: {
                        current: this.model.get('status')
                    }
                });
                renderer.render();
                var content = renderer.$el.html();
                this.ui.statusToolTip.attr('data-content', content);
                this.ui.statusToolTip.popover({
                    trigger: 'hover',
                    placement: 'bottom',
                    html: 'true',
                    container: 'body'
                }).data('bs.popover').tip().addClass('run-prep-popover');
            }
        }
    });
});

