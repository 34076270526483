define('extendBackboneRelational',[
    'backbone',
    'underscore',
    'entities/ns',
    'entities/caccounts/userpids',
    'entities/caccounts/entityError'
], function (
    Backbone,
    _
) {
    'use strict';
    var OriginalInitializeRelations = Backbone.RelationalModel.prototype.initializeRelations;

    Backbone.RelationalModel.prototype.initializeRelations = function (options) {
        if (!_.any(this.relations, function (relation) {
            return relation.key === 'creatBy';
        })) {
            this.relations.push({
                type: Backbone.HasOne,
                key: 'creatBy',
                relatedModel: 'UserPid',
                includeInJSON: ['id', 'firstName', 'lastName', 'email', 'nickName', 'logonId']
            });
        }
        if (!_.any(this.relations, function (relation) {
            return relation.key === 'modBy';
        })) {
            this.relations.push({
                type: Backbone.HasOne,
                key: 'modBy',
                relatedModel: 'UserPid',
                includeInJSON: ['id', 'firstName', 'lastName', 'email', 'nickName', 'logonId']
            });
        }
        if (!_.any(this.relations, function (relation) {
            return relation.key === 'entityError';
        })) {
            this.relations.push({
                type: Backbone.HasOne,
                key: 'entityError',
                relatedModel: 'EntityError',
                includeInJSON: ['id', 'code', 'className', 'type']
            });
        }
        // Appel du constructeur parent
        OriginalInitializeRelations.apply(this, options);
    };
});
