define('pcrSetupPcrRunCollectionView',[
    'module',
    'backbone',
    'backbone.marionette',
    'underscore',
    'jquery',
    'pcrSetupPcrRunItem',
    'pcrSetupPcrRunEmpty',
    'template!pcrSetupPcrRunCollectionView'
], function (
    module,
    Backbone,
    Marionette,
    _,
    $,
    Item,
    Empty,
    Tpl
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        template: Tpl,
        childViewContainer: '.js-container',
        className: 'runList rounded filter height-100',
        childView: function (model) {
            if (model.isNew()) {
                return Empty;
            }
            return Item;
        },
        modelEvents: {
            'change:currentAssay': 'render',
            'change:currentProtocol': 'render',
            'change:history': 'render'
        },
        initialize: function () {
            var PcrRunService = require('services/caccounts/pcrSetup/pcrRunLite');
            this.collection.push(PcrRunService.create());
            this.sortType = 'periodDesc';
        },
        childViewEvents: {
            'validateSelection': function () {
                this.trigger('validateSelection');
            },
            'item:new': function () {
                require([
                    'runPcrController'
                ], _.bind(function (PcrRunController) {
                    var PcrRunService = require('services/caccounts/pcrruns');
                    var model = PcrRunService.getModel({});
                    PcrRunController.showDetails({service: PcrRunService, model: model, callBackOnClose: _.bind(this.onCreateRun, this)});
                }, this));
            }
        },
        onCreateRun: function (results) {
            $('.js-global-loader').show();
            var defers = [];
            var models = [];
            var PcrRunService = require('services/caccounts/pcrSetup/pcrRunLite');
            _.each(results, _.bind(function (item) {
                var model = PcrRunService.create({id: item.id});
                models.push(model);
                defers.push(model.fetch());
            }, this));

            $.when.apply($, defers)
                .done(_.bind(function () {
                    _.each(models, _.bind(function (model) {
                        model.set('refPcrSetup', this.model);
                    }, this));
                }, this))
                .always(
                    _.bind(function () {
                        $('.js-global-loader').hide();
                    }, this));
        },
        viewFilter: function (view) {
            return view.model.canDisplay();
        },
        ui: {
            sortPeriodAsc: '.mdi-sort-ascending',
            sortPeriodDesc: '.mdi-sort-descending'
        },
        events: {
            'click .mdi-sort-ascending.clickable': 'onSortPeriodAscClick',
            'click .mdi-sort-descending.clickable': 'onSortPeriodDescClick'
        },
        attributes: function () {
            return {
                'style': 'background-color: #81c2cb;'
            };
        },
        onSortPeriodAscClick: function () {
            this.collection.remove(this.collection.filter(function (model) {
                return model.isNew();
            }));
            this.collection.comparator = function (model1, model2) {
                if (model1.get('period') === model2.get('period')) {
                    return 0;
                }
                if (model1.get('period') < model2.get('period')) {
                    return -1;
                }
                return 1;
            };
            this.collection.sort({silent: true});
            var PcrRunService = require('services/caccounts/pcrSetup/pcrRunLite');
            this.collection.push(PcrRunService.create(), {silent: true});
            this.sortType = 'periodAsc';
            this.render();
        },
        onSortPeriodDescClick: function () {
            this.collection.remove(this.collection.filter(function (model) {
                return model.isNew();
            }));
            this.collection.comparator = function (model1, model2) {
                if (model1.get('period') === model2.get('period')) {
                    return 0;
                }
                if (model1.get('period') < model2.get('period')) {
                    return 1;
                }
                return -1;
            };
            this.collection.sort({silent: true});
            var PcrRunService = require('services/caccounts/pcrSetup/pcrRunLite');
            this.collection.push(PcrRunService.create(), {silent: true});
            this.sortType = 'periodDesc';
            this.render();
        },
        _disable: function (ui) {
            ui.removeClass('clickable');
            ui.addClass('disabled');
            ui.attr('disabled', true);
        },
        _clickable: function (ui) {
            ui.addClass('clickable');
            ui.removeClass('disabled');
            ui.removeClass('disabled');
        },
        onRender: function () {
            if (this.sortType === 'periodDesc') {
                this._disable(this.ui.sortPeriodDesc);
                this._clickable(this.ui.sortPeriodAsc);
            }
            if (this.sortType === 'periodAsc') {
                this._disable(this.ui.sortPeriodAsc);
                this._clickable(this.ui.sortPeriodDesc);
            }
        }
    });
});

