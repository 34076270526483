
define('template!assayVersionCreateEditLayout', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="form-horizontal">\n    <div class="form-inputs-container">\n        <div class="form-group">\n\n            <label class="col-xs-2 control-label dataNameIdLabel">' +
((__t = ( _.i18n('common.assay') )) == null ? '' : __t) +
'</label>\n\n            <div class="col-xs-2" style="display: flex;">\n                <input autocomplete="off"\n                       class="form-control floating-label js-code inputBackground"\n                       data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                       data-placement="top" placeholder=""\n                       type="text" value="' +
((__t = ( code )) == null ? '' : __t) +
'">\n            </div>\n            <div class="col-xs-3">\n                <input autocomplete="off"\n                       class="form-control floating-label inputBackground"\n                       data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'" data-placement="top"\n                       placeholder=""\n                       type="text" value="' +
((__t = ( name?name:'' )) == null ? '' : __t) +
'">\n            </div>\n\n            <label class="col-xs-1 control-label dataNameIdLabel">' +
((__t = ( _.i18n('assay.groupName') )) == null ? '' : __t) +
'</label>\n\n            <div class="col-xs-2">\n                <input autocomplete="off"\n                       class="form-control floating-label inputBackground"\n                       data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                       data-placement="top"\n                       placeholder=""\n                       type="text" value="' +
((__t = ( assayGroupCode )) == null ? '' : __t) +
'">\n            </div>\n        </div>\n\n        <div class="form-group">\n\n            <label class="col-xs-2 control-label dataNameIdLabel">' +
((__t = ( _.i18n('menu.admin.kitprots')
                )) == null ? '' : __t) +
'</label>\n\n            <div class="col-xs-2">\n                <input autocomplete="off"\n                       class="form-control floating-label inputBackground"\n                       data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                       data-placement="top"\n                       placeholder=""\n                       type="text" value="' +
((__t = ( kitProtCode )) == null ? '' : __t) +
'">\n            </div>\n\n            <label class="col-xs-1 control-label dataNameIdLabel">' +
((__t = ( _.i18n('assay.protocolVariant')
                )) == null ? '' : __t) +
'</label>\n\n            <div class="col-xs-1">\n                <input autocomplete="off"\n                       class="form-control floating-label inputBackground"\n                       data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'" data-field-name="protocolVariant"\n                       data-placement="top"\n                       placeholder=""\n                       type="text" value="' +
((__t = ( protocolVariant )) == null ? '' : __t) +
'">\n            </div>\n\n            <div class="col-xs-1"></div>\n\n            <label class="col-xs-1 control-label dataNameIdLabel">' +
((__t = ( _.i18n('assay.volumeBySample')
                )) == null ? '' : __t) +
'</label>\n\n            <div class="col-xs-1">\n                <input class="form-control inputBackground" data-field-name="volumeBySample" min="0"\n                       placeholder="' +
((__t = ( _.i18n('common.empty.placeholder') )) == null ? '' : __t) +
'"\n                       step="0.01"\n                       type="number"\n                       value="' +
((__t = ( volumeBySample )) == null ? '' : __t) +
'"/>\n            </div>\n        </div>\n\n        <div class="form-group p-l-0 p-r-0">\n            <label class="col-xs-2 control-label noWrapHidden" style="text-align: right;">' +
((__t = (
                _.i18n('assayConfiguration.code') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-2 js-code">\n                <input type="text"\n                       class="form-control inputBackground"\n                       placeholder=""\n                       value="' +
((__t = ( configurationCode )) == null ? '' : __t) +
'"\n                       data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                       data-placement="top" autocomplete="off">\n            </div>\n            <label class="col-xs-1 control-label noWrapHidden" style="text-align: right;">' +
((__t = (
                _.i18n('assayConfiguration.colorCompensation') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-2 js-colorCompensation">\n                <input type="text"\n                       class="form-control inputBackground"\n                       placeholder=""\n                       value="' +
((__t = ( colorCompensationCode )) == null ? '' : __t) +
'"\n                       data-content="' +
((__t = ( _.i18n('common.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                       data-placement="top" autocomplete="off">\n            </div>\n        </div>\n\n        <div class="col-xs-12 m-t-5 m-b-5 rounded">\n            <div class="col-w-all col-w-100 p-l-0 p-r-0 tableUnderlineTitle d-f">\n                <div class="col-w-3 noWrapHidden">' +
((__t = ( _.i18n('assayresult.sequence.title') )) == null ? '' : __t) +
'\n                </div>\n                <!-- liaison result - labels -->\n                <div class="col-w-9 noWrapHidden p-l-5"><!--p-l-1-->\n                    ' +
((__t = ( _.i18n('assay.kitp.title.target') )) == null ? '' : __t) +
'\n                </div>\n                <div class="col-w-2 p-l-5 noWrapHidden"><!--ic-->\n                    ' +
((__t = ( _.i18n('assay.kitp.title.ic') )) == null ? '' : __t) +
'\n                </div>\n\n                <div class="col-w-45 p-l-0 p-r-0 d-f">\n                    <div class="col-w-100 col-w-all d-f">\n                        <div class="col-w-22 noWrapHidden p-l-3">' +
((__t = ( _.i18n('assay.kitp.title.algo.res') )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="col-w-all col-w-78 d-f">\n                            <div class="col-w-27 noWrapHidden p-l-3">' +
((__t = ( _.i18n('assay.kitp.title.algo.dye') )) == null ? '' : __t) +
'\n                            </div>\n                            <div class="col-w-73 noWrapHidden p-l-3">' +
((__t = ( _.i18n('assay.kitp.title.algo.param') )) == null ? '' : __t) +
'\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n                <div class="col-w-1"></div>\n\n                <div class="col-w-15 d-b p-l-0 p-r-0">\n                    <div class="col-w-all d-f">\n                        <div class="col-w-40 noWrapHidden p-l-3">' +
((__t = ( _.i18n('assay.kitp.title.validation.algo') )) == null ? '' : __t) +
'\n                        </div>\n                        <div class="col-w-60 noWrapHidden p-l-3">' +
((__t = ( _.i18n('assay.kitp.title.validation.param') )) == null ? '' : __t) +
'\n                        </div>\n                    </div>\n                </div>\n\n                <div class="col-w-1"></div>\n\n                <div class="col-w-1"></div>\n\n                <div class="col-w-4 noWrapHidden">' +
((__t = ( _.i18n('assay.kitp.title.algotexport') )) == null ? '' : __t) +
'</div>\n                <div class="col-w-11 noWrapHidden p-l-5">' +
((__t = ( _.i18n('assay.kitp.title.export.params') )) == null ? '' : __t) +
'</div>\n            </div>\n            <!-- liaison results - table -->\n            <div class="js-assayResult-region"></div>\n        </div>\n    </div>\n    <!-- liaison lots -->\n    <div class="col-xs-12 m-t-10 m-b-10 p-5 js-kitlots rounded">\n    </div>\n    <!-- comments row -->\n\n    <div class="js-description"></div>\n    <!-- cancel/confirm row -->\n    <div class="cancelConfirmRow">\n        <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n    </div>\n\n</div>\n';

}
return __p
};});

