define('sampleCreateEditHeaderView',[
    'module',
    'backbone.marionette',
    'template!sampleCreateEditHeaderView',
    'settings',
    'moment',
    'rolesMixin',
    'bootbox',
    'jquery',
    'underscore',
    'app',
    'privileges'
], function (
    module,
    Marionette,
    Tpl,
    Settings,
    moment,
    RolesMixin,
    bootbox,
    $,
    _,
    App,
    Privileges
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        className: 'row',
        ui: {
            copyParameters: '.js-copy-parameters',
            editSampleId: '.js-edit-sampleId'
        },
        events: {
            'click @ui.editSampleId': 'onChangeSampleId'
        },

        serializeData: function () {
            var templateData = {};
            templateData.lisCode = this.model.get('refLIS') ? this.model.get('refLIS').get('code') : '-';
            templateData.sampleClassCode = this.model.get('refSampleClass') ? this.model.get('refSampleClass').get('code') : '-';

            templateData.parent = {sample: '-', lisCode: '-', sampleClassCode: '-', id: null};
            if (this.model.get('parent')) {
                var parent = this.model.get('parent');
                templateData.parent = {
                    sample: parent.sample,
                    lisCode: parent.lis ? parent.lis.code : '-',
                    sampleClassCode: parent.sampleClass ? parent.sampleClass.code : '-',
                    id: parent.id
                };
            }
            templateData.patientId = this.model.get('patientId') ? this.model.get('patientId') : '-';
            templateData.orderCode = this.model.get('order') ? this.model.get('order').get('code') : '-';
            templateData.bioGroupCode = this.model.get('bioGroup') ? this.model.get('bioGroup').get('code') : '-';
            templateData.specieCode = this.model.get('specie') ? this.model.get('specie').get('code') : '-';
            templateData.lisComment = this.model.get('lisComment') ? this.model.get('lisComment') : '-';
            templateData.comment = this.model.get('comment') ? this.model.get('comment') : '-';
            templateData.sample = this.model.get('code') ? this.model.get('code') : '-';
            templateData.created = this.model.get('creatDate') ? moment(this.model.get('creatDate')).format('YYYY-MM-DD HH:mm') : '-';
            templateData.modified = this.model.get('modDate') ? moment(this.model.get('modDate')).format('YYYY-MM-DD HH:mm') : '-';
            templateData.untilValidated = this.model.get('expirationDate') ? moment(this.model.get('expirationDate')).format('YYYY-MM-DD HH:mm') : '-';

            templateData.validSampleClass = this.model.get('validSampleClass');
            templateData.verified = this.model.get('verified');
            templateData.sampleNotValidated = templateData.verified === 'NOT_VALIDATED';
            templateData.canRefreshSampleClass = Settings.get('currentUserModel').hasRole(RolesMixin.SAMPLECLASS) ? '' : 'invisible';
            templateData.canSynchronizeLis = Settings.get('currentUserModel').hasRole(RolesMixin.LIS) ? '' : 'invisible';

            return templateData;
        },
        
        onRender: function () {
            if (!Settings.get('currentUserModel').hasType(RolesMixin.SAMPLES, Privileges.SAMPLE_EDIT_CODE)) {
                this.ui.editSampleId.hide();
            }
            App.trigger('forClipboard', this.ui.copyParameters);
        },

        onChangeSampleId: function () {
            bootbox.prompt({
                title: _.i18n('sample.changeSampleId'),
                callback: _.bind(function (sampleId) {
                    $('.js-global-loader').show();
                    var SampleService = require('services/caccounts/samples');
                    SampleService.valid(sampleId, this.model.get('id')).done(_.bind(function (response) {
                        if (response.valid) {
                            this.model.set({
                                'code': sampleId,
                                'name': sampleId,
                                'refSampleClass': response.sampleClass,
                                'refLIS': response.lis
                            });
                        } else {
                            bootbox.alert(_.i18n('sample.error.' + response.error));
                        }
                    }, this))
                        .always(function () {
                            $('.js-global-loader').hide();
                        });
                }, this),
                value: this.model.get('code')
            });
        }
    });
});

