define('assayReagentMMXItemView',[
    'module',
    'template!assayReagentMMXItemTpl',
    'underscore',
    'autocompleteView',
    'dialogFormView'
], function (
    module,
    Tpl,
    _,
    AutocompleteView,
    DialogFormView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input-mmx'
        },

        events: {
            'change @ui.input': 'onInputChange',
            'click .delete-row': 'onDelete'
        },

        regions: {
            pcrKit: '.js-kit',
            reagent: '.js-reagent'
        },

        modelEvents: {
            'change:volumeByTest': 'onChangeVolumeByTest'
        },

        className: 'd-f col-w-100',

        attributes: {
            style: 'gap: 3px;'
        },

        initialize: function () {
            if (this.model.get('reagent')) {
                this.pcrKit = this.model.get('reagent').get('kit');
            }
        },
        serializeData: function () {
            return {
                volumeByTest: this.model.get('volumeByTest'),
                remark: this.model.get('remark'),
                sequence: this.model.get('sequence')
            };
        },
        onRender: function () {
            this.displayPcrKitAutocomplete();
            if (this.model.get('reagent')) {
                this.displayReagent();
            }
        },

        onChangePcrKit: function (fieldName, model) {
            if (model) {
                var PcrKitService = require('services/caccounts/kitspcr');
                model = PcrKitService.getModel(model.toJSON());
            }
            this.pcrKit = model;
            if (this.pcrKit && this.pcrKit.get('reagents')) {
                this.displayReagent();
            }
        },
        displayPcrKitAutocomplete: function () {
            var PcrKitService = require('services/caccounts/kitspcr');
            this.getRegion('pcrKit').show(new AutocompleteView(_.extend(this._getAutocompleteOptionObject(PcrKitService.getAutocompleteParamForAssay({
                    modelProperty: 'reagent.kit',
                    callBackOnChange: this.onChangePcrKit,
                    assaySecId: this.model.get('refAssay').get('id'),
                    reagentTypes: ['MMX'],
                    callBackAdd: this.onAddPcrKit,
                    callBackEdit: this.onEditPcrKit
                })), {model: this.pcrKit})
            ));
        },
        onEditPcrKit: function (id) {
            var PcrKitService = require('services/caccounts/kitspcr');
            var model = PcrKitService.getModel({id: id});
            model.fetch().done(_.bind(function () {
                this.showPcrKit(model);
            }, this));
        },

        onAddPcrKit: function () {
            var PcrKitService = require('services/caccounts/kitspcr');
            var model = PcrKitService.getModel();
            this.showPcrKit(model);
        },

        showPcrKit: function (model) {
            var PcrKitService = require('services/caccounts/kitspcr');
            PcrKitService.showDetails({model: model, callBackRefresh: _.bind(this.onRender, this)});
        },
        displayReagent: function () {
            if (!this.pcrKit) {
                return;
            }
            var PcrReagentService = require('services/caccounts/pcrreagents');
            this.getRegion('reagent').show(new AutocompleteView(
                this._getAutocompleteOptionObject(PcrReagentService.getAutocompleteParam({
                    data: _.map(this.pcrKit.get('reagents').filter(_.bind(function (model) {
                        return ['MMX'].includes(model.get('type'));
                    }, this)), function (model) {
                        return model.toJSON();
                    }),
                    modelProperty: 'reagent',
                    callBackOnChange: this.onChangeReagent,
                    canAddOrEdit: false
                }))));
        },
        onChangeReagent: function (fieldName, model) {
            this.onChange(fieldName, model);
            this.trigger('item:updateReagent', model);
        },

        onDelete: function () {
            this.trigger('item:delete', this.model);
        },
        refresh: function () {
            this.displayPcrKitAutocomplete();
        },
        onChangeVolumeByTest: function () {
            this.trigger('item:updateVolume');
        }
    });
});

