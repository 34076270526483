define('jqGridValue',[
    'module',
    'underscore'
], function (
    module,
    _
) {
    'use strict';

    module.exports = {
        check: function () {
            return ':' + _.i18n('common.any') + ';true:' + _.i18n('Yes') + ';false:' + _.i18n('No');
        }
    };
});
