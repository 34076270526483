define('tableItemView',[
    'module',
    'backbone.marionette',
    'underscore',
    'backbone'
], function (
    module,
    Marionette,
    _,
    Backbone
) {
    'use strict';

    module.exports = Marionette.View.extend({
        tagName: 'tr',
        template: _.template(''),
        onRender: function () {
            this.$el.empty();
            _.each(this.options.columns, _.bind(function (column) {
                var content = this.get(this.model, column.attribute.split('.'));
                this.$el.append('<td>' + content + '</td>');
            }, this));
        },
        get: function (obj, paths) {
            if (_.isNull(obj) || _.isUndefined(obj)) {
                return '';
            }
            if (paths.length === 0) {
                return obj;
            } else {
                if (obj instanceof Backbone.Model) {
                    return this.get(obj.get(paths[0]), paths.slice(1));
                } else {
                    return this.get(obj[paths[0]], paths.slice(1));
                }
            }
        }
    });
});
