define('entities/global/textSlave',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.TextSlave = Backbone.RelationalModel.extend({
        service: 'services/global/textSlave',
        fetch: function () {
            this.url = Settings.url('rest/global/text/' + this.get('text').get('id') + '/slaves/' + this.get('id'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'locale': '',
            'value': ''
        },
        idAttribute: 'id',

        postUrl: function () {
            return Settings.url('rest/global/textSlaves');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'TextSlaveJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'TextSlave',

        importable: true
    });

    app.TextSlave.role = RolesMixin.SUPER_USER;

    app.TextSlaveCollection = Backbone.Collection.extend({
        model: app.TextSlave,
        comparator: 'locale'
    });
});

