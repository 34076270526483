define('services/caccounts/cyclers',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/cyclers'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.Cycler,
                collection: app.CyclerCollection
            };
        },
        getName: function () {
            return 'cycler';
        },
        getUrl: function () {
            return 'rest/v2/cyclers/';
        },
        getDynamicJson: function () {
            return 'CyclerJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('common.name'),
                name: 'name',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'name',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('cycler.refCycler'),
                name: 'refCycler.code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'cyclerCode',
                searchoptions: {size: 10},
                width: 45
            }, {
                label: _.i18n('cycler.assays'),
                name: 'assays',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameListFormatter,
                searchoptions: {
                    size: 10
                },
                sortable: false,
                search: false,
                index: 'assays',
                width: 80
            }, {
                label: _.i18n('cycler.colorCompensation'),
                name: 'colorCompensation',
                classes: 'dynamic-link',
                formatter: viewDynamic.booleanFormatter,
                sortable: false,
                search: false,
                index: 'colorCompensation',
                searchoptions: {size: 10},
                width: 20
            }, {
                label: _.i18n('cycler.lmbCyclers'),
                name: 'lmbCyclers',
                classes: 'dynamic-link',
                formatter: viewDynamic.codeNameListFormatter,
                searchoptions: {
                    size: 10
                },
                sortable: false,
                search: true,
                index: 'lmbCycler.code',
                width: 100
            }, {
                label: _.i18n('tags'),
                name: 'tags',
                formatter: viewDynamic.defaultFormatter,
                sortable: false,
                search: true,
                index: 'tags',
                width: 40
            }, {
                label: _.i18n('common.description'),
                name: 'description',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'description'
            }, {
                label: _.i18n('common.comment'),
                name: 'comment',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'comment'
            });
            return columns;
        },
        getCollection: function (modelJson) {
            if (!modelJson) {
                modelJson = [];
            }
            return new app.CyclerCollection(modelJson);
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name', 'refCycler.code']};
        },

        getCreateEditFieldValidate: function () {
            var ignored = Dynamic.getCreateEditFieldValidate();
            ignored.push({name: 'refCycler', type: 'required'});
            return ignored;
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            var CyclerPublicService = require('services/admin/cyclers');
            custom.push(
                {
                    'field': 'colorCompensation',
                    'param': {
                        'type': 'BOOLEAN',
                        display: {
                            'readOnly': true
                        }
                    }
                },
                {
                    'field': 'refCycler',
                    'param': {
                        'type': 'REFERENCE',
                        'config': {
                            'getAutocompleteParam': CyclerPublicService.getAutocompleteParam
                        }
                        // 'context': CyclerPublics
                    }
                },
                {
                    'field': 'assays',
                    'param': {
                        'type': 'LIST2ENTITY'
                    }
                }
            );
            return custom;
        },
        findDefault: function () {
            var defer = $.Deferred();
            var cyclerDefault;
            var SettingService = require('services/caccounts/setting');
            SettingService.findByCode('DEFAULT-VALUES')
                .done(function (value) {
                    if (value && value.params) {
                        cyclerDefault = value.params.split(';')[0];
                    }
                })
                .always(_.bind(function () {
                    if (cyclerDefault) {
                        var url = Settings.url(this.getUrl() + '?' + Settings.generateParamUrl({
                            'sord': 'asc',
                            'sidx': 'code',
                            'code': cyclerDefault
                        }));

                        $.ajax({
                            type: 'GET',
                            url: url,
                            success: _.bind(function (data) {
                                defer.resolve(this.getModel(data[0]));
                            }, this)
                        });
                    } else {
                        defer.resolve(undefined);
                    }
                }, this));
            return defer.promise();
        },
        findAllFilter: function (model, params) {
            if (!params) {
                return true;
            }
            var ret = true;
            if (params.cyclerPublicSecId) {
                ret = ret && model.get('refCycler').id === params.cyclerPublicSecId;
            }
            if (params.plateSize) {
                ret = ret && model.get('refCycler') && model.get('refCycler').get('plateSize') === params.plateSize;
            }
            if (params.assaySecId) {
                var AssayService = require('services/caccounts/assay');
                ret = ret && AssayService.findAllSync().any(function (assay) {
                    return assay.id === params.assaySecId && assay.get('configurations').any(function (configuration) {
                        return configuration.get('cyclers').any(function (cycler) {
                            return cycler.get('refCycler') === model.get('refCycler');
                        });
                    });
                });
            }
            if (params.kitProtSecId) {
                var KitProtService = require('services/caccounts/kitprots');
                ret = ret && KitProtService.findAllSync().any(function (kitProt) {
                    return kitProt.id === params.kitProtSecId && kitProt.get('configurations').any(function (configurations) {
                        return configurations.get('refCycler') === model.get('refCycler');
                    });
                });
            }
            return ret;
        },
        getAutocompleteParamForPublic: function (config, context) {
            if (!config) {
                config = {};
            }
            if (!context) {
                context = this;
            }
            config.data = context.findAllSync(config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'code'
            })
                .chain()
                .map(function (cycler) {
                    return cycler.get('refCycler');
                })
                .uniq(function (cycler) {
                    return cycler.id;
                })
                .sort(function (cycler1, cycler2) {
                    return cycler1.get('code').localeCompare(cycler2.get('code'));
                })
                .map(function (model) {
                    return model.toJSON();
                })
                .value();
            return Dynamic.getAutocompleteParam(config, require('services/admin/cyclers'));
        }
    }, Dynamic);
});

