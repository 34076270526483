define('servicesCaccounts',[
'module',
    'services/caccounts/suppliers',
    'services/caccounts/preprocess',
    'services/caccounts/userCodelist',
    'services/caccounts/lisBioGroups',
    'services/caccounts/kitprots',
    'services/caccounts/assayVersion',
    'services/caccounts/kitlotpcr',
    'services/caccounts/mbanaresgrs',
    'services/caccounts/samplehandlers',
    'services/caccounts/runats',
    'services/caccounts/orders',
    'services/caccounts/assayReagentTargetPcrKitLots',
    'services/caccounts/lmbsamplehandlers',
    'services/caccounts/biogroups',
    'services/caccounts/mbanas',
    'services/caccounts/routingaction_state',
    'services/caccounts/colorCompensations',
    'services/caccounts/lisRecordQueries',
    'services/caccounts/assayConfigurationResultOrdered',
    'services/caccounts/sampleClass',
    'services/caccounts/fileFormatSampleLists',
    'services/caccounts/kitprotconfiguration',
    'services/caccounts/fileFormatPlateLayouts',
    'services/caccounts/pcrruns',
    'services/caccounts/pcrwelltemplates',
    'services/caccounts/role',
    'services/caccounts/prepruns',
    'services/caccounts/colorCompensationMatrices',
    'services/caccounts/displayModes',
    'services/caccounts/curves',
    'services/caccounts/sampleResultDefinitions',
    'services/caccounts/lisana',
    'services/caccounts/mbanares',
    'services/caccounts/lisRecords',
    'services/caccounts/lmbextractors',
    'services/caccounts/setting',
    'services/caccounts/userpidNotification',
    'services/caccounts/pcrruntracs',
    'services/caccounts/lisSpecies',
    'services/caccounts/dataConnection',
    'services/caccounts/authorityGroup',
    'services/caccounts/extractionMethodReagent',
    'services/caccounts/displayModeConfigurations',
    'services/caccounts/prepwells',
    'services/caccounts/kitlotextraction',
    'services/caccounts/routinggroup',
    'services/caccounts/files',
    'services/caccounts/extractors',
    'services/caccounts/extractionMethod',
    'services/caccounts/reports',
    'services/caccounts/prepruntracs',
    'services/caccounts/userGroup',
    'services/caccounts/attachment',
    'services/caccounts/extractionreagents',
    'services/caccounts/assayReagentVersion',
    'services/caccounts/samples',
    'services/caccounts/platesize',
    'services/caccounts/routingaction',
    'services/caccounts/colorCompensationMatriceValues',
    'services/caccounts/species',
    'services/caccounts/kitspcr',
    'services/caccounts/kitsextraction',
    'services/caccounts/printers',
    'services/caccounts/assayreagent',
    'services/caccounts/userCodelistelement',
    'services/caccounts/pcrruntemplates',
    'services/caccounts/prepruntemplates',
    'services/caccounts/prepwelltemplates',
    'services/caccounts/pcrwellresults',
    'services/caccounts/assayConfigurationResult',
    'services/caccounts/pcrreagents',
    'services/caccounts/lisConfiguration',
    'services/caccounts/assay',
    'services/caccounts/cyclers',
    'services/caccounts/userpids',
    'services/caccounts/lmbcyclers',
    'services/caccounts/system',
    'services/caccounts/assayconfiguration',
    'services/caccounts/reportProperties',
    'services/caccounts/assaygroup',
    'services/caccounts/routingaction_routinggroup',
    'services/caccounts/lis',
    'services/caccounts/pcrwells',
    'services/caccounts/assayResultVersion',
    'services/caccounts/connector/connectorHl7Tracings',
    'services/caccounts/standardCurve/standardCurveSamples',
    'services/caccounts/standardCurve/standardCurves',
    'services/caccounts/standardCurve/standardCurveAssayConfigurationResults',
    'services/caccounts/pcrSetup/pcrSetup',
    'services/caccounts/pcrSetup/pcrRunLite',
    'services/caccounts/pcrSetup/prepWellLite',
    'services/caccounts/pcrSetup/pcrWellLite',
    'services/caccounts/setting/defaultCheckbox'
], function (module) {
'use strict';
module.exports = {
    getServices: function() {
        var ret = [];
    ret.push(require('services/caccounts/suppliers'));
    ret.push(require('services/caccounts/preprocess'));
    ret.push(require('services/caccounts/userCodelist'));
    ret.push(require('services/caccounts/lisBioGroups'));
    ret.push(require('services/caccounts/kitprots'));
    ret.push(require('services/caccounts/assayVersion'));
    ret.push(require('services/caccounts/kitlotpcr'));
    ret.push(require('services/caccounts/mbanaresgrs'));
    ret.push(require('services/caccounts/samplehandlers'));
    ret.push(require('services/caccounts/runats'));
    ret.push(require('services/caccounts/orders'));
    ret.push(require('services/caccounts/assayReagentTargetPcrKitLots'));
    ret.push(require('services/caccounts/lmbsamplehandlers'));
    ret.push(require('services/caccounts/biogroups'));
    ret.push(require('services/caccounts/mbanas'));
    ret.push(require('services/caccounts/routingaction_state'));
    ret.push(require('services/caccounts/colorCompensations'));
    ret.push(require('services/caccounts/lisRecordQueries'));
    ret.push(require('services/caccounts/assayConfigurationResultOrdered'));
    ret.push(require('services/caccounts/sampleClass'));
    ret.push(require('services/caccounts/fileFormatSampleLists'));
    ret.push(require('services/caccounts/kitprotconfiguration'));
    ret.push(require('services/caccounts/fileFormatPlateLayouts'));
    ret.push(require('services/caccounts/pcrruns'));
    ret.push(require('services/caccounts/pcrwelltemplates'));
    ret.push(require('services/caccounts/role'));
    ret.push(require('services/caccounts/prepruns'));
    ret.push(require('services/caccounts/colorCompensationMatrices'));
    ret.push(require('services/caccounts/displayModes'));
    ret.push(require('services/caccounts/curves'));
    ret.push(require('services/caccounts/sampleResultDefinitions'));
    ret.push(require('services/caccounts/lisana'));
    ret.push(require('services/caccounts/mbanares'));
    ret.push(require('services/caccounts/lisRecords'));
    ret.push(require('services/caccounts/lmbextractors'));
    ret.push(require('services/caccounts/setting'));
    ret.push(require('services/caccounts/userpidNotification'));
    ret.push(require('services/caccounts/pcrruntracs'));
    ret.push(require('services/caccounts/lisSpecies'));
    ret.push(require('services/caccounts/dataConnection'));
    ret.push(require('services/caccounts/authorityGroup'));
    ret.push(require('services/caccounts/extractionMethodReagent'));
    ret.push(require('services/caccounts/displayModeConfigurations'));
    ret.push(require('services/caccounts/prepwells'));
    ret.push(require('services/caccounts/kitlotextraction'));
    ret.push(require('services/caccounts/routinggroup'));
    ret.push(require('services/caccounts/files'));
    ret.push(require('services/caccounts/extractors'));
    ret.push(require('services/caccounts/extractionMethod'));
    ret.push(require('services/caccounts/reports'));
    ret.push(require('services/caccounts/prepruntracs'));
    ret.push(require('services/caccounts/userGroup'));
    ret.push(require('services/caccounts/attachment'));
    ret.push(require('services/caccounts/extractionreagents'));
    ret.push(require('services/caccounts/assayReagentVersion'));
    ret.push(require('services/caccounts/samples'));
    ret.push(require('services/caccounts/platesize'));
    ret.push(require('services/caccounts/routingaction'));
    ret.push(require('services/caccounts/colorCompensationMatriceValues'));
    ret.push(require('services/caccounts/species'));
    ret.push(require('services/caccounts/kitspcr'));
    ret.push(require('services/caccounts/kitsextraction'));
    ret.push(require('services/caccounts/printers'));
    ret.push(require('services/caccounts/assayreagent'));
    ret.push(require('services/caccounts/userCodelistelement'));
    ret.push(require('services/caccounts/pcrruntemplates'));
    ret.push(require('services/caccounts/prepruntemplates'));
    ret.push(require('services/caccounts/prepwelltemplates'));
    ret.push(require('services/caccounts/pcrwellresults'));
    ret.push(require('services/caccounts/assayConfigurationResult'));
    ret.push(require('services/caccounts/pcrreagents'));
    ret.push(require('services/caccounts/lisConfiguration'));
    ret.push(require('services/caccounts/assay'));
    ret.push(require('services/caccounts/cyclers'));
    ret.push(require('services/caccounts/userpids'));
    ret.push(require('services/caccounts/lmbcyclers'));
    ret.push(require('services/caccounts/system'));
    ret.push(require('services/caccounts/assayconfiguration'));
    ret.push(require('services/caccounts/reportProperties'));
    ret.push(require('services/caccounts/assaygroup'));
    ret.push(require('services/caccounts/routingaction_routinggroup'));
    ret.push(require('services/caccounts/lis'));
    ret.push(require('services/caccounts/pcrwells'));
    ret.push(require('services/caccounts/assayResultVersion'));
    ret.push(require('services/caccounts/connector/connectorHl7Tracings'));
    ret.push(require('services/caccounts/standardCurve/standardCurveSamples'));
    ret.push(require('services/caccounts/standardCurve/standardCurves'));
    ret.push(require('services/caccounts/standardCurve/standardCurveAssayConfigurationResults'));
    ret.push(require('services/caccounts/pcrSetup/pcrSetup'));
    ret.push(require('services/caccounts/pcrSetup/pcrRunLite'));
    ret.push(require('services/caccounts/pcrSetup/prepWellLite'));
    ret.push(require('services/caccounts/pcrSetup/pcrWellLite'));
    ret.push(require('services/caccounts/setting/defaultCheckbox'));
        return ret;
    }
};
});
