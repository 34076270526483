define('adminEntityErrorController',[
    'module',
    'underscore',
    'app',
    'adminEntityErrorView',
    'jquery',
    'settings',
    'backbone'
], function (
    module,
    _,
    App,
    View,
    $,
    Settings,
    Backbone
) {
    'use strict';

    module.exports = {
        showEntityError: function () {
            var loader = $('.js-global-loader');
            loader.show();
            var url = Settings.url('rest/v2/entityError/');

            $.ajax({
                type: 'GET',
                url: url,
                success: function (data) {
                    var collection = new Backbone.Collection();
                    collection.comparator = function (m1, m2) {
                        return m1.get('code').compareTo(m2.get('code'));
                    };
                    _.each(_.keys(data), function (key) {
                        collection.push({id: key, code: key, enable: data[key]});
                    });
                    
                    App.getView().getRegion('content').show(new View({collection: collection}));
                    collection.save = function () {
                        var codes = collection.chain().filter(function (model) {
                            return !model.get('enable');
                        })
                            .map(function (model) {
                                return model.get('code');
                            })
                            .value();
                        $.ajax({
                            type: 'POST',
                            url: url,
                            contentType: 'application/json',
                            data: JSON.stringify(codes),
                            success: function () {
                                var SystemService = require('services/caccounts/system');
                                SystemService.clearCache().done(function () {
                                    window.location.reload();
                                });
                            }
                        });
                    };
                },
                complete: function () {
                    loader.hide();
                }
            });
        }
    };
});
