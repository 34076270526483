define('kitPcrLotCustomJqGridView',[
    'module',
    'dialogFormView',
    'underscore',
    'jquery',
    'template!kitPcrLotCustomJqGridView',
    'settings',
    'rolesMixin'
], function (
    module,
    DialogFormView,
    _,
    $,
    tpl,
    Settings,
    RolesMixin
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        events: {
            'click .js-maintenance-assayReagent': 'onMaintenanceAssayReagent'
        },

        attributes: {
            style: 'display: flex; column-gap: 5px;'
        },

        serializeData: function () {
            return {
                superUser: Settings.get('currentUserModel').hasRole(RolesMixin.SUPER_USER)
            };
        },

        /**
         * Maintenance assay reagent
         *  @deprecated since 2.5.1
         */
        onMaintenanceAssayReagent: function () {
            var loader = $('.js-global-loader');
            loader.show();
            var service = require('services/caccounts/kitlotpcr');
            service.maintenanceAssayReagentMbAnaPcrKitLot().always(function () {
                loader.hide();
            });
        }
    });
});
