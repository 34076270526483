define('pcrWellResultView',[
    'module',
    'underscore',
    'jquery',
    'backbone.marionette',
    'jqGridController',
    'jqGridFormatter',
    'pcrWellResultController',
    'settings',
    'rolesMixin',
    'privileges',
    'backbone',
    'wellPopupDisplayGraphView',
    'wellPopupDisplayGraphAmpView',
    'colorUtils',
    'editWellView',
    'bootbox',
    'pcrWellResultIconView',
    'entities/ns'
], function (
    module,
    _,
    $,
    Marionette,
    JqGridController,
    JqGridFormatter,
    PcrWellResultController,
    Settings,
    RolesMixin,
    Privileges,
    Backbone,
    DisplayPopupGraphLayoutView,
    DisplayPopupGraphAmpView,
    ColorUtils,
    EditWellView,
    Bootbox,
    PcrWellResultIconView,
    app
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<div class="js-grid-region" style="flex-grow: 1; width: 100%; height: 100%;"></div>'),
        regions: {
            gridRegion: '.js-grid-region'
        },

        className: 'content d-f',
        attributes: {
            style: 'flex-grow: 1; width: 100%; height: 100%;'
        },

        paginationObject: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: 9999,
                currentPage: 1
            };
        },

        initialize: function () {
            this.PcrWellResultService = require('services/caccounts/pcrwellresults');
        },

        onRender: function () {
            this.renderResults();
        },

        renderResults: function () {
            var data = this.getListViewData();

            this.jqGridView = JqGridController.show({
                data: data,
                pager: true,
                filtersName: 'wellViewListFilters',
                gridOptions: _.bind(this.gridOptions, this),
                paginationObject: this.paginationObject
            }, this.getRegion('gridRegion'));
            this.listenTo(this.jqGridView, 'gridComplete', _.bind(this.onGridComplete, this));
            this.listenTo(this.jqGridView, 'loadComplete', _.bind(this.onLoadComplete, this));
            this.listenTo(this.jqGridView, 'click', _.bind(this.onClick, this));
        },

        onClick: function (obj) {
            var item = obj.gridView.ui.jqGrid.jqGrid('getLocalRow', obj.rowId);
            var model = require('services/caccounts/pcrwellresults').getById(item.id);
            if (obj.event.target.classList.contains('mdi-format-list-bulleted')) {
                require(['propertiesController'], _.bind(function (Controller) {
                    Controller.showProperties(model);
                }, this));
                return;
            }

            if (obj.event.target.classList.contains('btn-graph-well')) {
                this.onBtnGraphClick(model);
                return;
            }
            if (obj.event.target.classList.contains('js-target-propC-popup')) {
                this.onTargetPropC(model);
                return;
            }
            var defer = $.Deferred();
            var isControl = ['RC', 'PC', 'OC', 'NC'].includes(model.get('pcrWell').get('smpType'));
            if (obj.event.target.classList.contains('btn-validate-result')) {
                if (isControl) {
                    Bootbox.prompt(_.i18n('pcrWellResult.confirm.justificatory'), _.bind(function (justificatory) {
                        defer.resolve(justificatory);
                    }, this));
                } else {
                    defer.resolve();
                }
                defer.done(_.bind(function (justificatory) {
                    if ((isControl && justificatory) || !isControl) {
                        $('.js-global-loader').show();
                        model.confirmResult(justificatory)
                            .always(_.bind(function () {
                                this.render();
                                $('.js-global-loader').hide();
                            }, this));
                    }
                }, this));
            }
            if (obj.event.target.classList.contains('btn-negative-result')) {
                if (isControl) {
                    Bootbox.prompt(_.i18n('pcrWellResult.confirm.justificatory'), _.bind(function (justificatory) {
                        defer.resolve(justificatory);
                    }, this));
                } else {
                    defer.resolve();
                }
                defer.done(_.bind(function (justificatory) {
                    if ((isControl && justificatory) || !isControl) {
                        $('.js-global-loader').show();
                        model.negativeResult(justificatory)
                            .always(_.bind(function () {
                                this.render();
                                $('.js-global-loader').hide();
                            }, this));
                    }
                }, this));
            }
            if (obj.event.target.classList.contains('mdi-alert')) {
                return;
            }
            if (obj.event.target.classList.contains('btn-stc')) {
                this.onBtnStcClick(model);
                return;
            }
            if (obj.event.target.classList.contains('btn-edit-well-target-result')) {
                this.onEditWellTargetResult(model);
            }
        },

        onGridComplete: function (gridView) {
            var grid = gridView.ui.jqGrid;
            var rowIds = grid.jqGrid('getDataIDs');

            rowIds.forEach(function (rowId) {
                var rowData = grid.jqGrid('getRowData', rowId);
                var data = _.findWhere(grid[0].p.data, {id: rowData.id});

                if (!data.results || data.results.length === 0) {
                    $('#' + rowId, grid[0]).find('td.sgcollapsed').html(''); // Enlève le "+"
                }
            });
        },
        onLoadComplete: function (gridView, data) {
            var grid = gridView.ui.jqGrid;
            var rows = data; // Les données renvoyées
            for (var i = 0; i < rows.length; i++) {
                var rowId = rows[i].id; // Identifiant de la ligne
                var color = rows[i].backgroundColor; // Valeur dans la colonne "status"
                if (color) {
                    // Appliquer une classe CSS personnalisée
                    grid.jqGrid('setRowData', rowId, false, {'background-color': color});
                }
            }
        },

        gridOptions: function () {
            var colModels = [
                {
                    label: '',
                    name: 'id',
                    key: true,
                    hidden: true
                }, {
                    label: _.i18n('run.result.target'),
                    name: 'target',
                    index: 'target',
                    sortable: true,
                    search: true,
                    classes: '',
                    formatter: _.bind(this.targetFormatter, this),
                    fixed: true,
                    width: 100,
                    searchoptions: {
                        sopt: ['cn']
                    }
                }, {
                    label: ' ',
                    name: _.i18n('run.result.editTarget'),
                    sortable: false,
                    search: false,
                    classes: 'result-edit-target',
                    formatter: _.bind(this.editTargetFormatter, this),
                    fixed: true,
                    width: 25
                }, {
                    label: _.i18n('run.result.res'),
                    name: 'icon',
                    sortable: false,
                    search: false,
                    classes: 'result-result',
                    formatter: JqGridFormatter.defaultHtmlFormatter,
                    fixed: true,
                    width: 25
                }, {
                    label: _.i18n('run.result.result'),
                    name: 'result',
                    index: 'result',
                    sortable: true,
                    search: true,
                    classes: '',
                    formatter: JqGridFormatter.defaultFormatter,
                    fixed: true,
                    width: 40,
                    stype: 'select',
                    searchrules: {select: true},
                    searchoptions: {sopt: ['eq'], value: this.resultSearch}
                }, {
                    label: _.i18n('run.result.resultQuantification'),
                    name: 'resultQuantifcation',
                    sortable: false,
                    search: false,
                    classes: '',
                    formatter: JqGridFormatter.defaultFormatter,
                    fixed: true,
                    width: 50
                }, {
                    label: _.i18n('run.result.displayMode'),
                    name: 'displayMode',
                    sortable: false,
                    search: false,
                    classes: '',
                    formatter: JqGridFormatter.defaultHtmlFormatter,
                    fixed: true,
                    width: 100
                }, {
                    label: _.i18n('run.result.actions'),
                    name: 'type',
                    sortable: false,
                    search: false,
                    classes: 'result-action',
                    formatter: _.bind(this.actionFormatter, this),
                    fixed: true,
                    width: 100
                }, {
                    label: _.i18n('run.result.custom'),
                    name: 'type',
                    sortable: false,
                    search: false,
                    classes: 'result-custom',
                    formatter: _.bind(this.customFormatter, this),
                    width: 355
                }, {
                    label: _.i18n('run.result.comment'),
                    name: 'comment',
                    sortable: false,
                    search: false,
                    classes: '',
                    formatter: JqGridFormatter.defaultFormatter,
                    fixed: true,
                    width: 150
                }, {
                    label: _.i18n('run.result.errorMessage'),
                    name: 'errorMessage',
                    sortable: false,
                    search: false,
                    classes: '',
                    formatter: JqGridFormatter.defaultFormatter,
                    fixed: true,
                    width: 150
                }, {
                    label: _.i18n('run.result.val1'),
                    name: 'userVal1Code',
                    index: 'userVal1Code',
                    sortable: false,
                    search: true,
                    classes: '',
                    formatter: _.bind(this.user1Formatter, this),
                    fixed: true,
                    stype: 'select',
                    searchrules: {select: true},
                    searchoptions: {sopt: ['eq'], value: this.user1Search},
                    width: 50
                }, {
                    label: _.i18n('run.result.val2'),
                    name: 'userVal2Code',
                    index: 'userVal2Code',
                    sortable: false,
                    search: true,
                    classes: '',
                    formatter: _.bind(this.user2Formatter, this),
                    fixed: true,
                    stype: 'select',
                    searchrules: {select: true},
                    searchoptions: {sopt: ['eq'], value: this.user2Search},
                    width: 50
                }
            ];
            var options = {
                colModel: colModels,
                sortname: this.sortOn ? this.sortOn : 'row',
                // set the subGrid property to true to show expand buttons for each row
                subGrid: true,
                // set the subgrid type to json
                subGridRowExpanded: _.bind(this.showChildGrid, this),
                subGridOptions: {
                    // load the subgrid data only once
                    // and the just show/hide
                    reloadOnExpand: false,
                    // select the row when the expand column is clicked
                    selectOnExpand: true
                }
            };
            options.shrinkToFit = true;
            options.autowidth = false;
            return options;
        },

        showChildGrid: function (parentRowID, parentRowKey) {
            $('.js-global-loader').show();
            var defer = $.Deferred();
            var grid = this.jqGridView;
            var item = grid.ui.jqGrid.jqGrid('getLocalRow', parentRowKey);

            defer.done(_.bind(function (results) {
                // create unique table and pager
                var childGridID = parentRowID + '_table';
                var childGridPagerID = parentRowID + '_pager';

                // add a table and pager HTML elements to the parent grid row - we will render the child grid here
                grid.$el.find('#' + parentRowID).append('<table id=' + childGridID + '></table><div id=' + childGridPagerID + '></div>');
                // add table to region container
                var region = this.addRegion(childGridID, '#' + childGridID);
                // create the child grid
                var childGrid = JqGridController.show({
                    data: {
                        datatype: 'local',
                        data: results
                    },
                    pager: '#' + childGridPagerID,
                    filtersName: 'wellViewListFilters',
                    gridOptions: _.bind(this.gridOptions, this),
                    paginationObject: this.paginationObject
                }, region);
                this.listenTo(childGrid, 'gridComplete', _.bind(this.onGridComplete, this));
                this.listenTo(childGrid, 'loadComplete', _.bind(this.onLoadComplete, this));
                this.listenTo(childGrid, 'click', _.bind(this.onClick, this));
                $('.js-global-loader').hide();
            }, this));
            // create the child grid in setTimeout to make sure
            setTimeout(_.bind(function () {
                var results = _.map(item.results, _.bind(function (result) {
                    return this._convertModelToItem(result);
                }, this));
                defer.resolve(results);
            }, this), 1);
        },

        getListViewData: function () {
            var datas = [];
            this.collection.each(_.bind(function (model) {
                datas.push(this._convertModelToItem(model));
            }, this));

            this.resultSearch = ':' + _.i18n('common.any') + ';' + _.chain(datas).pluck('result')
                .filter(function (item) {
                    return item;
                })
                .map(function (item) {
                    return item + ':' + item;
                }).uniq().value().join(';');
            if (this.resultSearch.endsWith(';')) {
                this.resultSearch = this.resultSearch.slice(0, -1);
            }
            this.user1Search = ':' + _.i18n('common.any') + ';' + _.chain(datas).pluck('userVal1Code')
                .filter(function (item) {
                    return item;
                })
                .map(function (item) {
                    return item + ':' + item;
                }).uniq().value().join(';');
            if (this.user1Search.endsWith(';')) {
                this.user1Search = this.user1Search.slice(0, -1);
            }
            this.user2Search = ':' + _.i18n('common.any') + ';' + _.chain(datas).pluck('userVal2Code')
                .filter(function (item) {
                    return item;
                })
                .map(function (item) {
                    return item + ':' + item;
                }).uniq().value().join(';');
            if (this.user2Search.endsWith(';')) {
                this.user2Search = this.user2Search.slice(0, -1);
            }

            var data = {
                datatype: 'local',
                data: datas
            };
            return data;
        },

        _convertModelToItem: function (model) {
            var item = {};
            item.id = String(model.id);
            item.type = model.get('type');
            item.target = model.get('refAssayResult').get('target').get('code');
            var view = new PcrWellResultIconView({
                model: model,
                config: {
                    displayCt: false,
                    displayResult: false,
                    displayQuantification: false
                }
            });
            view.render();
            item.icon = view.$el.html();
            item.displayMode = PcrWellResultController.generateContent(model, 'Well');
            item.resultQuantifcation = model.get('cts').join(', ') + (_.isEmpty(model.get('quantifications')) ? '' : ' (' + _.map(model.get('quantifications'), function (quantification) {
                return quantification.toExponential(2);
            }).join(', ') + ')');
            item.result = model.get('result') ? model.get('result').get('code') : '';
            item.comment = model.get('comment');
            item.commentErrAuto = model.get('commentErrAuto');
            item.hasStandardCurve = model.get('standardCurveAssayConfigurationResultValue');
            item.refPcrWellExported = model.get('pcrWell') ? model.get('pcrWell').get('exported') : false;
            item.valSt = model.get('valSt');
            item.justificatory = model.get('justificatory');
            item.readOnly = (this.options.readOnly !== undefined && this.options.readOnly) || model.isReadOnly();
            item.needValidation = model.get('needValidation');
            item.canConfirm = item.canNegative = Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL1) ||
                Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL2) ||
                Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL12) ||
                Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL_EXPERT);
            item.codeErr = model.get('codeErr');
            item.errorMessage = '';
            if (item.codeErr) {
                item.errorMessage = item.codeErr + ' ' + item.commentErrAuto;
            }
            if (item.justificatory) {
                item.errorMessage = item.justificatory;
            }
            item.userVal1Code = '';
            item.userVal1Title = '';
            if (model.get('refValUser')) {
                item.userVal1Code = model.get('refValUser').get('nickName');
                if (model.get('refValUser').get('email')) {
                    item.userVal1Title = model.get('refValUser').get('email');
                }
                if (item.userVal1Code === '' && item.userVal1Title) {
                    item.userVal1Code = item.userVal1Title;
                }
                if (model.get('refValUser').get('firstName') && model.get('refValUser').get('lastName')) {
                    item.userVal1Title = model.get('refValUser').get('firstName') + ' ' + model.get('refValUser').get('lastName');
                }
            }
            item.userVal2Code = '';
            item.userVal2Title = '';
            if (model.get('refValUser2')) {
                item.userVal2Code = model.get('refValUser2').get('nickName');
                if (model.get('refValUser2').get('email')) {
                    item.userVal2Title = model.get('refValUser2').get('email');
                }
                if (item.userVal2Code === '' && item.userVal2Title) {
                    item.userVal2Code = item.userVal2Title;
                }
                if (model.get('refValUser2').firstName && model.get('refValUser2').get('lastName')) {
                    item.userVal2Title = model.get('refValUser2').get('firstName') + ' ' + model.get('refValUser2').get('lastName');
                }
            }
            item.results = [];

            switch (item.type) {
                case 'CURVE':
                    item.showWellCompareGraph = !!(model.get('result') && model.get('curves').any(function (curve) {
                        return curve.get('type') === 'AMP' || curve.get('type') === 'AMP_IC';
                    }));
                    item.propCurves = model.get('propCurves');
                    item.allowToNeg = item.result !== 'N' && item.propCurves && model.get('codeErr') ? _.some(item.propCurves, function (propCurve) {
                        return propCurve.name.indexOf('Amp');
                    }) : false;
                    item.dyeToDisplay = model.get('dyeToDisplay');
                    item.dyeColorToDisplay = model.get('dyeColorToDisplay');
                    break;
                case 'MAGPIX':
                    item.beadCount = model.get('beadCount');
                    item.mfiValue = model.get('mfiValue');
                    item.mfiThreshold = model.get('mfiThreshold');
                    item.mddValue = model.get('mddValue');
                    item.mddThreshold = model.get('mddThreshold');
                    break;
                case 'OVAG_NPX':
                    model.get('results').each(_.bind(function (result) {
                        item.results.push(result);
                    }, this));
                    break;
                case 'OVAR':
                    if (model.get('result')) {
                        var color = model.get('result').get('color');
                        item.backgroundColor = color ? ColorUtils.rgbaString(ColorUtils.hexToRgba(ColorUtils.defineAlpha(color, '30'))) : 'transparent';
                    }
                    break;
                default:
                    break;
            }
            return item;
        },

        actionFormatter: function (cellValue, rowId, object) {
            var template = '<div>';
            switch (cellValue) {
                case 'CURVE':
                    if (object.showWellCompareGraph) {
                        template += '<span class="mdi mdi-chart-box clickable btn-graph-well" title="' + _.i18n('well.tooltip.graph') + '"></span>';
                        template += '<span class=" mdi mdi-chart-bell-curve-cumulative js-target-propC-popup clickable"></span>';
                    }
                    if (object.codeErr && !object.refPcrWellExported && object.valSt < 5 && !object.readOnly && object.needValidation && object.canConfirm) {
                        template += '<span class="mdi mdi-checkbox-marked btn-validate-result clickable" style="color: #2ab937;" title="' + _.i18n('well.tooltip.validate') + '"></span>';
                    }
                    if (object.allowToNeg && !object.readOnly && object.needValidation && object.canNegative) {
                        template += '<span class="btn-negative-result mdi mdi-minus-circle-outline clickable" style="color: red;" title="' + _.i18n('well.tooltip.negative') + '"></span>';
                    }
                    if (object.commentErrAuto && !object.codeErr) {
                        template += '<span class="mdi mdi-alert" title="' + object.commentErrAuto + '"></span>';
                    }
                    if (object.hasStandardCurve) {
                        template += '<span class="mdi mdi-abjad-hebrew clickable btn-stc" title="' + _.i18n('well.result.standardCurve') + '"></span>';
                    }
                    break;
                default:
                    break;
            }
            template += '<span class="mdi mdi-format-list-bulleted clickable" title="' + _.i18n('well.result.properties') + '"></span>';
            template += '</div>';
            return template;
        },
        customFormatter: function (cellValue, rowId, object) {
            var template = '';
            switch (cellValue) {
                case 'MAGPIX':
                    var mdd = '-';
                    if (object.mddValue) {
                        mdd = object.mddValue;
                        if (object.mddThreshold) {
                            mdd += ' (' + parseFloat(object.mddThreshold).toFixed(0) + ')';
                        }
                    }
                    var mfi = '-';
                    if (object.mfiValue) {
                        mfi = object.mfiValue;
                        if (object.mfiThreshold) {
                            mfi += ' (' + parseFloat(object.mfiThreshold).toFixed(0) + ')';
                        }
                    }
                    var beadCount = object.beadCount ? object.beadCount : '-';
                    template += '<div class="left-middle-right">';
                    template += '<div class="left">MDD: ' + mdd + '</div>';
                    template += '<div class="middle">MFI: ' + mfi + '</div>';
                    template += '<div class="right">BC: ' + beadCount + '</div>';
                    template += '</div>';
                    break;
                case 'CURVE':
                    var model = this.PcrWellResultService.getById(object.id);
                    var params = model.get('refAssayResult').get('algoResult').param.split(';');
                    var dyeName = params[0];
                    var curves = model.get('curves').map(function (curve) {
                        var nameSplitted = curve.get('name').split('_');
                        var name;
                        if (nameSplitted.length === 1) {
                            name = nameSplitted[0];
                        } else {
                            name = nameSplitted[1];
                        }
                        var refDye = model.get('pcrWell').get('run').get('refCyclerPublic').get('refDetectionFormat').get('dyes').find(function (dye) {
                            return dye.get('name').toLowerCase() === name.toLowerCase();
                        });

                        return {curve: curve, dye: refDye};
                    });
                    var curve = _.find(curves, function (curve) {
                        return curve.dye.get('shortName').toLowerCase() === dyeName.toLowerCase();
                    });
                    var color = 'transparent';
                    var dyeToDisplay = '';
                    if (curve) {
                        dyeToDisplay = curve.curve.get('name');
                        if (curve.dye) {
                            dyeToDisplay = curve.dye.get('shortName') ? curve.dye.get('shortName') : '';
                            color = ColorUtils.colorTarget(curve.dye.get('channel'), model.get('pcrWell').get('run'), model.get('pcrWell'), null, curve.curve);
                            color = ColorUtils.rgbaToHex(color.r, color.g, color.b, color.a);
                        }
                        var SettingService = require('services/caccounts/setting');
                        var setting = SettingService.findByCodeAsync('IC-PARAM');
                        if (model.get('internalControl') && setting) {
                            color = setting.get('params').split(';')[0];
                        }
                    }

                    template += '<span class="noWrapHidden result-channel" style="border-bottom: solid; height: 24px; border-color: ' + color + '">\n' +
                        '    <label data-html="true" data-toggle="tooltip">' + dyeToDisplay + '</label>\n' +
                        '</span>';
                    break;
                default:
                    break;
            }
            return template;
        },
        user1Formatter: function (cellValue, rowId, object) {
            var template = '';
            if (cellValue) {
                template += '<div title="' + object.userVal1Title + '">' + object.userVal1Code + '</div>';
            }
            return template;
        },
        user2Formatter: function (cellValue, rowId, object) {
            var template = '';
            if (cellValue) {
                template += '<div title="' + object.userVal2Title + '">' + object.userVal2Code + '</div>';
            }
            return template;
        },
        editTargetFormatter: function (cellValue, rowId, object) {
            var template = '';
            if (!object.refPcrWellExported && !object.readOnly) {
                template += '<div class="btn-edit-well-target-result mdi-content-create clickable" ';
                template += 'name="well-editTarget" style="font-size: 16px; top: 4px;" title="' + _.i18n('icon.edit.wellTarget') + '"></div>';
            }
            return template;
        },
        targetFormatter: function (cellValue, object) {
            var template = '';
            var model = this.PcrWellResultService.getById(object.rowId);
            if (model instanceof app.PcrWellResultCurve) {
                var params = model.get('refAssayResult').get('algoResult').param.split(';');
                var dyeName = params[0];
                var curves = model.get('curves').map(function (curve) {
                    var nameSplitted = curve.get('name').split('_');
                    var name;
                    if (nameSplitted.length === 1) {
                        name = nameSplitted[0];
                    } else {
                        name = nameSplitted[1];
                    }
                    var refDye = model.get('pcrWell').get('run').get('refCyclerPublic').get('refDetectionFormat').get('dyes').find(function (dye) {
                        return dye.get('name').toLowerCase() === name.toLowerCase();
                    });

                    return {curve: curve, dye: refDye};
                });
                var curve = _.find(curves, function (curve) {
                    return curve.dye.get('shortName').toLowerCase() === dyeName.toLowerCase();
                });
                var color = 'transparent';
                if (curve) {
                    if (curve.dye) {
                        color = ColorUtils.colorTarget(curve.dye.get('channel'), model.get('pcrWell').get('run'), model.get('pcrWell'), null, curve.curve);
                        color = ColorUtils.rgbaToHex(color.r, color.g, color.b, color.a);
                    }
                    var SettingService = require('services/caccounts/setting');
                    var setting = SettingService.findByCodeAsync('IC-PARAM');
                    if (model.get('internalControl') && setting) {
                        color = setting.get('params').split(';')[0];
                    }
                }

                template += '<span class="noWrapHidden result-channel" style="border-bottom: solid; height: 24px; border-color: ' + color + '">\n' +
                    '    <label data-html="true" data-toggle="tooltip">' + model.get('refAssayResult').get('target').get('code') + '</label>\n' +
                    '</span>';
                return template;
            }
            return model.get('refAssayResult').get('target').get('code');
        },
        onBtnGraphClick: function (model) {
            var collection = require('services/caccounts/pcrwellresults').getCollection();
            model.get('pcrWell').get('run').get('wells').each(_.bind(function (well) {
                well.get('results').filter(_.bind(function (result) {
                    if (result.get('refAssayResult') === model.get('refAssayResult')) {
                        collection.push(result);
                    }
                }, this));
            }, this));

            // extract all curves from the collection
            var curves = require('services/caccounts/curves').getCollection(collection.chain().map(function (model) {
                return model.get('curves').models;
            }).flatten().value());
            curves.fetch().done(_.bind(function () {
                this.displayGraphView = new DisplayPopupGraphLayoutView({
                    model: model,
                    currentTarget: model.get('refAssayResult').get('target'),
                    models: collection,
                    newItem: false,
                    hideExtraPoint: true
                });

                this.displayGraphView.graphToDisplay = model.get('pcrWell').getGraphToDisplay();
                this.displayGraphView.hideOkButton = true;
                this.displayGraphView.hideNextPreviousButton = true;
                this.displayGraphView.selectionMode = false;
                this.displayGraphView.show({
                    title: _.i18n('well.compare.curv'),
                    className: 'popupWellDetailsGraph'
                });
            }, this));
        },

        onTargetPropC: function (model) {
            var graphToDisplay = model.get('pcrWell').getGraphToDisplay();

            if (graphToDisplay.includes('A') || graphToDisplay.includes('M')) {
                this.propCPopupView = new DisplayPopupGraphAmpView({
                    model: model,
                    graphToDisplay: graphToDisplay,
                    properties: model.get('propCurves')
                });
                this.propCPopupView.show({
                    title: _.i18n('wellTarget.result.interpretation.' + graphToDisplay),
                    className: 'js-draggable-modal wellTargetPropC-popup'
                });
            }
        },

        onBtnStcClick: function (model) {
            var m = model.get('standardCurveAssayConfigurationResultValue');
            var defers = [];
            defers.push(m.get('standardCurveAssayConfigurationResult').fetch());
            defers.push(m.fetch());
            $.when.apply($, defers).done(_.bind(function () {
                require(['standardCurveController'], _.bind(function (controller) {
                    controller.showResults(require('services/caccounts/standardCurve/standardCurveAssayConfigurationResults'),
                        m.get('standardCurveAssayConfigurationResult'),
                        null,
                        {
                            pcrRun: m.get('pcrRun'),
                            readOnly: true
                        });
                }, this));
            }, this));
        },

        onEditWellTargetResult: function (model) {
            model.loadProperties().done(_.bind(function (properties) {
                this.createEditView = new EditWellView({
                    model: model,
                    newItem: false,
                    properties: properties
                });
                this.createEditView.show({
                    title: _.i18n('well.info.edit'),
                    className: 'popupWellView'
                }, _.bind(function () {
                    this.jqGridView.trigger('reloadGrid', this.getListViewData().data);
                }, this));
            }, this));
        }
    });
});
