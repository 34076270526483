define('runPcrKitLotCollectionView',[
    'module',
    'backbone.marionette',
    'template!runPcrKitLotCollectionView',
    'runPcrKitLotItemView'
], function (
    module,
    Marionette,
    Tpl,
    PcrKitLotItemView
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        template: Tpl,
        childView: PcrKitLotItemView,
        childViewContainer: 'tbody',
        tagName: 'table',
        className: 'table table-striped table-hover table-condense'
    });
});
