define('entities/caccounts/entityError',[
    'entities/ns',
    'backbone',
    'backboneRelational'
], function (
    app,
    Backbone
) {
    'use strict';

    app.EntityError = Backbone.RelationalModel.extend({
        defaults: {
        },
        relations: [{
            type: Backbone.HasMany,
            key: 'errors',
            relatedModel: 'EntityError',
            collectionType: 'EntityErrorCollection',
            includeInJSON: ['id', 'code', 'className']
        }],
        idAttribute: 'id',

        jsonObjectName: 'EntityErrorJson',

        jsonObject: 'EntityError',

        importable: true
    });
    
    app.EntityErrorCollection = Backbone.Collection.extend({
        model: app.EntityError
    });
});

