define('runPcrContentWellListView',[
    'module',
    'underscore',
    'jquery',
    'backbone.marionette',
    'jqGridController',
    'wellUtils',
    'colorUtils',
    'pcrWellResultController',
    'jqGridFormatter',
    'jqGridValue'
], function (
    module,
    _,
    $,
    Marionette,
    JqGridController,
    WellUtils,
    ColorUtils,
    PcrWellResultController,
    JqGridFormatter,
    JqGridValue
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template(''),
        regions: {
            gridRegion: '.js-grid-region'
        },

        className: 'content d-f column',
        attributes: {
            style: 'flex-grow: 1; width: 100%; height: 100%; gap: 10px;'
        },

        paginationObject: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: 9999,
                currentPage: 1
            };
        },

        initialize: function () {
            this.PcrWellService = require('services/caccounts/pcrwells');
        },

        onRender: function () {
            this.model.get('wells')
                .chain()
                .map(function (a) {
                    return a.get('refAssay');
                })
                .filter(function (a) {
                    return a;
                })
                .uniq()
                .each(_.bind(function (assay) {
                    var data = this.getListViewData(this.model.get('wells').filter(function (well) {
                        return well.get('refAssay') === assay;
                    }));
                    // append div for each assay
                    this.$el.append('<div class="grid-assay-' + assay.cid + '" style="width: 100%; "></div>');

                    var region = this.addRegion('assay-' + assay.cid, '.grid-assay-' + assay.cid);

                    var view = JqGridController.show({
                        data: data,
                        pager: true,
                        filtersName: 'wellTableListFilters-' + assay.id,
                        gridOptions: _.bind(this.gridOptions, this, assay),
                        paginationObject: this.paginationObject
                    }, region);
                    this.listenTo(view, 'click', _.bind(this.onClick, this));
                    this.listenTo(view, 'loadComplete', _.bind(this.onLoadComplete, this));
                }, this));
        },

        onLoadComplete: function (jqGridView, datas) {
            this.setWellListPopover(jqGridView, datas);
        },

        onClick: function (obj) {
            var results = obj.gridView.getCheckedRowsList();
            if (results.length > 0) {
                results = _.pluck(results, 'id');
            }
            var wellModel = this.PcrWellService.getModel({id: obj.rowId});
            require(['pcrWellController'], _.bind(function (PcrWellController) {
                PcrWellController.showDetails({
                    model: wellModel,
                    cSelection: results,
                    callBackOnClose: _.bind(function () {
                        obj.gridView.trigger('reloadGrid');
                    }, this)
                });
            }, this));
        },

        _getSmpType: function (data) {
            var ret = _.chain(data)
                .filter(function (item) {
                    return item.smpType;
                })
                .map(function (item) {
                    return item.smpType + ':' + item.smpType;
                })
                .uniq()
                .value();
            ret.unshift(':');
            return ret.join(';');
        },

        gridOptions: function (assay, data, jqGridView) {
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'id',
                        key: true,
                        hidden: true
                    }, {
                        label: _.i18n('comment'),
                        name: 'wellComment',
                        formatter: JqGridFormatter.defaultFormatter,
                        search: true,
                        sortable: true,
                        index: 'wellComment',
                        fixed: true,
                        width: 70,
                        exportcol: false
                    }, {
                        label: _.i18n('sample.biogroup'),
                        name: 'bioGroupCode',
                        formatter: JqGridFormatter.defaultFormatter,
                        search: true,
                        sortable: true,
                        index: 'bioGroupCode',
                        fixed: true,
                        width: 40,
                        exportcol: false
                    }, {
                        label: JqGridFormatter.getWarningLabelIcon(),
                        name: 'codeErr',
                        formatter: _.bind(this.warningFormater, this),
                        search: true,
                        sortable: true,
                        index: 'codeErr',
                        stype: 'select',
                        searchrules: {select: true},
                        searchoptions: {sopt: ['eq'], value: JqGridValue.check()},
                        fixed: true,
                        width: 30,
                        exportcol: false
                    }, {
                        label: JqGridFormatter.getExpertLabelIcon(),
                        name: 'expert',
                        formatter: _.bind(this.expertFormater, this),
                        search: true,
                        sortable: true,
                        index: 'expert',
                        stype: 'select',
                        searchrules: {select: true},
                        searchoptions: {sopt: ['eq'], value: JqGridValue.check()},
                        fixed: true,
                        width: 30,
                        exportcol: false
                    }, {
                        label: JqGridFormatter.getRepeatLabelIcon(),
                        name: 'repeatStatus',
                        formatter: _.bind(this.repeatStatusFormater, this),
                        search: true,
                        sortable: true,
                        index: 'repeatStatus',
                        stype: 'select',
                        searchrules: {select: true},
                        searchoptions: {sopt: ['eq'], value: JqGridValue.check()},
                        fixed: true,
                        width: 30,
                        exportcol: false
                    }, {
                        label: JqGridFormatter.getSameAssayLabelIcon(),
                        name: 'existOtherWellSameAssay',
                        formatter: _.bind(this.sameAssayFormater, this),
                        search: true,
                        sortable: true,
                        index: 'existOtherWellSameAssay',
                        stype: 'select',
                        searchrules: {select: true},
                        searchoptions: {sopt: ['eq'], value: JqGridValue.check()},
                        fixed: true,
                        width: 40,
                        exportcol: false
                    }, {
                        label: JqGridFormatter.getOtherAssayLabelIcon(),
                        name: 'existOtherWellOtherAssay',
                        formatter: _.bind(this.otherAssayFormater, this),
                        search: true,
                        sortable: true,
                        index: 'existOtherWellOtherAssay',
                        stype: 'select',
                        searchrules: {select: true},
                        searchoptions: {sopt: ['eq'], value: JqGridValue.check()},
                        fixed: true,
                        width: 40,
                        exportcol: false
                    }, {
                        label: JqGridFormatter.getSmpTypeLabelIcon(),
                        name: 'smpType',
                        formatter: _.bind(this.smptypeFormater, this),
                        search: true,
                        sortable: true,
                        index: 'smpType',
                        stype: 'select',
                        searchrules: {select: true},
                        searchoptions: {sopt: ['eq'], value: this._getSmpType(data.data)},
                        fixed: true,
                        width: 30
                    }, {
                        label: _.i18n('well.pos'),
                        name: 'pos',
                        formatter: JqGridFormatter.wellPosFormatter,
                        classes: 'css-grid-cell-accentuatuion-03',
                        sortable: true,
                        title: false,
                        index: 'pos',
                        fixed: true,
                        width: 50,
                        search: true,
                        searchoptions: {
                            sopt: ['cn']
                        }
                    }, {
                        label: _.i18n('well.smpId'),
                        name: 'smpId',
                        formatter: _.bind(this.wellSmpidFormatter, this),
                        classes: 'css-grid-cell-accentuatuion-02 displayPopover',
                        search: true,
                        sortable: true,
                        title: false,
                        index: 'smpId',
                        searchoptions: {
                            sopt: ['cn']
                        },
                        fixed: true,
                        width: 130
                    }, {
                        label: _.i18n('common.empty.placeholder'),
                        name: 'smpId.code',
                        sortable: false,
                        search: false,
                        classes: 'ignore-row-click css-no-leftBorder cell-copyForClipboard',
                        formatter: JqGridFormatter.copyToClipboardFormatter,
                        fixed: true,
                        width: 30,
                        exportcol: false
                    }, {
                        label: _.i18n('well.refAssay'),
                        name: 'assay',
                        formatter: JqGridFormatter.defaultFormatter,
                        classes: 'css-run-listView-accentuation',
                        search: false,
                        sortable: true,
                        title: false,
                        fixed: true,
                        width: 150
                    }
                ],
                sortname: this.sortOn ? this.sortOn : 'row'
            };

            var item = _.first(data.data);
            var pcrWell = require('services/caccounts/pcrwells').getById(item.id);
            if (pcrWell && pcrWell.get('assayVersion')) {
                var extraOptions = jqGridView.columnsForAssayVersion(pcrWell.get('assayVersion'), 'WELL_LIST', 'WellList');
                options.colModel = options.colModel.concat(extraOptions.colModel);
                options.formatters = extraOptions.formatters;
            }
            options.title = this.model.get('code') + ' - ' + _.i18n('runs.details.tabTitle');

            return options;
        },

        warningFormater: function (cellValue, call, object) {
            var model = this.PcrWellService.getModel({id: object.id});
            if (model.get('codeErr') === 'W' || model.get('codeErr') === 'E') {
                var start, end;
                start = '<div class="row m-b-0 m-t-0">';
                start += '<div class="col-xs-12" style="padding-left: 17px;">';
                start += '<span class="mdi mdi-alert" style="font-size: 17px; position: absolute; top: -7px; color: #ff5200;"></span>';
                start += '</div>';
                end = '</div>';
                return start + end;
            }
            return '';
        },
        expertFormater: function (cellValue) {
            if (cellValue) {
                var start, end;
                start = '<div class="row m-b-0 m-t-0">';
                start += '<div class="col-xs-12" style="padding-left: 17px;">';
                start += '<span class="mdi mdi-school" style="font-size: 17px; position: absolute; top: -7px; color: #0014FF;"></span>';
                start += '</div>';
                end = '</div>';
                return start + end;
            }
            return '';
        },

        repeatStatusFormater: function (cellValue) {
            if (cellValue) {
                return '<div style="position: relative;">' +
                    '<span class="mdi mdi-play mdi-flip-h" style="font-size: 21px; top: 0;"></span>' +
                    '<span class="mdi mdi-play mdi-flip-h" style="position: absolute; font-size: 21px; top: 0; left: 6px;"></span>' +
                    '</div>';
            }
            return '';
        },

        sameAssayFormater: function (cellValue, call, object) {
            if (object.existOtherWellSameAssay) {
                return '<div class="existOther" style="position:relative; overflow: initial; height: 20px;  left: 3px">' +
                    '<span class="triangle-same-assay-wellView"></span>' +
                    '</div>';
            }

            return '';
        },

        otherAssayFormater: function (cellValue, call, object) {
            if (object.existOtherWellOtherAssay) {
                return '<div class="existOther" style="position:relative; overflow: initial; height: 20px;  left: 3px">' +
                    '<span class="triangle-other-assay-wellView"></span>' +
                    '</div>';
            }
            return '';
        },

        smptypeFormater: function (cellValue) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12 p-l-15">';

            if (cellValue === 'NC') {
                start += '<span class="mdi mdi-minus-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'PC') {
                start += '<span class="mdi mdi-plus-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'OC') {
                start += '<span class="mdi mdi-compass" style="font-size: 17px;"></span>';
            } else if (cellValue === 'D') {
                start += '<span class="mdi mdi-alpha-d-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'C') {
                start += '<span class="mdi mdi-alpha-c-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'RC') {
                start += '<span class="mdi mdi-alpha-r-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'P') {
                start += '<span class="mdi mdi-google-circles-communities" style="font-size: 17px;"></span>';
            }

            start += '</div>';
            end = '</div>';
            return start + end;
        },

        wellSmpidFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '" style="font-weight: 600;">' + cellValue + '</span>';
        },

        getListViewData: function (filteredWells) {
            var wellListViewData = this.getWellListViewData(filteredWells);
            this.smpTypeList = wellListViewData.smpTypeList;
            return {
                datatype: 'local',
                data: wellListViewData.wellsForData
            };
        },
        getWellListViewData: function (wells) {
            var availableSmpTypes = [];
            var wellsForData = [];
            _.chain(wells)
                .filter(function (well) {
                    return well.get('smpType') !== 'E' && well.get('smpId') && well.get('smpId').get('code') && well.get('refAssay');
                })
                .each(function (well) {
                    if (well.get('smpType')) {
                        availableSmpTypes.push(well.get('smpType'));
                    }
                    var model = {
                        id: well.id,
                        wellRes: null,
                        pos: well.get('pos'),
                        codeErr: !!well.get('codeErr'),
                        expert: well.get('valWst') === 3.1,
                        repeatStatus: !!well.get('repeatStatus'),
                        existOtherWellSameAssay: well.get('existOtherWellSameAssay'),
                        existOtherWellOtherAssay: well.get('existOtherWellOtherAssay'),
                        smpType: well.get('smpType')
                    };
                    model.bioGroupCode = well.get('smpId') && well.get('smpId').get('bioGroup') ? well.get('smpId').get('bioGroup').get('code') : '';
                    model.wellRes = well.getDisplay('OVAR').first();

                    model.wellResSearchValue = model.wellRes;

                    model.smpId = well.get('smpId') ? well.get('smpId').get('code') : '';
                    model.assay = well.get('refAssay') ? well.get('refAssay').get('code') : '';

                    var wellResCode = model.wellRes && model.wellRes.get('result') ? model.wellRes.get('result').get('code') : '';

                    var wellResQuantification = model.wellRes && model.wellRes.get('quantificationFormatted') ?
                        model.wellRes.get('quantificationFormatted') : '';

                    model.wellResSearchValue = wellResCode + wellResQuantification;

                    well.get('results').each(function (target) {
                        model['target-' + target.get('refAssayResult').get('target').get('id')] = target.get('result') ? target.get('result').get('code') : '';
                        model[target.get('refAssayResult').get('target').get('code')] = target.get('result') ? target.get('result').get('code') : '';
                    });

                    model.wellComment = well.get('smpId') ? (well.get('smpId').get('lisComment') ? well.get('smpId').get('lisComment') : '') +
                        ' ' + (well.get('smpId').get('comment') ? well.get('smpId').get('comment') : '') : '';

                    wellsForData.push(model);
                });

            availableSmpTypes = _.uniq(availableSmpTypes);

            return {
                smpTypeList: this.getGridSearchField(availableSmpTypes),
                wellsForData: wellsForData
            };
        },
        getGridSearchField: function (list) {
            var retVal = '';
            _.each(list, function (l) {
                retVal += l + ':' + l + ';';
            });
            if (retVal) {
                retVal = retVal.substring(0, retVal.length - 1);
                retVal = ':' + _.i18n('common.any') + ';' + retVal;
            }
            return retVal;
        },

        setWellListPopover: function (jqGridView, datas) {
            _.each(datas, _.bind(function (data) {
                var well = this.model.get('wells').get(data.id);
                if (!well) {
                    return;
                }
                var popovers = jqGridView.$el.find('tr#' + data.id + ' .displayPopover');
                if (popovers.length < 1) {
                    return;
                }

                var params = {
                    model: well
                };

                params.targets = popovers;

                var placement = 'right';
                var index = _.indexOf(datas, data);
                if (index < 3) {
                    placement = 'bottom';
                } else if (index > datas.length - 4) {
                    placement = 'top';
                }
                params.placement = placement;
                WellUtils.showPcrWellPopover(params);
            }, this));
        }
    });
});

