define('caccountsEditView',[
    'module',
    'dialogFormView',
    'template!caccountsEditView',
    'imageUtils',
    'imageUploadBehavior',
    'savingBehavior',
    'stateMessages',
    'bootbox',
    'underscore',
    'autocompleteView',
    'translationsFilterLanguagesLayoutView',
    'dynamicCreateEditCustomListToList',
    'settings',
    'rolesMixin',
    'privileges'
], function (
    module,
    DialogFormView,
    createEditLayoutTpl,
    imageUtils,
    ImageUploadBehavior,
    SavingBehavior,
    StateMessages,
    bootbox,
    _,
    AutocompleteView,
    FilterLanguageLayoutView,
    DynamicCreateEditCustomListToList,
    Settings,
    rolesMixin,
    privileges
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: createEditLayoutTpl,

        ui: {
            logo: '.js-logo-holder',
            logoDiv: 'div.js-logo-holder',
            imgBlock: 'div.profile-img-block',
            changeLogo: '.js-change-logo',
            removeLogo: '.js-remove-logo',
            input: '.js-info-input',
            endUserIdInput: '.js-end-user-id',
            cancel: '.js-cancel',
            confirm: '.js-confirm'
        },

        events: {
            'click @ui.removeLogo': 'onRemoveLogo',
            'click @ui.cancel': 'onCancel',
            'change @ui.input': 'onInputChange',
            'focus @ui.input': 'onFocus',
            'focus @ui.codeInput': 'onCodeFocus',
            'click @ui.confirm': 'onConfirm'
        },

        regions: {
            accountCountries: '.select-countries',
            languagesRegion: '.languages-region',
            modulesRegion: '.modules-region'
        },

        className: 'profile-create-edit',

        behaviors: {
            StateMessages: {
                behaviorClass: StateMessages
            },
            ImageUpload: {
                behaviorClass: ImageUploadBehavior
            },
            Saving: {
                behaviorClass: SavingBehavior
            }
        },

        modelEvents: {
            sync: 'onSave'
        },

        fieldsToValidate: function () {
            return [
                {name: 'contactEmail', type: 'multipleEmail'},
                {name: 'name', type: 'required'},
                {name: 'code', type: 'required'},
                {name: 'city', type: 'required'},
                {name: 'address', type: 'required'},
                {name: 'zip', type: 'required'}
            ];
        },

        serializeData: function () {
            return this.model.toJSON();
        },

        onRender: function () {
            var countriesView = new AutocompleteView(
                this._getCountriesAutocompleteOptions()
            );
            this.displayLogo(this.model.get('logo'), this.model.get('code'));

            this.listenTo(countriesView, 'focus', this.removeCountriesError);

            this.getRegion('accountCountries').show(countriesView);

            this.options.langs.each(_.bind(function (model) {
                model.set('checked', this.model.get('languages').indexOf(model.get('abbreviation')) !== -1);
            }, this));

            this.getRegion('languagesRegion').show(new FilterLanguageLayoutView({collection: this.options.langs, callBackChange: _.bind(this.onChangeLanguage, this)}));

            this.showChildView('modulesRegion', new DynamicCreateEditCustomListToList({
                'field': 'modules',
                'model': this.model,
                items: {items: this.options.modules},
                display: {
                    readOnly: !Settings.get('currentUserModel').hasRole(rolesMixin.SUPER_USER)
                }
            }));

            this.setPermissions(Settings.get('currentUserModel').hasType(rolesMixin.ACCOUNT, privileges.WRITE) || Settings.get('currentUserModel').hasRole(rolesMixin.SUPER_USER));
            this.triggerMethod('enable:cancel:confirm');
        },

        onChangeLanguage: function (model) {
            this.model.set('languages', model.map(function (model) {
                return model.get('abbreviation');
            }));
            this.triggerMethod('enable:cancel:confirm');
        },

        displayLogo: function (logo, code, remove) {
            var image = imageUtils.getLogo(logo, code);

            if (image) {
                this.ui.logo.removeClass('hidden');
                this.ui.imgBlock.removeClass('hidden');
                this.ui.logo.attr('src', image);
                if (!this.ui.logoDiv.hasClass('hidden')) {
                    this.ui.logoDiv.addClass('hidden');
                }
            } else {
                if (remove) {
                    this.ui.logo.attr('src', '');
                }
                this.ui.logo.attr('src', '').addClass('hidden');
                this.ui.imgBlock.addClass('hidden');
                this.ui.logoDiv.removeClass('hidden');
            }

            if (!this.model.get('logoUid')) {
                this.ui.removeLogo.hide();
            } else {
                this.ui.removeLogo.show();
            }
        },

        onImageUploadConfirm: function (base64Value, fileExt) {
            this.model.set('encodedValue', base64Value);
            this.model.set('extension', fileExt);
            this.ui.logo.removeClass('hidden');
            this.ui.imgBlock.removeClass('hidden');

            if (!base64Value) {
                this.ui.logo.attr('src', imageUtils.getLogo(base64Value, this.model.get('code')));
                this.ui.removeLogo.hide();
                this.ui.logo.attr('src', '').addClass('hidden');
                this.ui.imgBlock.addClass('hidden');
                this.ui.logoDiv.removeClass('hidden');
            } else {
                this.ui.logo.attr('src', 'data:image/png;base64,' + base64Value);
                this.ui.removeLogo.show();
            }

            if (!this.ui.logoDiv.hasClass('hidden') && base64Value) {
                this.ui.logoDiv.addClass('hidden');
            }
        },

        onRemoveLogo: function () {
            bootbox.confirm(_.i18n('common.confirmDelete'), _.bind(function (result) {
                if (result) {
                    this.ui.changeLogo.val('');
                    this.model.set('logoUid', null);
                    this.onImageUploadConfirm(null, null);
                }
            }, this));
        },

        onConfirm: function () {
            if (this.validate(this.fieldsToValidate())) {
                this.model.url = this.model.postUrl();
                this.model.save();
            }
        },

        onSave: function () {
            this.hide();
        },

        onCancel: function () {
            this.model.fetch();
        },

        _getCountriesAutocompleteOptions: function () {
            var countryCode, countryValue, country;

            countryCode = this.model.get('country') || '';
            country = this.options.countries.findWhere({code: countryCode});
            countryValue = country ? country.get('name') : null;

            var CAccountService = require('services/global/caccounts');
            return {
                data: this.options.countries.toJSON(),
                valueKey: 'name',
                apiKey: 'id',
                limit: this.options.countries.length,
                placeholder: _.i18n('common.country'),
                value: countryValue,
                name: 'country',
                callback: _.bind(this.onChangeCountry, this),
                service: CAccountService
            };
        },

        onChangeCountry: function (fieldName, model) {
            var code = model ? model.get('code') : '';

            this.onChange(fieldName, code);
            this.triggerMethod('enable:cancel:confirm');
        }
    });
});

