
define('template!adminEntityErrorView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="header">\n    <div class="header-left">\n    </div>\n    <div class="header-center">\n        <h1>' +
((__t = ( _.i18n('menu.admin.entityError') )) == null ? '' : __t) +
'</h1>\n    </div>\n    <div class="header-right">\n    </div>\n</div>\n<div class="js-grid d-f col-w-100" style="overflow: auto;"></div>\n';
 if(!readOnly) { ;
__p += '\n<div class="cancelConfirmRow">\n    <button class="mdi-navigation-check btn popupBtn-confirm js-confirm"></button>\n</div>\n';
 } ;


}
return __p
};});

