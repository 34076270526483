
define('template!kitPcrLotCustomJqGridView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(superUser) { ;
__p += '\n<div class="js-maintenance-assayReagent clickable">\n    <span class="mdi mdi-restart action header" title="MAINTENANCE - Assay Reagent MbAna PcrKitLot">\n    </span>\n</div>\n';
 } ;


}
return __p
};});

