define('jqGridColumnItemView',[
    'module',
    'dialogFormView',
    'underscore'
], function (
    module,
    DialogFormView,
    _
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: _.template('<label><input type="checkbox" class="js-info-input" data-field-name="visible"><%= name %><span class="mdi mdi-key"></span></label>'),
        tagName: 'li',
        ui: {
            input: '.js-info-input',
            key: '.mdi-key'
        },
        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },
        className: 'column-item',
        serializeData: function () {
            return {
                name: this.model.get('name')
            };
        },
        onRender: function () {
            if (this.model.get('visible')) {
                this.ui.input.attr('checked', 'checked');
            }
            if (!this.model.get('key')) {
                this.ui.key.hide();
            }
        }
    });
});
