define('entities/caccounts/prepruntracs',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.PrepRunTrac = Backbone.RelationalModel.extend({
        service: 'services/caccounts/prepruntracs',
        fetch: function () {
            this.url = Settings.url('rest/v2/runtracs/prep/' + this.get('id'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'creatDate': null,
            'refUserpid': null,
            'action': '',
            'level': '',
            'comment': '',
            'wells': null,
            'refRun': null
        },
        idAttribute: 'id',
        relations: [{
            type: Backbone.HasOne,
            key: 'refUserpid',
            relatedModel: 'UserPid'
        }, {
            type: Backbone.HasOne,
            key: 'refRun',
            relatedModel: 'PcrRun'
        }],

        postUrl: function () {
            return Settings.url('rest/v2/runtracs/prep/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('PrepRunTracJson.pdf');
        }
    });

    app.PrepRunTrac.role = RolesMixin.RUN_PREP;

    app.PrepRunTracCollection = Backbone.Collection.extend({
        model: app.PrepRunTrac
    });
});

