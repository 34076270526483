define('servicesAdmin',[
'module',
    'services/admin/codelist',
    'services/admin/array',
    'services/admin/dye',
    'services/admin/translations',
    'services/admin/arrayelement',
    'services/admin/codelistelement',
    'services/admin/cyclers',
    'services/admin/detectionFormat'
], function (module) {
'use strict';
module.exports = {
    getServices: function() {
        var ret = [];
    ret.push(require('services/admin/codelist'));
    ret.push(require('services/admin/array'));
    ret.push(require('services/admin/dye'));
    ret.push(require('services/admin/translations'));
    ret.push(require('services/admin/arrayelement'));
    ret.push(require('services/admin/codelistelement'));
    ret.push(require('services/admin/cyclers'));
    ret.push(require('services/admin/detectionFormat'));
        return ret;
    }
};
});
