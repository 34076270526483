define('pcrWellResultIconView',[
    'module',
    'backbone.marionette',
    'underscore',
    'template!pcrWellResultIconView'
], function (
    module,
    Marionette,
    _,
    Tpl
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        modelEvents: {
            'change': 'render'
        },
        attributes: function () {
            var title = '';
            if (this.model.get('refAssayResult') && this.model.get('refAssayResult').get('target')) {
                title = this.model.get('refAssayResult').get('target').get('code') + ' - ' + (this.model.get('result') ? this.model.get('result').get('code') : '');
                if (this.options.displayCt && !_.isEmpty(this.model.get('cts'))) {
                    title += ' - ' + this.model.get('cts').join(', ');
                    if (this.options.displayQuantification && !_.isEmpty(this.model.get('quantifications'))) {
                        title += ' (' + this.model.get('quantifications').join(', ') + ')';
                    }
                }
            }
            return {
                title: title,
                'data-code': this.model.get('refAssayResult') && this.model.get('refAssayResult').get('target') && this.model.get('refAssayResult').get('target').get('code'),
                'data-id': this.model.get('refAssayResult') && this.model.get('refAssayResult').get('target') && this.model.get('refAssayResult').get('target').id
            };
        },

        className: 'd-f',

        serializeData: function () {
            var mdi = 'mdi-circle';
            if (this.model.get('pcrWell').get('smpType') === 'U' && !this.model.get('internalControl')) {
                mdi = this.model.get('containInLisAnaSample') ? 'mdi-circle' : 'mdi-circle-outline';
            }
            return {
                isResult: this.model.get('result') && this.model.get('result').get('code') ? 'border: grey solid 1px;' : '',
                color: this.model.get('result') ? this.model.get('result').get('color') + ' !important' : 'transparent',
                resCodeErr: this.model.get('codeErr'),
                resCommentErrAuto: this.model.get('commentErrAuto'),
                valSt: this.model.get('valSt'),
                repeatSt: this.model.get('pcrWell') && this.model.get('pcrWell').get('repeatStatus'),
                ic: this.model.get('internalControl'),
                displayResult: this.options.config.displayResult,
                displayCt: this.options.config.displayCt,
                displayQuantification: this.options.config.displayQuantification,
                cts: this.model.get('cts'),
                quantifications: this.model.get('quantifications'),
                result: this.model.get('result') ? this.model.get('result').get('code') : '',
                mdi: mdi
            };
        }
    });
});

