
define('template!kitLotsPcrTargetView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div style="display: grid; grid-template-columns: 1fr 80px 1fr;">\n    <div class="js-current-lot rounded" style="padding: 10px 10px 10px;"></div>\n    <div class="d-f column" style="align-items: center; justify-content: flex-start;">\n        <span class="mdi mdi-filter clickable js-filter" style="font-size: 40px; line-height: 40px; margin-bottom: 80px;"></span>\n        <span class="mdi mdi-transfer-left clickable js-left invisible" style="font-size: 80px; line-height: 80px;"></span>\n    </div>\n    <div class="js-other-lot rounded invisible" style="padding: 10px 10px 10px 10px;"></div>\n</div>\n\n<div class="cancelConfirmRow">\n    <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n    <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n</div>';

}
return __p
};});

