/**
 * Created by OLD on 07/08/2015.
 */
define('dynamicListItemAutocomplete',[
    'module',
    'underscore',
    'dynamicListItemPropertyView',
    'autocompleteView'
], function (
    module,
    _,
    ItemView,
    AutocompleteView
) {
    'use strict';

    module.exports = ItemView.extend({
        template: _.template('<div class="autocomplete"></div>'),
        tagName: 'div',
        regions: {
            autocomplete: '.autocomplete'
        },

        onChangeCurrentModel: function (fieldName, model) {
            if (model) {
                model.unset('autocompleteValue', {silent: true});
            }
            this.model.set(model.toJSON());
        },
        onRender: function () {
            ItemView.prototype.onRender.apply(this, arguments);
            var relation = this.model.getRelation(this.options.field.get('field'));
            var config = this.options.field.get('param').config;
            if (!config && relation) {
                config = new relation.relatedModel().getEntity();
                throw new Error('No config found for field ' + this.options.field.get('field') + ' in ' + this.model.get('entity') + ' model');
            }
            if (config.getAutocompleteParam) {
                var autocompleteParam;
                if (_.isFunction(config.getAutocompleteParam)) {
                    autocompleteParam = config.getAutocompleteParam({
                            modelProperty: this.options.field.get('field'),
                            callBackOnChange: this.options.field.get('param').self ? _.bind(this.onChangeCurrentModel, this) : _.bind(this.onChangeAutoComplete, this, this.options.field),
                            model: this.model,
                            readOnly: this.options.field.get('param').display ? this.options.field.get('param').display.readOnly : false
                        },
                        this.options.field.get('param').context,
                        this.options.field.get('dependsOn'));
                } else {
                    autocompleteParam = config.getAutocompleteParam;
                }
                this.getRegion('autocomplete').show(new AutocompleteView(this._getAutocompleteOptionObject(autocompleteParam)));
            } else {
                this.getRegion('autocomplete').empty();
            }
        },

        onChangeAutoComplete: function (field, fieldName, model) {
            if (field && field.get && field.get('param') && field.get('param').onChange) {
                field.get('param').onChange(fieldName, model, this.model);
            } else {
                if (model) {
                    model.unset('autocompleteValue', {silent: true});
                    if (!_.isEqual(_.sortBy(this.model.get(fieldName)), _.sortBy(model.toJSON()))) {
                        this.onChange(fieldName, model.toJSON());
                    }
                } else {
                    this.onChange(fieldName, null);
                }
            }
        }
    });
});
