
define('template!kitLotsPcrTargetFilterView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="d-f column gap-10" style="align-items: center;">\n    <label class="col-xs-6 control-label t-a-l">' +
((__t = ( _.i18n('lot.assay') )) == null ? '' : __t) +
'</label>\n    <div class="col-xs-6 js-assay"></div>\n    <br/>\n    <label class="col-xs-6 control-label t-a-l">' +
((__t = ( _.i18n('lot.reagent') )) == null ? '' : __t) +
'</label>\n    <div class="col-xs-6 js-reagent"></div>\n</div>\n\n<div class="cancelConfirmRow">\n    <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n    <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n</div>';

}
return __p
};});

