
define('template!prepRunDisplayKitsLotsView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="row col-xs-12 m-b-10">\n    <div class="col-xs-1 noWrapHidden">\n        <div style="border: solid 1px black; min-height: 22px; text-align: center; width: 45px;">' +
((__t = ( pos ? pos : ' ' )) == null ? '' : __t) +
'\n        </div>\n    </div>\n    <div class="col-xs-4 noWrapHidden">\n        <div class="p-l-5" style="border: solid 1px black; min-height: 22px;">' +
((__t = ( smpId.name )) == null ? '' : __t) +
'</div>\n    </div>\n</div>\n\n<div class="js-extractionKitLot"></div>\n\n<div class="js-kitLotByKits"></div>\n\n\n<!-- cancel/confirm row -->\n<div class="cancelConfirmRow">\n    <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n    <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n</div>\n';

}
return __p
};});

