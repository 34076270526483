
define('template!pcrSetupAssayItem', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<input type="radio" name="well" id="assay-' +
((__t = ( assayId )) == null ? '' : __t) +
'" value="' +
((__t = ( assayId )) == null ? '' : __t) +
'" class="js-info-input" data-field-name="assay"/>\n<label for="assay-' +
((__t = ( assayId )) == null ? '' : __t) +
'">' +
((__t = ( code )) == null ? '' : __t) +
'</label>\n<div>' +
((__t = ( wellSize )) == null ? '' : __t) +
'</div>\n';

}
return __p
};});

