define('runPcrPlateLayoutForCyclerExportView',[
    'module',
    'backbone',
    'underscore',
    'jquery',
    'dialogFormView',
    'template!runPcrPlateLayoutForCyclerExportView',
    'commonCancelExportView',
    'customBootboxMessage',
    'bootbox',
    'autocompleteView'
], function (
    module,
    Backbone,
    _,
    $,
    DialogFormView,
    Tpl,
    CommonCancelExportView,
    CustomBootboxMessage,
    Bootbox,
    AutocompleteView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input'
        },

        regions: {
            cancelExportRegion: '.js-cancel-export-region',
            selectCycler: '.js-select-cycler-region'
        },
        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },
        modelEvents: {
            'change:cycler': 'onValidateChange'
        },

        fieldsToValidate: function () {
            return [
                {name: 'cycler', type: 'required'}
            ];
        },

        initialize: function () {
            this.model = new Backbone.Model({cycler: this.options.model.get('refCyclerPublic')});
        },

        onValidateChange: function () {
            this.commonCancelExportView.enableButtons(this.validate(this.fieldsToValidate()));
        },

        onRender: function () {
            this.commonCancelExportView = new CommonCancelExportView({
                settingImport: this.options.settingImport
            });
            this.listenTo(this.commonCancelExportView, 'localExport:click', _.bind(this.onLocalExport, this));
            this.listenTo(this.commonCancelExportView, 'remoteExport:click', _.bind(this.onRemoteExport, this));
            this.listenTo(this.commonCancelExportView, 'cancel:click', _.bind(this.onCancel, this));
            this.getRegion('cancelExportRegion').show(this.commonCancelExportView);
            this.commonCancelExportView.enableButtons(false);

            var CyclerService = require('services/caccounts/cyclers');
            var view = new AutocompleteView(
                this._getAutocompleteOptionObject(CyclerService.getAutocompleteParamForPublic({
                    modelProperty: 'cycler',
                    readOnly: true
                }))
            );
            this.getRegion('selectCycler').show(view);
            this.commonCancelExportView.enableButtons(this.validate(this.fieldsToValidate()));
        },

        onLocalExport: function () {
            this.model.set('downloadFile', true);
            this._export();
        },
        onRemoteExport: function () {
            this.model.set('downloadFile', false);
            this._export();
        },

        _export: function () {
            this.options.model.exportFilePlateLayout(this.model)
                .done(function (display) {
                    if (display) {
                        CustomBootboxMessage.customThumbUpNotification();
                    }
                })
                .fail(function () {
                    CustomBootboxMessage.customThumbDownNotification();
                })
                .always(_.bind(function () {
                    this.hide();
                }, this));
        }
    });
});
