define('runContentGridCollectionView',[
    'module',
    'backbone',
    'underscore',
    'backbone.marionette',
    'runContentGridItemView',
    'runContentGridItemHeaderView',
    'colorUtils',
    'entities/ns'
], function (
    module,
    Backbone,
    _,
    Marionette,
    ItemView,
    ItemHeaderView,
    ColorUtils,
    app
) {
    'use strict';

    module.exports = Marionette.CollectionView.extend({
        className: 'grid-wells',
        attributes: function () {
            var column = Number.parseInt(this.model.get('plateSize').substring(1));
            var line = this.model.get('plateSize').substring(0, 1).charCodeAt(0) - 64;
            return {
                'style': 'flex-grow: 1;display: grid; grid-template-columns: 30px repeat(' + column + ', calc((100% - 30px)/ ' + column + ')); grid-template-rows: 30px repeat(' + line + ', 80px);'
            };
        },
        childView: function (model) {
            if (model.get('type') === 'header') {
                return ItemHeaderView;
            } else {
                return ItemView;
            }
        },
        initialize: function () {
            if (this.options.catchEventSync) {
                this.listenTo(this.options.collection, 'sync', this.options.catchEventSync);
            }
            this.collection = new Backbone.Collection();
            this.collection.comparator = 'pos';
            this.options.collection.each(_.bind(function (model) {
                this.collection.add(model);
            }, this));
            var plateSize = this.model.get('plateSize');
            this.column = Number.parseInt(plateSize.substring(1));
            var row = plateSize.substring(0, 1).charCodeAt(0);
            this.collection.push(new Backbone.Model({pos: '000', type: 'header', name: ''}));
            for (var i = 1; i <= this.column; i++) {
                this.collection.push(new Backbone.Model({
                    pos: '0' + String(i).padStart(2, '0'),
                    type: 'header',
                    name: String(i).padStart(2, '0')
                }));
            }
            for (var j = 65; j <= row; j++) {
                this.collection.push(new Backbone.Model({
                    pos: String.fromCharCode(j) + '00',
                    type: 'header',
                    name: String.fromCharCode(j)
                }));
            }
            this.collection.sort();
        },
        _render: function () {
            this.render();
        },
        childViewOptions: function () {
            return {
                view: this.options.view
            };
        },
        onRender: function () {
            var colorsAssay = ['20', '60', '#fff302', '#d06767', '#00FF00', '#285085', '#0012ff'];
            var SettingService = require('services/caccounts/setting');
            var settingColorsAssay = SettingService.findByCodeAsync('COLORS-ASSAY');
            if (settingColorsAssay && settingColorsAssay.get('params')) {
                colorsAssay = settingColorsAssay.get('params').split(';');
            }
            var index = 2;
            var assaysWaitingList = '';
            var cellOpacity = colorsAssay[0];
            var assayOpacity = colorsAssay[1];

            var colorsByWells = [];

            if (this.options.model instanceof app.PrepRun) {
                this.options.collection.each(_.bind(function (model) {
                    var backgroundColor = '#efefef';
                    var wellAssaysWaitingList = model.getWellAssaysWaitingList().join(';');
                    if (colorsAssay.length > 0 && wellAssaysWaitingList) {
                        if (assaysWaitingList !== wellAssaysWaitingList) {
                            assaysWaitingList = wellAssaysWaitingList;
                            index++;
                        }
                        if (index + 1 > colorsAssay.length) {
                            index = 2;
                        }
                    } else {
                        assaysWaitingList = '';
                    }
                    backgroundColor = ColorUtils.defineAlpha(colorsAssay[index], cellOpacity);
                    colorsByWells.push({
                        model: model,
                        backgroundColor: ColorUtils.rgbaString(ColorUtils.hexToRgba(backgroundColor))
                    });
                }, this));
            }

            var assayIndex = 2;
            var colorAssay;
            if (this.options.model instanceof app.PcrRun) {
                var assays = _.chain(this.options.model.get('wells').getAssays()).uniq().value();
                this.options.collection.each(function (model) {
                    _.each(assays, function (value, key) {
                        if (value && model.get('refAssay') && value === model.get('refAssay')) {
                            assayIndex = key + 1;
                        }
                    });
                    var backgroundColor;
                    var backgroundColorAssay;
                    if (!!(model.get('refAssay'))) {
                        var i = (assayIndex % (colorsAssay.length - 2));

                        if (i === 0) {
                            i = colorsAssay.length - 2;
                        }
                        colorAssay = colorsAssay[i + 1];
                        backgroundColor = ColorUtils.defineAlpha(colorAssay, cellOpacity);
                        backgroundColorAssay = ColorUtils.defineAlpha(colorAssay, assayOpacity);
                    } else {
                        backgroundColor = '#efefefff';
                        backgroundColorAssay = '#efefefff';
                    }
                    colorsByWells.push({
                        model: model,
                        backgroundColor: ColorUtils.rgbaString(ColorUtils.hexToRgba(backgroundColor)),
                        assayOpacity: ColorUtils.rgbaString(ColorUtils.hexToRgba(backgroundColorAssay))
                    });
                });
            } else if (this.options.model instanceof app.PrepRun) {
                var previous = null;
                this.options.collection.each(function (model) {
                    if (model.getWellAssaysWaitingList().length === 0) {
                        return;
                    }
                    if (!_.isEqual(previous, model.getWellAssaysWaitingList())) {
                        assayIndex++;
                    }
                    previous = model.getWellAssaysWaitingList();
                    var i = (assayIndex % (colorsAssay.length - 2));

                    if (i === 0) {
                        i = colorsAssay.length - 2;
                    }
                    colorAssay = colorsAssay[i + 1];
                    colorsByWells.push({
                        model: model,
                        backgroundColor: ColorUtils.rgbaString(ColorUtils.hexToRgba(ColorUtils.defineAlpha(colorAssay, cellOpacity)))
                    });
                });
            }
            this.children.each(function (child) {
                var model = _.find(colorsByWells, function (colorByWell) {
                    return colorByWell.model === child.model;
                });
                if (child.view && child.view.setBackgroundColor) {
                    if (model) {
                        child.view.setBackgroundColor(model.backgroundColor, model.assayOpacity);
                    } else {
                        child.view.setBackgroundColor('rgb(255, 255, 255,0)', 'rgba(255, 255, 255, 0)');
                    }
                }
            });
        },
        childViewEvents: {
            'item:click': '_itemClick'
        },
        _itemClick: function (view) {
            var func = this.options.events && this.options.events.click;
            if (func) {
                var childrenViews = this.children.filter(function (child) {
                    return child instanceof ItemView && child.isSelected();
                });
                // to keep the original order
                var wells = this.options.collection.filter(function (model) {
                    return _.find(childrenViews, function (child) {
                        return child.model === model;
                    });
                });
                func({model: view.model, wells: wells});
            }
        }
    });
});

