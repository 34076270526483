
define('template!runPrepHeaderDetailsView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div style="display:flex; flex-direction: column; justify-content: space-around;">\n    <div class="buttonCircle">\n        <span class="clickable hidden-print  btn-edit-preprun mdi-content-create"\n              title="' +
((__t = ( _.i18n('run.prep.edit.popover') )) == null ? '' : __t) +
'"\n              style="\n                top: 0;\n                position: relative;\n                font-size: 14px;\n                right: -2px;">\n        </span>\n    </div>\n    <div class="buttonCircle">\n        <span class="clickable hidden-print btn-show-kits mdi mdi-flask-outline"\n              title="' +
((__t = ( _.i18n('prepRun.extr.kits') )) == null ? '' : __t) +
'"\n              style="\n                top: -2px;\n                position: relative;\n                font-size: 14px;\n                right: -2px;">\n        </span>\n    </div>\n    <div class="buttonCircle">\n        <span class="clickable hidden-print btn-show-attachments mdi mdi-flip-v mdi-rotate-270 mdi-attachment"\n              title="' +
((__t = ( _.i18n('attachment') )) == null ? '' : __t) +
'"\n              style="top: -1px;\n                position: relative;\n                font-size: 16px;\n                line-height: 19px;">\n        </span>\n    </div>\n</div>\n<div style="display: flex; flex-direction: column; justify-content: space-between; flex: 1;">\n    <div class="noWrapHidden pop-mainHeaderRunBox" id="to-clipboard" data-name="' +
((__t = ( code )) == null ? '' : __t) +
'">\n        <div class="js-runName-div-container noWrapHidden"\n             style="font-size: 19px;\n                    height: 38px;\n                    border: solid 1px #333;\n                    border-radius: 5px;\n                    padding-top: 4px;\n                    padding-left: 6px;">\n            ' +
((__t = ( code )) == null ? '' : __t) +
'\n        </div>\n    </div>\n    <div class="js-pcrruns"></div>\n</div>\n<div style="display: flex; flex-direction: column; justify-content: space-between;">\n    <div style="display: flex; justify-content: space-between;">\n        <div class="buttonCircle">\n                                    <span class="clickable hidden-print js-copyParameters mdi-content-content-copy"\n                                          data-copy="' +
((__t = ( code )) == null ? '' : __t) +
'"\n                                          style="\n                                            top: 0;\n                                            position: relative;\n                                            font-size: 14px;\n                                            right: -2px;">\n                                    </span>\n        </div>\n\n        <div class="buttonCircle">\n                                     <span class="clickable hidden-print js-run-refresh mdi-navigation-refresh"\n                                           title="' +
((__t = ( _.i18n('refresh.run') )) == null ? '' : __t) +
'"\n                                           style="\n                                             top: 0;\n                                             position: relative;\n                                             font-size: 18px;\n                                             right: 0;">\n                                    </span>\n        </div>\n    </div>\n    <div style="height: 17px; margin-top: 8px;">\n        <div>\n            <span class="jqgrid-statusNb" style="font-size: 12px;">' +
((__t = ( status )) == null ? '' : __t) +
'</span>\n            <div class="cell-default js-status-tooltip"\n                 style="display: flex; position: relative; top: 3px;">\n                ';
 for (var i = 1; i <= 5; i++) { ;
__p += '\n                <div class="jqgrid-Header-statusIcon' +
((__t = ( i )) == null ? '' : __t) +
' ' +
((__t = ( i === status || i < status || status === 5 ? 'statusIcon-upTo' : '')) == null ? '' : __t) +
'"></div>\n                ';
 } ;
__p += '\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

