
define('template!exportCustomJqGridView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="js-retry clickable">\n    <span class="mdi mdi-restart action header" title="' +
((__t = ( _.i18n('lisrecord.retry') )) == null ? '' : __t) +
'"></span>\n</div>';

}
return __p
};});

