
define('template!assayVersionLotItem', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-xs-1 p-r-2 p-l-5">' +
((__t = ( reagentType )) == null ? '' : __t) +
'</div>\n<div class="col-xs-1 p-r-2 p-l-2">' +
((__t = ( reagentCode )) == null ? '' : __t) +
'</div>\n<div class="col-xs-3 p-r-2 p-l-2 js-kit"></div>\n<div class="col-xs-3 p-r-2 p-l-2 js-lot"></div>\n<div class="col-xs-1 p-r-2 p-l-2">' +
((__t = ( volumeByTest )) == null ? '' : __t) +
'</div>\n<div class="col-xs-1 p-r-2 p-l-2">' +
((__t = ( volumeDeath )) == null ? '' : __t) +
'</div>\n<div class="col-xs-1 p-r-5 p-l-2"></div>';

}
return __p
};});

