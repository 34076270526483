
define('template!pcrSetupPrepRunItem', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="headerRun">\n    <h3>' +
((__t = ( name )) == null ? '' : __t) +
'</h3>\n</div>\n<div class="subHeader">\n    <div title="' +
((__t = ( pcrProtocolCodes )) == null ? '' : __t) +
'">' +
((__t = ( pcrProtocolCodes )) == null ? '' : __t) +
'</div>\n</div>\n<hr>\n<div class="js-wells">\n\n</div>';

}
return __p
};});

