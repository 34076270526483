define('entities/caccounts/routingaction_routinggroup',[
    'entities/ns',
    'backbone',
    'underscore',
    'settings',
    'jquery',
    'rolesMixin',
    'entities/caccounts/routinggroup',
    'entities/caccounts/routingaction'
], function (
    app,
    Backbone,
    _,
    Settings,
    $,
    RolesMixin
) {
    'use strict';

    app.RoutingActionRoutingGroup = Backbone.RelationalModel.extend({
        service: 'services/caccounts/routingaction_routinggroup',
        defaults: {
            'sequence': null,
            'position': null,
            'refRoutingGroup': null,
            'color': null,
            'rackId': null,
            'condition': null
        },
        idAttribute: 'id',
        relations: [{
            type: Backbone.HasOne,
            key: 'refRoutingGroup',
            relatedModel: 'RoutingGroup',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'routingAction',
            relatedModel: 'RoutingAction',
            includeInJSON: ['id', 'code', 'name']
        }],

        findSamples: function () {
            var url = Settings.url('rest/v2/routingaction/' + this.get('routingAction').get('id') + '/routingGroup/' + this.get('refRoutingGroup').get('id') + '/samples');
            var defer = $.Deferred();
            $.ajax({
                url: url,
                type: 'GET',
                dataType: 'json',
                contentType: 'application/json',
                success: _.bind(function (data) {
                    require(['entities/caccounts/samples'], function (Samples) {
                        defer.resolve(Samples.getCollection(data));
                    });
                }, this)
            });
            return defer.promise();
        }
    });

    app.RoutingActionRoutingGroup.role = RolesMixin.ROUTINGACTION;

    app.RoutingActionRoutingGroupCollection = Backbone.Collection.extend({
        model: app.RoutingActionRoutingGroup,
        comparator: 'sequence'
    });

});

