define('entities/caccounts/sampleResultDefinitions',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'entities/caccounts/mbanas'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.SampleResultDefinition = Backbone.RelationalModel.extend({
        service: 'services/caccounts/sampleResultDefinitions',
        fetch: function () {
            this.url = Settings.url('rest/v2/sampleResultDefinitions/' + this.get('id'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'sequence': '',
            'target': {},
            'tags': [],
            'algoResult': {type: 'NO', param: ''},
            'algoExport': {type: 'NO', param: ''}
        },
        idAttribute: 'id',
        relations: [{
            type: Backbone.HasOne,
            key: 'target',
            relatedModel: 'MbAna',
            includeInJSON: ['id', 'code', 'name']
        }],

        postUrl: function () {
            return Settings.url('rest/v2/sampleResultDefinitions/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'SampleResultDefinitionJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'SampleResultDefinition',

        importable: true
    });

    app.SampleResultDefinition.role = RolesMixin.SPO_OVAR;

    app.SampleResultDefinitionCollection = Backbone.Collection.extend({
        model: app.SampleResultDefinition,
        comparator: 'sequence'
    });
});

