define('pcrSetup',[
    'module',
    'underscore',
    'jquery',
    'dialogFormView',
    'template!pcrSetup',
    'pcrSetupAssayCollection',
    'pcrSetupPrepRunCollection',
    'pcrSetupPcrRunCollectionView'
], function (
    module,
    _,
    $,
    DialogFormView,
    Tpl,
    AssayCollectionView,
    PrepRunCollectionView,
    PcrRunCollectionView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        className: 'content pcrSetup height-100',

        ui: {
            input: '.js-info-input',
            protocol: '#protocol',
            filter: '.js-filter',
            historyUndo: '.js-undo',
            historyRestore: '.js-redo',
            bulk: '.js-bulk',
            transfer: '.js-transfer'
        },

        regions: {
            prepRuns: '.js-prepRuns',
            pcrRuns: '.js-pcrRuns',
            assays: '.js-assay'
        },

        events: {
            'change @ui.input': 'onInputChange',
            'change @ui.protocol': 'onChangeProtocol',
            'click .js-undo.clickable': 'onHistoryUndoClick',
            'click .js-redo.clickable': 'onHistoryRestoreClick',
            'click @ui.bulk': 'onBulkClick',
            'click @ui.transfer': 'onTransferClick'
        },

        modelEvents: {
            'change:history': 'onHistoryChange',
            'change:currentProtocol': 'onModelChangeProtocol'
        },

        initialize: function () {
            this.filter = {
                protocol: null,
                assay: null
            };
        },

        _filter: function () {
            var loader = $('.js-global-loader');
            loader.show();
            this.model.generateColor();
            this.prepRunCollectionView.applyFilter();
            this.pcrRunCollectionView.applyFilter();
            loader.hide();
        },

        onModelChangeProtocol: function () {
            this.ui.protocol.val(this.model.get('currentProtocol') ? this.model.get('currentProtocol').id : '');
        },
        onChangeProtocol: function (e) {
            var value = e.currentTarget.value;
            this.ui.protocol.val(value);
            var protocol = this.model.get('protocols').get(value);
            if (protocol) {
                this.model.set('currentProtocol', protocol);
            } else {
                this.model.set({currentProtocol: null, currentAssay: null});
            }
        },

        serializeData: function () {
            var templateData = {};
            templateData.protocols = this.model.get('protocols').toJSON();
            return templateData;
        },

        onRender: function () {
            this.assayCollectionView = new AssayCollectionView({model: this.model, collection: this.model.get('wellsByAssay')});
            this.showChildView('assays', this.assayCollectionView);
            this.prepRunCollectionView = new PrepRunCollectionView({
                collection: this.model.get('prepRuns'),
                model: this.model
            });
            this.getRegion('prepRuns').show(this.prepRunCollectionView);
            this.listenTo(this.prepRunCollectionView, 'enableButtons', _.bind(function (enable) {
                if (enable) {
                    this.ui.bulk.removeClass('disabled');
                    this.ui.transfer.removeClass('disabled');
                } else {
                    this.ui.bulk.addClass('disabled');
                    this.ui.transfer.addClass('disabled');
                }
            }, this));
            this.pcrRunCollectionView = new PcrRunCollectionView({
                collection: this.model.get('pcrRuns'),
                model: this.model
            });
            this.getRegion('pcrRuns').show(this.pcrRunCollectionView);
            this.pcrRunCollectionView.listenTo(this.pcrRunCollectionView, 'validateSelection', _.bind(function () {
                this.model.get('prepWellSelected').each(function (well) {
                    well.unset('selected');
                    if (!['PC', 'NC', 'RC', 'OC'].includes(well.get('sampleType'))) {
                        well.unset('available');
                    }
                });
                this.model.get('prepWellSelected').reset();
                this.model.set({'qualityControl': null}, {silent: true});
                this.model.set({'mode': null}, {silent: true});
            }, this));
        },

        onBulkClick: function () {
            this.ui.bulk.addClass('selected');
            this.ui.bulk.removeClass('clickable');
            this.ui.transfer.removeClass('selected');
            this.ui.bulk.addClass('clickable');
            this.model.set({'transfer': 'bulk'}, {silent: true});
        },
        onTransferClick: function () {
            this.ui.bulk.removeClass('selected');
            this.ui.bulk.addClass('clickable');
            this.ui.transfer.addClass('selected');
            this.ui.transfer.removeClass('clickable');
            this.model.set({'transfer': 'transfer'}, {silent: true});
        },

        hide: function () {
            this.box.modal('hide');
        },

        onHistoryChange: function () {
            if (_.isEmpty(this.model.get('history'))) {
                this.ui.historyUndo.removeClass('clickable');
                this.ui.historyRestore.removeClass('clickable');
                this.ui.historyUndo.addClass('disabled');
                this.ui.historyRestore.addClass('disabled');
            } else {
                this.ui.historyUndo.addClass('clickable');
                this.ui.historyRestore.addClass('clickable');
                this.ui.historyUndo.removeClass('disabled');
                this.ui.historyRestore.removeClass('disabled');
                if (this.model.get('historyIndex') === -1) {
                    this.ui.historyUndo.addClass('disabled');
                    this.ui.historyUndo.removeClass('clickable');
                } else if (this.model.get('historyIndex') === _.size(this.model.get('history')) - 1) {
                    this.ui.historyRestore.addClass('disabled');
                    this.ui.historyRestore.removeClass('clickable');
                }
            }
        },
        onHistoryUndoClick: function () {
            this.model.undo(this.filter.assay);
        },
        onHistoryRestoreClick: function () {
            this.model.restore(this.filter.assay);
        }
    });
});

