
define('template!splitLisAnaView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row"\n	 style="display:flex; align-items: center; justify-content: center; flex-direction: row;column-gap: 10px;">\n	<div class="col-w-45">\n		<label for="lisAnaOriginSample">' +
((__t = ( _.i18n('preProcess.split.sample.originLisAna') )) == null ? '' : __t) +
'</label>\n		<select multiple="multiple" id="lisAnaOriginSample" style="width: 100%;" size="10">\n			';
 _.each(lisAnas, function(lisAna) { ;
__p += '\n			<option value="' +
((__t = ( lisAna.id )) == null ? '' : __t) +
'">' +
((__t = ( lisAna.code )) == null ? '' : __t) +
'</option>\n			';
 }) ;
__p += '\n		</select>\n	</div>\n	<div class="col-w-all p-0"\n		 style="display:flex; align-items: center; justify-content: center; flex-direction: column;">\n		<span class="mdi mdi-transfer-right clickable invisible" style="font-size: 80px; line-height: 80px;"></span>\n		<span class="mdi mdi-transfer-left clickable invisible" style="font-size: 80px; line-height: 80px;"></span>\n	</div>\n	<div class="col-w-45">\n		<label for="lisAnaNewSample">' +
((__t = ( _.i18n('preProcess.split.sample.newLisAna') )) == null ? '' : __t) +
'</label>\n		<select multiple="multiple" id="lisAnaNewSample" style="width: 100%;" size="10">\n		</select>\n	</div>\n</div>\n<div class="cancelConfirmRow">\n    <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n    <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n</div>\n';

}
return __p
};});

