define('dynamicCreateEditCustomListToList',[
    'module',
    'formView',
    'underscore',
    'jquery',
    'backbone',
    'dynamicListToList'
], function (
    module,
    FormView,
    _,
    $,
    Backbone,
    DynamicListToList
) {
    'use strict';

    module.exports = FormView.extend({
        template: _.template('<%= values %>'),

        events: {
            'click': 'onShowListToList'
        },
        tagName: 'textarea',
        className: 'listToList form-control inputBackground clickable',
        attributes: function () {
            return {
                'data-field-name': this.options.field,
                'placeholder': this.options.display && this.options.display.placeHolder ? this.options.display.placeHolder : _.i18n('common.empty.placeholder'),
                'style': 'resize: vertical;' + (this.options.display && this.options.display.style ? this.options.display.style : ''),
                'rows': this.options.display && this.options.display.rows ? this.options.display.rows : 1,
                'disabled': this.options.display && this.options.display.disabled ? this.options.display.disabled : false,
                readonly: true
            };
        },

        initialize: function (options) {
            this.separator = options.separator || ';';
        },
        serializeData: function () {
            var templateData = {};
            if (this.model.get(this.options.field) instanceof Backbone.Collection) {
                templateData.values = this.model.get(this.options.field).map(function (model) {
                    return model.get('code');
                }).join(', ');
            } else if (_.isArray(this.model.get(this.options.field))) {
                templateData.values = this.model.get(this.options.field).map(function (model) {
                    if (model instanceof Backbone.Model) {
                        return model.get('code');
                    } else {
                        return model.code ? model.code : model;
                    }
                }).join(', ');
            } else {
                templateData.values = this.model.get(this.options.field) ? this.model.get(this.options.field) : '';
            }
            return templateData;
        },

        onRender: function () {
            this._loadItems(this.options.field).done(_.bind(function (response) {
                this.response = response;
                var values = this.model.get(this.options.field);
                if (values instanceof Backbone.Collection) {
                    values = values.map(function (model) {
                        return model.get('code');
                    });
                } else if (_.isArray(values)) {
                    values = _.map(values, function (model) {
                        if (model instanceof Backbone.Model) {
                            return model.get('code');
                        } else {
                            return model.code ? model.code : model;
                        }
                    });
                } else {
                    values = values ? values.split(this.separator) : [];
                }
                this.$el.attr('title', values.join(this.options.separator));
            }, this));
        },

        onShowListToList: function (e) {
            var fieldName = e.currentTarget.attributes['data-field-name'].value;
            var name = this.model.getEntity ? this.model.getEntity().getName() : 'TEST';
            var view = new DynamicListToList({
                'field': fieldName,
                'values': this.model.get(fieldName),
                'items': this.response,
                'entityName': name,
                'codeListCode': this.response.codeListCode,
                'separator': this.options.separator,
                'display': this.options.display,
                sortable: this.options.sortable
            });
            this.listenTo(view, 'listToList:confirm', _.bind(this.listToListConfirm, this, fieldName));
            view.show({
                title: _.i18n(name + '.' + fieldName),
                className: 'baseTableEditAddPopup'
            });
        },
        _loadItems: function (fieldName) {
            var defer = $.Deferred();
            if (this.options.items) {
                if (this.options.items instanceof Backbone.Collection) {
                    return defer.resolve(this.options.items.models);
                }
                if (this.options.items.items instanceof Backbone.Collection) {
                    return defer.resolve(this.options.items.items.models);
                }
                if (this.options.items.items) {
                    return defer.resolve(this.options.items.items);
                }
                return defer.resolve(this.options.items);

            } else {
                if (!this.model.service) {
                    throw new Error('Service not defined for ' + this.model);
                }
                require(this.model.service).getList(fieldName, this.model).done(_.bind(function (items) {
                    if (items && items.items && items.items instanceof Backbone.Collection) {
                        defer.resolve(items.items.models);
                        return;
                    }
                    defer.resolve(items.items);
                }, this));
            }
            return defer.promise();
        },
        listToListConfirm: function (fieldName, itemsSelected) {
            if (this.options.changeCallBack) {
                this.options.changeCallBack(fieldName, itemsSelected);
            } else {
                if (itemsSelected instanceof Backbone.Collection) {
                    itemsSelected = itemsSelected.models;
                }
                itemsSelected = _.map(itemsSelected, function (item) {
                    if (item instanceof Backbone.Model) {
                        return item.toJSON();
                    }
                    return item;
                });

                if (this.model.get(fieldName) instanceof Backbone.Collection) {
                    this.model.get(fieldName).reset();
                    _.each(itemsSelected, _.bind(function (item) {
                        this.model.get(fieldName).add(item);
                    }, this));
                    this.model.trigger('change:' + fieldName);
                } else {
                    itemsSelected = _.map(itemsSelected, function (item) {
                        if (item instanceof Backbone.Model) {
                            if (item.get('id')) {
                                return item.toJSON();
                            }
                            item = item.toJSON();
                        }
                        if (_.isString(item)) {
                            return item;
                        }
                        if (item.id) {
                            return item;
                        }
                        if (item.code) {
                            return item.code;
                        }
                    });
                    this.onChange(fieldName, itemsSelected);
                }
            }
            if (!this.isDestroyed()) {
                this.render();
            }
        }
    });
});

