define('entities/caccounts/pcrSetup/pcrSetup',[
    'entities/ns',
    'backbone',
    'jquery',
    'underscore',
    'colorUtils',
    'settings',
    'services/caccounts/setting',
    'services/caccounts/samples',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/pcrSetup/prepRunLite',
    'entities/caccounts/pcrSetup/prepWellLite',
    'entities/caccounts/pcrSetup/pcrRunLite'
], function (
    app,
    Backbone,
    $,
    _,
    ColorUtils,
    Settings,
    SettingService,
    SampleService,
    RolesMixin
) {
    'use strict';

    app.PcrSetup = Backbone.RelationalModel.extend({
        service: 'services/caccounts/pcrSetup/pcrSetup',
        defaults: {
            'prepRuns': [],
            'pcrRuns': [],
            'prepWellSelected': [],
            'pcrWellSelected': [],
            'qualityControl': null,
            'mode': null,
            'assays': [],
            'transfer': 'grid',
            'history': [],
            'currentProtocol': null,
            'currentAssay': null
        },
        relations: [{
            type: Backbone.HasMany,
            key: 'prepRuns',
            relatedModel: 'PrepRunLite',
            collectionType: 'PrepRunLiteCollection',
            reverseRelation: {
                key: 'refPcrSetup',
                includeInJSON: false
            }
        }, {
            type: Backbone.HasMany,
            key: 'pcrRuns',
            relatedModel: 'PcrRunLite',
            collectionType: 'PcrRunLiteCollection',
            reverseRelation: {
                key: 'refPcrSetup',
                includeInJSON: false
            }
        }, {
            type: Backbone.HasMany,
            key: 'prepWellSelected',
            relatedModel: 'PcrWellLite',
            collectionType: 'PcrWellLiteCollection'
        }, {
            type: Backbone.HasMany,
            key: 'assays',
            relatedModel: 'Assay',
            collectionType: 'AssayCollection',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasMany,
            key: 'protocols',
            relatedModel: 'KitProt',
            collectionType: 'KitProtCollection',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'currentAssay',
            relatedModel: 'Assay',
            includeInJSON: ['id', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'currentProtocol',
            relatedModel: 'KitProt',
            includeInJSON: ['id', 'code', 'name']
        }],

        url: function () {
            return Settings.url('rest/v2/pcrSetup/find');
        },

        initialize: function () {
            this.listenTo(this, 'change:currentAssay', _.bind(function () {
                this.generateColor();
            }, this));
            this.listenTo(this, 'change:currentProtocol', _.bind(function () {
                this.generateColor();
            }, this));
            this.set('wellsByAssay', new Backbone.Collection());
            this.get('wellsByAssay').comparator = function (m1, m2) {
                return m2.get('wells').length - m1.get('wells').length;
            };
        },

        generateWellHeader: function () {
            this.get('prepRuns').each(function (prepRun) {
                prepRun.generateWellHeader();
            });
            this.get('pcrRuns').each(function (pcrRun) {
                pcrRun.generateWellHeader();
            });
        },
        generateColor: function () {
            this.get('prepRuns').generateColor(this.get('currentAssay'));
            this.get('pcrRuns').generateColor(this.get('currentAssay'));
        },
        linkPrepWellToPcrWell: function (prepWell, pcrWell, assay) {
            if (pcrWell.get('refPrepWell')) {
                pcrWell.get('refPrepWell').get('waiting').push(pcrWell.get('assay'));
                pcrWell.get('refPrepWell').get('pcrWells').remove(pcrWell);
                pcrWell.get('refPrepWell').unset('selected', {silent: true});
                pcrWell.get('refPrepWell').trigger('change');
            }
            if (!['PC', 'RC', 'OC', 'NC'].includes(prepWell.get('sampleType'))) {
                prepWell.get('waiting').remove(assay);
            }
            prepWell.get('pcrWells').push(pcrWell);
            pcrWell.set({
                sampleType: prepWell.get('sampleType'),
                assay: assay,
                sample: prepWell.get('sample'),
                refPrepWell: prepWell
            });
            pcrWell.added = true;
            prepWell.trigger('change');
            if (pcrWell.get('refRun').get('pcrProtocol')) {
                var containOneAssay = pcrWell.get('refRun').get('wells').any(function (well) {
                    return well.get('assay');
                });
                if (containOneAssay === null) {
                    pcrWell.get('refRun').set('pcrProtocol', null);
                }
            }
        },
        defineQualityControl: function (wellQualityControl, pcrWell) {
            if (pcrWell.get('refPrepWell')) {
                pcrWell.get('refPrepWell').get('waiting').push(pcrWell.get('assay'));
                pcrWell.get('refPrepWell').get('pcrWells').remove(pcrWell);
                pcrWell.get('refPrepWell').unset('selected', {silent: true});
                pcrWell.get('refPrepWell').trigger('change');
            }
            pcrWell.set({
                sampleType: wellQualityControl.get('sampleType'),
                sample: SampleService.getModel({
                    code: wellQualityControl.get('sampleType'),
                    name: wellQualityControl.get('sampleType')
                }),
                assay: wellQualityControl.get('assay'),
                refPrepWell: null
            });
        },
        reset: function () {
            var setting = SettingService.findByCodeAsync('COLORS-ASSAY');
            var colors = setting && setting.get('params') ? setting.get('params').split(';') : [];
            this.set('colors', []);
            if (!_.isEmpty(colors)) {
                if (colors.length > 2) {
                    this.get('colors')[0] = ColorUtils.defineAlpha(colors[2], colors[1]);
                }
                if (colors.length > 3) {
                    this.get('colors')[1] = ColorUtils.defineAlpha(colors[3], colors[1]);
                }
                if (colors.length > 4) {
                    this.get('colors')[2] = ColorUtils.defineAlpha(colors[4], colors[1]);
                }
                if (colors.length > 5) {
                    this.get('colors')[3] = ColorUtils.defineAlpha(colors[5], colors[1]);
                }
                if (colors.length > 6) {
                    this.get('colors')[4] = ColorUtils.defineAlpha(colors[6], colors[1]);
                }
                if (colors.length > 7) {
                    this.get('colors')[5] = ColorUtils.defineAlpha(colors[7], colors[1]);
                }
                this.generateColor();
                this.wellByAssays();
            }
        },
        wellByAssays: function () {
            this.get('wellsByAssay').each(function (wellsByAssay) {
                wellsByAssay.get('wells').reset();
            });
            // group well from prepRun by assay and add it to "wellByAssays" attribute
            this.get('prepRuns').each(_.bind(function (prepRun) {
                prepRun.get('wells').each(_.bind(function (well) {
                    well.get('waiting').each(_.bind(function (assay) {
                        if (!this.get('wellsByAssay').any(function (wellsByAssay) {
                            return wellsByAssay.get('assay') === assay;
                        })) {
                            this.get('wellsByAssay').push(new Backbone.Model({
                                assay: assay,
                                wells: new Backbone.Collection()
                            }));
                        }
                        this.get('wellsByAssay').find(function (wellsByAssay) {
                            return wellsByAssay.get('assay') === assay;
                        }).get('wells').push(well);
                    }, this));
                }, this));
            }, this));
            this.get('wellsByAssay').sort();
            this.get('wellsByAssay').each(function (wellsByAssay) {
                wellsByAssay.trigger('change');
            });
        },
        filter: function (filter) {
            this.get('assays').clear();
            this.get('prepRuns').each(_.bind(function (prepRun) {
                if (prepRun.canDisplay(filter)) {
                    prepRun.get('wells').each(_.bind(function (well) {
                        well.get('waiting').each(_.bind(function (assay) {
                            this.get('assays').push(assay);
                        }, this));
                    }, this));
                }
            }, this));
        },
        addHistory: function (pcrWells) {
            var history = this.get('history').splice(0, this.get('historyIndex') + 1);
            var items = _.map(pcrWells, function (pcrWell) {
                return {
                    prepWell: pcrWell.get('refPrepWell'),
                    pcrWell: pcrWell,
                    assay: pcrWell.get('assay')
                };
            });
            history.push(items);
            this.set({history: history}, {silent: true});
            this.set({historyIndex: this.get('history').length - 1});
            this.wellByAssays();
            this.trigger('change:history');
        },
        undo: function (assay) {
            if (this.get('historyIndex') >= 0) {
                var items = this.get('history').at(this.get('historyIndex'));
                _.each(items, function (item) {
                    if (item.prepWell) {
                        item.prepWell.get('pcrWells').remove(item.pcrWell);
                        item.prepWell.get('waiting').push(item.pcrWell.get('assay'));
                        item.prepWell.unset('selected', {silent: true});
                        item.prepWell.trigger('change');
                    }
                    item.pcrWellUndo = {
                        sampleType: item.pcrWell.get('sampleType'),
                        sample: item.pcrWell.get('sample'),
                        assay: item.pcrWell.get('assay'),
                        refPrepWell: item.pcrWell.get('refPrepWell')
                    };
                    item.pcrWell.set({
                        sampleType: item.pcrWell.get('previous').sampleType,
                        sample: item.pcrWell.get('previous').sample,
                        assay: item.pcrWell.get('previous').assay,
                        refPrepWell: item.pcrWell.get('previous').refPrepWell
                    }, {silent: true});
                    if (item.pcrWell.get('refPrepWell')) {
                        item.pcrWell.get('refPrepWell').push(item.pcrWell);
                    }
                    item.pcrWell.trigger('change');
                    delete item.pcrWell.added;
                });
                this.set('historyIndex', this.get('historyIndex') - 1);
                this.generateColor(assay);
                this.wellByAssays();
                this.trigger('change:history');
            }
        },
        restore: function (assay) {
            if (this.get('historyIndex') < _.size(this.get('history'))) {
                var items = this.get('history').at(this.get('historyIndex') + 1);
                _.each(items, function (item) {
                    if (item.prepWell) {
                        item.prepWell.get('pcrWells').push(item.pcrWell);
                        item.prepWell.get('waiting').remove(item.assay);
                        item.prepWell.trigger('change');
                    }
                    item.pcrWell.set(item.pcrWellUndo);
                    item.pcrWell.added = true;
                });
                this.set('historyIndex', this.get('historyIndex') + 1);
                this.generateColor(assay);
                this.wellByAssays();
                this.trigger('change:history');
            }
        },
        removePcrRun: function (pcrRun) {
            this.get('pcrRuns').remove(pcrRun);
            var itemToDeletes = this.get('history').filter(function (item) {
                return item[0].pcrWell.get('refRun') === pcrRun;
            });
            var itemDeletedBeforeIndex = itemToDeletes.reduce(_.bind(function (count, item) {
                var index = this.get('history').indexOf(item);
                return count + (index < this.get('historyIndex') ? 1 : 0);
            }, this), 0);
            this.set('historyIndex', this.get('historyIndex') - itemDeletedBeforeIndex);
            this.set('history', _.difference(this.get('history'), itemToDeletes));
            this.trigger('change:history');
        }
    });

    app.PcrSetup.role = RolesMixin.PCR_SETUP;
});

