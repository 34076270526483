define('entities/caccounts/extractionMethod',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.ExtractionMethod = Backbone.RelationalModel.extend({
        service: 'services/caccounts/extractionMethod',
        fetch: function () {
            this.url = Settings.url('rest/v2/extractionMethods/' + this.get('id'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            code: '',
            description: '',
            comment: '',
            tags: [],
            manual: false,
            reagents: [],
            id: null
        },
        idAttribute: 'id',
        relations: [{
            type: Backbone.HasMany,
            key: 'reagents',
            relatedModel: 'ExtractionMethodReagent',
            collectionType: 'ExtractionMethodReagentCollection'
        }, {
            type: Backbone.HasMany,
            key: 'refExtractors',
            relatedModel: 'Extractor',
            collectionType: 'ExtractorCollection',
            includeInJSON: ['id', 'code', 'name']
        }],

        postUrl: function () {
            return Settings.url('rest/v2/extractionMethods/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'ExtractionMethodJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'ExtractionMethod',

        importable: true
    });

    app.ExtractionMethod.role = RolesMixin.EXTRACTIONMETHOD;

    app.ExtractionMethodCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/extractionMethod/');
        },
        model: app.ExtractionMethod
    });
});

