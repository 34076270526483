define('entities/caccounts/files',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.File = Backbone.RelationalModel.extend({
        service: 'services/caccounts/files',
        defaults: {
            'code': '',
            'name': '',
            'description': ''
        },
        idAttribute: 'id',

        jsonObjectName: 'FileJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'File',

        importable: true,
        creatable: false
    });
    
    app.FileCollection = Backbone.Collection.extend({
        model: app.File
    });

    app.File.role = RolesMixin.FILE_EXPLORER;
});

