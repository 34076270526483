define('entities/admin/translations',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.TranslationItem = Backbone.RelationalModel.extend({
        service: 'services/admin/translations',
        defaults: {
            code: '',
            text: {
                localized: {}
            },
            app: 'dashboard'
        },

        url: Settings.url('rest/public/texts/'),

        save: function () {
            var that = this;
            Backbone.Model.prototype.save.apply(this, [{}, {
                success: function () {
                    that.trigger('saved');
                }
            }]);
        },

        idAttribute: 'id'
    });

    app.TranslationItem.role = RolesMixin.SUPER_USER;

    app.TranslationCollection = Backbone.Collection.extend({
        model: app.TranslationItem,
        filters: {
            pageIndex: 0,
            rows: 15
        },

        url: function () {
            return Settings.url('rest/public/texts/', this.filters);
        }
    });

});

