
define('template!samplePrepWellView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="col-w-3 noWrapHidden t-a-l clickable headerRunActionButton js-show-sample-prepWell"\n     style="border-radius: 20px; height: 27px; width: 27px; position: relative; bottom: 1px;"\n     data-id="' +
((__t = ( id )) == null ? '' : __t) +
'">\n    <div class="headerRunActionButtonIcon mdi-action-visibility"\n         style="font-size: 22px; position: relative; left: 3px; top: 4px;">\n    </div>\n</div>\n<span class="col-w-18 noWrapHidden p-r-15 js-tooltip-prepwell js-preprun"\n      style="text-align: left; padding-top: 4px;">\n</span>\n<div class="col-w-3 noWrapHidden p-r-10 js-tooltip-prepwell"\n     style="text-align: left; padding-top: 4px;">\n    ' +
((__t = ( pos )) == null ? '' : __t) +
'\n</div>\n<div class="col-w-2 noWrapHidden p-r-10 css-assayWaiting-cell-bg "\n     style="text-align: left;">\n    ' +
((__t = ( assayWaiting.length > 0 ? assayIcons.waiting : '' )) == null ? '' : __t) +
'\n</div>\n<div class="col-w-1"></div>\n<div class="col-w-2 noWrapHidden p-r-10 css-assayRunning-cell-bg"\n     style="text-align: left;">\n    ' +
((__t = ( assayRunning.length > 0 ? assayIcons.running : '' )) == null ? '' : __t) +
'\n</div>\n<div class="col-w-1"></div>\n<div class="col-w-2 noWrapHidden p-r-10 css-assayDone-cell-bg"\n     style="text-align: left;">\n    ' +
((__t = ( assayDone.length > 0 ? assayIcons.done : '' )) == null ? '' : __t) +
'\n</div>\n<div class="col-w-1"></div>\n<div class="col-w-69 js-prepwell-pcrwell-region">\n</div>';

}
return __p
};});

