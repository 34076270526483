define('routingCommonSampleDetailView',[
        'app',
        'module',
        'dialogFormView',
        'template!routingCommonSampleDetailView',
        'jquery',
        'underscore'
    ], function (
        App,
        module,
        DialogFormView,
        Tpl,
        $,
        _
    ) {
        'use strict';

        module.exports = DialogFormView.extend({
            template: Tpl,

            ui: {
                showSample: '.js-show-sample'
            },

            events: {
                'click span.js-show-sample.clickable': 'onSampleClick'
            },

            className: function () {
                return 'rounded row ' + this.options.i18nCode;
            },
            attributes: {
                style: 'border-color: black;'
            },

            onSampleClick: function () {
                require(['samplesController'], _.bind(function (Controller) {
                    Controller.showDetails({service: require(this.model.service), model: this.model});
                }, this));
            },

            serializeData: function () {
                return {
                    sampleId: this.model.get('code'),
                    lisCode: this.model.get('lisCode'),
                    orderCode: this.model.get('order') ? this.model.get('order').get('code') : null,
                    bioGroupCode: this.model.get('bioGroup') ? this.model.get('bioGroup').get('code') : null,
                    specieCode: this.model.get('specie') ? this.model.get('specie').get('code') : null,
                    patient: this.model.get('patient'),
                    lisAnas: this.model.get('lisAnas') ? this.model.get('lisAnas').map(function (lisAna) {
                        return lisAna.get('code');
                    }) : [],
                    targets: this.model.get('lisAnas') ? this.model.get('targets').map(function (target) {
                        return target.get('code');
                    }) : [],
                    assayGroupCode: this.model.get('assayGroups') ? this.model.get('assayGroups').map(function (assayGroup) {
                        return assayGroup.get('code');
                    }) : []
                };
            },
            onRender: function () {
                if (this.model.get('id')) {
                    this.ui.showSample.show();
                } else {
                    this.ui.showSample.hide();
                }
            }
        });
    }
);

